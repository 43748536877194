import { initialize } from 'redux-form/immutable';
import { replace } from 'connected-react-router/immutable';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import {
  APP_ACTIONS,
  FileCategory,
  MEASUREMENT_PREFERENCES_FORM,
  MODAL,
  NOTIFICATION_VARIANTS,
  SystemPermission,
  UPLOAD_DOCUMENT_FORM,
  USER_PREFERENCES_FORM,
} from 'app/app.constants';

import {
  getCurrentLocationFromState,
  getCurrentThemeFromState,
  getCurrentUserFromState,
  getPreviousLocationFromState,
} from 'app/app.selectors';

import {
  deleteMsalFromLocalStorage,
  getCurrentThemeOrDefault,
  makeActionCreator,
  setCurrentTheme,
} from 'utils/app.util';

import mappers from 'mappers';
import routePaths from 'app/routePaths';
import config from 'infrastructure/config';
import service from 'services/app.service';
import appService from 'services/app.service';
import authSingleton from 'services/auth.service';
import { toLogin, toRoot } from 'utils/route.util';
import appInsights from 'infrastructure/appInsights';

export const setSubPage = (page) => (dispatch) =>
  dispatch({ type: APP_ACTIONS.PROJECT_SET_SUBPAGE, page });

export const toggleModal =
  ({ modalId, force = false, activeIndex, payload, ...extra }) =>
  (dispatch) => {
    dispatch({
      type: APP_ACTIONS.TOGGLE_MODAL,
      modalId,
      force,
      payload,
      activeIndex,
      ...extra,
    });
    return payload;
  };

export const setGlobalLocation = (path) => ({
  type: 'SET_GLOBAL_LOCATION',
  payload: path,
});

export const saveRedirectPath = (currentPath) => (dispatch) => {
  const action = {
    type: 'SAVE_REDIRECT_PATH',
    payload: currentPath,
  };
  dispatch(action);
};

export const onLoad = () => (dispatch, getState) => {
  dispatch({ type: APP_ACTIONS.APP_LOAD, skipTracking: true });

  const hasWellAccessOnly = getCurrentUserFromState(getState())
    ?.get('permissions')
    .includes(SystemPermission.WELL_ACCESS_ONLY);

  const redirect = getCurrentUserFromState(getState())?.get('id')
    ? hasWellAccessOnly
      ? routePaths.wells.root
      : routePaths.root
    : config.loginUrl;
  if (redirect) {
    dispatch(replace(redirect));
  }
};

export const getCurrentUser = () => (dispatch) => {
  const payload = service.getCurrentUser();

  dispatch({
    type: APP_ACTIONS.GET_CURRENT_USER,
    payload,
  });

  return payload;
};

export const fetchPrivacyPolicy = () => (dispatch) => {
  const payload = service.getPrivacyPolicy();

  dispatch({
    type: APP_ACTIONS.INITIATE_FETCH_PRIVACY_POLICY,
    payload,
  });

  return payload;
};

export const fetchChangeLog = () => (dispatch) => {
  dispatch({
    type: APP_ACTIONS.INITIATE_FETCH_CHANGE_LOG,
  });
};

export const storeChangeLog = (text) => ({
  type: APP_ACTIONS.STORE_CHANGE_LOG,
  payload: text,
});

export const newAppVersionNotificationAction = {
  type: APP_ACTIONS.NEW_APP_VERSION_NOTIFICATION,
};
export const newAppVersionNotification = () => (dispatch) => {
  dispatch(newAppVersionNotificationAction);
};

export const getMainVendorUsers = () => (dispatch) => {
  const payload = service.getMainVendorUsers();

  dispatch({
    type: APP_ACTIONS.GET_MAIN_VENDOR_USERS,
    payload,
  });

  return payload;
};

export const onUnauthorized = () => (dispatch) =>
  dispatch(replace(routePaths.login));

export const loadOrganizations = makeActionCreator({
  type: APP_ACTIONS.GET_ALL_ORGANIZATIONS,
  payload: service.getAllOrganizations,
});

export const autoLogin = () => (dispatch, getState) => {
  const payload = Promise.all([
    dispatch(getCurrentUser()),
    dispatch(getUserPreferences()),
  ])
    .then((user) => {
      dispatch(
        setTheme(
          getCurrentThemeOrDefault(
            user.userId,
            getState().getIn(['common', 'theme']),
          ),
        ),
      );
    })
    .then(() =>
      Promise.all([
        dispatch(getCurrentUser()),
        dispatch(getUserPreferences()),
        dispatch(loadOrganizations()),
      ]),
    )
    .then((params) => {
      const previousLocation = getPreviousLocationFromState(getState());
      const currentLocation = getCurrentLocationFromState(getState());

      const [{ permissions }] = params;
      const hasWellAccessOnly = permissions.some((permission) =>
        Object.values(permission).includes(SystemPermission.WELL_ACCESS_ONLY),
      );

      if (currentLocation === routePaths.login) {
        if (hasWellAccessOnly) {
          dispatch(replace(routePaths.wells.root));
        } else if (previousLocation) {
          dispatch(replace(previousLocation));
        } else {
          dispatch(replace(toRoot()));
        }
      } else if (hasWellAccessOnly) {
        dispatch(replace(routePaths.wells.root));
      }
    })
    .catch(() => {
      dispatch(replace(toLogin()));
    });

  dispatch({
    type: APP_ACTIONS.LOGIN,
    payload,
  });

  return payload;
};

export const logOut = () => (dispatch) => {
  const payload = authSingleton
    .getMsalInstance()
    .then((msalInstance) => msalInstance.logout())
    .then(() => deleteMsalFromLocalStorage());

  dispatch({ type: APP_ACTIONS.LOGOUT });

  return payload;
};

export const setApplicationFailure =
  (error, componentStack = '') =>
  (dispatch) => {
    appInsights?.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: componentStack,
    });

    dispatch({
      payload: error,
      type: APP_ACTIONS.SET_APPLICATION_FAILURE,
    });
  };

export const markProjectAsSeen = (projectId) => (dispatch) =>
  dispatch({
    type: APP_ACTIONS.PROJECT_MARK_AS_SEEN,
    payload: () => service.markAsSeen(projectId),
  });

export const removeNotification = () => (dispatch) => {
  dispatch({ type: APP_ACTIONS.REMOVE_NOTIFICATION });
};

export const getAllServices = () => (dispatch) => {
  const payload = service.getAllServices();

  dispatch({
    type: APP_ACTIONS.GET_ALL_SERVICES,
    payload,
  });
  return payload;
};

export const getAllDepartments = () => (dispatch) => {
  const payload = service.getAllDepartments();

  dispatch({
    type: APP_ACTIONS.GET_ALL_DEPARTMENTS,
    payload,
  });
  return payload;
};

export const getAllFileCategories = () => (dispatch) => {
  const payload = service.getAllFileCategories();
  dispatch({
    type: APP_ACTIONS.GET_ALL_FILE_CATEGORIES,
    payload,
  });
  return payload;
};

export const setHeaderValues = (values) => (dispatch) =>
  dispatch({ type: APP_ACTIONS.SET_HEADER_VALUES, ...values });

export const setFile =
  (files, category = FileCategory.OTHER) =>
  (dispatch) => {
    if (files) {
      dispatch(
        initialize(UPLOAD_DOCUMENT_FORM.ID, {
          [UPLOAD_DOCUMENT_FORM.FILES]: files.map((file) => ({
            [UPLOAD_DOCUMENT_FORM.FILE]: file,
            [UPLOAD_DOCUMENT_FORM.META]: mappers.Document.Meta.initial(
              file,
              category,
            ),
          })),
        }),
      );
    }
    dispatch(toggleModal({ modalId: MODAL.UPLOAD_FILE }));
  };

export const initializeMeasurementPreferences =
  (system, preferences) => (dispatch) =>
    dispatch(
      initialize(
        MEASUREMENT_PREFERENCES_FORM.ID,
        preferences.set('system', system),
      ),
    );

export const initializeUserPreferences =
  (system, units, preferences) => (dispatch, getState) => {
    dispatch(
      initialize(USER_PREFERENCES_FORM.ID, {
        units: {
          system,
          ...units,
        },
        general: {
          ...preferences,
          theme: getCurrentThemeFromState(getState()),
        },
      }),
    );
  };

export const updateUserPreferences =
  (userPreferences) => (dispatch, getState) => {
    const { units, general } = userPreferences.toJS();
    const { system } = units;

    const currentUser = getCurrentUserFromState(getState()).toJS();

    const payload = Promise.all([
      service.updateUserMeasurementPreference(units),
      service.updateUser({
        ...currentUser,
        unit: system,
      }),
      service.updateUserPreference(general),
    ]).then((response) => {
      dispatch(
        toggleModal({
          modalId: MODAL.EDIT_USER_PREFERENCES,
          payload: response,
        }),
      );

      window.location.reload();

      return response;
    });

    dispatch({
      type: APP_ACTIONS.UPDATE_USER_MEASUREMENT_PREFERENCES,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Preferences successfully updated',
      },
      payload,
    });

    return payload;
  };

export const getUserPreferences = () => (dispatch) => {
  const payload = service.getUserPreferences();

  dispatch({
    type: APP_ACTIONS.GET_USER_PREFERENCES,
    payload,
  });

  return payload;
};

export const setTheme = (theme) => (dispatch, getState) => {
  const currentUser = getCurrentUserFromState(getState());

  setCurrentTheme(currentUser.get('id'), theme);
  dispatch({
    theme,
    type: APP_ACTIONS.SET_THEME,
  });
};

export const getAppFeatures = makeActionCreator({
  type: APP_ACTIONS.GET_APPLICATION_FEATURES,
  payload: appService.getAppFeatures,
});

export const setCurrentClientOrganization = (currentOrganizationId) => ({
  type: APP_ACTIONS.SET_CURRENT_CLIENT_ORGANIZATION,
  currentOrganizationId,
});
