import React from 'react';
import { SwipeableDrawer as Drawer, Grid } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from 'app/components/withTooltip';
import withStyles from '@material-ui/styles/withStyles';
import MobileToolstringPreview from 'features/mobile/projects/execute/taskToolstring/MobileToolstringPreview';

const StyledBox = styled('div')(() => ({
  backgroundColor: '#2A2A2A',
  minHeight: '100%',
  minWidth: '90%',
  overflowY: 'auto',
}));

const MobileToolImageDrawer = ({
  classes,
  toolstringSections,
  open,
  toggleOpen,
}) => {
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => toggleOpen(false)}
      disableSwipeToOpen={true}
      variant="temporary"
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        square: false,
        style: {
          overflow: 'auto',
          backgroundColor: 'transparent',
          borderRadius: '25px 0 0 25px',
          minHeight: '100%',
          maxWidth: '90%',
        },
      }}
    >
      <StyledBox>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.closeIcon}>
            <IconButton
              title="Close"
              color="inherit"
              onClick={() => toggleOpen(false)}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container item xs={12} className={classes.toolstringContainer}>
            <MobileToolstringPreview toolstringSections={toolstringSections} />
          </Grid>
        </Grid>
      </StyledBox>
    </Drawer>
  );
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'fixed',
    right: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    backgroundColor: '#2A2A2A',
  },
  toolstringPreview: {
    height: '100%',
  },
  toolstringContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
});

export default withStyles(styles)(MobileToolImageDrawer);
