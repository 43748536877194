import { Map } from 'immutable';

import mappers, { measurementPreferenceToDto } from 'mappers';
import { PROJECT_STATUS as ProjectStatus } from 'app/app.constants';

export const projectStatusToString = (status) =>
  ({
    [ProjectStatus.PLAN]: 'Plan',
    [ProjectStatus.EXECUTE]: 'Execute',
    [ProjectStatus.REPORT]: 'Report',
  }[status]);

export const toFacilityFromDto = ({ id, name }) =>
  Map({
    id: id,
    name: name,
  });

export const toProjectStatusFromDto = ({ id, name }) =>
  Map({
    id: id,
    name: name,
  });

export const projectToDTO = (project) => {
  const {
    id,
    wellboreId,
    fieldId,
    description,
    facilityId,
    estimatedStart,
    estimatedEnd,
    referenceNumber,
    departments,
    status,
    unit,
    organizationId,
    externalId,
    externalName,
    displayReferenceNumber,
    background,
    objective,
    internalInformation,
  } = project;
  return {
    fieldId,
    facilityId,
    wellboreId,
    projectId: id,
    referenceNumber: referenceNumber,
    description: description,
    departments: departments.map(mappers.Department.to),
    estimatedStart: Object.values(estimatedStart).includes('Invalid date')
      ? '--'
      : Object.values(estimatedStart).join('-'),
    estimatedEnd: Object.values(estimatedEnd).join('-'),
    status: status,
    measurementSystem: measurementPreferenceToDto(unit),
    organizationId,
    externalId,
    externalName,
    displayReferenceNumber,
    background,
    objective,
    internalInformation,
  };
};

const projectsMappers = {
  toFacilityFromDto,
  toProjectStatusFromDto,
};

export default projectsMappers;
