import Agent from 'infrastructure/agent';
import wellboreSectionMappers from 'features/wells/sections/wellboreSection.mappers';

const getProjectWellboreSection = (projectId, wellboreSectionId) =>
  Agent.get(`/project/${projectId}/wellbore/section/${wellboreSectionId}`);

const getProjectWellboreSections = (projectId) =>
  Agent.get(`/project/${projectId}/wellbore/section`);

const getDefaultProjectWellboreSection = (projectId) =>
  Agent.get(`/project/${projectId}/wellbore/section/default`);

const createProjectWellboreSection = (projectId, wellboreSection) =>
  Agent.post(
    `/project/${projectId}/wellbore/section`,
    wellboreSectionMappers.WellboreSection.to(wellboreSection),
  );
  
const syncProjectWellboreSectionsWithLatest = (projectId) =>
Agent.post(`/project/${projectId}/wellbore/section/sync`);

const updateProjectWellboreSection = (
  projectId,
  wellboreSectionId,
  wellboreSection,
) =>
  Agent.put(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}`,
    wellboreSectionMappers.WellboreSection.to(wellboreSection),
  );

const deleteProjectWellboreSection = (projectId, wellboreSectionId) =>
  Agent.delete(`/project/${projectId}/wellbore/section/${wellboreSectionId}`);

const createProjectWellboreSectionNipple = (
  projectId,
  wellboreSectionId,
  wellboreSectionNipple,
) =>
  Agent.post(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}/nipple`,
    wellboreSectionMappers.WellboreSectionNipple.to(wellboreSectionNipple),
  );

const updateProjectWellboreSectionNipple = (
  projectId,
  wellboreSectionId,
  wellboreSectionNippleId,
  wellboreSectionNipple,
) =>
  Agent.put(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}/nipple/${wellboreSectionNippleId}`,
    wellboreSectionMappers.WellboreSectionNipple.to(wellboreSectionNipple),
  );

const deleteProjectWellboreSectionNipple = (
  projectId,
  wellboreSectionId,
  wellboreSectionNippleId,
) =>
  Agent.delete(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}/nipple/${wellboreSectionNippleId}`,
  );

const projectWellboreSectionService = {
  getProjectWellboreSection,
  getProjectWellboreSections,
  updateProjectWellboreSection,
  createProjectWellboreSection,
  deleteProjectWellboreSection,
  getDefaultProjectWellboreSection,
  createProjectWellboreSectionNipple,
  updateProjectWellboreSectionNipple,
  deleteProjectWellboreSectionNipple,
  syncProjectWellboreSectionsWithLatest,
};

export default projectWellboreSectionService;
