import * as THREE from 'three';

export const createTextSprite = (text, fontSize = 70) => {
  const scale = 30;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `Bold ${fontSize}px Arial`;
  const textMetrics = context.measureText(text);
  canvas.width = textMetrics.width + 10;
  context.clearRect(0, 0, canvas.width, canvas.height);
  const texture = new THREE.Texture(canvas);

  context.font = `Bold ${fontSize}px Arial`;
  context.fillStyle = '#9E9E9E';
  context.fillText(text, 10, fontSize);

  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.needsUpdate = true;

  const sprite = new THREE.Sprite(
    new THREE.SpriteMaterial({
      map: texture,
      transparent: true,
      depthTest: true,
      depthWrite: true,
    }),
  );
  sprite.material.alphaTest = 0.1;
  sprite.name = text;
  sprite.scale.set(scale, scale, scale);
  sprite.geometry.computeBoundingBox();

  return sprite;
};
