import { useHistory } from 'react-router-dom';
import { useRef, useState, useCallback, useEffect } from 'react';

import { blue, lightGreen } from '@material-ui/core/colors';
import { Box, Grid } from '@material-ui/core';

import moment from 'moment';
import Highcharts from 'highcharts';
import gantt from 'highcharts/highcharts-gantt';
import HighchartsReact from 'highcharts-react-official';

import { PROJECT_STATUS } from 'app/app.constants';
import { toProjectDetails } from 'utils/route.util';
import highchartsTheme from 'layout/highcharts.theme';
import winchHighchartsTheme from 'features/projects/dashboard/components/dashboards/winch/winch.highcharts.theme';

const { fontColor, defaultFontSize } = winchHighchartsTheme.style;

const ProjectsGanttChart = ({ projects }) => {
  const chartComponent = useRef(null);
  const history = useHistory();
  const baseDate = moment();
  const oneWeekBefore = baseDate
    .clone()
    .subtract(1, 'weeks')
    .startOf('isoWeek')
    .toDate()
    .getTime();
  const fiveWeeksAfter = baseDate
    .clone()
    .add(5, 'weeks')
    .endOf('isoWeek')
    .toDate()
    .getTime();

  const formatDateTime = (date) => {
    const [year, month, day] = date;
    return moment({ year, month: month - 1, day })
      .toDate()
      .getTime();
  };

  useEffect(() => {
    if (chartComponent?.current && projects?.size) {
      const chart = chartComponent?.current?.chart;
      if (chart?.xAxis) {
        chart.xAxis[0]?.setExtremes(oneWeekBefore, fiveWeeksAfter);
      }
    }
  }, [fiveWeeksAfter, oneWeekBefore, projects]);

  const createSeries = useCallback((projects) => {
    const chart = chartComponent.current.chart;

    if (chart?.series) {
      for (let i = chart.series.length - 1; i >= 0; i--) {
        chart.series[i].remove(false);
      }
    }

    const dataTest = projects.map((field) => {
      let fieldParent = {
        name: undefined,
        id: undefined,
      };

      const seriesData = field.map((project) => {
        fieldParent.name = project.get('fieldName');
        fieldParent.id = project.get('externalFieldId').toString();

        const projectToAdd = {
          name: project.get('projectNumber'),
          id: project.get('id').toString(),
          start: project.get('startTime')
            ? moment(project.get('startTime'))?.toDate().getTime()
            : formatDateTime(project.get('estimatedStart')),
          end: project.get('endTime')
            ? moment(project.get('endTime'))?.toDate().getTime()
            : formatDateTime(project.get('estimatedEnd')),
          parent: project.get('externalFieldId').toString(),
          projectId: project.get('projectId').toString(),
          projectNumber: project.get('projectNumber'),
          wellboreName: project.get('wellboreName'),
          color:
            project.get('status') === PROJECT_STATUS.PLAN
              ? blue[500]
              : lightGreen.A400,
        };
        return projectToAdd;
      });

      const dataArray = Array.from(seriesData.values());
      dataArray.splice(0, 0, fieldParent);

      const serie = {
        name: fieldParent.name,
        data: dataArray,
      };

      return serie;
    });

    dataTest.map((serie) => {
      return chart.addSeries(serie, false);
    });

    chart.redraw();
    chart.reflow();
  }, []);

  useEffect(() => {
    if (projects.size) createSeries(projects);
  }, [projects, createSeries]);

  const [chartOptions] = useState(
    Highcharts.merge(false, highchartsTheme, {
      yAxis: {
        labels: {
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
        },
      },
      rangeSelector: {
        inputEnabled: true,
        enabled: true,
        labels: {
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
        },
        buttons: [
          {
            type: 'week',
            count: 1,
            text: '1w',
            title: 'View 1 week',
          },
          {
            type: 'month',
            count: 1,
            text: '1m',
            title: 'View 1 month',
          },
          {
            type: 'month',
            count: 3,
            text: '3m',
            title: 'View 3 months',
          },
          {
            type: 'month',
            count: 6,
            text: '6m',
            title: 'View 6 months',
          },
          {
            type: 'ytd',
            text: 'YTD',
            title: 'View year to date',
          },
          {
            type: 'year',
            count: 1,
            text: '1y',
            title: 'View 1 year',
          },
          {
            type: 'all',
            text: 'All',
            title: 'View all',
          },
        ],
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        labels: {
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
        },
        enabled: true,
        liveRedraw: true,
        series: {
          type: 'gantt',
          pointPlacement: 0.5,
          pointPadding: 0.25,
          accessibility: {
            enabled: false,
          },
        },
      },
      xAxis: {
        plotBands: {
          color: fontColor,
        },
        showEmpty: false,
        tickColor: fontColor,
        labels: {
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
        },
      },
      accessibility: {
        keyboardNavigation: {
          seriesNavigation: {
            mode: 'serialize',
          },
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.point.projectId && this.point.projectNumber) {
                if (this.point.projectId === this.point.projectNumber) {
                  return `${this.point.projectId} - ${this.point.wellboreName}`;
                } else {
                  return `${this.point.projectId} - ${this.point.projectNumber} - ${this.point.wellboreName}`;
                }
              } else {
                return `${this.point.name}`;
              }
            },
          },
          point: {
            events: {
              click: function (e) {
                if (e.point.projectId && e.point.projectNumber)
                  history.push(toProjectDetails(e.point.id));
              },
            },
          },
        },
      },
    }),
  );

  return projects?.size ? (
    <HighchartsReact
      ref={chartComponent}
      constructorType={'ganttChart'}
      highcharts={gantt}
      options={chartOptions}
    />
  ) : (
    <Grid
      container
      style={{ height: '100hv' }}
      alignItems="center"
      justifyContent="center"
    >
      <Box>No projects available</Box>
    </Grid>
  );
};

export default ProjectsGanttChart;
