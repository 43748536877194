import { Field } from 'formik';
import { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { isImmutable, Map } from 'immutable';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Close';
import { memo, useCallback } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { sortableElement } from 'react-sortable-hoc';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ShowAttachments from '@material-ui/icons/Link';
import HideAttachments from '@material-ui/icons/LinkOff';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import AttachIcon from '@material-ui/icons/InsertLink';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, IconButton, Typography, Tooltip } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { useModal } from 'altus-modal';
import { Menu, MenuItem, Select } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import _isNil from 'lodash/isNil';

import {
  DragHandle,
  SortableTable,
  TableRowActionsCell,
} from 'altus-ui-components';

import {
  ToolType,
  ToolCategoryType,
} from 'features/projects/tool/tool.constants';

import { formatValue } from 'utils/format.util';
import { getQuantityUnitFromItems } from 'utils/app.util';
import TableCellDense from 'app/components/Table/TableCellDense';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import TableRowAutocompleteTextFieldFormik from 'app/components/form/formik/TableRowAutocompleteTextFieldFormik';
import ToolstringToolsTableRow from 'features/projects/tasks/task/toolstring/components/ToolstringToolsTableRow';
import { TASK_URLS } from 'features/projects/activities/activities.constants';
import {
  ASSET_HISTORY_EQUIPMENT_TYPE,
  ASSET_HISTORY_TEST_TYPES,
  EquipmentType,
  MODAL,
} from 'features/equipment/equipment.constants';
import CreateAssetHistoryModalContainer from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import { TOOLSTRING_OPTION_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import { updateToolStringToolStatus } from 'features/projects/tasks/task/toolstring/toolstring.actions';

const getSubRows = (row) => {
  const immutable = isImmutable(row);
  let tools = immutable
    ? row.get('toolAssemblyTools')
    : row.original?.toolAssemblyTools;
  const attachments = immutable
    ? row.get('attachedTools')
    : row.original?.attachedTools;

  if (tools && !Array.isArray(tools)) {
    tools = tools?.toArray();
  }

  return attachments?.size > 0 ? attachments.toArray() : tools;
};

const SortableFormikRow = sortableElement(EditableTableRowFormik);

const getConnectorName = (item) => item.get('name', '').toUpperCase();

const TaskToolstringEditorToolsTable = ({
  classes,
  toolConnectors,
  toolstringTools,
  onDuplicateItem,
  onAddToolAttachments,
  onSortToolstringTool,
  updateItemProperties,
  onDeleteToolstringTool,
  onAddToolAssemblyTools,
  onDeleteToolAssemblyTool,
  onDeleteToolstringAttachment,
  hiddenColumns = [],
  showCustomActions,
  widthSerialColumn = 1,
  templateEditor,
  projectId,
  taskId,
  toolstringId,
  hideDragHandle,
  disabledSerialNumbers = false,
}) => {
  const [isOpen, toggleModal] = useModal(MODAL.ADD_ASSET_HISTORY_TEST);
  const [serialNumber, setSerialNumber] = useState();
  const [toolId, setToolId] = useState();

  const openModal = (event) => {
    event?.preventDefault();
    toggleModal();
  };
  const lengthUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('length'),
  );

  const weightUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('weight'),
  );

  const forceUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('yieldValue'),
  );

  const diameterUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('outerDiameter'),
  );

  const ToolStatusMenu = ({ toolstringItemId, status }) => {
    const [value, setValue] = useState(status);
    const dispatch = useDispatch();

    const handleChange = (event) => {
      setValue(event.target.value);
      dispatch(
        updateToolStringToolStatus(
          projectId,
          taskId,
          toolstringId,
          toolstringItemId,
          event.target.value,
        ),
      );
    };

    return (
      <Select
        value={value}
        label="Status"
        title="Tool Status"
        onChange={handleChange}
      >
        <MenuItem value={1}>N/A</MenuItem>
        <MenuItem value={2}>Set</MenuItem>
        <MenuItem value={3}>Retrieve</MenuItem>
      </Select>
    );
  };

  const DropdownMenu = ({ externalToolId, serialNumber }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const showOptions = externalToolId && serialNumber;
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const complaintMenuItem = () => {
      return (
        <MenuItem
          href={TASK_URLS.ADD_COMPLAINT_URL}
          onClick={() => {
            handleClose();
            window.open(
              TASK_URLS.ADD_COMPLAINT_URL,
              '_blank',
              'noopener,noreferrer',
            );
          }}
        >
          <OpenInNewIcon style={{ paddingRight: '5px' }} />
          Add Complaint
        </MenuItem>
      );
    };

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        {!showOptions ? (
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {complaintMenuItem()}
          </Menu>
        ) : (
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setSerialNumber(serialNumber);
                setToolId(externalToolId);
                handleClose();
                openModal();
              }}
            >
              {<PlaylistAddIcon style={{ paddingRight: '5px' }} />} Add Event to
              Asset
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                window.open(
                  `/equipment/${externalToolId}/assethistory/${serialNumber}`,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              {<OpenInNewIcon style={{ paddingRight: '5px' }} />}
              Open Asset History
            </MenuItem>
            {complaintMenuItem()}
          </Menu>
        )}
      </>
    );
  };

  const columns = [
    {
      id: 'expander',
      xs: 1,
      Header: <TableRowActionsCell minItems={1} />,
      Cell: ({ row }) => {
        const hasAttachments = row.original.get('attachedTools')?.size > 0;
        const [less, more] = hasAttachments
          ? [<HideAttachments />, <ShowAttachments />]
          : [<ExpandLess />, <ExpandMore />];

        const title = hasAttachments
          ? 'Toggle Attached Tools'
          : row.getToggleRowExpandedProps().title;

        const actions = [
          {
            visible: row.depth > 0, // placeholder to keep DragHandle left aligned
            content: null,
          },
          {
            visible: row.depth < 2 && !hideDragHandle,
            content: <DragHandle key={0} />,
          },
          {
            visible: hasAttachments || row.canExpand,
            content: (
              <IconButton
                key={1}
                {...row.getToggleRowExpandedProps()}
                title={title}
              >
                {row.isExpanded ? less : more}
              </IconButton>
            ),
          },
        ];

        return (
          <TableRowActionsCell minItems={1}>
            {actions
              .filter((action) => action.visible)
              .map((action) => action.content)}
          </TableRowActionsCell>
        );
      },
    },
    {
      xs: 1,
      id: 'dataVerified',
      Cell: ({ row }) => {
        const tool = row.original;
        if (row.canExpand) {
          const toolAssemblyTools = tool.get('toolAssemblyTools');
          if (_isNil(toolAssemblyTools)) {
            return null;
          }
          const hasNullDataVerified = toolAssemblyTools?.some(
            (tool) => tool.get('dataVerified') === null,
          );
          if (hasNullDataVerified) {
            return (
              <TableRowActionsCell minItems={0}>
                <Grid container justifyContent="center" alignItems="center">
                  <Tooltip title="Unverified tool">
                    <WarningIcon className={classes.warningIcon} />
                  </Tooltip>
                </Grid>
              </TableRowActionsCell>
            );
          } else {
            return null;
          }
        } else if (!tool.get('dataVerified')) {
          return (
            <TableRowActionsCell minItems={0}>
              <Grid container justifyContent="center" alignItems="center">
                <Tooltip title="Unverified tool">
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
              </Grid>
            </TableRowActionsCell>
          );
        } else return null;
      },
    },
    {
      xs: 1,
      id: 'toolItemNo',
      Header: 'Item No.',
      accessor: (tool) => tool.get('m3ItemNumber'),
      Cell: ({ value, row }) => (
        <Typography variant="subtitle2">
          {value
            ? row.original.get('toolGroupCategory') !==
              ToolCategoryType.ThirdParty
              ? `#${value}`
              : value
            : null}
        </Typography>
      ),
    },
    {
      xs: widthSerialColumn,
      id: 'serialNo',
      Header: 'Serial No.',
      accessor: (tool) => tool.get('serialNo'),
      Cell: ({ value, row }) => {
        const hideAssemblySerialNumber =
          hiddenColumns.includes('hideAssemblySerialNumber') && row?.canExpand;

        const hasAttachedTools = row.original.get('attachedTools')?.size > 0;

        if (hideAssemblySerialNumber && !hasAttachedTools) {
          return null;
        }

        if (row.original.get('type') === ToolType.ASSEMBLY) {
          return null;
        }

        if (hiddenColumns.includes('hideSerialNumbers')) {
          return value;
        }

        if (_isNil(row.original.get('m3ItemNumber'))) {
          return null;
        }

        return (
          <Field
            disabled={disabledSerialNumbers}
            type="string"
            name="serialNo"
            placeholder={'Serial no.'}
            component={TableRowAutocompleteTextFieldFormik}
            keepChangeAfterOnBlur={true}
          />
        );
      },
    },
    {
      xs: 2,
      id: 'toolName',
      Header: 'Name',
      accessor: (tool) => tool.get('name'),
    },
    {
      xs: 1,
      id: 'toolAccumulatedLength',
      accessor: (tool) =>
        formatValue(tool.getIn(['accumulatedLength', 'value']), '', 0.01),
      Header: () => (
        <QuantityTableHeaderTitleCell title="Acc. Length" unit={lengthUnit} />
      ),
    },
    {
      xs: 1,
      id: 'toolFishNeck',
      accessor: (tool) =>
        formatValue(tool.getIn(['fishNeck', 'value']), '', 0.01),
      Header: () => (
        <QuantityTableHeaderTitleCell title="FN" unit={diameterUnit} />
      ),
    },
    {
      xs: 1,
      id: 'toolLength',
      accessor: (tool) =>
        formatValue(tool.getIn(['length', 'value']), '', 0.01),
      Header: () => (
        <QuantityTableHeaderTitleCell title="Length" unit={lengthUnit} />
      ),
    },
    {
      xs: 1,
      id: 'toolWeight',
      accessor: (tool) =>
        formatValue(tool.getIn(['weight', 'value']), '', 0.01),
      Header: () => (
        <QuantityTableHeaderTitleCell title="Weight" unit={weightUnit} />
      ),
    },
    {
      xs: 1,
      id: 'toolOuterDiameter',
      accessor: (tool) =>
        formatValue(tool.getIn(['outerDiameter', 'value']), '', 0.01),
      Header: () => (
        <QuantityTableHeaderTitleCell title="Outer Dia." unit={diameterUnit} />
      ),
    },
    {
      xs: 1,
      id: 'yieldValue',
      accessor: (tool) =>
        formatValue(tool.getIn(['yieldValue', 'value']), '', 0.01),
      Header: () => (
        <QuantityTableHeaderTitleCell title="Yield" unit={forceUnit} />
      ),
    },

    {
      xs: 1,
      id: 'topC',
      Header: 'Top C.',
      Cell: ({ row }) => {
        const connectorId = row.original.get('topToolConnectorId');

        if (!toolConnectors?.size || !connectorId) return null;

        return getConnectorName(toolConnectors.get(connectorId));
      },
    },
    {
      xs: 1,
      id: 'botC',
      Header: 'Bot. C.',
      Cell: ({ row }) => {
        const connectorId = row.original.get('bottomToolConnectorId');

        if (!toolConnectors?.size || !connectorId) return null;

        return getConnectorName(toolConnectors.get(connectorId));
      },
    },
    {
      xs: 1,
      id: 'supplier',
      Header: 'Supplier',
      accessor: (tool) => tool.get('supplier'),
      Cell: ({ value: supplier, row }) => {
        const isAssembly = row.original.get('type') === ToolType.ASSEMBLY;

        if (isAssembly) return null;

        return (
          <Typography variant="subtitle2">
            {supplier || 'Altus Inc.'}
          </Typography>
        );
      },
    },
    {
      xs: 1,
      id: 'status',
      Header: 'Tool Status',
      Cell: ({ row }) => {
        const tool = row.original;

        if (_isNil(tool.get('toolAssemblyTools'))) {
          return null;
        }

        return (
          <ToolStatusMenu
            status={tool.get('toolStatus')}
            toolstringItemId={tool.get('toolstringItemId')}
          />
        );
      },
    },
    {
      id: 'actions',
      Header: <TableRowActionsCell minItems={3} />,
      Cell: ({ row }) => {
        const { original: toolstringItem } = row;
        const externalToolId = toolstringItem.get('toolId');
        const serialNumber = toolstringItem.get('serialNo');

        const isToolstringAssembly =
          toolstringItem.get('type') === ToolType.ASSEMBLY;

        const isToolstringAssemblyTool =
          toolstringItem.get('type') === ToolType.ASSEMBLY_TOOL;

        const isToolstringAttachment =
          toolstringItem.get('type') === ToolType.ATTACHMENT;

        const actions = [
          {
            id: TOOLSTRING_OPTION_ACTIONS.ADD_TOOL,
            visible: isToolstringAssembly && !templateEditor,
            content: (
              <IconButton
                key={0}
                title="Add tool"
                onClick={() => onAddToolAssemblyTools(toolstringItem)}
              >
                <AddIcon />
              </IconButton>
            ),
          },
          {
            id: TOOLSTRING_OPTION_ACTIONS.ATTACH_TOOL,
            visible: !isToolstringAssembly && !isToolstringAttachment,
            content: (
              <IconButton
                key={1}
                title="Attach item"
                onClick={() => onAddToolAttachments(toolstringItem)}
              >
                <AttachIcon />
              </IconButton>
            ),
          },
          {
            visible: true,
            content: (
              <IconButton
                key={1}
                title="Duplicate item"
                onClick={() => onDuplicateItem(toolstringItem)}
              >
                <DuplicateIcon />
              </IconButton>
            ),
          },
          {
            id: TOOLSTRING_OPTION_ACTIONS.ASSET_HISTORY,
            visible: false,
            content: (
              <div>
                <DropdownMenu
                  key={6}
                  externalToolId={externalToolId}
                  serialNumber={serialNumber}
                />
              </div>
            ),
          },
          {
            id: TOOLSTRING_OPTION_ACTIONS.REMOVE_TOOL,
            visible: true,
            content: (
              <IconButton
                key={2}
                disabled={toolstringTools?.size <= 1}
                title={
                  isToolstringAttachment ? 'Remove attachment' : 'Remove tool'
                }
                onClick={() =>
                  isToolstringAttachment
                    ? onDeleteToolstringAttachment(toolstringItem)
                    : isToolstringAssemblyTool
                    ? onDeleteToolAssemblyTool(toolstringItem)
                    : onDeleteToolstringTool(toolstringItem)
                }
              >
                <DeleteIcon />
              </IconButton>
            ),
          },
        ];

        const filteredActions = showCustomActions
          ? actions.filter((action) => showCustomActions.includes(action.id))
          : actions.filter((action) => action.visible);

        return (
          <TableRowActionsCell minItems={3}>
            {filteredActions.map((action) => action.content)}
          </TableRowActionsCell>
        );
      },
    },
  ];

  const visibleColumns = columns.filter(
    (column) => !hiddenColumns.includes(column.id),
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <SortableFormikRow
        {...props}
        onSubmit={updateItemProperties}
        TableRowComponent={ToolstringToolsTableRow}
      />
    ),
    [updateItemProperties],
  );

  return (
    <Grid item container direction="column">
      <Grid
        xs
        item
        container
        wrap="nowrap"
        direction="column"
        className={classes.scrollable}
      >
        <Grid item>
          <SortableTable
            useExpanded
            stickyHeader
            useDragHandle
            disableSortBy
            columns={visibleColumns}
            useGlobalFilter={false}
            items={toolstringTools}
            getSubRows={getSubRows}
            autoResetExpanded={false}
            onItemSorted={onSortToolstringTool}
            noItemsMessage="This BHA got no tools"
            TableRowComponent={renderTableRowComponent}
            TableCellComponent={TableCellDense}
            TableHeaderCellComponent={TableHeaderCellDense}
          />
        </Grid>
      </Grid>
      <CreateAssetHistoryModalContainer
        toggleModal={openModal}
        isOpen={isOpen}
        serialNumber={serialNumber}
        externalToolId={toolId}
        equipmentType={EquipmentType.TOOL}
        getAssetAfterRefresh={false}
        testTypesItems={ASSET_HISTORY_TEST_TYPES.filter(
          (item) =>
            item.equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.TOOL ||
            item.equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.BHA ||
            item.equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.ALL,
        )}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  scrollable: {
    overflowY: 'auto',
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
});

TaskToolstringEditorToolsTable.propTypes = {
  toolstring: PropTypes.instanceOf(Map),
};

export default compose(
  memo,
  withStyles(styles),
)(TaskToolstringEditorToolsTable);
