import { compose } from 'redux';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Grid, Tab, Tabs, withStyles } from '@material-ui/core';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import { DATA_AQUISITION_ACTIVE_TAB } from 'features/projects/tasks/task/task.constants';
import WellTabContainer from 'features/projects/tasks/task/dataAcquisition/components/WellTabContainer';
import ToolsTabContainer from 'features/projects/tasks/task/dataAcquisition/components/ToolsTabContainer';
import CableTabContainer from 'features/projects/tasks/task/dataAcquisition/components/CableTabContainer';
import {
  CABLE_TAB_FIELDS,
  TOOLS_TAB_FIELDS,
} from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { WORK_ITEM_STATUS } from 'app/app.constants';
import ConsumablesTabContainer from 'features/projects/tasks/task/dataAcquisition/components/ConsumablesTabContainer';
import { validationSchema } from './dataAcquisition.validators';
import {
  isEqualRestOfDataAcquisitionStructure,
  richEditorStateToString,
} from 'features/projects/tasks/task/dataAcquisition/helpers/utils';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

const DataAcquisitionContainer = ({
  task,
  taskId,
  classes,
  projectId,
  debrisTypes,
  greaseTypes,
  glycolTypes,
  dataAcquisition,
  injectionMediums,
  productionMediums,
  frictionReducerTypes,
  changeDataAcquisition,
}) => {
  const [activeTab, setActiveTab] = useState(DATA_AQUISITION_ACTIVE_TAB.WELL);
  const [rawCableCommentsJson, setRawCableCommentsJson] = useState();
  const [cableCommentsEditorState, setCableCommentsEditorState] = useState(
    EditorState.createEmpty(),
  );
  const [rawToolsDescriptionJson, setRawToolsDescriptionJson] = useState();
  const [toolsDescriptionEditorState, setToolsDescriptionEditorState] =
    useState(EditorState.createEmpty());

  const updateContent = useCallback(
    (content, setContent, setEditorState) => {
      if (dataAcquisition && dataAcquisition.get(content) && !setContent) {
        const rawContentState = JSON.parse(dataAcquisition.get(content));
        const newEditorState = EditorState.createWithContent(
          convertFromRaw(rawContentState),
        );
        setEditorState(newEditorState);
      }
    },
    [dataAcquisition],
  );

  const updateValues = (values, key, setRawJson) => {
    const JSONValue = JSON.stringify(
      convertToRaw(values[key].getCurrentContent()),
    );
    setRawJson(JSONValue);
  };

  useEffect(() => {
    updateContent(
      CABLE_TAB_FIELDS.COMMENTS,
      rawCableCommentsJson,
      setCableCommentsEditorState,
    );
    updateContent(
      TOOLS_TAB_FIELDS.DESCRIPTION,
      rawToolsDescriptionJson,
      setToolsDescriptionEditorState,
    );
  }, [
    dataAcquisition,
    updateContent,
    rawCableCommentsJson,
    rawToolsDescriptionJson,
  ]);

  const initialValues = useMemo(() => {
    if (dataAcquisition) {
      const {
        [CABLE_TAB_FIELDS.COMMENTS]: _,
        [TOOLS_TAB_FIELDS.DESCRIPTION]: __,
        ...restOfDataAcquisitionObject
      } = dataAcquisition.toJS();
      return {
        [CABLE_TAB_FIELDS.COMMENTS]: cableCommentsEditorState,
        [TOOLS_TAB_FIELDS.DESCRIPTION]: toolsDescriptionEditorState,
        ...restOfDataAcquisitionObject,
      };
    } else return {};
  }, [dataAcquisition, cableCommentsEditorState, toolsDescriptionEditorState]);

  const initialFormValues = useMemo(() => {
    return initialValues;
  }, [initialValues]);

  const submitDataAcquisition = useCallback(
    (values) => {
      const currentCableCommentAsString = richEditorStateToString(
        values[CABLE_TAB_FIELDS.COMMENTS],
      );
      const initialCableCommentAsString = richEditorStateToString(
        initialFormValues[CABLE_TAB_FIELDS.COMMENTS],
      );
      const currentToolsDescriptionAsString = richEditorStateToString(
        values[TOOLS_TAB_FIELDS.DESCRIPTION],
      );
      const initialToolsDescriptionAsString = richEditorStateToString(
        initialFormValues[TOOLS_TAB_FIELDS.DESCRIPTION],
      );

      const isCableCommentsChanged =
        currentCableCommentAsString !== initialCableCommentAsString;
      const isToolsDescriptionChanged =
        currentToolsDescriptionAsString !== initialToolsDescriptionAsString;

      if (isCableCommentsChanged || isToolsDescriptionChanged) {
        updateValues(
          values,
          CABLE_TAB_FIELDS.COMMENTS,
          setRawCableCommentsJson,
        );
        updateValues(
          values,
          TOOLS_TAB_FIELDS.DESCRIPTION,
          setRawToolsDescriptionJson,
        );
        changeDataAcquisition(projectId, taskId, values);
      } else {
        const isEqual = isEqualRestOfDataAcquisitionStructure(
          values,
          initialFormValues,
        );
        if (!isEqual) {
          changeDataAcquisition(projectId, taskId, values);
        }
      }
    },
    [projectId, taskId, changeDataAcquisition, initialFormValues],
  );

  const disabled = useMemo(() => {
    const taskStatus = task?.get('status');
    return taskStatus && taskStatus >= WORK_ITEM_STATUS.COMPLETED;
  }, [task]);

  useEffect(() => {
    if (
      dataAcquisition &&
      !rawCableCommentsJson &&
      dataAcquisition.get(CABLE_TAB_FIELDS.COMMENTS)
    ) {
      setRawCableCommentsJson(dataAcquisition.get(CABLE_TAB_FIELDS.COMMENTS));
    }
  }, [dataAcquisition, rawCableCommentsJson]);

  const handleTabs = (_, value) => {
    setActiveTab(value);
  };
  const activeTabHandler = (activeTab) => {
    switch (activeTab) {
      default:
        return null;
      case DATA_AQUISITION_ACTIVE_TAB.WELL:
        return (
          <WellTabContainer
            taskId={taskId}
            disabled={disabled}
            projectId={projectId}
            injectionMediums={injectionMediums}
            productionMediums={productionMediums}
            fieldMarginClass={classes.fieldMargin}
          />
        );
      case DATA_AQUISITION_ACTIVE_TAB.CABLE:
        return (
          <CableTabContainer
            fieldMarginClass={classes.fieldMargin}
            disabled={disabled}
          />
        );
      case DATA_AQUISITION_ACTIVE_TAB.TOOLS:
        return (
          <ToolsTabContainer
            disabled={disabled}
            debrisTypes={debrisTypes}
            fieldMarginClass={classes.fieldMargin}
          />
        );
      case DATA_AQUISITION_ACTIVE_TAB.CONSUMABLES:
        return (
          <ConsumablesTabContainer
            taskId={taskId}
            disabled={disabled}
            projectId={projectId}
            glycolTypes={glycolTypes}
            greaseTypes={greaseTypes}
            fieldMarginClass={classes.fieldMargin}
            frictionReducerTypes={frictionReducerTypes}
          />
        );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => submitDataAcquisition(values)}
      validationSchema={validationSchema}
    >
      <AutoSaveFormik timeout={1000}>
        <Form>
          <Grid container>
            <Grid item xs={8}>
              <Tabs value={activeTab} onChange={handleTabs}>
                <Tab
                  label="Well"
                  value={DATA_AQUISITION_ACTIVE_TAB.WELL}
                  className={classes.tab}
                />
                <Tab
                  label="Cable"
                  value={DATA_AQUISITION_ACTIVE_TAB.CABLE}
                  className={classes.tab}
                />
                <Tab
                  label="Tools"
                  value={DATA_AQUISITION_ACTIVE_TAB.TOOLS}
                  className={classes.tab}
                />
                <Tab
                  label="Consumables"
                  value={DATA_AQUISITION_ACTIVE_TAB.CONSUMABLES}
                  className={classes.tab}
                />
              </Tabs>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />
          <Box className={classes.box}>
            <Grid container className={classes.contentContainer}>
              <Grid item container className={classes.tabPanel} spacing={1}>
                {activeTabHandler(activeTab)}
              </Grid>
            </Grid>
          </Box>
        </Form>
      </AutoSaveFormik>
    </Formik>
  );
};
const styles = (theme) => ({
  contentContainer: {
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  box: {
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  tabsContainer: {
    paddingLeft: '12%',
    borderRadius: 3,
  },
  tab: {
    backgroundColor: 'transparent !important',
  },
  tabPanel: {
    flex: 1,
    display: 'block',
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  fieldMargin: {
    marginTop: '16px',
  },
});

export default compose(withStyles(styles))(DataAcquisitionContainer);
