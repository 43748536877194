import { Map } from 'immutable';

import { createSelector } from 'utils/app.util';
import { naturalSort } from 'features/wells/wellbore.utils';

export const getWellboresPageResultsFromState = createSelector(
  (state) => state.getIn(['entity', 'wellbore', 'pageResults']),
  (wellbores) => wellbores,
);

export const getWellboresPageParamsFromState = createSelector(
  (state) => state.getIn(['entity', 'wellbore', 'pageParams']),
  (wellbores) => wellbores,
);

const getWellboreTrajectoriesFromState = createSelector(
  (state) => state.getIn(['entity', 'wellboreTrajectory']),
  (wellbores) => wellbores,
);

export const getWellboreDetailsFromState = createSelector(
  (state) => state.getIn(['entity', 'wellboreDetail']),
  (wellboreDetails) => wellboreDetails,
);

export const getWellboreFluidsSelector = createSelector(
  (state) => state.getIn(['entity', 'wellboreDetail', 'fluids']),
  (fluids) => fluids,
);

export const getFieldsFromState = createSelector(
  (state) => state.getIn(['entity', 'field']),
  (fields) => fields,
);

export const getWellboresGroupedByWellNameFromState = createSelector(
  getWellboresPageResultsFromState,
  (wellbores) => {
    const sortedWellbores = wellbores.sort((a, b) => {
      if (!a.get('fieldName') || !b.get('fieldName')) {
        if (!a.get('fieldName') && !b.get('fieldName')) {
          return 0;
        }
        return a.get('fieldName') ? -1 : 1;
      }
      const fieldCompare = a.get('fieldName').localeCompare(b.get('fieldName'));
      if (fieldCompare !== 0) {
        return fieldCompare; // If field names are different, sort by field name
      }

      // If field names are the same, then sort by wellName using natural sort
      return naturalSort(a.get('wellName'), b.get('wellName'));
    });
    return sortedWellbores
      .groupBy((x) => x.get('wellName'))
      .entrySeq()
      .filter(([wellName]) => !!wellName)
      .map(([wellName, wellboresByWellName]) => {
        const isWell = wellboresByWellName.size > 1;

        if (isWell) {
          const fieldId = wellboresByWellName.first().get('fieldId');

          return Map({
            fieldId,
            isWell: true,
            name: wellName,
            wellbores: wellboresByWellName.toList(),
          });
        }

        return wellboresByWellName.first();
      });
  },
);

export const getWellboresGroupedByFieldFromState = createSelector(
  getWellboresGroupedByWellNameFromState,
  getFieldsFromState,
  (wellboresByWellName, fields) =>
    wellboresByWellName
      .groupBy((wellbore) => wellbore.get('fieldId'))
      .entrySeq()
      .filter(([fieldId]) => !!fieldId)
      .filter(([fieldId]) => fields.get(fieldId.toString()))
      .map(([fieldId, wellboresByField]) =>
        Map({
          fieldId,
          isField: true,
          wellbores: wellboresByField,
          name: fields.getIn([fieldId.toString(), 'name']),
        }),
      )
      .toList(),
);

export const getAvailableWellStatuses = createSelector(
  (state) => state.getIn(['wells', 'data', 'wellStatuses']),
  (wellStatuses) => wellStatuses,
);

export const getWellEventsFromState = createSelector(
  (state) => state.getIn(['wells', 'events', 'data']),
  (wellEvents) => wellEvents,
);

export const getWellboreTrajectoryFromState = (wellboreId) =>
  createSelector(getWellboreTrajectoriesFromState, (trajectories) =>
    trajectories.get(wellboreId?.toString()),
  );

export const getWellboreDetailByIdFromState = (wellboreDetailId) =>
  createSelector(getWellboreDetailsFromState, (wellboreDetails) =>
    wellboreDetails.get(wellboreDetailId?.toString()),
  );

export const getWellboreByIdFromState = (wellboreId) =>
  createSelector(getWellboresPageResultsFromState, (wellbores) =>
    wellbores.get(wellboreId?.toString()),
  );
