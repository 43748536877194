import React from 'react';
import { Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';
import AutoCompleteDrumItemNumberAdapter from './AutoCompleteDrumItemNumberAdapter';
import AutoCompleteDrumSerialNumberAdapter from './AutoCompleteDrumSerialNumberAdapter';
import { useState } from 'react';

const CableSpoolingMaintenanceFormComponent = ({ form }) => {
  const [autoCompleteItemNumber, setAutoCompleteItemNumber] = useState(null);
  const [autoCompleteValue, setAutocompleteValue] = useState(null);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography noWrap variant="body1">
              {'Drum Item Number'}
            </Typography>
          </Grid>
          <Field
            type="number"
            name={AssetHistoryForm.CABLE_SPOOLING_DRUM_ITEM_NUMBER}
            label="Drum Item Number"
            placeholder={''}
            component={() => (
              <AutoCompleteDrumItemNumberAdapter
                form={form}
                autoCompleteItemNumber={autoCompleteItemNumber}
                setAutoCompleteItemNumber={setAutoCompleteItemNumber}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography noWrap variant="body1">
              {'Drum Serial Number'}
            </Typography>
          </Grid>
          <Field
            type="number"
            name={AssetHistoryForm.CABLE_SPOOLING_DRUM_SERIAL_NUMBER}
            label="Drum Serial Number"
            placeholder={''}
            component={() => (
              <AutoCompleteDrumSerialNumberAdapter
                form={form}
                autoCompleteValue={autoCompleteValue}
                setAutocompleteValue={setAutocompleteValue}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CableSpoolingMaintenanceFormComponent;
