import { formatDateTimeFromSeconds } from 'utils/app.util';

const AssetHistoryFormValues = (
  formValues,
  serialNumber,
  assetId,
  task,
  toolstringId,
  taskId,
  externalToolId,
  description,
  bopLeakTypeDescription,
) => ({
  serialNumber: serialNumber,
  assetId: assetId,
  startDate: formatDateTimeFromSeconds(formValues.date, formValues.time),
  time: formatDateTimeFromSeconds(formValues.date, formValues.time),
  typeOfEvent: formValues.typeOfEvent,
  externalToolId: externalToolId,
  externalTaskId: task?.get('externalId'),

  // complaint and maintenance
  description,

  // test
  comment: description,
  testType: formValues.typeOfTest,
  result: formValues.result,
  cableCut: formValues.cableCut,
  cableSignal: formValues.cableSignal,
  cableResistance: formValues.cableResistance,
  torqueInnerStrand: formValues.torqueInnerStrand,
  torqueOuterStrand: formValues.torqueOuterStrand,
  wrapTest: formValues.wrapTest,

  //BOP TEST
  bopRam: formValues.bopRam,
  bopLeakType: formValues.bopLeakType,
  bopLeakTypeDescription: bopLeakTypeDescription,

  //STRING TEST
  toolstringId: toolstringId,
  taskId: taskId,

  //CABLE TORSION TEST
  cableTorsionWrap: parseInt(formValues.cableTorsionWrap),
  strandsPassed: {
    item1: parseInt(formValues.strandsPassedPassX),
    item2: parseInt(formValues.strandsPassedOutY),
  },

  //CABLE TORTURE TEST
  outerArmourDuctilityScore: formValues.outerArmourDuctilityScore,
  innerArmourDuctilityScore: formValues.innerArmourDuctilityScore,

  //CABLE CONTINUITY TEST
  resistance: formValues.resistance,

  //CABLE INSULATION TEST
  cableInsulationResistance: formValues.cableInsulationResistance,

  //SIL2 TEST
  assetSil2Bop: {
    surfaceId: formValues.assetSil2Bop,
  },
  assetSil2Bcs: {
    surfaceId: formValues.assetSil2Bcs,
  },
  assetSil2Cabin: {
    surfaceId: formValues.assetSil2Cabin,
  },

  // CABLE WRAP BRAIDED MONO
  cableWrapBraidedMonoTestInnerStrand: {
    item1: parseInt(formValues.cableWrapBraidedMonoTestInnerStrandPassX),
    item2: parseInt(formValues.cableWrapBraidedMonoTestInnerStrandOutY),
  },
  cableWrapBraidedMonoTestOuterStrand: {
    item1: parseInt(formValues.cableWrapBraidedMonoTestOuterStrandPassX),
    item2: parseInt(formValues.cableWrapBraidedMonoTestOuterStrandOutY),
  },

  //CABLE BREAK TEST
  cableBreakTestForceNumber: formValues.cableBreakTestForceNumber,

  // CABLE CUT MAINTENANCE
  distanceQuantity: formValues.distanceQuantity,

  // CABLE SERVICE MAINTENANCE
  cableLengthQuantity: formValues.cableLengthQuantity,
  maxODQuantity: formValues.maxODQuantity,

  //CABLE SPOOLING MAINTENANCE
  drumItemNumber: formValues.drumItemNumber,
  drumSerialNumber: formValues.drumSerialNumber,
});

export default AssetHistoryFormValues;
