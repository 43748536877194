import { compose } from 'redux';
import { Box, Grid } from '@material-ui/core';
import { useSelector, connect } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { useModal } from 'altus-modal';
import { EMPTY_LIST, EMPTY_STRING } from 'app/app.constants';
import { getCurrentClientOrganizationIdFromState } from 'app/app.selectors';

import {
  getToolsFromState,
  getToolGroupsFromState,
  getToolSuppliersFromState,
} from 'features/equipment/equipment.selectors';

import {
  onLoadWithoutToolstrings as onLoad,
  onUnload,
  searchTools,
} from 'features/equipment/equipment.actions';

import {
  MODAL,
  EQIPMENT_CATEGORY_IDS,
} from 'features/equipment/equipment.constants';

import M3ModalContainer from 'features/equipment/m3Components/M3ModalContainer';
import { Filters } from 'features/equipment/mwlTools/components/MwlToolsFilter';
import MwlToolsTable from 'features/equipment/mwlTools/components/MwlToolsTable';
import MwlToolsFilter from 'features/equipment/mwlTools/components/MwlToolsFilter';

const defaultFormValues = {
  [Filters.TOOL_GROUPS]: EMPTY_LIST,
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
};

const MwlToolsContainer = ({
  classes,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchOnSearch,
}) => {
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  const [addToolModal, toggleAddToolModal] = useModal(MODAL.M3_TOOL);

  useEffect(() => {
    dispatchOnLoad(EQIPMENT_CATEGORY_IDS.MWL_TOOLS);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload]);

  const initialFilterValues = useMemo(
    () => ({
      ...defaultFormValues,
      organizationId: currentClientOrganizationId,
    }),
    [currentClientOrganizationId],
  );

  const tools = useSelector(getToolsFromState);
  const toolGroups = useSelector(getToolGroupsFromState);
  const toolSuppliers = useSelector(getToolSuppliersFromState);

  const handleSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
        categoryId: EQIPMENT_CATEGORY_IDS.MWL_TOOLS,
        [Filters.TOOL_GROUPS]:
          filterValues[Filters.TOOL_GROUPS] === '' ||
          filterValues[Filters.TOOL_GROUPS] === EMPTY_LIST
            ? null
            : [filterValues[Filters.TOOL_GROUPS]],
      });
    },
    [dispatchOnSearch],
  );

  return (
    <Grid container>
      <Grid item xs={12} container component={Box} direction="column">
        <Grid item className={classes.filterContainer}>
          <MwlToolsFilter
            toolGroups={toolGroups}
            onSubmit={handleSearch}
            initialValues={initialFilterValues}
            currentClientOrganizationId={currentClientOrganizationId}
            toggleAddToolModal={toggleAddToolModal}
          />
        </Grid>
        <Grid item container className={classes.tableContainer}>
          <MwlToolsTable
            tools={tools}
            toolCategory={EQIPMENT_CATEGORY_IDS.MWL_TOOLS}
          />
        </Grid>
        <M3ModalContainer
          toolGroups={toolGroups}
          openModal={addToolModal}
          suppliers={toolSuppliers}
          toggleModal={toggleAddToolModal}
        />
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  filterContainer: {
    height: 'fit-content',
    width: '50%',
  },
  tableContainer: {
    flex: 1,
  },
});

export default compose(
  connect(() => ({}), {
    dispatchOnLoad: onLoad,
    dispatchOnUnload: onUnload,
    dispatchOnSearch: searchTools,
  }),
  withStyles(styles),
)(MwlToolsContainer);
