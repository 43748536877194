import { Map } from 'immutable';
import { CABLE_TAB_FIELDS, TOOLS_TAB_FIELDS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { convertToRaw } from 'draft-js';

const DataAcquisitionMapper = {
  from: (dataAcquisition) => {
    return Map({
      ...dataAcquisition,
    });
  },
  to: (dataAcquisition) => {
    const { [CABLE_TAB_FIELDS.COMMENTS]: _, [TOOLS_TAB_FIELDS.DESCRIPTION]: __, ...restDataAcquisition } =
      dataAcquisition;
    let content =
      dataAcquisition[CABLE_TAB_FIELDS.COMMENTS].getCurrentContent();
    const toolsDescriptionRawContent = convertToRaw(dataAcquisition[TOOLS_TAB_FIELDS.DESCRIPTION].getCurrentContent())
    const rawContent = convertToRaw(content);
    restDataAcquisition[CABLE_TAB_FIELDS.COMMENTS] = JSON.stringify(rawContent);
    restDataAcquisition[TOOLS_TAB_FIELDS.DESCRIPTION] = JSON.stringify(toolsDescriptionRawContent);

    return {
      ...restDataAcquisition,
    };
  },
};

const dataAcquisitionMappers = {
  DataAcquisition: DataAcquisitionMapper,
};

export default dataAcquisitionMappers;
