import moment from 'moment';
import { List, fromJS } from 'immutable';
import { useMemo, useState } from 'react';
import { Grid, Link, Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { useToggle } from 'altus-hooks';
import { Table, TableRowActionsCell } from 'altus-ui-components';

import {
  getEventKeyByValue,
  TEST_RESULT_TO_TOOLTIP_TITLE,
  TASK_STATUS_TO_TOOLTIP_TITLE,
} from 'features/equipment/equipment.constants';

import GreenIcon from 'assets/Green.svg';
import { Format } from 'app/app.constants';
import { EMPTY_MAP } from 'app/app.constants';
import { formatDate } from 'utils/format.util';
import { extractTimeIn24HourFormat } from 'utils/app.util';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import { toolAssetHistoryEventToString } from 'features/equipment/equipment.helpers';
import AssetHistoryDrawer from 'features/equipment/assets/components/drawer/AssetHistoryDrawer';
import { testResultIcons } from 'features/equipment/assets/components/AssetHistoryTableRowDetails';

const handleResultIcon = (testResult) => {
  return testResultIcons[testResult] || GreenIcon;
};

const getTooltipTitle = (testResult) => {
  return TEST_RESULT_TO_TOOLTIP_TITLE[testResult] || '-';
};

const getTaskStatusTitle = (testResult) => {
  return TASK_STATUS_TO_TOOLTIP_TITLE[testResult] || '-';
};

const AssetHistoryTable = ({
  searchedAssets,
  classes,
  assets = EMPTY_MAP,
  displayComments,
  hiddenColumns = [],
}) => {
  const [assetHistory, setAssetHistory] = useState(null);
  const [isOpen, toggle] = useToggle();

  const columns = useMemo(
    () => [
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => <TableRowActionsCell />,
      },
      {
        id: 'date',
        xs: 2,
        accessor: (item) => {
          if (!item?.get('startDate')) return '-';
          const startDate = item.get('startDate');
          return `${formatDate(
            moment(startDate),
            Format.date,
          )} ${extractTimeIn24HourFormat(item.get('startDate'))}`;
        },
        Header: 'Date',
      },
      {
        id: 'event',
        xs: 2,
        accessor: (item) => toolAssetHistoryEventToString(item.get('event')),
        Header: 'Event',
      },
      {
        id: 'project',
        xs: 3,
        accessor: (item) =>
          `${item.get('facilityName')} ${item.get('wellboreName')}`,
        Header: 'Project',
        Cell: ({ row }) => {
          const item = row.original;
          let title = '-';

          if (item.get('facilityName') || item.get('wellboreName')) {
            title = `${item.get('facilityName')} - ${item.get('wellboreName')}`;
          }

          return !item.get('projectId') ? (
            <>-</>
          ) : (
            <Grid container alignItems="center" style={{ height: '100%' }}>
              <Link target="_blank" href={`/projects/${item.get('projectId')}`}>
                {title}
              </Link>
            </Grid>
          );
        },
      },
      {
        id: 'Result',
        xs: 3,
        Header: 'Result',
        accessor: (item) => {
          return item?.get(getEventKeyByValue(item.get('event')))?.get('result')
            ? item?.get(getEventKeyByValue(item.get('event')))?.get('result')
            : item?.get('task')?.get('status');
        },
        Cell: ({ row }) => {
          const currentEvent = row.original.get(
            getEventKeyByValue(row.original.get('event')),
          );
          const currentTask = row.original?.get('task');

          // for event show result and for task status
          return (
            <>
              <Grid item xs={6}>
                {currentEvent.get('result') ? (
                  <Tooltip title={getTooltipTitle(currentEvent.get('result'))}>
                    <>
                      {currentEvent.get('result') ? (
                        <img
                          src={handleResultIcon(currentEvent.get('result'))}
                          alt="Status icon"
                          className={classes.iconStyle}
                        />
                      ) : null}
                      {getTooltipTitle(currentEvent.get('result'))}
                    </>
                  </Tooltip>
                ) : currentTask !== null ? (
                  <Tooltip
                    title={getTaskStatusTitle(currentTask.get('status'))}
                  >
                    <>
                      {currentTask.get('status') ? (
                        <Grid
                          item
                          xs
                          container
                          wrap="nowrap"
                          alignItems="center"
                        >
                          <Grid item>
                            <WorkItemStatus
                              status={currentTask.get('status')}
                            />
                          </Grid>
                          <Grid item style={{ paddingLeft: '5px' }}>
                            {getTaskStatusTitle(currentTask.get('status'))}
                          </Grid>
                        </Grid>
                      ) : null}
                    </>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>
            </>
          );
        },
      },
      {
        id: 'task',
        xs: 2,
        accessor: (item) => {
          return item?.get('task')?.get('title');
        },
        Header: 'Task',
        Cell: ({ row }) => {
          const currentTask = row.original?.get('task');

          return (
            <>
              {currentTask === null ? null : (
                <Grid item xs={6}>
                  <Link
                    target="_blank"
                    href={`/projects/${row.original.get(
                      'projectId',
                    )}/execution/${currentTask.get('taskId')}/activities`}
                  >
                    {currentTask.get('runNumber') ? (
                      currentTask.get('runNumber')
                    ) : (
                      <></>
                    )}
                    <> - </>
                    {currentTask.get('title') ? (
                      currentTask.get('title')
                    ) : (
                      <></>
                    )}
                  </Link>
                </Grid>
              )}
            </>
          );
        },
      },
    ],
    [classes],
  );
  var assetsList = searchedAssets
    ? List(fromJS(searchedAssets))
    : fromJS(assets);

  const visibleColumns = columns.filter(
    (column) => !hiddenColumns.includes(column.id),
  );

  return (
    <>
      <Table
        className={classes.tableSpacing}
        items={assetsList.sortBy((asset) => asset.get('startDate')).reverse()}
        columns={visibleColumns}
        useGlobalFilter={false}
        onItemSorted={() => null}
        onRowClick={(asset) => {
          setAssetHistory(asset);
          toggle();
        }}
        TableHeaderCellComponent={TableHeaderCellDense}
      />
      {assetHistory && (
        <AssetHistoryDrawer
          isOpen={isOpen}
          toggleDrawer={toggle}
          assetHistory={assetHistory}
          displayComments={displayComments}
        />
      )}
    </>
  );
};

const styles = () => ({
  taskTitleLink: {
    display: 'inline',
  },
  iconStyle: {
    height: '2.2vh',
    display: 'inline-block',
    verticalAlign: 'middle',
    objectFit: 'contain',
  },
});

export default withStyles(styles)(AssetHistoryTable);
