import React from 'react';
import { SwipeableDrawer as Drawer, Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from 'app/components/withTooltip';
import withStyles from '@material-ui/styles/withStyles';
import { testResultIcons } from 'features/projects/assets/AssetTestTable';
import GreenIcon from 'assets/Green.svg';
import DateIcon from '@material-ui/icons/CalendarToday';
import { TEST_RESULT_TO_TOOLTIP_TITLE } from 'features/equipment/equipment.constants';
import { extractTimeFromDate, getRichEditorText } from 'utils/app.util';
import { formatDate } from 'utils/format.util';
import moment from 'moment';
import { EMPTY_LIST, Format } from 'app/app.constants';
import MobileToolstringListContiner from 'features/mobile/projects/execute/taskToolstring/MobileToolstringListContiner';
import { Avatar as TooltipAvatar } from 'app/components/withTooltip';

const StyledBox = styled('div')(() => ({
  backgroundColor: '#2A2A2A',
  minHeight: '100%',
  minWidth: '100%',
  overflowY: 'auto',
}));

const handleResultIcon = (testResult) => {
  return testResultIcons[testResult] || GreenIcon;
};

const getTooltipTitle = (testResult) => {
  return TEST_RESULT_TO_TOOLTIP_TITLE[testResult] || 'Unknown';
};

const userInitials = (fullName) => {
  const nameParts = fullName.split(',').map(function (name) {
    return name.trim();
  });

  const correctOrderName = `${nameParts[1]}  ${nameParts[0]}`;

  const initials = correctOrderName
    .split(' ')
    .map(function (name) {
      return name[0];
    })
    .join('');

  return initials;
};

const Avatar = ({ user, classes, onClick }) => (
  <TooltipAvatar
    title={user.get('fullName')}
    src={user.get('avatar')}
    onClick={onClick}
    classes={{
      root: classes.triggerIconRoot,
    }}
  >
    {!user.get('avatar') && userInitials(user.get('fullName'))}
  </TooltipAvatar>
);

const setAvatar = (avatarUser, avatarClasses) => {
  return <Avatar user={avatarUser} classes={avatarClasses} />;
};

const MobileStringVerificationDrawerContainer = ({
  classes,
  open,
  toggleOpen,
  stringVerification,
  toolstringTools = EMPTY_LIST,
}) => {
  const time = stringVerification?.getIn(['stringTestResult', 'time']);
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => toggleOpen(false)}
      disableSwipeToOpen={true}
      variant="temporary"
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        square: false,
        style: {
          overflow: 'auto',
          backgroundColor: 'transparent',
          minHeight: '100%',
          minWidth: '100%',
        },
      }}
    >
      <StyledBox>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.content}>
            <Grid item xs={10}>
              <Typography className={classes.fontLarge} variant="h6">
                {`String Verification ${stringVerification.getIn([
                  'stringTestResult',
                  'sequence',
                ])}`}
              </Typography>
            </Grid>

            <Grid item xs={2} className={classes.closeIcon}>
              <IconButton
                title="Close"
                color="inherit"
                onClick={() => toggleOpen(false)}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container className={classes.resultContainer}>
            <Grid item xs={12} className={classes.resultRow}>
              <Grid item xs={2} style={{ marginRight: '8px' }}>
                <img
                  src={handleResultIcon(
                    stringVerification.getIn(['stringTestResult', 'result']),
                  )}
                  alt="Status icon"
                />
              </Grid>
              <Grid item xs={12} className={classes.resultContent}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {'Result'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {getTooltipTitle(
                      stringVerification.getIn(['stringTestResult', 'result']),
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.resultRow}>
              <Grid item xs={2} style={{ marginRight: '8px' }}>
                {setAvatar(
                  stringVerification.getIn(['stringTestResult', 'user']),
                  classes,
                )}
              </Grid>
              <Grid item xs={12} className={classes.resultContent}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {'Verified by'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {stringVerification.getIn([
                      'stringTestResult',
                      'user',
                      'fullName',
                    ])}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.resultRow}>
              <Grid item xs={2} style={{ paddingLeft: '8px' }}>
                <DateIcon />
              </Grid>
              <Grid item xs={12} className={classes.resultContent}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {'Date'}
                  </Typography>
                </Grid>
                <Grid item xs={12} nowrap>
                  <Typography variant="h6" nowrap>
                    {`${formatDate(
                      moment(time),
                      Format.date_dayfirst,
                    )}, ${extractTimeFromDate(time)}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.resultRow}>
              <Grid item xs={12} className={classes.resultContent}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {'Comment'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" nowrap>
                    {getRichEditorText(
                      stringVerification.getIn(['stringTestResult', 'comment']),
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            pt={2}
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MobileToolstringListContiner
              toolstringTools={toolstringTools}
              assets={stringVerification.get('assets')}
              isStringVerified={true}
            />
          </Grid>
        </Grid>
      </StyledBox>
    </Drawer>
  );
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
  },
  closeIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.default,
  },
  resultRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resultContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
  },
  fontLarge: {
    fontSize: 'x-large',
  },
  triggerIconRoot: {
    height: 30,
    width: 30,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
});

export default withStyles(styles)(MobileStringVerificationDrawerContainer);
