import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';

import {
  InputAdornment,
  CircularProgress,
  TextField as MuiTextField,
} from '@material-ui/core';

const TextField = ({
  meta,
  input,
  margin,
  loading,
  classes,
  fullwidth,
  InputProps,
  ...rest
}) => {
  const { value, onChange: onChangeInput, onFocus: onFocusInput } = input;

  const invalidInput = meta && meta.dirty && meta.invalid;

  return (
    <MuiTextField
      value={value}
      margin={margin}
      onChange={onChangeInput}
      onFocus={onFocusInput}
      fullwidth={fullwidth}
      SelectProps={{
        IconComponent: loading ? () => null : undefined,
      }}
      error={invalidInput}
      InputProps={
        loading
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CircularProgress
                    size={20}
                    className={classes.circularProgress}
                  />
                </InputAdornment>
              ),
              ...InputProps,
            }
          : InputProps
      }
      {...rest}
    />
  );
};

TextField.propTypes = {
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
};

TextField.defaultProps = {
  margin: 'normal',
  fullWidth: true,
};

const styles = {
  circularProgress: {
    color: 'white',
  },
};

export default withStyles(styles)(TextField);
