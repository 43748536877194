import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogContent,
} from '@material-ui/core';

import { EMPTY_MAP } from 'app/app.constants';

import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import AddCablesModalTable from 'features/projects/tasks/task/surfaceEquipment/components/cables/AddCablesModalTable';

const AddCablesModal = ({
  isOpen,
  classes,
  onAddCable,
  toggleModal,
  onRemoveCable,
  selectedCables,
  setSelectedCables,
  cables = EMPTY_MAP,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          maxHeight: '80%',
          height: '80%',
        },
      }}
      open={isOpen}
      onClose={toggleModal}
    >
      <ApplicationHeaderBase>
        <Grid container alignItems="center">
          <Grid xs item container direction="column">
            <Typography item component={Typography} variant="h6">
              Add cable
            </Typography>
          </Grid>
          <Grid item container xs justifyContent="flex-end">
            <Button color="secondary" variant="standard" onClick={toggleModal}>
              Close
            </Button>
          </Grid>
        </Grid>
      </ApplicationHeaderBase>
      <DialogContent>
        <AddCablesModalTable
          cables={cables}
          classes={classes}
          onAddCable={onAddCable}
          onRemoveCable={onRemoveCable}
          selectedCables={selectedCables}
          setSelectedCables={setSelectedCables}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddCablesModal;
