import { compose } from 'redux';
import LazyLoad from 'react-lazyload';
import withStyles from '@material-ui/styles/withStyles';
import NoLiveDataIcon from '@material-ui/icons/SignalWifiOff';

import {
  Box,
  Card,
  Grid,
  CardMedia,
  IconButton,
  CardHeader,
  Typography,
  CardContent,
  Link,
} from '@material-ui/core';

import Counter from 'app/components/Counter';
import { EMPTY_STRING } from 'app/app.constants';
import { formatDuration } from 'utils/format.util';
import ReadOnlyField from 'app/components/ReadOnlyField';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import OperationCardQuantity from 'features/operations/components/OperationCardQuantity';
import ProjectWellboreTrajectoryContainer from 'app/components/WellboreTrajectory/ProjectWellboreTrajectoryContainer';
import HasProjectPermission from 'app/components/HasProjectPermission';

import { ProjectPermission } from 'app/app.constants';

const CARD_MEDIA_HEIGHT = 300;

const OperationCard = ({
  onClick,
  classes,
  children,
  operation,
  scrollContainerRef,
}) => {
  const project = operation.get('project');

  const projectId = project?.get('id');
  const projectTitle = project?.get('title');
  const fieldName = project?.get('fieldName');
  const wellboreName = project?.get('wellboreName');
  const wellboreId = project?.get('wellboreId');

  const currentDepth = operation.get('depth');
  const currentTension = operation.get('tension');
  const currentSpeed = operation.get('speed');
  const fieldId = project?.get('fieldId');
  const subheader = `${projectId}. - ${projectTitle}`;
  const duration = operation.getIn(['project', 'duration']);

  const hasLiveData = !!operation.get('cheetahJobs').size;

  const projectStatus = operation.getIn(['projectExecutionStatus']);

  const currentTask = operation.getIn([
    'projectExecutionStatus',
    'currentTask',
  ]);

  const currentActivity = operation.getIn([
    'projectExecutionStatus',
    'currentActivity',
  ]);

  return (
    <>
      {
        <Typography variant="h6" noWrap className={classes.title}>
          <Link className={classes.link} href={`/projects?fieldId=${fieldId}`}>{fieldName}</Link>
        </Typography>}
      <Card className={classes.card}>
        <CardHeader
          title={
            !wellboreId ? wellboreName :
              <Box sx={{ typography: 'body1' }}>
                <Link className={classes.link} href={`field/${fieldId}/wells/${wellboreId}`}>{wellboreName}</Link>
              </Box>}
          subheader={
            <Box sx={{ typography: 'body1' }}>
              <Link className={classes.grayLink} href={`/projects/${projectId}/`}>{subheader}</Link>
            </Box>}
          titleTypographyProps={{
            variant: 'h6',
            noWrap: true,
          }}
          action={
            !hasLiveData ? (
              <IconButton title="No live data" size="small">
                <NoLiveDataIcon fontSize="small" />
              </IconButton>
            ) : undefined
          }
          subheaderTypographyProps={{
            noWrap: true,
          }}
          classes={{
            content: classes.cardHeaderContent,
          }}
        />
        <CardContent onClick={onClick} className={classes.cardContent}>
          <LazyLoad
            once
            scroll
            resize
            overflow
            height={CARD_MEDIA_HEIGHT}
            scrollContainer={scrollContainerRef.current}
            placeholder={
              <CardMedia className={classes.cardMedia}>
                <></> {/* Add placeholder element to avoid console warnings */}
              </CardMedia>
            }
          >
            <CardMedia className={classes.cardMedia}>
              <ProjectWellboreTrajectoryContainer
                hideGrid
                hideLegends
                hideAxisLabels
                disableOrbitControls
                sceneKey={projectId}
                projectId={projectId}
                currentDepth={currentDepth}
              >
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body2">
                    Well Trajectory Not Available
                  </Typography>
                </Grid>
              </ProjectWellboreTrajectoryContainer>
            </CardMedia>
          </LazyLoad>
        </CardContent>
        {children}
        <Grid container padding={2} component={Box}>
          <Grid item container wrap="nowrap">
            <Grid item xs={6}>
              <ReadOnlyField
                label={
                  <Link className={classes.grayLink} href={`/projects/${projectId}/plan/`}>{'Task/Run'}</Link>
                }
                TextComponent={Grid}
                classes={{
                  text: classes.readOnlyFieldText,
                }}
                value={
                  <>
                    <Typography noWrap component="span" variant="body2">
                      {currentTask?.title ?? 'N/A'}
                    </Typography>
                    <Box component="span" paddingLeft={0.5} paddingRight={0.5}>
                      {currentTask
                        ? `(${projectStatus.currentTaskNumber}/${projectStatus.taskCount})`
                        : EMPTY_STRING}
                    </Box>
                  </>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyField
                label=
                {
                  !currentTask?.taskId ? 'Activity' :
                    <HasProjectPermission permissions={ProjectPermission.EDIT_ACTIVITIES}
                      render={(hasPermission) => {
                        const link = hasPermission ? `/projects/${projectId}/execution/${currentTask?.taskId}/activities` : `/projects/${projectId}/report/`;
                        return <Link className={classes.grayLink} href={link}>{'Activity'}</Link>
                      }
                      }>
                    </HasProjectPermission>
                }
                TextComponent={Grid}
                classes={{
                  text: classes.readOnlyFieldText,
                }}
                value={
                  <>
                    <Typography noWrap component="span" variant="body2">
                      {currentActivity?.name ?? 'N/A'}
                    </Typography>
                    <Box component="span" paddingLeft={0.5} paddingRight={0.5}>
                      {currentActivity
                        ? `(${projectStatus.currentActivityNumber}/${projectStatus.activityCount})`
                        : EMPTY_STRING}
                    </Box>
                    <WorkItemStatus status={currentActivity?.status} />
                  </>
                }
              />
            </Grid>
          </Grid>
          <Grid item container onClick={onClick}>
            <Grid item xs>
              <OperationCardQuantity label="Speed" quantity={currentSpeed} />
            </Grid>
            <Grid item xs>
              <OperationCardQuantity
                label="Tension"
                quantity={currentTension}
              />
            </Grid>
            <Grid item xs>
              <OperationCardQuantity label="Depth" quantity={currentDepth} />
            </Grid>
            <Grid item xs>
              <Counter
                isTimerActive
                initialDuration={duration}
                renderValue={({ elapsedTime }) => (
                  <ReadOnlyField
                    variant="body2"
                    label="Duration"
                    value={formatDuration(elapsedTime)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const styles = (theme) => ({
  cardMedia: {
    display: 'flex',
    height: CARD_MEDIA_HEIGHT,
  },
  card: {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[800],
    border: `1px solid ${theme.palette.grey[600]}`,
  },
  cardContent: {
    padding: 0,
  },
  cardHeaderContent: {
    overflow: 'hidden',
  },
  title: {
    paddingLeft: `${theme.spacing(1)}px`,
  },
  link: {
    color: 'white',
    textDecorationThickness: '1%',
  },
  grayLink:
  {
    color: 'rgba(255, 255, 255, 0.7)',
    textDecorationThickness: '1%',
  },
  readOnlyFieldText: {
    display: 'flex',
    overflow: 'hidden',
  },
});

export default compose(withStyles(styles))(OperationCard);
