import { List, Map, fromJS } from 'immutable';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';

import { ACTIONS } from 'features/projects/mobilisation/projectMobilisation.constants';
import { ProjectMobilisationMappers } from 'features/projects/mobilisation/projectMobilisation.mappers';

const initialState = Map({
  tasks: EMPTY_LIST,
  toolstrings: EMPTY_LIST,
  stringTestReportEvent: EMPTY_MAP,
  stringTestReportsForTask: EMPTY_LIST,
  mobilisationToolstringForTask: EMPTY_MAP,
});

const projectMobilisationReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_PROJECT_MOBILISATION_TASKS: {
      if (error) return state;

      return state.set(
        'tasks',
        List(
          payload.map((task) =>
            ProjectMobilisationMappers.projectMobilisationTasksMapper.from(
              task,
            ),
          ),
        ),
      );
    }

    case ACTIONS.RECEIVE_STRING_TEST_REPORTS_FOR_TASK: {
      if (error) return state;

      return state.set(
        'stringTestReportsForTask',
        List(payload.map((report) => fromJS(report))),
      );
    }

    case ACTIONS.RECEIVE_STRING_TEST_REPORT_EVENT: {
      if (error) return state;

      return state.set('stringTestReportEvent', fromJS(payload));
    }

    default:
      return state;
  }
};

export default projectMobilisationReducer;
