import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { Cylinder } from '@react-three/drei';
import React, { useEffect, useState } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

const Tool3DObject = ({
  tool3DObjectUrl,
  handleToggleViewTool3DModel,
  toolDiameter,
  toolLength,
  cameraPosition,
}) => {
  const [objectLoaded, setObjectLoaded] = useState(null);
  const { camera } = useThree();

  const handleOnClick = (event) => {
    handleToggleViewTool3DModel();
    event.stopPropagation();
  };

  const handleOnPointerOver = () => {
    camera.position.set(0, 0, cameraPosition - 0.01);
    document.body.style.cursor = 'pointer';
  };

  const handleOnPointerOut = () => {
    camera.position.set(0, 0, cameraPosition);
    document.body.style.cursor = 'auto';
  };

  useEffect(() => {
    if (tool3DObjectUrl) {
      const loader = new OBJLoader();
      loader.load(
        tool3DObjectUrl,
        (toolObj) => {
          setObjectLoaded(toolObj);
          toolObj.traverse((child) => {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial({
                color: '#63686c',
                roughness: 0,
                metalness: 1,
              });
            }
          });
        },
        undefined,
        (error) => {
          console.error('An error occurred:', error);
        },
      );
    }
  }, [tool3DObjectUrl]);
  return tool3DObjectUrl ? (
    objectLoaded && (
      <>
        <primitive
          object={objectLoaded}
          position={[0, 0, 0]}
          onClick={handleOnClick}
          onPointerOver={handleOnPointerOver}
          onPointerOut={handleOnPointerOut}
          scale={0.003}
        />
      </>
    )
  ) : (
    <Cylinder
      name="BHA"
      rotation={[0, 0, Math.PI / 2]}
      args={[toolDiameter / 2, toolDiameter / 2, toolLength, 32, 1]}
      onClick={handleOnClick}
      onPointerOver={handleOnPointerOver}
      onPointerOut={handleOnPointerOut}
      scale={10}
    >
      <meshStandardMaterial
        color={'#63686c'}
        roughness={0}
        metalness={1}
        envMapIntensity={3}
        side={THREE.DoubleSide}
      />
    </Cylinder>
  );
};

export default Tool3DObject;
