import { createSelector } from 'utils/app.util';

export const createOperationsSelector = (filter) => {
  const { projectStatuses } = filter;

  return createSelector(
    (state) => state.getIn(['operations', 'pageResults']), // .get('operations'),
    (pageResults) =>
      pageResults.filter((operation) =>
        projectStatuses.includes(operation.get('projectStatus')),
      ),
  );
};

export const getOperationsPageParamsFromState = createSelector(
  (state) => state.getIn(['operations', 'pageParams']),
  (pageParams) => pageParams,
);

export const getOperationsFromState = createSelector(
  (state) => state.getIn(['operations', 'pageResults']),
  (pageResults) => pageResults,
);

export const getFieldsFromState = createSelector(
  (state) => state.getIn(['operations', 'data', 'fields']),
  (operations) => operations,
);

export const getFacilitiesFromState = createSelector(
  (state) => state.getIn(['operations', 'data', 'facilities']),
  (operations) => operations,
);
