import * as yup from 'yup';
import { compose } from 'redux';
import Close from '@material-ui/icons/Close';
import { connect, useDispatch } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { useRef, useCallback, useState, useEffect } from 'react';

import {
  Grid,
  IconButton,
  Typography,
  InputAdornment,
} from '@material-ui/core';

import { Button } from 'app/components/withTooltip';
import { LoadingOverlay, LoadingButton } from 'altus-ui-components';

import { EMPTY_STRING } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import {
  deleteSurfaceEquipmentImage,
  uploadSurfaceEquipmentImage,
} from 'features/equipment/equipment.actions';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import {
  EQUIPMENT_ACTIONS,
  UNIT_OF_MEASURE,
} from 'features/equipment/equipment.constants';
import { SystemPermission } from 'app/app.constants';
import YesNoSwitchFormik from 'app/components/form/formik/YesNoSwitchFormik';
import TextAreaFieldFormik from 'app/components/form/formik/TextAreaFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';

export const FormFields = {
  NAME: 'name',
  GROUP: 'group',
  LENGTH: 'length',
  WIDTH: 'width',
  HEIGHT: 'height',
  WEIGHT: 'weight',
  M3ITEMNUMBER: 'm3ItemNumber',
  DESCRIPTION: 'description',
  IS_VERIFIED: 'isVerified',
  FILE: 'toolImageFile',
  TOOL_IMAGE_ID: 'toolImageId',
};

const MAX_FILE_SIZE = 512 * 1024;

const getKey = (item) => item.get('id');
const getName = (item) => item.get('name')?.toUpperCase();

const AddSurfaceEquipmentForm = ({
  edit,
  tool,
  classes,
  saveTool,
  dataState,
  toolGroups,
  initialFormValues,
  unitsOfMeasurePreference,
}) => {
  const schema = yup.object().shape({
    name: yup.string().required('Required'),
    group: yup.number().required('Required').typeError('Required'),
    length: yup.string().required('Required'),
    height: yup.string().required('Required'),
    width: yup.string().required('Required'),
    weight: yup.string().required('Required'),
    m3ItemNumber: yup.string().required('Required'),
    toolImageFile: yup
      .mixed()
      .nullable()
      .notRequired()
      .test('FILE_SIZE', 'Uploaded file is too big', (value) => {
        if (value && fileRef.current) {
          if (fileRef.current.files[0].size <= MAX_FILE_SIZE) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .test('FILE_FORMAT', 'Only .png is allowed', (value) => {
        if (value && fileRef.current) {
          if (fileRef.current.files[0].type === 'image/png') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
  });

  const dispatch = useDispatch();
  const fileRef = useRef(null);

  const setValue = useCallback((setValue) => {
    setValue(FormFields.TOOL_IMAGE_ID, EMPTY_STRING);
    setValue(FormFields.FILE, null);
  }, []);

  const [disableValidation, setDisableValidation] = useState(true);
  const { userPermissions } = useSystemPermissions();

  useEffect(() => {
    setDisableValidation(
      !userPermissions.includes(SystemPermission.ITEM_VERIFICATION),
    );
  }, [userPermissions, disableValidation, setDisableValidation]);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={edit ? null : schema}
      initialValues={initialFormValues}
    >
      {({ isValid, values }) => (
        <Form>
          <Grid container className={classes.root}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                xs={12}
                item
                container
                spacing={2}
                wrap="nowrap"
                className={classes.form}
              >
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Name*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.NAME}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            tool && !tool.isEmpty()
                              ? tool.get('name')
                              : 'Enter name'
                          }
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      FormFields.NAME,
                                      initialFormValues[FormFields.NAME],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Tool group*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      getItemName={getName}
                      label={
                        tool && !tool.isEmpty() ? tool.get('toolGroupName') : ''
                      }
                      getItemValue={getKey}
                      margin="none"
                      items={toolGroups}
                      name={FormFields.GROUP}
                    />
                  </Grid>
                </Grid>

                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>M3ItemNumber*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.M3ITEMNUMBER}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            tool && !tool.isEmpty()
                              ? tool.get('m3ItemNumber')
                              : 'Enter M3ItemNumber'
                          }
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      FormFields.M3ITEMNUMBER,
                                      initialFormValues[
                                        FormFields.M3ITEMNUMBER
                                      ],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Length*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.LENGTH}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={''}
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      FormFields.LENGTH,
                                      initialFormValues[FormFields.LENGTH],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL
                        ? 'in'
                        : 'mm'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Heigth*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.HEIGHT}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={''}
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      FormFields.HEIGHT,
                                      initialFormValues[FormFields.HEIGHT],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL
                        ? 'in'
                        : 'mm'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Width*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.WIDTH}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={''}
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      FormFields.LENGTH,
                                      initialFormValues[FormFields.WIDTH],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL
                        ? 'in'
                        : 'mm'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Weight*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.WEIGHT}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={''}
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      FormFields.WEIGHT,
                                      initialFormValues[FormFields.WEIGHT],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL
                        ? 'lbs'
                        : 'kg'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Description</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.DESCRIPTION}>
                      {({ form, ...formik }) => (
                        <TextAreaFieldFormik
                          form={form}
                          placeholder={
                            tool && !tool.isEmpty()
                              ? tool.get('description')
                              : ''
                          }
                          margin="none"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      FormFields.DESCRIPTION,
                                      initialFormValues[FormFields.DESCRIPTION],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Verified</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.IS_VERIFIED}>
                      {({ form, ...formik }) => (
                        <YesNoSwitchFormik
                          disabled={disableValidation}
                          form={form}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Upload image - .png only</Typography>
                  </Grid>
                  <Grid item container xs={6}>
                    <Field
                      name={FormFields.FILE}
                      innerRef={fileRef}
                      type="file"
                    >
                      {({ form, ...formik }) => (
                        <>
                          <Grid item xs={4}>
                            <input
                              ref={fileRef}
                              hidden
                              type="file"
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                              onChange={(event) => {
                                if (event.target.files.length > 0) {
                                  form.setTouched({
                                    ...form.touched,
                                    [FormFields.FILE]: true,
                                  });
                                  yup
                                    .reach(schema, FormFields.FILE)
                                    .isValid(event.target.files[0])
                                    .then((valid) => {
                                      form.setFieldValue(
                                        FormFields.FILE,
                                        event.target.files[0],
                                      );
                                      valid === true &&
                                        dispatch(
                                          uploadSurfaceEquipmentImage(
                                            event.target.files[0],
                                          ),
                                        ).then((payload) => {
                                          form.setFieldValue(
                                            FormFields.TOOL_IMAGE_ID,
                                            payload.toolImageId,
                                          );
                                        });
                                    });
                                }
                              }}
                              {...formik}
                            />
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                fileRef.current.click();
                              }}
                            >
                              Upload
                            </Button>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={6}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="body2" noWrap>
                              {values.toolImageFile &&
                                values.toolImageFile.name}
                            </Typography>
                          </Grid>
                          {values.toolImageFile && values.toolImageFile.name && (
                            <Grid
                              item
                              xs={2}
                              container
                              className={classes.removeUploadContainer}
                            >
                              <IconButton
                                size="small"
                                title="Remove uploaded file"
                                onClick={() => {
                                  if (values.toolImageId) {
                                    dispatch(
                                      deleteSurfaceEquipmentImage(
                                        values.toolImageId,
                                      ),
                                    ).then(setValue(form.setFieldValue));
                                  } else {
                                    setValue(form.setFieldValue);
                                  }
                                }}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </Grid>
                          )}
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center" />
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.errorText}>
                      <ErrorMessage
                        name={FormFields.FILE}
                        render={(msg) => <div>{msg}</div>}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <LoadingOverlay dataState={dataState} text={'Loading...'} />
          </Grid>
          <Grid item container className={classes.submitBtn}>
            <LoadingButton
              color="primary"
              variant="contained"
              disabled={!isValid}
              onClick={() => {
                saveTool(values);
              }}
            >
              Save
            </LoadingButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const styles = (theme) => ({
  root: {
    paddingTop: 15,
    marginBottom: 15,
  },
  leftIcon: {
    marginRight: 10,
  },
  form: {
    flexDirection: 'column',
  },
  formLegend: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  submitBtn: {
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
  },
  errorText: {
    color: '#f44336',
  },
  removeUpload: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeUploadContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(
      state,
      EQUIPMENT_ACTIONS.CREATE_SURFACE_EQUIPMENT,
      EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_UPLOAD_IMAGE,
      EQUIPMENT_ACTIONS.DELETE_SURFACE_EQUIPMENT_IMAGE,
    ),
  })),
  withStyles(styles),
)(AddSurfaceEquipmentForm);
