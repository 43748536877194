import { OrderedMap } from 'immutable';

import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { WELLS_ACTIONS as ACTIONS } from 'features/wells/wells.constants';

const initialState = EMPTY_MAP;

const fieldReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_FIELDS: {
      if (error) return state;

      return OrderedMap(
        payload.map((field) => [
          field.fieldId.toString(),
          mappers.Field.from(field),
        ]),
      );
    }

    default:
      return state;
  }
};

export default fieldReducer;
