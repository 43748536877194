import { generatePath } from 'react-router-dom';

import config from 'infrastructure/config/index';
import routePaths, { pages, projectsBase } from 'app/routePaths';
import { ENVIRONMENTS as Environments } from 'app/app.constants';
import { CheetahJobSource } from 'features/projects/dashboard/dashboard.constants';
import { EDIT_ACTIVITY_MODAL_ID } from 'features/projects/activities/activities.constants';
import { EquipmentType } from '../features/equipment/equipment.constants';

export const toLogin = () => routePaths.login;
export const toLogout = () => routePaths.logout;
export const toChangeLog = () => routePaths.changelog;
export const toPrivacyPolicy = () => routePaths.privacyPolicy;

export const toRoot = () => routePaths.root;

export const toProjectTasks = (projectId, taskId, subPage) => {
  return taskId
    ? subPage
      ? `/${projectsBase}/${projectId}/${pages.plan}/${taskId}/${subPage}`
      : `/${projectsBase}/${projectId}/${pages.plan}/${taskId}`
    : `/${projectsBase}/${projectId}/${pages.plan}`;
};

export const toTaskToolstring = (projectId, taskId, toolstringId) =>
  toolstringId
    ? `/${projectsBase}/${projectId}/${pages.plan}/${taskId}/${pages.task.toolstring}/${toolstringId}`
    : `/${projectsBase}/${projectId}/${pages.plan}/${taskId}/${pages.task.toolstring}`;

export const toTaskToolstringEdit = (projectId, taskId, toolstringId) =>
  `/${projectsBase}/${projectId}/${pages.plan}/${taskId}/${pages.task.toolstring}/${toolstringId}/edit`;

export const toProject = (projectId, path = routePaths.project) =>
  generatePath(path, { projectId });

export const toExecutionSection = (projectId, taskId, section = 'activities') =>
  taskId
    ? `/${projectsBase}/${projectId}/execution/${taskId}/${section}`
    : `/${projectsBase}/${projectId}/execution`;

export const toExecutionSimulation = (
  projectId,
  taskId,
  simulationId,
  tab = 'options',
) =>
  simulationId
    ? `/${projectsBase}/${projectId}/execution/${taskId}/simulation/${simulationId}/${tab}`
    : `/${projectsBase}/${projectId}/execution/${taskId}/simulation`;

export const toExecutionSimulationFluid = (
  projectId,
  taskId,
  simulationId,
  simulationFluidId,
) =>
  simulationFluidId
    ? `/${projectsBase}/${projectId}/execution/${taskId}/simulation/${simulationId}/fluids/${simulationFluidId}`
    : `/${projectsBase}/${projectId}/execution/${taskId}/simulation/${simulationId}/fluids`;

export const toLessonsLearned = (projectId) =>
  `/${projectsBase}/${projectId}/${pages.lessons}`;

export const toProjectReport = (projectId, taskId) =>
  taskId
    ? `/${projectsBase}/${projectId}/${pages.report}/${taskId}`
    : `/${projectsBase}/${projectId}/${pages.report}`;

export const toProjectDetails = (projectId) =>
  generatePath(routePaths.details, { projectId });

export const toProjectDocuments = (projectId) =>
  generatePath(routePaths.documents, { projectId });

export const toProjectWellboreSections = (projectId) =>
  generatePath(routePaths.projectWellbore.sections, { projectId });

export const toProjectEvents = (projectId) =>
  `/${projectsBase}/${projectId}/${pages.events}`;

export const toProjectDashboard = (path, projectId) =>
  generatePath(path, { projectId });

export const toProjectDashboardV2 = (projectId, projectDashboardId) =>
  generatePath(routePaths.dashboard.index, { projectId, projectDashboardId });

export const toFacility = (facilityId) =>
  facilityId && generatePath(routePaths.facilities.index, { facilityId });

export const toWellbore = (
  fieldId,
  wellboreId,
  path = routePaths.wells.index,
) => fieldId && wellboreId && generatePath(path, { fieldId, wellboreId });

export const toTool = (
  equipmentId,
  toolCategory,
  path = routePaths.equipment.index,
) =>
  equipmentId &&
  toolCategory &&
  generatePath(path, { equipmentId, toolCategory });

export const toCable = (equipmentId, path = routePaths.cable.index) =>
  equipmentId && generatePath(path, { equipmentId });

export const toEquipmentAssetHistory = (
  equipmentId,
  equipmentType,
  serialNumber,
) => {
  const path =
    equipmentType === EquipmentType.SURFACE
      ? routePaths.surfaceEquipment.assetHistory
      : equipmentType === EquipmentType.CABLE
      ? routePaths.cable.assetHistory
      : routePaths.equipment.assetHistory;

  return (
    equipmentId &&
    serialNumber &&
    generatePath(path, { equipmentId, serialNumber })
  );
};

export const toSurfaceEquipment = (
  equipmentId,
  path = routePaths.surfaceEquipment.index,
) => {
  return equipmentId && generatePath(path, { equipmentId });
};

export const toWellboreSections = (
  fieldId,
  wellboreId,
  path = routePaths.wells.sections,
) => fieldId && wellboreId && generatePath(path, { fieldId, wellboreId });

export const toTaskWellCondition = (projectId, taskId) =>
  `/${projectsBase}/${projectId}/${pages.plan}/${taskId}/${pages.task.wellCondition}`;

export const toTaskActivities = (projectId, taskId) =>
  `/${projectsBase}/${projectId}/${pages.plan}/${taskId}/${pages.task.activities}`;

export const toTaskRisk = (projectId, taskId) =>
  `/${projectsBase}/${projectId}/${pages.plan}/${taskId}/${pages.task.risk}`;

export const toTaskSimulation = (
  projectId,
  taskId,
  simulationId = null,
  path = routePaths.plan.task.simulation.match,
) =>
  taskId &&
  projectId &&
  generatePath(path, { projectId, taskId, simulationId });

export const toTaskSimulationFluid = (
  projectId,
  taskId,
  simulationId,
  simulationFluidId,
  path = routePaths.plan.task.simulation.fluids.index,
) => generatePath(path, { projectId, taskId, simulationId, simulationFluidId });

export const toEditActivity = (projectId, taskId, index) =>
  `/${projectsBase}/${projectId}/${pages.execute}/${taskId}?${EDIT_ACTIVITY_MODAL_ID}&index=${index}`;

export const toProjectRole = (projectRoleId) =>
  generatePath('/v2/settings/access-roles/project/:projectRoleId?', {
    projectRoleId,
  });

export const toSystemRole = (systemRoleId) =>
  generatePath('/v2/settings/access-roles/system/:systemRoleId?', {
    systemRoleId,
  });

export const toProjectWellboreDetail = (projectId, projectWellboreDetailId) =>
  generatePath(routePaths.projectWellbore.details.match, {
    projectId,
    projectWellboreDetailId,
  });

export const isRouteEnabledForEnvironment = (route) => {
  if (config.environmentName === Environments.LOCAL) return true;

  if (!route.environments) return true;

  return route.environments.includes(config.environmentName);
};

export const toOperations = () => routePaths.operations.root;

export const toLiveProject = (projectId) =>
  generatePath(routePaths.dataExchange.liveProjects.project.root, {
    projectId,
  });

export const toCheetahJobCurves = (projectId, cheetahJobId, source, path) => {
  const { cheetah, warrior, winch } =
    routePaths.dataExchange.liveProjects.project;

  switch (source) {
    case CheetahJobSource.CHEETAH:
      path = cheetah.index;
      break;
    case CheetahJobSource.WARRIOR:
      path = warrior.index;
      break;
    case CheetahJobSource.SM_LOCATION:
      path = winch.index;
      break;
    default:
      break;
  }

  return generatePath(path, {
    projectId,
    cheetahJobId,
  });
};

export const toMobileProjectStatus = (projectId) =>
  `/${projectsBase}/${projectId}/status`;
