import * as THREE from 'three';

import { white } from 'layout/colors';
import { invokeIfFunction } from 'utils/app.util';

const ThreeJsManager = (canvasNode) => {
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    canvas: canvasNode,
  });

  // Set transparent background
  const alpha = 0;
  renderer.setClearColor(white, alpha);

  // Assign the renderer instance to a global variable
  window.renderer = renderer;

  const scenesInfo = {};

  const addScene = (sceneKey, sceneInfo) => {
    if (scenesInfo[sceneKey]) {
      disposeScene(sceneKey);
    }

    scenesInfo[sceneKey] = sceneInfo;
  };

  const updateScene = (sceneKey, sceneUpdater) => {
    const sceneInfo = scenesInfo[sceneKey];

    if (!sceneInfo) return;

    invokeIfFunction(sceneUpdater, sceneInfo);
  };

  const disposeScene = (sceneKey) => {
    const sceneInfo = scenesInfo[sceneKey];

    if (!sceneInfo) return;

    const { scene, camera } = sceneInfo;

    scene.children.forEach((child) => {
      invokeIfFunction(child?.dispose);
      scene.remove(child);
    });

    invokeIfFunction(camera?.dispose);
    invokeIfFunction(scene?.geometry?.dispose);
    invokeIfFunction(scene?.geometry?.map?.dispose);
    invokeIfFunction(scene?.geometry?.texture?.dispose);

    delete scenesInfo[sceneKey];
  };

  const renderScene = ({ scene, camera, domElement }) => {
    if (!renderer || !scene || !camera || !domElement) {
      //Do nothing
      return;
    }

    const canvas = renderer.domElement;

    const {
      left: domElementLeft,
      right: domElementRight,
      top: domElementTop,
      bottom: domElementBottom,
      width: domElementWidth,
      height: domElementHeight,
    } = domElement.getBoundingClientRect();

    const { width: canvasWidth, height: canvasHeight } =
      canvas.getBoundingClientRect();

    const isOffscreen =
      domElementTop < 0 ||
      domElementBottom > canvasHeight ||
      domElementLeft < 0 ||
      domElementRight > canvasWidth;

    if (isOffscreen) {
      // Don't render if the domElement of the scene is offscreen.
      return;
    }

    camera.aspect = domElementWidth / domElementHeight;
    camera.updateProjectionMatrix();

    const positiveYUpBottom = canvasHeight - domElementBottom;

    renderer.setScissor(
      domElementLeft,
      positiveYUpBottom,
      domElementWidth,
      domElementHeight,
    );
    renderer.setViewport(
      domElementLeft,
      positiveYUpBottom,
      domElementWidth,
      domElementHeight,
    );

    renderer.render(scene, camera);
  };

  const resizeRendererToDisplaySize = (renderer) => {
    const canvas = renderer.domElement;

    const clientWidth = canvas.clientWidth;
    const clientHeight = canvas.clientHeight;

    const needResize =
      canvas.width !== clientWidth || canvas.height !== clientHeight;

    if (needResize) {
      renderer.setSize(clientWidth, clientHeight, false);
    }

    return needResize;
  };

  //Animation loop.
  let frameId;
  const render = () => {
    resizeRendererToDisplaySize(renderer);

    renderer.setScissorTest(false);
    renderer.clear(true, true);
    renderer.setScissorTest(true);

    Object.values(scenesInfo).forEach(renderScene);

    frameId = requestAnimationFrame(render);
  };

  render();

  const dispose = () => {
    if (!renderer) return;

    cancelAnimationFrame(frameId);

    Object.keys(scenesInfo).forEach(disposeScene);

    renderer.dispose();
    renderer.forceContextLoss();
  };

  return {
    dispose,
    addScene,
    updateScene,
    disposeScene,
  };
};

export default ThreeJsManager;
