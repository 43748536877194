import moment from 'moment';
import { Field } from 'formik';
import { compose } from 'redux';
import { List } from 'immutable';
import { useMemo, useCallback } from 'react';
import Close from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import withStyles from '@material-ui/styles/withStyles';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { Table, UserAvatar, TableRowActionsCell } from 'altus-ui-components';
import { EMPTY_LIST, ProjectPermission } from 'app/app.constants';
import HasProjectPermission from 'app/components/HasProjectPermission';
import DatePickerFormik from 'app/components/form/formik/DatePickerFormik';
import YesNoSwitchFormik from 'app/components/form/formik/YesNoSwitchFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import ProjectMemberAccessCell from 'features/projects/team/components/ProjectMemberAccessCell';

const initialState = {
  sortBy: [
    {
      id: 'name',
      desc: false,
    },
  ],
};

const shiftTypes = List([
  { id: 0, name: 'Day' },
  { id: 1, name: 'Night' },
]);

const FormFields = {
  TO_DATE: 'to',
  FROM_DATE: 'from',
  CREW_ID: 'crewId',
  QUALIFICATION_ID: 'qualificationId',
  SHIFT_TYPE: 'shiftTypeId',
  SAFTY_DELEGATE: 'safetyDelegate',
};

const ProjectFieldCrewTable = ({
  project,
  crews,
  qualifications,
  updateProjectFieldCrew,
  projectRoles,
  projectMembers,
  deleteProjectMember,
  assignProjectFieldCrewToRole,
}) => {
  const projectOwnersByUserId = useMemo(
    () =>
      project
        .get('owners', EMPTY_LIST)
        .toMap()
        .mapKeys((_, user) => user.get('userId')),
    [project],
  );

  const projectHasOtherOwners = useCallback(
    (projectMember) =>
      !projectOwnersByUserId.delete(projectMember.get('userId')).isEmpty(),
    [projectOwnersByUserId],
  );

  const columns = useMemo(
    () => [
      {
        id: 'avatar',
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const projectMember = row.original;
          return (
            <TableRowActionsCell>
              <UserAvatar
                avatar={projectMember.getIn(['user', 'avatar'])}
                initials={projectMember.getIn(['user', 'initials'])}
              />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 4,
        id: 'name',
        Header: 'Name',
        accessor: (projectMember) =>
          projectMember.getIn(['user', 'name'], null),
      },
      {
        xs: 3,
        Header: 'Shift Type',
        Cell: () => (
          <SelectTextFieldFormik
            displayEmpty={false}
            margin="none"
            items={shiftTypes}
            name={FormFields.SHIFT_TYPE}
            getItemValue={(row) => row.id}
            getItemName={(row) => row.name}
          />
        ),
      },
      {
        xs: 2,
        Header: 'Safety Delegate',
        Cell: () => (
          <Field name={FormFields.SAFTY_DELEGATE}>
            {({ form, ...formik }) => (
              <YesNoSwitchFormik form={form} {...formik} />
            )}
          </Field>
        ),
      },
      {
        xs: 4,
        Header: 'Crew',
        Cell: () => (
          <SelectTextFieldFormik
            margin="none"
            displayEmpty={false}
            items={crews}
            name={FormFields.CREW_ID}
            getItemValue={(row) => row.get('id')}
            getItemName={(row) => row.get('name')}
          />
        ),
      },
      {
        xs: 5,
        id: 'fromCol',
        Header: () => {
          return (
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={1} style={{ margin: '0 35px 10px 0' }}></Grid>
              <Grid item xs>
                {'From'}
              </Grid>
            </Grid>
          );
        },

        Cell: ({ row }) => (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            style={{ overflow: 'hidden' }}
          >
            <Grid
              item
              container
              xs={3}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '5px',
              }}
            >
              {isCurrentDateInRange(
                row.original.get('from'),
                row.original.get('to'),
              ) ? (
                <Tooltip title={'Currently offshore'}>
                  <CircleIcon
                    style={{
                      color: green[500],
                    }}
                  />
                </Tooltip>
              ) : null}
            </Grid>
            <Grid item container>
              <Field name={FormFields.FROM_DATE}>
                {({ form, ...formik }) => (
                  <DatePickerFormik
                    autoOk
                    form={form}
                    {...formik}
                    maxDate={moment(row.original.get('to'))}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        ),
      },
      {
        xs: 4,
        Header: 'To',
        Cell: ({ row }) => (
          <Field name={FormFields.TO_DATE}>
            {({ form, ...formik }) => (
              <DatePickerFormik
                autoOk
                form={form}
                {...formik}
                minDate={moment(row.original.get('from'))}
              />
            )}
          </Field>
        ),
      },
      {
        xs: 4,
        Header: 'Qualification',
        Cell: () => (
          <SelectTextFieldFormik
            margin="none"
            displayEmpty={false}
            items={qualifications}
            name={FormFields.QUALIFICATION_ID}
            getItemValue={(row) => row.get('id')}
            getItemName={(row) => row.get('name')}
          />
        ),
      },
      {
        xs: 4,
        Header: 'Job title',
        accessor: (projectMember) => projectMember.getIn(['user', 'title']),
      },
      {
        xs: 4,
        Header: 'Organization',
        accessor: (projectMember) =>
          projectMember.getIn(['user', 'organizationName']),
      },
      {
        xs: 4,
        Header: 'Access',
        Cell: ({ row }) => {
          const projectMember = row.original;
          const hasOtherOwners = projectHasOtherOwners(projectMember);

          return (
            <ProjectMemberAccessCell
              projectRoles={projectRoles}
              projectMember={projectMember}
              hasOtherOwners={hasOtherOwners}
              assignProjectMemberToRole={assignProjectFieldCrewToRole}
            />
          );
        },
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const projectMember = row.original;
          const hasOtherOwners = projectHasOtherOwners(projectMember);

          return (
            <TableRowActionsCell>
              <HasProjectPermission
                permissions={ProjectPermission.DELETE_TEAM_MEMBERS}
              >
                <IconButton
                  title="Remove member"
                  disabled={!hasOtherOwners}
                  onClick={() =>
                    deleteProjectMember(
                      projectMember.get('projectMemberFieldCrewId'),
                      projectMember.getIn(['user', 'name'], null),
                    )
                  }
                >
                  <Close fontSize="small" />
                </IconButton>
              </HasProjectPermission>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [
      projectRoles,
      deleteProjectMember,
      projectHasOtherOwners,
      assignProjectFieldCrewToRole,
      crews,
      qualifications,
    ],
  );

  const isCurrentDateInRange = (dateTo, dateFrom) => {
    const now = moment();
    if (dateTo === undefined || dateFrom === undefined) return false;
    return now.isSameOrAfter(dateTo) && now.isSameOrBefore(dateFrom);
  };

  const renderTableRowComponent = useCallback(
    (props) => (
      <EditableTableRowFormik {...props} onSubmit={updateProjectFieldCrew} />
    ),
    [updateProjectFieldCrew],
  );
  return (
    <Table
      columns={columns}
      items={projectMembers}
      useGlobalFilter={false}
      initialState={initialState}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

const styles = (theme) => ({
  chip: {
    marginLeft: 0,
    margin: theme.spacing(0.5),
  },
});

export default compose(withStyles(styles))(ProjectFieldCrewTable);
