import * as THREE from 'three';
import React, { useMemo } from 'react';

const TextSprite = ({
  text,
  position,
  fontSize = 70,
  textColor = '#9E9E9E',
}) => {
  const scale = 60;
  const texture = useMemo(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const texture = new THREE.Texture(canvas);

    context.font = `${fontSize}px Arial`;
    context.fillStyle = textColor;
    context.fillText(text, 0, fontSize);

    texture.minFilter = THREE.LinearFilter;
    texture.needsUpdate = true;
    return texture;
  }, [text, fontSize, textColor]);

  return (
    <sprite scale={[scale * 10, scale * 5, 1.0]} position={position}>
      <spriteMaterial attach="material" map={texture} />
    </sprite>
  );
};

export default TextSprite;
