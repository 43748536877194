import { push, replace } from 'connected-react-router/immutable';

import { addNotification } from 'altus-redux-middlewares';

import {
  getAllActivityTypes,
  getAllPointInTimeActivityTypes,
  getAllWaitingActivityTypes,
  getProjectStatus,
  getProjectStatusLite,
} from 'features/projects/activities/activities.actions';

import {
  FormikFormStatus,
  HTTP_STATUS,
  NOTIFICATION_VARIANTS,
} from 'app/app.constants';

import routePaths from 'app/routePaths';
import service from 'services/app.service';
import { invokeIfFunction } from 'utils/app.util';
import { toProjectDetails } from 'utils/route.util';
import projectService from 'services/project.service';
import { getFields } from 'features/wells/wellbore.actions';

import {
  PROJECTS_ACTIONS,
  PROJECT_VIEWS,
} from 'features/projects/projects.constants';
import { receiveProjectMember } from 'features/projects/team/team.actions';

export const onLoad = (organizationId) => (dispatch) => {
  const filter = {
    organizationId,
  };

  const payload = Promise.all([
    dispatch(getFields(filter)),
    service.getFacilities(filter),
    service.getAllProjectStatuses(),
  ]);

  dispatch({
    type: PROJECTS_ACTIONS.PROJECTS_PAGE_LOADED,
    payload,
  });

  return payload;
};

export const onUnload = () => (dispatch) => {
  dispatch({ type: PROJECTS_ACTIONS.PROJECTS_PAGE_UNLOADED });
};

export const updateFilter = (filter) => (dispatch) => {
  if (filter?.ganttView === PROJECT_VIEWS.CHART_VIEW) {
    getAllProjectsGantt(filter)(dispatch);
    return;
  }

  dispatch({
    filter,
    type: PROJECTS_ACTIONS.SEARCH_PROJECTS,
    payload: () =>
      projectService
        .searchProjects(filter)
        .then((projects) => dispatch(receiveProjects(projects))),
  });
};

export const submitCreateProjectForm =
  (project, formik, callback) => (dispatch) => {
    const { setStatus, setSubmitting } = formik;

    setSubmitting(true);

    return dispatch(createProject(project))
      .then((createdProject) => {
        setSubmitting(false);
        setStatus(FormikFormStatus.SUCCESS);

        invokeIfFunction(callback);

        dispatch(push(toProjectDetails(createdProject.projectId)));
      })
      .catch((error) => {
        setSubmitting(false);
        setStatus(FormikFormStatus.ERROR);

        if (error?.response?.body?.hasErrors) {
          var message = error.response.body.errors[0];

          dispatch(
            addNotification({
              message,
              variant: NOTIFICATION_VARIANTS.ERROR,
            }),
          );
        }
      });
  };

export const createProject = (project) => (dispatch) => {
  const payload = projectService.createProject(project).then((project) => {
    dispatch(receiveProject(project));

    return project;
  });

  dispatch({
    payload,
    type: PROJECTS_ACTIONS.CREATE_PROJECT,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'A new project was successfully created',
    },
  });

  return payload;
};

export const getProjectById = (projectId) => (dispatch) => {
  const payload = service.getProjectById(projectId).then((project) => {
    dispatch(receiveProject(project));

    return project;
  });

  dispatch({
    payload,
    type: PROJECTS_ACTIONS.GET_PROJECT_BY_ID,
  });

  return payload;
};

export const getCurrentProjectMember = (projectId) => (dispatch) => {
  const payload = projectService
    .getCurrentProjectMember(projectId)
    .catch((error) => {
      if (error.status === HTTP_STATUS.NOT_FOUND) {
        return;
      }

      return Promise.reject(error);
    })
    .then((projectMember) => {
      if (projectMember) {
        dispatch(receiveProjectMember(projectMember));
      }
    });

  dispatch({
    payload,
    type: PROJECTS_ACTIONS.GET_CURRENT_PROJECT_MEMBER,
  });

  return payload;
};

export const receiveProject = (project) => ({
  payload: project,
  type: PROJECTS_ACTIONS.RECEIVE_PROJECT,
});

export const receiveProjects = (projects) => ({
  payload: projects,
  type: PROJECTS_ACTIONS.RECEIVE_PROJECTS,
});

export const receiveGanttProjects = (projects) => ({
  payload: projects,
  type: PROJECTS_ACTIONS.RECEIVE_GANTT_PROJECTS,
});

export const clearProjects = (projects) => ({
  payload: projects,
  type: PROJECTS_ACTIONS.CLEAR_PROJECTS,
});

export const getProjectResources = (projectId) => (dispatch) => {
  const payload = Promise.all([
    dispatch(getProjectById(projectId)),
    dispatch(getProjectStatusLite(projectId)),
    dispatch(getCurrentProjectMember(projectId)),
  ]);

  dispatch({
    type: PROJECTS_ACTIONS.GET_PROJECT_RESOURCES,
    payload,
  });

  return payload;
};

export const getProjectStatusSilent = (projectId) => (dispatch) =>
  dispatch({
    type: PROJECTS_ACTIONS.GET_PROJECT_STATUS_SILENT,
    payload: () => Promise.all([dispatch(getProjectStatus(projectId))]),
  });

export const getActivityTypes = () => (dispatch) =>
  dispatch({
    type: PROJECTS_ACTIONS.GET_ACTIVITY_TYPES,
    notification: {
      [NOTIFICATION_VARIANTS.ERROR]:
        'Failed to fetch activity types - try reloading',
    },
    payload: () =>
      Promise.all([
        dispatch(getAllActivityTypes()),
        dispatch(getAllPointInTimeActivityTypes()),
        dispatch(getAllWaitingActivityTypes()),
      ]),
  });

export const resetProjectResources = () => (dispatch) =>
  dispatch({
    type: PROJECTS_ACTIONS.RESET_PROJECT_RESOURCES,
  });

export const projectPagesOnUnload = () => (dispatch) => {
  dispatch({
    type: PROJECTS_ACTIONS.PROJECT_PAGES_UNLOADED,
  });
};

export const toProjectsHome = () => (dispatch) =>
  dispatch(replace(routePaths.projects));

export const updateProject = (projectId, project) => (dispatch) => {
  const payload = projectService
    .updateProject(projectId, project)
    .then((updatedProject) => {
      dispatch(receiveProject(updatedProject));

      return updatedProject;
    });

  dispatch({
    payload,
    type: PROJECTS_ACTIONS.UPDATE_PROJECT,
  });

  return payload;
};

export const archiveProject = (projectId, isArchive, filter) => (dispatch) => {
  dispatch({
    payload: () =>
      projectService.archiveProject(projectId, isArchive).then(() => {
        projectService
          .searchProjects(filter)
          .then((projects) => dispatch(receiveProjects(projects)));
      }),
    type: PROJECTS_ACTIONS.ARCHIVE_PROJECT,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: `Project ${projectId} was restored`,
    },
    confirmationDialog: {
      title: 'Restore project?',
      confirmButtonText: 'Restore',
      description: 'Are you sure you want to restore project?',
    },
  });
};

export const getAllProjectsGantt = (filter) => (dispatch) => {
  dispatch({
    type: PROJECTS_ACTIONS.GANTT_PROJECTS,
    payload: () =>
      projectService
        .getAllProjectsGantt(filter)
        .then((projects) => dispatch(receiveGanttProjects(projects))),
  });
};
