import { Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';

import { Tab, Tabs } from 'altus-ui-components';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { ToolCategoryType } from 'features/projects/tool/tool.constants';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import MultiSelectTextFieldFormik from 'app/components/form/formik/MultiSelectTextFieldFormik';

export const Filters = {
  textSearch: 'textSearch',
  categoryId: 'categoryId',
  toolGroupIds: 'toolGroupIds',
  supplierSearch: 'supplierSearch',
  topConnectorIds: 'topConnectorIds',
  bottomConnectorIds: 'bottomConnectorIds',
  projectId: 'projectId',
};

const getKey = (item) => item.get('id');
const getName = (item) => item.get('name')?.toUpperCase();

const ToolPickerModalFilters = ({
  loading,
  onSearch,
  suppliers,
  toolGroups,
  activeFilters,
  topConnectors,
  activeCategory,
  toolCategories,
  bottomConnectors,
  onChangeCategory,
}) => (
  <Formik onSubmit={onSearch} enableReinitialize initialValues={activeFilters}>
    <Form>
      <AutoSaveFormik>
        <Grid item container>
          <Grid item xs>
            <Tabs
              value={activeCategory}
              onChange={(_, category) => onChangeCategory(category)}
            >
              {toolCategories.map((category, index) => (
                <Tab key={index} value={category} label={category.label} />
              ))}
            </Tabs>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                autoFocus
                fullWidth
                margin="normal"
                label="Search tool"
                name={Filters.textSearch}
                component={TextFieldFormik}
              />
            </Grid>
            <Grid item xs>
              <Field
                getKey={getKey}
                getName={getName}
                disabled={loading}
                label="Tool Group"
                items={toolGroups}
                name={Filters.toolGroupIds}
                component={MultiSelectTextFieldFormik}
              />
            </Grid>
            <Grid item xs>
              <Field
                getKey={getKey}
                getName={getName}
                disabled={loading}
                label="Top Connector"
                items={topConnectors}
                name={Filters.topConnectorIds}
                component={MultiSelectTextFieldFormik}
              />
            </Grid>
            <Grid item xs>
              <Field
                getKey={getKey}
                getName={getName}
                disabled={loading}
                label="Bottom Connector"
                items={bottomConnectors}
                name={Filters.bottomConnectorIds}
                component={MultiSelectTextFieldFormik}
              />
            </Grid>
            {activeCategory.value === ToolCategoryType.ThirdParty && (
              <Grid item xs>
                <SelectTextFieldFormik
                  disabled={loading}
                  label="Supplier"
                  items={suppliers}
                  getItemName={getName}
                  getItemValue={getName}
                  name={Filters.supplierSearch}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </AutoSaveFormik>
    </Form>
  </Formik>
);

export default ToolPickerModalFilters;
