import PropTypes from 'prop-types';
import { castArray } from 'lodash';
import { useCallback, useMemo } from 'react';

import { RouteContainer } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';
import { isRouteEnabledForEnvironment } from 'utils/route.util';

const ValidRoutesContainer = ({
  render,
  routes,
  hasPermission,
  MissingRouteComponent,
}) => {
  const filterRoutesWithPermissions = useCallback(
    (routes, callback) =>
      castArray(routes).reduce(callback, EMPTY_LIST).toArray(),
    [],
  );

  const handleValidRoute = useCallback(
    (route, callback) => {
      const { routes: subRoutes } = route;

      if (!subRoutes) return route;

      return {
        ...route,
        routes: filterRoutesWithPermissions(subRoutes, callback),
      };
    },
    [filterRoutesWithPermissions],
  );

  const filterRoute = useCallback(
    (validRoutes, route) => {
      const isValidRoute = hasPermission(route);
      const isEnabledForEnvironment = isRouteEnabledForEnvironment(route);

      return isValidRoute && isEnabledForEnvironment
        ? validRoutes.push(handleValidRoute(route, filterRoute))
        : validRoutes;
    },
    [hasPermission, handleValidRoute],
  );

  const validRoutes = useMemo(
    () => filterRoutesWithPermissions(routes, filterRoute),
    [routes, filterRoute, filterRoutesWithPermissions],
  );

  if (render) return invokeIfFunction(render, validRoutes);

  return (
    <RouteContainer
      routes={validRoutes}
      MissingRouteComponent={MissingRouteComponent}
    />
  );
};

ValidRoutesContainer.propTypes = {
  render: PropTypes.func,
  MissingRouteComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.elementType,
  ]),
  hasPermission: PropTypes.func.isRequired,
  routes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default ValidRoutesContainer;
