import * as yup from 'yup';
import moment from 'moment/moment';

const startTimeValidation = {
  startTime: yup
    .date()
    .typeError('Start Date is required')
    .required('Start Date is required')
    .when(
      [
        'endTime',
        'previousActivityStartTime',
        'hasWaitCodes',
        'nextActivityStartTime',
      ],
      (
        endTime,
        previousActivityStartTime,
        hasWaitCodes,
        nextActivityStartTime,
      ) => {
        let baseValidation = yup
          .date()
          .typeError('Start Date is required')
          .required('Start Date is required')
          .test(
            'is-start-date-future',
            "Start Date can't be in the future",
            (value) => value < new Date(),
          );

        if (
          hasWaitCodes &&
          nextActivityStartTime &&
          previousActivityStartTime &&
          moment(nextActivityStartTime).isValid() &&
          moment(previousActivityStartTime).isValid()
        ) {
          return baseValidation
            .max(
              nextActivityStartTime,
              'Start date must be before start date of next activity or end of wait code',
            )
            .min(
              previousActivityStartTime,
              'Start date must be after start of previous activity',
            );
        }

        if (
          hasWaitCodes &&
          nextActivityStartTime &&
          !previousActivityStartTime &&
          moment(nextActivityStartTime).isValid()
        ) {
          return baseValidation.max(
            nextActivityStartTime,
            'Start date must be before start date of next activity or end of wait code',
          );
        }

        if (
          endTime &&
          previousActivityStartTime &&
          moment(endTime).isValid() &&
          moment(previousActivityStartTime).isValid()
        ) {
          return baseValidation
            .max(
              endTime,
              'Start date must be before start date of next activity or end of wait code',
            )
            .min(
              previousActivityStartTime,
              'Start date must be after start of previous activity',
            );
        }
        if (
          endTime &&
          !previousActivityStartTime &&
          moment(endTime).isValid()
        ) {
          return baseValidation.max(
            endTime,
            'Start date must be before start date of next activity',
          );
        }
        if (
          !endTime &&
          previousActivityStartTime &&
          moment(previousActivityStartTime).isValid()
        ) {
          return baseValidation.min(
            previousActivityStartTime,
            'Start date must be after start date of previous activity',
          );
        }
        return baseValidation;
      },
    ),
};

const endTimeValidationSpecific = {
  endTime: yup
    .date()
    .typeError('End date is required')
    .required('End date is required')
    .when(
      ['startTime', 'nextActivityStartTime'],
      (startTime, nextActivityStartTime) => {
        let baseValidation = yup
          .date()
          .typeError('End Date is required')
          .required('End Date is required')
          .test(
            'is-start-date-future',
            "End Date can't be in the future",
            (value) => value < new Date(),
          );
        if (
          startTime &&
          nextActivityStartTime &&
          moment(startTime).isValid() &&
          moment(nextActivityStartTime).isValid()
        ) {
          return baseValidation
            .min(startTime, 'End date must be after start date of wait code')
            .max(
              nextActivityStartTime,
              'End date must be before start time of following activity',
            );
        }
        if (
          !nextActivityStartTime &&
          startTime &&
          moment(startTime).isValid()
        ) {
          return baseValidation.min(
            startTime,
            'End date must be after start date of wait code',
          );
        }

        return baseValidation;
      },
    ),
};

export const validationSchemaForCompletedItem = yup
  .object()
  .shape(startTimeValidation);
export const validationSchemaForCurrentItem = yup
  .object()
  .shape(startTimeValidation);
export const customEndDateValidation = yup
  .object()
  .shape(endTimeValidationSpecific);
