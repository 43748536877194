import * as colors from '@material-ui/core/colors';
import makeStyles from '@material-ui/styles/makeStyles';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

import { SmLocationStatus } from 'app/app.constants';

const SMLocationStatusIndicator = ({ title, status }) => {
  const classes = useStyles(status);

  return (
    <FiberManualRecord
      titleAccess={title}
      fontSize="medium"
      classes={{
        root: classes.root,
      }}
    />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    fill: (status) => {
      switch (status) {
        case SmLocationStatus.PING:
          return colors.green[500];
        case SmLocationStatus.NO_PING:
          return colors.yellow[500];
        default:
          return colors.grey[500];
      }
    },
  },
}));

export default SMLocationStatusIndicator;
