import { useCallback } from 'react';
import AssetHistoryForm from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';
import { searchM3Items } from 'features/equipment/equipment.actions';
import { AutocompleteTextField } from 'app/components/form/AutocompleteTextField';

const AutoCompleteDrumItemNumberAdapter = ({
  form,
  autoCompleteItemNumber,
  setAutoCompleteItemNumber,
}) => {
  const getOptionLabel = useCallback((option) => option.m3ItemNumber, []);
  const valueSelectorFromAutocomplete = useCallback(
    (newValue) => (newValue ? newValue.m3ItemNumber : null),
    [],
  );
  // There are no initial values here so this is NO-OP
  const setInitialFieldValue = useCallback(() => {}, []);
  return (
    <AutocompleteTextField
      form={form}
      autoCompleteValue={autoCompleteItemNumber}
      setAutocompleteValue={setAutoCompleteItemNumber}
      formFieldName={AssetHistoryForm.CABLE_SPOOLING_DRUM_ITEM_NUMBER}
      getOptionLabel={getOptionLabel}
      valueSelectorFromAutocomplete={valueSelectorFromAutocomplete}
      setInitialFieldValue={setInitialFieldValue}
      fetchAutocompleteDataAction={searchM3Items}
      placeholder={''}
      id={'auto-complete-m3'}
    />
  );
};

export default AutoCompleteDrumItemNumberAdapter;
