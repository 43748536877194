import {
  createGetToolstringSelector,
  getToolstringsByTaskFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';
import { requestProjectWellboreSections } from 'features/projects/wellbore/sections/projectWellboreSection.actions';
import { getToolstringsForTask } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { createProjectWellboreSectionsSelector } from 'features/projects/projects.selectors';
import WellboreTrajectoryDetailed3DContainer from './WellboreTrajectoryDetailed3DContainer';
import { getToolstring } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { getProjectWellbore } from 'features/projects/wellbore/projectWellbore.actions';
import { getAllTasksFromState } from 'features/projects/tasks/tasks.selectors';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { EMPTY_QUANTITY, WORK_ITEM_STATUS } from 'app/app.constants';
import { getAllTasks } from 'features/projects/tasks/tasks.actions';
import useProject from 'features/projects/hooks/useProject';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const WellboreTrajectoryDataFetchContainer = ({
  classes,
  projectId,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [toolsByToolstringToolId, setToolsByToolstringToolId] = useState(null);
  const [BHA_LENGTH, setBHALength] = useState(EMPTY_QUANTITY);
  const [toolStringId, setToolStringId] = useState(null);
  const [maxOD, setMaxOD] = useState(EMPTY_QUANTITY);
  const [taskId, setTaskId] = useState(null);
  const [unplannedToolString, setUnPlannedToolString] = useState(false);

  useEffect(() => {
    dispatch(getProjectWellbore(projectId));
    dispatch(getAllTasks(projectId));
  }, [dispatch, projectId]);

  const project = useProject(projectId);
  const tasks = useSelector(getAllTasksFromState);

  useEffect(() => {
    dispatch(requestProjectWellboreSections(projectId));
  }, [projectId, dispatch]);

  const projectWellboreSectionsSelector = useMemo(
    () => createProjectWellboreSectionsSelector(projectId),
    [projectId],
  );

  const wellboreSections = useSelector(projectWellboreSectionsSelector);

  useEffect(() => {
    if (project && project.toJS() && [...tasks?.entries()].length > 0) {
      tasks.forEach((task) => {
        if (task.get('status') === WORK_ITEM_STATUS.STARTED) {
          dispatch(getToolstringsForTask(projectId, task.get('taskId')));
        }
      });
    }
  }, [project, tasks, projectId, dispatch]);

  const toolstrings = useSelector(getToolstringsByTaskFromState);

  useEffect(() => {
    toolstrings.forEach((tool) => {
      const toolObj = tool.toJS();
      const id = Object.values(toolObj)?.find(
        (toolstring) => toolstring?.isPlanned === true,
      );
      if (id) {
        setToolStringId(id?.id);
        setTaskId(id.taskId);
        setUnPlannedToolString(false);
      }
    });
  }, [toolstrings]);

  useEffect(() => {
    if (toolStringId) dispatch(getToolstring(projectId, taskId, toolStringId));
    else {
      setUnPlannedToolString(true);
    }
  }, [projectId, taskId, toolStringId, dispatch]);

  const toolstring = useSelector(createGetToolstringSelector(toolStringId));

  useEffect(() => {
    const ts = toolstring.toJS();
    if (ts.maxOD && ts.totalLength) {
      setToolsByToolstringToolId(ts.toolsByToolstringToolId);
      setBHALength(ts.totalLength);
      setMaxOD(ts.maxOD);
    }
  }, [toolstring]);

  if (wellboreSections && BHA_LENGTH && maxOD && toolsByToolstringToolId)
    return (
      <WellboreTrajectoryDetailed3DContainer
        projectId={projectId}
        wellboreSections={wellboreSections}
        BHA_LENGTH={BHA_LENGTH}
        maxOD={maxOD}
        toolsByToolstringToolId={toolsByToolstringToolId}
        {...rest}
      />
    );

  if (unplannedToolString) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="body2">
            Please mark a BHA as planned from Simulations
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      xs
      item
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  );
};

export default WellboreTrajectoryDataFetchContainer;
