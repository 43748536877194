import * as THREE from 'three';
import * as MuiColors from '@material-ui/core/colors';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import * as colors from 'layout/colors';
import ThreeJsUtils from 'app/threeJs/ThreeJsUtils';

const upVector = new THREE.Vector3(0, 1, 0);
const startVector = new THREE.Vector3();
const endVector = new THREE.Vector3();
const TrajectoryCylinderPrefix = 'trajectory-cylinder';
const Materials = {
  maximumWorkingDepth: new THREE.MeshBasicMaterial({
    color: colors.dashboard.maximumWorkingDepth,
  }),
  targetDepth: new THREE.MeshBasicMaterial({
    color: colors.dashboard.targetDepth,
  }),
  dhsv: new THREE.MeshBasicMaterial({
    color: colors.dashboard.dhsv,
  }),
  currentDepth: new THREE.MeshBasicMaterial({
    color: colors.dashboard.depth,
  }),
  grid: new THREE.MeshBasicMaterial({
    color: MuiColors.grey[800],
  }),
  base: new THREE.MeshBasicMaterial({
    color: MuiColors.blueGrey[100],
  }),
};

const WellboreTrajectoryScene = ({
  zoom,
  theme,
  maxTVD,
  domElement,
  maxNorthEast,
  hideAxisLabels,
  trajectoryPoints,
  hideGrid = false,
  disableOrbitControls = false,
  displayCardinalPoints,
  depthsOfInterest = [],
}) => {
  const scene = new THREE.Scene();

  const camera = setupCamera({
    domElement,
    maxNorthEast: maxNorthEast * 1.33,
    maxTVD,
    zoom,
    disableOrbitControls,
  });

  if (!hideGrid) {
    const {
      grid,
      gridLength,
      tickInterval: gridTickInterval,
    } = ThreeJsUtils.createGrid({
      max: maxNorthEast < 100 ? maxNorthEast * 100 : maxNorthEast,
    });
    scene.add(grid);

    if (displayCardinalPoints) {
      // This hasn't been requested as a feature yet, but I have turned it on to get oriented while debugging.
      const cardinalPointsLabels = createCardinalPointsLabels({
        gridLength,
        theme,
      });

      cardinalPointsLabels.forEach((label) => {
        scene.add(label);
      });
    }

    const yAxis = createYAxis({ maxTVD });
    scene.add(yAxis);

    if (!hideAxisLabels) {
      const yAxisLabels = createYAxisLabels({
        maxTVD,
        theme,
        tickInterval: gridTickInterval,
      });
      yAxisLabels.forEach((label) => {
        scene.add(label);
      });
    }
  }

  trajectoryPoints.forEach((previousPoint, index) => {
    const currentPoint = trajectoryPoints.get(index + 1);

    // Bottom reached
    if (!currentPoint) return false;

    const trajectoryCylinder = createTrajectoryCylinder({
      previousPoint,
      maxTVD,
      currentPoint,
      index,
    });

    scene.add(trajectoryCylinder);

    const marker = maybeCreateMarker({
      previousPoint,
      currentPoint,
      depthsOfInterest,
    });

    if (marker) {
      scene.add(marker);
    }
  });

  return { scene, camera, domElement };
};

const setupCamera = ({
  domElement,
  maxNorthEast,
  maxTVD,
  zoom = 2,
  disableOrbitControls = false,
}) => {
  const clientWidth = domElement.clientWidth;
  const clientHeight = domElement.clientHeight;

  const camera = new THREE.PerspectiveCamera(
    50,
    clientWidth / clientHeight,
    0.1,
    (maxNorthEast + maxTVD) * 4,
  );

  camera.up = upVector;
  camera.position.z = zoom * maxNorthEast;
  camera.position.y = 2 * maxTVD;

  const orbitControls = new OrbitControls(camera, domElement);

  orbitControls.maxDistance =
    maxNorthEast < 100
      ? (zoom + 2) * maxNorthEast * 100
      : (zoom + 2) * maxNorthEast;
  orbitControls.enablePan = false;
  orbitControls.enableKeys = false;
  orbitControls.enabled = !disableOrbitControls;

  return camera;
};

const createYAxis = ({ maxTVD }) => {
  startVector.set(0, 0, 0);
  endVector.set(0, maxTVD, 0);

  const yLine = ThreeJsUtils.createCylinder({
    v1: startVector,
    v2: endVector,
    material: Materials.grid,
    radius: 3,
  });

  return yLine;
};

const createYAxisLabels = ({ maxTVD, theme, tickInterval }) => {
  const yTicks = ThreeJsUtils.getTicks({ max: maxTVD, tickInterval });
  const { fontFamily } = theme;

  return yTicks.map((tick) => {
    const yAxisLabel = ThreeJsUtils.createTextSprite({
      text: tick,
      fontFamily,
    });

    yAxisLabel.position.set(-50, maxTVD - tick, -50);

    return yAxisLabel;
  });
};

const createCardinalPointsLabels = ({ gridLength, theme }) => {
  const { fontFamily } = theme;
  const middle = gridLength / 2.0;

  const northLabel = ThreeJsUtils.createTextSprite({ text: 'N', fontFamily });
  northLabel.position.z = -middle;

  const southLabel = ThreeJsUtils.createTextSprite({ text: 'S', fontFamily });
  southLabel.position.z = middle;
  southLabel.position.x = 0;

  const westLabel = ThreeJsUtils.createTextSprite({ text: 'W', fontFamily });
  westLabel.position.z = 0;
  westLabel.position.x = -middle;

  const eastLabel = ThreeJsUtils.createTextSprite({ text: 'E', fontFamily });
  eastLabel.position.z = 0;
  eastLabel.position.x = middle;

  return [northLabel, southLabel, westLabel, eastLabel];
};

const createTrajectoryCylinder = ({ previousPoint, currentPoint, maxTVD }) => {
  startVector.set(
    previousPoint.getIn(['eastWest', 'value']),
    maxTVD - previousPoint.getIn(['verticalDepth', 'value']),
    previousPoint.getIn(['northSouth', 'value']),
  );

  endVector.set(
    currentPoint.getIn(['eastWest', 'value']),
    maxTVD - currentPoint.getIn(['verticalDepth', 'value']),
    currentPoint.getIn(['northSouth', 'value']),
  );

  const cylinder = ThreeJsUtils.createCylinder({
    v1: startVector,
    v2: endVector,
    material: Materials.base,
  });

  const measuredDepth = currentPoint.getIn(['measuredDepth', 'value']);
  cylinder.name = `${TrajectoryCylinderPrefix}-${measuredDepth}`;
  cylinder.data = {
    measuredDepth,
  };

  return cylinder;
};

const maybeCreateMarker = ({
  previousPoint,
  currentPoint,
  depthsOfInterest,
}) => {
  const depthOfInterest = depthsOfInterest.find(
    ({ depth }) =>
      depth &&
      depth >= previousPoint.getIn(['measuredDepth', 'value']) &&
      depth <= currentPoint.getIn(['measuredDepth', 'value']),
  );

  if (!depthOfInterest) return;

  const depthOfInterestCylinder = ThreeJsUtils.createCylinder({
    v1: startVector,
    v2: endVector,
    length: 10,
    radius: 80,
    material: depthOfInterest.material,
  });

  depthOfInterestCylinder.name = depthOfInterest.name;

  return depthOfInterestCylinder;
};

export { Materials, TrajectoryCylinderPrefix };

export default WellboreTrajectoryScene;
