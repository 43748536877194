import _isEmpty from 'lodash/isEmpty';
import { OrderedMap } from 'immutable';
import { EMPTY_MAP } from 'app/app.constants';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';

const initialState = EMPTY_MAP;

const simulationResultReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_SIMULATION_RESULTS: {
      if (error) return state;

      // TODO: Currently using the first result set to keep current functionality
      // Should update client state to handle new data model where a simulation can have multiple sets of simulation results
      const resultSet = payload?.[0] ?? {};
      return _isEmpty(resultSet)
        ? state
        : OrderedMap(
            resultSet.simulationResults.map((simulationResult) => [
              simulationResult.measuredDepth.value.toString(),
              simulationMappers.SimulationResult.from(simulationResult).set(
                'simulationId',
                resultSet.simulationId,
              ),
            ]),
          );
    }

    case ACTIONS.DELETE_SIMULATION_RESULTS: {
      if (error) return state;

      return initialState;
    }

    default:
      return state;
  }
};

export default simulationResultReducer;
