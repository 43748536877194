import _isNil from 'lodash/isNil';
import { useDispatch } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Box, CardActions } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FavoriteButton } from 'altus-ui-components';

import { toExecutionSection, toExecutionSimulation } from 'utils/route.util';
import * as simulationActions from 'features/projects/tasks/task/simulation/simulation.actions';
import CardNavigationItem from 'app/components/CardNavigationItem/CardNavigationItem';
import { PlannedIndicator } from './PlannedIndicator';

export const SimulationMenuItem = ({ simulation }) => {
  const { projectId, taskId } = useParams();
  const dispatch = useDispatch();
  const to = toExecutionSimulation(
    projectId,
    taskId,
    simulation.get('simulationId'),
  );
  const match = useRouteMatch(
    `/projects/${projectId}/execution/${taskId}/simulation/${simulation.get(
      'simulationId',
    )}/:simulationSection?`,
  );
  const isSelected = !_isNil(match);

  const onDeleteSimulation = () =>
    dispatch(
      simulationActions.requestDeleteSimulation(
        projectId,
        taskId,
        simulation.get('simulationId'),
        isSelected ? toExecutionSection(projectId, taskId, 'simulation') : null,
      ),
    );

  const onToggleFavorite = (event) => {
    event.preventDefault();

    dispatch(
      simulationActions.requestToggleSimulationFavorite(
        projectId,
        taskId,
        simulation.get('simulationId'),
      ),
    );
  };

  return (
    <Box paddingTop={1} paddingBottom={1} key={simulation.get('simulationId')}>
      <CardNavigationItem
        selected={isSelected}
        to={to}
        data={[
          {
            key: 'BHA',
            value: simulation.getIn(['toolstring', 'name']) ?? 'No BHA',
          },
          {
            key: 'Cable',
            value: simulation.getIn(['cable', 'name']) ?? 'No Cable',
          },
        ]}
        renderHeaderAction={() => (
          <FavoriteButton
            isFavorite={simulation.get('isFavorite')}
            onClick={onToggleFavorite}
          />
        )}
        actions={
          simulation.get('isUpdatedPlanned')
            ? [
                {
                  Icon: DeleteIcon,
                  title: 'Delete',
                  onClick: onDeleteSimulation,
                },
              ]
            : []
        }
        title={simulation.get('name')}
      >
        <CardActions>
          <PlannedIndicator
            simulation={simulation}
            hidden={!simulation.get('isPlanned')}
          />
        </CardActions>
      </CardNavigationItem>
    </Box>
  );
};
