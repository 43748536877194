import { Map } from 'immutable';

const ProjectMobilisationTasksMapper = {
  from: ({ ...projectMobilisationTasks }) =>
    Map({ ...projectMobilisationTasks }),
};

export const ProjectMobilisationMappers = {
  projectMobilisationTasksMapper: ProjectMobilisationTasksMapper,
};
