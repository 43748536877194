import * as colors from '@material-ui/core/colors';
import makeStyles from '@material-ui/styles/makeStyles';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

export const Status = {
  SUCCESS: 1,
  WARNING: 2,
  ERROR: 3,
};

const StatusIndicator = ({ title, status, color, ...rest }) => {
  const classes = useStyles({ status, color });

  return (
    <FiberManualRecord
      titleAccess={title}
      fontSize="medium"
      classes={{
        root: classes.root,
      }}
      {...rest}
    />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    fill: ({ status, color }) => {
      if (color) return color;

      switch (status) {
        case Status.SUCCESS:
          return colors.green[500];
        case Status.WARNING:
          return colors.yellow[500];
        case Status.ERROR:
          return colors.red[500];
        default:
          return colors.grey[500];
      }
    },
  },
}));

export default StatusIndicator;
