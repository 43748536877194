import { Map } from 'immutable';

import { FlowPath } from 'features/projects/tasks/task/simulation/simulation.constants';

import toolMapper from 'features/projects/tool/tool.mappers';
import toolstringMapper from 'features/projects/tasks/task/toolstring/toolstring.mappers';
import { FluidType, RheologyModel } from 'utils/fluids/constants.utils';
import { FluidSelectionType } from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.constants';
import { editorStateToString, stringToEditorState } from 'utils/app.util';

export const flowPathToString = (flowPath) =>
({
  [FlowPath.FLOW_UP]: 'Flow up',
  [FlowPath.FLOW_DOWN]: 'Flow down',
}[flowPath]);

export const fluidTypeToString = (fluidType) =>
({
  [FluidType.GAS]: 'Gas',
  [FluidType.LIQUID]: 'Liquid',
  [FluidType.MULTIPHASE]: 'Multiphase',
}[fluidType]);

export const rheologyModelToString = (rheologyModel) =>
({
  [RheologyModel.NEWTONIAN]: 'Newtonian',
  [RheologyModel.POWER_LAW]: 'Power law',
  [RheologyModel.BINGHAM_PLASTIC]: 'Bingham Plastic',
  [RheologyModel.HERSCHEL_BULKLEY]: 'Herschel–Bulkley',
}[rheologyModel]);

export const fluidSelectionTypeToString = (fluidSelectionType) =>
({
  [FluidSelectionType.AIR]: 'Air',
  [FluidSelectionType.WELLBORE_FLUID]: 'Wellbore Fluid',
}[fluidSelectionType]);

const SimulationMapper = {
  from: ({ cable, toolstring, toolStringId, comment, ...simulation }) =>
    Map({
      toolStringId, // TODO: Having both to not break anything. Remove later.
      toolstringId: toolStringId,
      cable: cable ? toolMapper.Cable.from(cable) : undefined,
      toolstring: toolstring
        ? toolstringMapper.Toolstring.from(toolstring)
        : undefined,
      comment: comment
        ? stringToEditorState(comment)
        : undefined,
      ...simulation,
    }),
  to: ({ toolstringId, comment, ...simulation }) => ({
    ...simulation,
    toolStringId: toolstringId,
    comment: comment
      ? editorStateToString(comment)
      : null,
  }),
};

const SimulationResultMapper = {
  from: ({
    tractorForceRunInHole,
    surfaceTensionRunInHole,
    minCableTensionRunInHole,
    cableHeadTensionRunInHole,
    surfaceTensionPullOutOfHole,
    cableHeadTensionPullOutOfHole,
    maxPickupWeightPullOutOfHole,
    workingLimitPullOutOfHole,
    compressionLimitRunInHole,
    maxSetDownRunInHole,
    weakPointLowSFPullOutOfHole,
    weakPointLowPullOutOfHole,
    weakPointHighPullOutOfHole,
    timestampPullOutOfHole,
    timestampRunInHole,
    surfaceWeightJarFiringLowPullOutOfHole,
    surfaceWeightJarFiringHighPullOutOfHole,
    cableHeadTensionJarFiringLowPullOutOfHole,
    cableHeadTensionJarFiringHighPullOutOfHole,
    stretchRunInHole,
    stretchPullOutOfHole,
    ...simulationResult
  }) =>
    Map({
      runInHole: Map({
        tractorForce: tractorForceRunInHole,
        surfaceTension: surfaceTensionRunInHole,
        minCableTension: minCableTensionRunInHole,
        cableHeadTension: cableHeadTensionRunInHole,
        compressionLimit: compressionLimitRunInHole,
        maxSetDown: maxSetDownRunInHole,
        stretch: stretchRunInHole,
        timestamp: timestampRunInHole,
      }),
      pullOutOfHole: Map({
        workingLimit: workingLimitPullOutOfHole,
        surfaceTension: surfaceTensionPullOutOfHole,
        maxPickupWeight: maxPickupWeightPullOutOfHole,
        cableHeadTension: cableHeadTensionPullOutOfHole,
        weakPointLowSF: weakPointLowSFPullOutOfHole,
        weakPointLow: weakPointLowPullOutOfHole,
        weakPointHigh: weakPointHighPullOutOfHole,
        surfaceWeightJarFiringLow: surfaceWeightJarFiringLowPullOutOfHole,
        surfaceWeightJarFiringHigh: surfaceWeightJarFiringHighPullOutOfHole,
        cableHeadTensionJarFiringLow: cableHeadTensionJarFiringLowPullOutOfHole,
        cableHeadTensionJarFiringHigh:
          cableHeadTensionJarFiringHighPullOutOfHole,
        stretch: stretchPullOutOfHole,
        timestamp: timestampPullOutOfHole,
      }),
      ...simulationResult,
    }),
};

const SimulationParameterMapper = {
  from: (simulationParameter) =>
    Map({
      ...simulationParameter,
    }),
  to: (simulationParameter) => ({
    ...simulationParameter,
  }),
};

const SimulationFluidMapper = {
  initial: () =>
    Map({
      newtonianViscosity: 1,
      fluidType: FluidType.LIQUID,
      rheologyModel: RheologyModel.NEWTONIAN,
    }),
  from: ({ ...simulationFluid }) =>
    Map({
      ...simulationFluid,
    }),
  to: (simulationFluid) => ({
    ...simulationFluid,
  }),
};

const SimulationDynamicToolParameterMapper = {
  from: (simulationDynamicToolParameter) =>
    Map({
      ...simulationDynamicToolParameter,
    }),
  to: (simulationDynamicToolParameter) => ({
    ...simulationDynamicToolParameter,
  }),
};

const simulationMappers = {
  Simulation: SimulationMapper,
  SimulationFluid: SimulationFluidMapper,
  SimulationResult: SimulationResultMapper,
  SimulationParameter: SimulationParameterMapper,
  SimulationDynamicToolParameter: SimulationDynamicToolParameterMapper,
};

export default simulationMappers;
