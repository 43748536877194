import { Formik, Form } from 'formik';

import withStyles from '@material-ui/styles/withStyles';

import { Grid } from '@material-ui/core';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import { EMPTY_LIST } from 'app/app.constants';

export const Filters = {
  TOOL_GROUPS: 'toolGroupId',
};

const shouldSubmit = ({ isValid }) => isValid;

const getKey = (item) => item.get('id');
const getName = (item) => item.get('name')?.toUpperCase();

const SurfaceEquipmentFilter = ({
  classes,
  onSubmit,
  toolGroups = EMPTY_LIST,
  initialValues,
}) => (
  <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues}>
    <Form>
      <AutoSaveFormik timeout={1000} shouldSubmit={shouldSubmit}>
        <Grid container className={classes.root}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container xs spacing={2} wrap="nowrap">
              <Grid xs item>
                <SelectTextFieldFormik
                  getItemName={getName}
                  getItemValue={getKey}
                  margin="none"
                  label="Tool Group"
                  items={toolGroups}
                  name={Filters.TOOL_GROUPS}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AutoSaveFormik>
    </Form>
  </Formik>
);

const styles = {
  root: {
    marginBottom: 15,
  },
  leftIcon: {
    marginRight: 10,
  },
};

export default withStyles(styles)(SurfaceEquipmentFilter);
