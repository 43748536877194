import * as yup from 'yup';
import { compose } from 'redux';
import Close from '@material-ui/icons/Close';
import { connect, useDispatch } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCallback, useRef, useState, useEffect } from 'react';

import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';

import { Button } from 'app/components/withTooltip';
import { LoadingButton, LoadingOverlay } from 'altus-ui-components';

import { EMPTY_MAP, EMPTY_STRING } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';

import {
  deleteTool3DObject,
  deleteToolImage,
  uploadTool3DObject,
  uploadToolImage,
} from 'features/equipment/equipment.actions';

import {
  EDITABLE_TOOL_PROPERTIES,
  EQUIPMENT_ACTIONS,
} from 'features/equipment/equipment.constants';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import TextAreaFieldFormik from 'app/components/form/formik/TextAreaFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import { AutocompleteTextFieldAdapter } from './components/AutocompleteTextFieldAdapter';
import {
  getUnitForProperty,
  unitPlaceholder,
} from 'features/equipment/equipment.helpers';
import SwitchFormik from 'app/components/form/formik/SwitchFormik';
import YesNoSwitchFormik from 'app/components/form/formik/YesNoSwitchFormik';
import { SystemPermission } from 'app/app.constants';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';

export const FormFields = {
  M3_ITEM: 'm3Item',
  NAME: 'name',
  SUPPLIER: 'supplier',
  SUPPLIER_PART_NUMBER: 'supplierPartNumber',
  GROUP: 'group',
  OUTER_DIAMETER: 'outerDiameter',
  INNER_DIAMETER: 'innerDiameter',
  LENGTH: 'length',
  WEIGHT: 'weight',
  TOP_CONNECTOR: 'topConnector',
  BOTTOM_CONNECTOR: 'bottomConnector',
  FISHNECK: 'fishneck',
  SAFE_WORKING_LOAD: 'safeWorkingLoad',
  YIELD: 'yield',
  DESCRIPTION: 'description',
  FILE: 'toolImageFile',
  TOOL_IMAGE_ID: 'toolImageId',
  AXIAL_FRICTION_REDUCED_TO: 'axialFrictionReducedTo',
  MAXIMUM_OD: 'maximumOD',
  MAXIMUM_STANDOFF_FORCE: 'maximumStandoffForce',
  MINIMUM_OD: 'minimumOD',
  MINIMUM_STANDOFF_FORCE: 'minimumStandoffForce',
  WEIGHT_ABOVE_JARRING_POINT_FACTOR: 'weightAboveJarringPointFactor',
  JAR_ACTIVATION_LIMIT_LOW: 'jarActivationLimitLow',
  JAR_ACTIVATION_LIMIT_HIGH: 'jarActivationLimitHigh',
  ADDITIONAL_NORMAL_FORCE: 'additionalNormalForce',
  IS_KNUCKLE_JOINT: 'isKnuckleJoint',
  IS_VERIFIED: 'isVerified',
  TOOL_3D_OBJECT_ID: 'tool3DObjectId',
  FILE_3D: 'tool3DObjectFile',
};

const MAX_FILE_SIZE = 512 * 1024;
const MAX_3D_OBJECT_FILE_SIZE = 10 * 1024 * 1024;

const getKey = (item) => item.get('id');
const getName = (item) => item.get('name')?.toUpperCase();

const M3ModalForm = ({
  edit,
  tool = EMPTY_MAP,
  classes,
  saveTool,
  dataState,
  toolGroups,
  toolSuppliers,
  toolConnectors,
  initialFormValues,
  unitsOfMeasurePreference,
  selectedCustomUnitsOfMeasure,
}) => {
  //#region placeholders for inputs
  const initialPlaceholders = {
    [FormFields.AXIAL_FRICTION_REDUCED_TO]: unitPlaceholder(
      tool,
      'axialFrictionReducedTo',
      '0 - 1',
    ),
    [FormFields.MAXIMUM_OD]: unitPlaceholder(tool, 'maximumOD'),
    [FormFields.NAME]:
      tool && !tool.isEmpty() ? tool.get('name') : 'Enter name',
    [FormFields.SUPPLIER_PART_NUMBER]:
      tool && !tool.isEmpty()
        ? tool.get('supplierPartNumber')
        : 'Enter part number',
    [FormFields.OUTER_DIAMETER]: unitPlaceholder(tool, 'outerDiameter'),
    [FormFields.INNER_DIAMETER]: unitPlaceholder(tool, 'innerDiameter'),
    [FormFields.LENGTH]:
      tool && !tool.isEmpty()
        ? `${tool.getIn(['length', 'value'])} ${tool.getIn(['length', 'unit'])}`
        : '',
    [FormFields.WEIGHT]:
      tool && !tool.isEmpty()
        ? `${tool.getIn(['weight', 'value'])} ${tool.getIn(['weight', 'unit'])}`
        : '',
    [FormFields.FISHNECK]:
      tool && !tool.isEmpty()
        ? `${tool.getIn(['fishNeck', 'value'])} ${tool.getIn([
            'fishNeck',
            'unit',
          ])}`
        : '',
    [FormFields.SAFE_WORKING_LOAD]: unitPlaceholder(tool, 'safeWorkingLoad'),
    [FormFields.YIELD]: unitPlaceholder(tool, 'yieldValue'),
    [FormFields.DESCRIPTION]:
      tool && !tool.isEmpty() ? tool.get('description') : '',
    [FormFields.MINIMUM_OD]: unitPlaceholder(tool, 'minimumOD'),
    [FormFields.MAXIMUM_STANDOFF_FORCE]: unitPlaceholder(
      tool,
      'maximumStandoffForce',
    ),
    [FormFields.MINIMUM_STANDOFF_FORCE]: unitPlaceholder(
      tool,
      'minimumStandoffForce',
    ),
    [FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR]: unitPlaceholder(
      tool,
      'weightAboveJarringPointFactor',
      '0 - 1',
    ),
    [FormFields.JAR_ACTIVATION_LIMIT_LOW]: unitPlaceholder(
      tool,
      'jarActivationLimitLow',
    ),
    [FormFields.JAR_ACTIVATION_LIMIT_HIGH]: unitPlaceholder(
      tool,
      'jarActivationLimitHigh',
    ),
    [FormFields.ADDITIONAL_NORMAL_FORCE]: unitPlaceholder(
      tool,
      'additionalNormalForce',
    ),
  };
  //#endregion

  const initialEditFormValues = {
    [FormFields.M3_ITEM]: tool.get('m3ItemNumber'),
    [FormFields.NAME]: tool.get('name'),
    [FormFields.SUPPLIER]: tool.get('supplier'),
    [FormFields.SUPPLIER_PART_NUMBER]:
      tool.get('supplierPartNumber') ?? EMPTY_STRING,
    [FormFields.GROUP]: tool.get('toolGroupId'),
    [FormFields.OUTER_DIAMETER]:
      tool.getIn(['outerDiameter', 'value']) ?? EMPTY_STRING,
    [FormFields.INNER_DIAMETER]:
      tool.getIn(['innerDiameter', 'value']) ?? EMPTY_STRING,
    [FormFields.LENGTH]: tool.getIn(['length', 'value']) ?? EMPTY_STRING,
    [FormFields.WEIGHT]: tool.getIn(['weight', 'value']) ?? EMPTY_STRING,
    [FormFields.TOP_CONNECTOR]: tool.get('topConnector'),
    [FormFields.BOTTOM_CONNECTOR]: tool.get('bottomConnector'),
    [FormFields.FISHNECK]: tool.getIn(['fishNeck', 'value']) ?? EMPTY_STRING,
    [FormFields.SAFE_WORKING_LOAD]:
      tool.getIn(['safeWorkingLoad', 'value']) ?? EMPTY_STRING,
    [FormFields.YIELD]: tool.getIn(['yieldValue', 'value']) ?? EMPTY_STRING,
    [FormFields.DESCRIPTION]: tool.get('description') ?? EMPTY_STRING,
    [FormFields.FILE]: null,
    [FormFields.TOOL_IMAGE_ID]: tool.get('toolImageId') ?? EMPTY_STRING,
    [FormFields.MAXIMUM_OD]: tool.getIn(['maximumOD', 'value']) ?? EMPTY_STRING,
    [FormFields.MAXIMUM_STANDOFF_FORCE]:
      tool.getIn(['maximumStandoffForce', 'value']) ?? EMPTY_STRING,
    [FormFields.MINIMUM_OD]: tool.getIn(['minimumOD', 'value']) ?? EMPTY_STRING,
    [FormFields.MINIMUM_STANDOFF_FORCE]:
      tool.getIn(['minimumStandoffForce', 'value']) ?? EMPTY_STRING,
    [FormFields.JAR_ACTIVATION_LIMIT_LOW]:
      tool.getIn(['jarActivationLimitLow', 'value']) ?? EMPTY_STRING,
    [FormFields.JAR_ACTIVATION_LIMIT_HIGH]:
      tool.getIn(['jarActivationLimitHigh', 'value']) ?? EMPTY_STRING,
    [FormFields.ADDITIONAL_NORMAL_FORCE]:
      tool.getIn(['additionalNormalForce', 'value']) ?? EMPTY_STRING,
    [FormFields.IS_KNUCKLE_JOINT]: tool.get('isKnuckleJoint'),
    [FormFields.IS_VERIFIED]: tool.get('isVerified'),
    [FormFields.AXIAL_FRICTION_REDUCED_TO]:
      tool.getIn(['axialFrictionReducedTo', 'value']) ?? EMPTY_STRING,
    [FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR]:
      tool.getIn(['weightAboveJarringPointFactor', 'value']) ?? EMPTY_STRING,
    [FormFields.TOOL_3D_OBJECT_ID]: tool.get('tool3DObjectId') ?? EMPTY_STRING,
  };

  const [placeholders, setPlaceholders] = useState(initialPlaceholders);

  const resetPlaceholder = (fieldName) => {
    setPlaceholders((prevPlaceholders) => ({
      ...prevPlaceholders,
      [fieldName]: null,
    }));
  };

  const schema = yup.object().shape({
    additionalNormalForce: yup.number().typeError('Must be a number'),
    jarActivationLimitHigh: yup.number().typeError('Must be a number'),
    jarActivationLimitLow: yup.number().typeError('Must be a number'),
    weightAboveJarringPointFactor: yup
      .number()
      .nullable(true)
      .min(0, 'Value must be greater than or equal to 0')
      .max(1, 'Value must be less than or equal to 1')
      .typeError('Must be a number'),
    minimumStandoffForce: yup.number().typeError('Must be a number'),
    maximumStandoffForce: yup.number().typeError('Must be a number'),
    minimumOD: yup.number().typeError('Must be a number'),
    maximumOD: yup.number().typeError('Must be a number'),
    axialFrictionReducedTo: yup
      .number()
      .nullable(true)
      .min(0, 'Value must be greater than or equal to 0')
      .max(1, 'Value must be less than or equal to 1'),
    name: yup.string().required('Required'),
    supplier: yup.string().required('Required').typeError('Required'),
    m3Item: yup.string().required('Required').typeError('Required'),
    supplierPartNumber: yup.string(),
    fishneck: yup.string(),
    group: yup.number().required('Required').typeError('Required'),
    length: yup.number().required('Required').max(60),
    outerDiameter: yup.number().required('Required').typeError('Required'),
    innerDiameter: yup.number().required('Required').typeError('Required'),
    safeWorkingLoad: yup.string(),
    weight: yup.string().required('Required'),
    yield: yup.string(),
    toolImageFile: yup
      .mixed()
      .nullable()
      .notRequired()
      .test('FILE_SIZE', 'Uploaded file is too big', (value) => {
        if (value && fileRef.current) {
          if (fileRef.current.files[0].size <= MAX_FILE_SIZE) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .test('FILE_FORMAT', 'Only .png is allowed', (value) => {
        if (value && fileRef.current) {
          if (fileRef.current.files[0].type === 'image/png') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
    tool3DObjectFile: yup
      .mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        `Max allowed file size is ${Math.floor(
          MAX_3D_OBJECT_FILE_SIZE / 1024 / 1024,
        )}MB`,
        (value) => {
          if (value && file3DRef.current) {
            if (file3DRef.current.files[0].size <= MAX_3D_OBJECT_FILE_SIZE) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        },
      ),
  });

  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const file3DRef = useRef(null);

  const [autoCompleteValue, setAutocompleteValue] = useState(null);

  const setValue = useCallback((setValue) => {
    setValue(FormFields.TOOL_IMAGE_ID, EMPTY_STRING);
    setValue(FormFields.FILE, null);
  }, []);

  const set3DFormValue = useCallback((setValue) => {
    setValue(FormFields.TOOL_3D_OBJECT_ID, EMPTY_STRING);
    setValue(FormFields.FILE_3D, null);
  }, []);

  const [disableValidation, setDisableValidation] = useState(true);
  const { userPermissions } = useSystemPermissions();

  useEffect(() => {
    setDisableValidation(
      !userPermissions.includes(SystemPermission.ITEM_VERIFICATION),
    );
  }, [userPermissions, disableValidation, setDisableValidation]);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={schema}
      initialValues={edit ? initialEditFormValues : initialFormValues}
    >
      {({ isValid, values }) => (
        <Form>
          <Grid container className={classes.root}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                xs={12}
                item
                container
                spacing={2}
                wrap="nowrap"
                className={classes.form}
              >
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Name*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.NAME}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.NAME]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.NAME);
                                    return form.setFieldValue(
                                      FormFields.NAME,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>M3 Item lookup*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.M3_ITEM}>
                      {({ form }) => {
                        return (
                          <AutocompleteTextFieldAdapter
                            autoCompleteValue={autoCompleteValue}
                            setAutocompleteValue={setAutocompleteValue}
                            form={form}
                            placeholder={tool?.get('m3ItemNumber')}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>
                {autoCompleteValue ? (
                  <Grid xs item container className={classes.row}>
                    <Grid
                      item
                      xs={6}
                      container
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography>{autoCompleteValue.m3ItemName}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography>{autoCompleteValue.m3ItemNumber}</Typography>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Supplier*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      label={
                        tool && !tool.isEmpty() ? tool.get('supplier') : ''
                      }
                      getItemName={getName}
                      getItemValue={getName}
                      margin="none"
                      items={toolSuppliers}
                      name={FormFields.SUPPLIER}
                    />
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Supplier part number</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.SUPPLIER_PART_NUMBER}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.SUPPLIER_PART_NUMBER]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.SUPPLIER_PART_NUMBER,
                                    );
                                    return form.setFieldValue(
                                      FormFields.SUPPLIER_PART_NUMBER,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Tool group*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      getItemName={getName}
                      getItemValue={getKey}
                      label={
                        tool && !tool.isEmpty() ? tool.get('toolGroupName') : ''
                      }
                      margin="none"
                      items={toolGroups}
                      name={FormFields.GROUP}
                    />
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Outer diameter*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.OUTER_DIAMETER}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          type="number"
                          margin="none"
                          placeholder={placeholders[FormFields.OUTER_DIAMETER]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.OUTER_DIAMETER);

                                    return form.setFieldValue(
                                      FormFields.OUTER_DIAMETER,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.OUTER_DIAMETER,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Inner diameter*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.INNER_DIAMETER}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          type="number"
                          margin="none"
                          placeholder={placeholders[FormFields.INNER_DIAMETER]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.INNER_DIAMETER);
                                    return form.setFieldValue(
                                      FormFields.INNER_DIAMETER,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.INNER_DIAMETER,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Length*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.LENGTH}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.LENGTH]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.LENGTH);

                                    return form.setFieldValue(
                                      FormFields.LENGTH,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.LENGTH,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Weight*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.WEIGHT}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.WEIGHT]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.WEIGHT);
                                    return form.setFieldValue(
                                      FormFields.WEIGHT,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.WEIGHT,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Top connector</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      label={
                        tool && !tool.isEmpty()
                          ? tool.get('topConnectorDescription')
                          : ''
                      }
                      getItemName={getName}
                      getItemValue={getKey}
                      margin="none"
                      items={toolConnectors}
                      name={FormFields.TOP_CONNECTOR}
                    />
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Bottom connector</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      label={
                        tool && !tool.isEmpty()
                          ? tool.get('bottomConnectorDescription')
                          : ''
                      }
                      getItemName={getName}
                      getItemValue={getKey}
                      margin="none"
                      items={toolConnectors}
                      name={FormFields.BOTTOM_CONNECTOR}
                    />
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Fishneck</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.FISHNECK}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.FISHNECK]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.FISHNECK);
                                    return form.setFieldValue(
                                      FormFields.FISHNECK,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.FISHNECK,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Safe working load</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.SAFE_WORKING_LOAD}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.SAFE_WORKING_LOAD]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.SAFE_WORKING_LOAD,
                                    );

                                    return form.setFieldValue(
                                      FormFields.SAFE_WORKING_LOAD,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.SWL,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Yield</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.YIELD}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.YIELD]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.YIELD);

                                    return form.setFieldValue(
                                      FormFields.YIELD,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.YIELD,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Description</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.DESCRIPTION}>
                      {({ form, ...formik }) => (
                        <TextAreaFieldFormik
                          form={form}
                          placeholder={placeholders[FormFields.DESCRIPTION]}
                          margin="none"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.DESCRIPTION);
                                    return form.setFieldValue(
                                      FormFields.DESCRIPTION,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Axial friction reduced to </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.AXIAL_FRICTION_REDUCED_TO}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.AXIAL_FRICTION_REDUCED_TO]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.AXIAL_FRICTION_REDUCED_TO,
                                    );

                                    return form.setFieldValue(
                                      FormFields.AXIAL_FRICTION_REDUCED_TO,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.AXIAL_FRICTION_REDUCED_TO,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Maximum OD</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.MAXIMUM_OD}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.MAXIMUM_OD]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.MAXIMUM_OD);
                                    return form.setFieldValue(
                                      FormFields.MAXIMUM_OD,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.MAXIMUM_OD,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Minimum OD</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.MINIMUM_OD}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.MINIMUM_OD]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.MINIMUM_OD);

                                    return form.setFieldValue(
                                      FormFields.MINIMUM_OD,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.MINIMUM_OD,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Maximum standoff force</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.MAXIMUM_STANDOFF_FORCE}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.MAXIMUM_STANDOFF_FORCE]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.MAXIMUM_STANDOFF_FORCE,
                                    );
                                    return form.setFieldValue(
                                      FormFields.MAXIMUM_STANDOFF_FORCE,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.MAXIMUM_STANDOFF_FORCE,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Minimum standoff force</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.MINIMUM_STANDOFF_FORCE}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.MINIMUM_STANDOFF_FORCE]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.MINIMUM_STANDOFF_FORCE,
                                    );
                                    return form.setFieldValue(
                                      FormFields.MINIMUM_STANDOFF_FORCE,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.MINIMUM_STANDOFF_FORCE,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Weight above jarring point factor</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[
                              FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR
                            ]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR,
                                    );
                                    return form.setFieldValue(
                                      FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.WEIGHT_ABOVE_JARRING_POINT_FACTOR,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Jar activation limit low</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.JAR_ACTIVATION_LIMIT_LOW}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.JAR_ACTIVATION_LIMIT_LOW]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.JAR_ACTIVATION_LIMIT_LOW,
                                    );
                                    return form.setFieldValue(
                                      FormFields.JAR_ACTIVATION_LIMIT_LOW,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_LOW,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Jar activation limit high</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.JAR_ACTIVATION_LIMIT_HIGH}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.JAR_ACTIVATION_LIMIT_HIGH]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.JAR_ACTIVATION_LIMIT_HIGH,
                                    );
                                    return form.setFieldValue(
                                      FormFields.JAR_ACTIVATION_LIMIT_HIGH,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_HIGH,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Additional normal force</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.ADDITIONAL_NORMAL_FORCE}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.ADDITIONAL_NORMAL_FORCE]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.ADDITIONAL_NORMAL_FORCE,
                                    );
                                    return form.setFieldValue(
                                      FormFields.ADDITIONAL_NORMAL_FORCE,
                                      null,
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.ADDITIONAL_NORMAL_FORCE,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Is knuckle joint</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.IS_KNUCKLE_JOINT}>
                      {({ form, ...formik }) => (
                        <SwitchFormik form={form} {...formik} />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Verified</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.IS_VERIFIED}>
                      {({ form, ...formik }) => (
                        <YesNoSwitchFormik
                          disabled={disableValidation}
                          form={form}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Upload image - .png only</Typography>
                  </Grid>
                  <Grid item container xs={6}>
                    <Field
                      name={FormFields.FILE}
                      innerRef={fileRef}
                      type="file"
                    >
                      {({ form, ...formik }) => (
                        <>
                          <Grid item xs={4}>
                            <input
                              ref={fileRef}
                              hidden
                              type="file"
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                              onChange={(event) => {
                                if (event.target.files.length > 0) {
                                  form.setTouched({
                                    ...form.touched,
                                    [FormFields.FILE]: true,
                                  });
                                  yup
                                    .reach(schema, FormFields.FILE)
                                    .isValid(event.target.files[0])
                                    .then((valid) => {
                                      form.setFieldValue(
                                        FormFields.FILE,
                                        event.target.files[0],
                                      );
                                      valid === true &&
                                        dispatch(
                                          uploadToolImage(
                                            event.target.files[0],
                                          ),
                                        ).then((payload) => {
                                          form.setFieldValue(
                                            FormFields.TOOL_IMAGE_ID,
                                            payload.toolImageId,
                                          );
                                        });
                                    });
                                }
                              }}
                              {...formik}
                            />
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                fileRef.current.click();
                              }}
                            >
                              Upload
                            </Button>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={6}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="body2" noWrap>
                              {values.toolImageFile &&
                                values.toolImageFile.name}
                            </Typography>
                          </Grid>
                          {values.toolImageFile && values.toolImageFile.name && (
                            <Grid
                              item
                              xs={2}
                              container
                              className={classes.removeUploadContainer}
                            >
                              <IconButton
                                size="small"
                                title="Remove uploaded file"
                                onClick={() => {
                                  if (values.toolImageId) {
                                    dispatch(
                                      deleteToolImage(values.toolImageId),
                                    ).then(setValue(form.setFieldValue));
                                  } else {
                                    setValue(form.setFieldValue);
                                  }
                                }}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </Grid>
                          )}
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center" />
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.errorText}>
                      <ErrorMessage
                        name={FormFields.FILE}
                        render={(msg) => <div>{msg}</div>}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                {/* for 3DObject upload */}
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Upload 3D Object</Typography>
                  </Grid>
                  <Grid item container xs={6}>
                    <Field
                      name={FormFields.FILE_3D}
                      innerRef={file3DRef}
                      type="file"
                    >
                      {({ form, ...formik }) => (
                        <>
                          <Grid item xs={4}>
                            <input
                              ref={file3DRef}
                              hidden
                              type="file"
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                              onChange={(event) => {
                                if (event.target.files.length > 0) {
                                  form.setTouched({
                                    ...form.touched,
                                    [FormFields.FILE_3D]: true,
                                  });
                                  yup
                                    .reach(schema, FormFields.FILE_3D)
                                    .isValid(event.target.files[0])
                                    .then((valid) => {
                                      form.setFieldValue(
                                        FormFields.FILE_3D,
                                        event.target.files[0],
                                      );
                                      valid === true &&
                                        dispatch(
                                          uploadTool3DObject(
                                            event.target.files[0],
                                          ),
                                        ).then((payload) => {
                                          form.setFieldValue(
                                            FormFields.TOOL_3D_OBJECT_ID,
                                            payload.tool3DObjectId,
                                          );
                                        });
                                    });
                                }
                              }}
                              {...formik}
                            />
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                file3DRef.current.click();
                              }}
                            >
                              Upload
                            </Button>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={6}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="body2" noWrap>
                              {values.tool3DObjectFile &&
                                values.tool3DObjectFile.name}
                            </Typography>
                          </Grid>
                          {values.tool3DObjectFile &&
                            values.tool3DObjectFile.name && (
                              <Grid
                                item
                                xs={2}
                                container
                                className={classes.removeUploadContainer}
                              >
                                <IconButton
                                  size="small"
                                  title="Remove uploaded file"
                                  onClick={() => {
                                    if (values.tool3DObjectId) {
                                      dispatch(
                                        deleteTool3DObject(
                                          values.tool3DObjectId,
                                        ),
                                      ).then(
                                        set3DFormValue(form.setFieldValue),
                                      );
                                    } else {
                                      set3DFormValue(form.setFieldValue);
                                    }
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </Grid>
                            )}
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center" />
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.errorText}>
                      <ErrorMessage
                        name={FormFields.FILE_3D}
                        render={(msg) => <div>{msg}</div>}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <LoadingOverlay dataState={dataState} text={'Loading...'} />
          </Grid>
          <Grid item container className={classes.submitBtn}>
            <LoadingButton
              color="primary"
              variant="contained"
              disabled={!isValid}
              onClick={() => saveTool(values)}
            >
              Save
            </LoadingButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const styles = (theme) => ({
  root: {
    paddingTop: 15,
    marginBottom: 15,
  },
  leftIcon: {
    marginRight: 10,
  },
  form: {
    flexDirection: 'column',
  },
  formLegend: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  submitBtn: {
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
  },
  errorText: {
    color: '#f44336',
  },
  removeUpload: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeUploadContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(
      state,
      EQUIPMENT_ACTIONS.CREATE_TOOL,
      EQUIPMENT_ACTIONS.UPDATE_TOOL,
      EQUIPMENT_ACTIONS.UPLOAD_IMAGE,
      EQUIPMENT_ACTIONS.DELETE_IMAGE,
      EQUIPMENT_ACTIONS.M3_ITEM_SEARCH,
    ),
  })),
  withStyles(styles),
)(M3ModalForm);
