import { useCallback, useMemo, React } from 'react';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { Table } from 'altus-ui-components';

import ProjectTableRow from 'features/projects/components/ProjectTableRow';
import ProjectsTableRowMobileView from 'features/mobile/projects/ProjectsTableRowMobileView';

const ProjectsTable = ({
  title,
  classes,
  projects,
  displayLocationMarkers = true,
  zoomInAndOutByProjectId,
  isArchiveProject,
  setArchiveProjects,
  isMobileAppView = false,
  toggleProjectsList,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 12,
        id: 'title',
        Header: (
          <Typography
            variant="h6"
            className={isMobileAppView ? classes.mobileAppTitle : classes.title}
          >
            {title}
          </Typography>
        ),
      },
    ],
    [title, classes, isMobileAppView],
  );

  const renderTableRowComponent = useCallback(
    (props) => {
      return isMobileAppView ? (
        <ProjectsTableRowMobileView
          toggleProjectsList={toggleProjectsList}
          {...props}
        />
      ) : (
        <ProjectTableRow
          {...props}
          displayLocationMarker={displayLocationMarkers}
          zoomInAndOutByProjectId={zoomInAndOutByProjectId}
          isArchiveProject={isArchiveProject}
          setArchiveProjects={setArchiveProjects}
        />
      );
    },
    [
      setArchiveProjects,
      displayLocationMarkers,
      isArchiveProject,
      zoomInAndOutByProjectId,
      isMobileAppView,
      toggleProjectsList,
    ],
  );

  return (
    <Table
      stickyHeader
      items={projects}
      columns={columns}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

const styles = () => ({
  title: {
    fontSize: '1.125rem',
  },
  mobileAppTitle: {
    color: '#90CAF9',
  },
});

export default withStyles(styles)(ProjectsTable);
