import { Map } from 'immutable';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/styles/withStyles';
import { Grid, DialogContent } from '@material-ui/core';

import { EMPTY_MAP } from 'app/app.constants';
import TaskToolstringPreview from 'features/projects/tasks/task/toolstring/components/TaskToolstringPreview';
import ToolstringItemHighlightProvider from 'features/projects/tasks/task/toolstring/components/ToolstringItemHighlightProvider';
import TaskToolstringEditorToolsTable from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditorToolsTable';
import TaskToolstringEditorContentHeader from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditorDetailsHeader';

const TaskToolstringEditorContent = ({
  taskId,
  classes,
  projectId,
  onAddTools,
  toolstring,
  toolstringId,
  templateEditor = false,
  toolConnectors,
  toolstringTools,
  onDuplicateItem,
  onAddToolAttachments,
  toolstringSections,
  onUpdateToolstring,
  onSortToolstringTool,
  updateItemProperties,
  onDeleteToolstringTool,
  onAddToolAssemblyTools,
  onDeleteToolAssemblyTool,
  onDeleteToolstringAttachment,
  showHeader = true,
  hiddenColumns,
  height,
  placeHolderAlignBha,
  columns = 2,
  paddingTopPreview,
  widthSerialColumn,
  showBreakPoint,
  showCustomActions,
  hideDragHandle,
  disabledSerialNumbers = false,
}) => {
  return (
    <Grid
      container
      component={DialogContent}
      classes={{ root: classes.root }}
      style={{ height: height }}
    >
      <ToolstringItemHighlightProvider>
        <Grid container item xs={columns} className={classes.toolstringPreview}>
          <TaskToolstringPreview
            editable
            taskId={taskId}
            projectId={projectId}
            toolstringId={toolstringId}
            toolstringSections={toolstringSections}
            placeHolderAlignBha={placeHolderAlignBha}
            paddingTopPreview={paddingTopPreview}
            showBreakPoint={showBreakPoint}
          />
        </Grid>
        <Grid container item xs={10} direction="column">
          <Grid item container>
            {showHeader && (
              <TaskToolstringEditorContentHeader
                toolstring={toolstring}
                onAddTools={onAddTools}
                onUpdateToolstring={onUpdateToolstring}
              />
            )}
          </Grid>
          <Grid item container xs>
            <TaskToolstringEditorToolsTable
              toolConnectors={toolConnectors}
              toolstringTools={toolstringTools}
              onDuplicateItem={onDuplicateItem}
              onAddToolAttachments={onAddToolAttachments}
              onSortToolstringTool={onSortToolstringTool}
              updateItemProperties={updateItemProperties}
              onDeleteToolstringTool={onDeleteToolstringTool}
              onAddToolAssemblyTools={onAddToolAssemblyTools}
              onDeleteToolAssemblyTool={onDeleteToolAssemblyTool}
              onDeleteToolstringAttachment={onDeleteToolstringAttachment}
              hiddenColumns={hiddenColumns}
              widthSerialColumn={widthSerialColumn}
              templateEditor={templateEditor}
              showCustomActions={showCustomActions}
              projectId={projectId}
              taskId={taskId}
              toolstringId={toolstringId}
              hideDragHandle={hideDragHandle}
              disabledSerialNumbers={disabledSerialNumbers}
            />
          </Grid>
        </Grid>
      </ToolstringItemHighlightProvider>
    </Grid>
  );
};
TaskToolstringEditorContent.defaultProps = {
  toolstring: EMPTY_MAP,
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  toolstringPreview: {
    height: '100%',
  },
  [theme.breakpoints.up('xs')]: {
    // Mobile
    toolstringPreview: {
      height: '81%',
    },
  },
  [theme.breakpoints.up('sm')]: {
    // Tablet
    toolstringPreview: {
      height: '88%',
    },
  },
  [theme.breakpoints.up('md')]: {
    // Desktop
    toolstringPreview: {
      height: '88%',
    },
  },
  [theme.breakpoints.up('lg')]: {
    // HD
    toolstringPreview: {
      height: '95%',
    },
  },
  [theme.breakpoints.up('xl')]: {
    // Full HD
    toolstringPreview: {
      height: '100%',
    },
  },
  [theme.breakpoints.up('xxl')]: {
    // 2K
    toolstringPreview: {
      height: '100%',
    },
  },
  [theme.breakpoints.up('xxxl')]: {
    // 4K
    toolstringPreview: {
      height: '100%',
    },
  },
});

TaskToolstringEditorContent.propTypes = {
  taskId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  toolstringId: PropTypes.string.isRequired,
  toolstring: PropTypes.instanceOf(Map).isRequired,
};

export default compose(withStyles(styles))(TaskToolstringEditorContent);
