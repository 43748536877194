import { Map, OrderedMap } from 'immutable';

import { WellboreSectionType } from 'features/wells/sections/wellboreSection.constants';

export const wellboreSectionTypeToString = (wellboreSectionType) =>
  ({
    [WellboreSectionType.TUBING]: 'Tubing',
    [WellboreSectionType.CASING]: 'Casing',
    [WellboreSectionType.LINER]: 'Liner',
    [WellboreSectionType.OPEN_HOLE]: 'Open Hole',
    [WellboreSectionType.DRILLPIPE]: 'Drillpipe',
    [WellboreSectionType.RISER]: 'Riser',
  }[wellboreSectionType]);

export const wellboreSectionFromDto = ({ wellboreSectionNipples, ...rest }) =>
  Map({
    wellboreSectionNipples: wellboreSectionNipplesFromDto(
      wellboreSectionNipples,
    ),
    ...rest,
  });

export const wellboreSectionsFromDto = (wellboreSections) =>
  OrderedMap(
    wellboreSections.map((wellboreSection) => [
      wellboreSection.wellboreSectionId.toString(),
      wellboreSectionFromDto(wellboreSection),
    ]),
  );

export const wellboreSectionNipplesFromDto = (wellboreSectionNipples) =>
  OrderedMap(
    wellboreSectionNipples.map((wellboreSectionNipple) => [
      wellboreSectionNipple.wellboreSectionNippleId.toString(),
      wellboreSectionNippleFromDto(wellboreSectionNipple),
    ]),
  );

export const wellboreSectionNippleFromDto = ({ name, ...rest }) =>
  Map({
    type: name,
    isNipple: true,
    ...rest,
  });

export const wellboreSectionToDto = ({
  top,
  type,
  bottom,
  wellboreId,
  innerDiameter,
  frictionFactorIn,
  frictionFactorOut,
  wellboreSectionId,
}) => ({
  top,
  type,
  bottom,
  wellboreId,
  innerDiameter,
  frictionFactorIn,
  frictionFactorOut,
  wellboreSectionId,
});

export const wellboreSectionNippleToDto = ({
  type,
  top,
  length,
  innerDiameter,
  wellboreSectionId,
}) => ({
  top,
  length,
  name: type,
  innerDiameter,
  wellboreSectionId,
});

const wellboreSectionsMappers = {
  WellboreSection: {
    to: wellboreSectionToDto,
    from: wellboreSectionFromDto,
  },
  WellboreSectionNipple: {
    to: wellboreSectionNippleToDto,
    from: wellboreSectionNippleFromDto,
  },
  WellboreSectionNipples: {
    from: wellboreSectionNipplesFromDto,
  },
};

export default wellboreSectionsMappers;
