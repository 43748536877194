import { createSelector } from 'utils/app.util';
import { getSimulationCompressionDepth } from './simulation.utils';

const getSimulationResultSelector = (state) =>
  state.getIn(['entity', 'simulationResult']);

const getSimulationsSelector = (state) => state.getIn(['entity', 'simulation']);

const getSimulationFluidSelector = (state) =>
  state.getIn(['entity', 'simulationFluid']);

const getWellboreSectionSelector = (state) =>
  state.getIn(['entity', 'wellboreSection']);

export const getSimulationFromState = createSelector(
  (state, simulationId) =>
    state.getIn(['entity', 'simulation', simulationId?.toString()]),
  (simulation) => simulation,
);

export const getSimulationsForTaskFromState = createSelector(
  getSimulationsSelector,
  (_, taskId) => taskId,
  (simulations, taskId) =>
    simulations
      .groupBy((simulation) => simulation.get('taskId').toString())
      .get(taskId.toString()),
);

export const getSelectedCablesForTaskFromState = createSelector(
  getSimulationsForTaskFromState,
  (simulations) => simulations?.map((simulation) => simulation.get('cable')).toList(),
);

export const hasPlannedSimulationForTaskSelection = createSelector(
  getSimulationsForTaskFromState,
  (simulations) => simulations?.some((s) => s.get('isPlanned')),
);

export const createSimulationResultsSelector = (simulationId) =>
  createSelector(getSimulationResultSelector, (simulationResults) =>
    simulationResults
      .groupBy((result) => result.get('simulationId')?.toString())
      .get(simulationId?.toString()),
  );

export const createSimulationResultsByDepthSelector = (simulationId) =>
  createSelector(
    createSimulationResultsSelector(simulationId),
    (simulationResults) =>
      simulationResults?.mapKeys((_key, result) =>
        result.getIn(['measuredDepth', 'value']),
      ),
  );

export const createSimulationWellboreSectionsSelector = (simulationId) =>
  createSelector(getWellboreSectionSelector, (wellboreSections) =>
    wellboreSections
      .filter((section) => section?.get('simulationId'))
      .groupBy((section) => section.get('simulationId').toString())
      .get(simulationId.toString()),
  );

export const createSimulationFluidsSelector = (simulationId) =>
  createSelector(getSimulationFluidSelector, (simulationFluids) =>
    simulationFluids
      .groupBy((fluid) => fluid.get('simulationId').toString())
      .get(simulationId.toString()),
  );

export const createSimulationCompressionDepthSelector = (simulationId) =>
  createSelector(
    createSimulationResultsSelector(simulationId),
    getSimulationCompressionDepth,
  );
