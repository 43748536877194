import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import RadioGroupField from 'app/components/form/RadioGroupField';

const useStyles = makeStyles({
  checkedRadio: {
    '& .MuiTypography-root': {
      fontSize: '0.85rem',
    },
    '& svg:last-child': {
      color: green[500],
    },
  },
});

export const PlannedIndicator = ({
  simulation,
  onToggle = () => null,
  hidden = false,
  isDisabled = false,
}) => {
  const classes = useStyles();

  if (hidden) {
    return null;
  }

  const isPlanned = simulation?.get('isPlanned', false);
  const disabled = simulation.get('canResimulate', false) || isDisabled;

  return (
    <RadioGroupField
      variant="default"
      size="small"
      fontVariant="h1"
      input={{
        value: isPlanned ? 'planned' : 'not-planned',
      }}
      options={[
        {
          onClick: onToggle,
          value: 'planned',
          label: isPlanned ? 'Planned' : 'Not Planned',
          disabled,
          className: classes.checkedRadio,
        },
      ]}
    />
  );
};
