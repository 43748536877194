import { compose } from 'redux';
import { useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  getCablesByProject,
  onUnload,
} from 'features/equipment/equipment.actions';
import { getCablesFromState } from 'features/equipment/equipment.selectors';
import AddCablesModal from 'features/projects/tasks/task/surfaceEquipment/components/cables/AddCablesModal';

const AddCablesModalContainer = ({
  isOpen,
  classes,
  dataState,
  onAddCable,
  toggleModal,
  onRemoveCable,
  selectedCables,
  dispatchOnUnload,
  setSelectedCables,
  projectId,
}) => {
  const dispatch = useDispatch();

  const dispatchOnLoad = useCallback(() => {
    dispatch(getCablesByProject(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatchOnLoad();
    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload]);

  const cables = useSelector(getCablesFromState);

  return (
    <AddCablesModal
      cables={cables}
      isOpen={isOpen}
      classes={classes}
      dataState={dataState}
      onAddCable={onAddCable}
      toggleModal={toggleModal}
      onRemoveCable={onRemoveCable}
      selectedCables={selectedCables}
      setSelectedCables={setSelectedCables}
    />
  );
};

export default compose(
  connect(() => ({}), {
    dispatchOnUnload: onUnload,
  }),
)(AddCablesModalContainer);
