import React, { useEffect, useCallback, useState } from 'react';

import { Grid, withStyles, Typography } from '@material-ui/core';

const MetalMillingROP = ({ classes, registerDataPointsHandler }) => {
  const [data, setData] = useState(0);

  const onReceiveData = useCallback((data) => {
    setData(data);
  }, []);

  useEffect(() => {
    registerDataPointsHandler(onReceiveData);
  }, [onReceiveData, registerDataPointsHandler]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h6" className={classes.ropTitle}>
        ROP
      </Typography>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classes.ropContainer_wrap}
      >
        <Typography variant="h5" align="center" className={classes.ropMetric}>
          {`${data.rop} mm/min`}
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  ropContainer_wrap: {
    border: '2px solid gray',
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    width: '30%',
  },
  ropMetric: {
    color: '#74FA02',
    align: 'center',
    fontWeight: 'bold',
  },
  ropTitle: {
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(MetalMillingROP);
