import { convertMetric } from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import { Cylinder } from '@react-three/drei';
import { compose } from 'redux';
import * as THREE from 'three';
import React from 'react';

const Toolstring = ({ BHA_LENGTH }) => {
  return (
    <Cylinder
      name="Toolstring"
      position={[
        -convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) * 1.5,
        0,
        0,
      ]}
      rotation={[-Math.PI / 2, 0, Math.PI / 2]}
      args={[
        0.005,
        0.005,
        convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) * 2.5,
        32,
        1,
        false,
        0,
      ]}
    >
      <meshStandardMaterial
        color="#aaa9ad"
        roughness={0}
        metalness={1}
        side={THREE.DoubleSide}
      />
    </Cylinder>
  );
};

export default compose()(Toolstring);
