import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tab } from '@material-ui/core';

import { NavTabsContainer } from 'altus-ui-components';

import RoutesContainer from 'app/components/RoutesContainer';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';
import { SystemPermission } from 'app/app.constants';

const NavigationAppBarBase = ({ routes, isRouteHidden }) => {
  const { userPermissions } = useSystemPermissions();

  const routeIfNotHidden = useCallback(
    (route) => {
      return isRouteHidden(!!route.subSurfaceRoute) ? null : route;
    },
    [isRouteHidden],
  );

  const renderTabs = useCallback(
    (routes) => {
      const routesToDisplay = routes.filter((route) => {
        if (route.permission) {
          if (userPermissions.includes(route.permission)) {
            return routeIfNotHidden(route);
          } else return null;
        } else {
          return routeIfNotHidden(route);
        }
      });
      return (
        <NavTabsContainer
          centered
          routes={routesToDisplay}
          TabComponent={Tab}
        />
      );
    },
    [routeIfNotHidden, userPermissions],
  );

  if (userPermissions.includes(SystemPermission.WELL_ACCESS_ONLY)) return null;

  return (
    <AppBar position="static" color="default">
      <RoutesContainer routes={routes} render={renderTabs} />
    </AppBar>
  );
};

const NavigationAppBar = (props) => {
  return <NavigationAppBarBase {...props} isRouteHidden={() => false} />;
};

export default NavigationAppBar;

// Special case, hide subsurface routes (tabs) for surface tasks.
export const NavigationAppBarForTaskRoutes = ({
  isTaskTypeSurface,
  ...props
}) => {
  return (
    <NavigationAppBarBase
      {...props}
      isRouteHidden={(isSubSurfaceRoute) =>
        isTaskTypeSurface && isSubSurfaceRoute
      }
    />
  );
};

NavigationAppBarForTaskRoutes.propTypes = {
  render: PropTypes.func,
};
