import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  completeActivity,
  createActivityWithNotification,
  createNewPointInTimeActivity,
  deleteActivityWithNotification,
  goBack,
  pauseActivity,
  updateActivity,
  updateActivityTimes,
  duplicateActivityWithNotification,
} from '../activities.actions';

export const useRealTimeNotifications = (projectId, taskId, connection) => {
  const dispatch = useDispatch();

  const dispatchGoBack = useCallback(
    (projectId, taskId) => dispatch(goBack(projectId, taskId, connection)),
    [connection, dispatch],
  );

  const dispatchCompleteActivity = useCallback(
    (projectId, taskId, activityId) =>
      dispatch(completeActivity(projectId, taskId, activityId, connection)),
    [connection, dispatch],
  );

  const dispatchCreateActivity = useCallback(
    () => dispatch(createActivityWithNotification(taskId, connection)),
    [connection, dispatch, taskId],
  );

  const dispatchDuplicateActivity = useCallback(
    (values) => {
      return dispatch(
        duplicateActivityWithNotification(taskId, connection, values),
      );
    },
    [connection, dispatch, taskId],
  );

  const dispatchDeleteActivity = useCallback(
    (projectId, taskId, activityId, index) =>
      dispatch(
        deleteActivityWithNotification(
          projectId,
          taskId,
          activityId,
          index,
          connection,
        ),
      ),
    [connection, dispatch],
  );

  const dispatchUpdateActivity = useCallback(
    (projectId, taskId, activity) =>
      dispatch(updateActivity(projectId, taskId, activity, connection)),
    [connection, dispatch],
  );

  const dispatchUpdateActivityTimes = useCallback(
    (projectId, taskId, autosave, values, formikPrimitives) =>
        dispatch(
            updateActivityTimes(projectId, taskId, autosave, values, formikPrimitives, connection),
        ),
    [connection, dispatch],
  );

  const dispatchPauseActivity = useCallback(
    (projectId, taskId, activityId, activityTypeId) =>
      dispatch(
        pauseActivity(
          projectId,
          taskId,
          activityId,
          activityTypeId,
          connection,
        ),
      ),
    [connection, dispatch],
  );

  const dispatchCreateNewPointInTimeActivity = useCallback(
    (projectId, taskId, activityId, activityTypeId) => {
      dispatch(
        createNewPointInTimeActivity(
          projectId,
          taskId,
          activityId,
          activityTypeId,
          connection,
        ),
      );
    },
    [connection, dispatch],
  );

  return [
    dispatchGoBack,
    dispatchCompleteActivity,
    dispatchCreateActivity,
    dispatchDeleteActivity,
    dispatchUpdateActivity,
    dispatchUpdateActivityTimes,
    dispatchPauseActivity,
    dispatchCreateNewPointInTimeActivity,
    dispatchDuplicateActivity,
  ];
};
