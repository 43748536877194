import React from 'react';

import {
  Box,
  Grid,
  Button,
  Dialog,
  Accordion,
  Typography,
  DialogContent,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

import { compose } from 'redux';
import { useState } from 'react';
import { Field, Formik, Form, useFormikContext } from 'formik';

import withStyles from '@material-ui/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SITUATIONSMODAL } from 'features/wellControlDrillActivity/constants';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import SearchTextFieldFormik from 'app/components/form/formik/SearchTextFieldFormik';
import { useEffect } from 'react';

export const Filters = {
  TEXT_SEARCH: 'textSearch',
};

const SituationsModal = ({
  isOpen,
  toggleModal,
  classes,
  categories,
  form,
  field,
  onSearch,
}) => {
  const { value, name } = field;
  const { setFieldValue } = form;
  const [selectedSituationItems, setSelectedSituationItems] = useState([]);
  const { formValuesContext } = useFormikContext();

  const onAddHandler = () => {
    setFieldValue(name, selectedSituationItems);
    toggleModal({ modalId: SITUATIONSMODAL + form.values.id });
  };

  useEffect(() => {
    setSelectedSituationItems(value);
  }, [value]);

  const onSubmit = (formValues) => {
    const search = formValues ? formValues[Filters.TEXT_SEARCH] : '';
    onSearch(search);
  };

  const onKeyUp = (event) => {
    if (event.target?.value?.length === 0) {
      onSubmit(formValuesContext);
    }
  };

  const initialValues = {
    [Filters.TEXT_SEARCH]: '',
  };

  const shouldSubmit = ({ isValid }) => isValid;

  return (
    <Dialog fullScreen open={isOpen} onClose={toggleModal}>
      <ApplicationHeaderBase>
        <Grid container alignItems="center">
          <Grid item xs />
          <Grid
            xs
            item
            container
            zeroMinWidth
            alignItems="center"
            direction="column"
          >
            <Typography item component={Typography} variant="h6">
              Select situations
            </Typography>
          </Grid>
          <Grid item container xs justifyContent="flex-end">
            <Button color="secondary" variant="contained" onClick={toggleModal}>
              Close
            </Button>
          </Grid>
        </Grid>
      </ApplicationHeaderBase>
      <DialogContent>
        <Grid container component={Box} spacing={2}>
          <Grid item container xs={6} className={classes.test}>
            <Formik
              enableReinitialize
              onSubmit={onSubmit}
              initialValues={initialValues}
              shouldSubmit={shouldSubmit}
            >
              <Form>
                <AutoSaveFormik timeout={1000}>
                  <Field
                    name={Filters.TEXT_SEARCH}
                    component={SearchTextFieldFormik}
                    onKeyUp={onKeyUp}
                  />
                </AutoSaveFormik>
              </Form>
            </Formik>
            {!categories?.length ? (
              <Grid
                item
                xs
                container
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: '20px' }}
              >
                No results...
              </Grid>
            ) : (
              categories.map((category, index) => {
                return (
                  <Accordion className={classes.accordionDetails} key={index}>
                    <AccordionSummary
                      className={classes.accordionHeader}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography>{category.get('title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item container wrap="wrap">
                        {category.get('situations').map((situation) => {
                          return (
                            <Grid
                              item
                              container
                              className={classes.situationsItem}
                              onClick={() => {
                                if (!selectedSituationItems.includes(situation))
                                  setSelectedSituationItems((current) => [
                                    ...current,
                                    situation.toJS(),
                                  ]);
                              }}
                            >
                              <Typography
                                noWrap={true}
                                className={classes.situationsText}
                              >
                                {situation.get('title')}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            )}
          </Grid>
          <Grid item container xs={6} className={classes.test2}>
            <Grid item container xs={12} className={classes.selected}>
              <Typography>Selected items</Typography>
            </Grid>
            {selectedSituationItems.map((situation) => {
              return (
                <Grid
                  item
                  container
                  className={classes.situationsItem}
                  onClick={() => {
                    const selectedSituations = selectedSituationItems.filter(
                      (x) => x !== situation,
                    );
                    return setSelectedSituationItems(selectedSituations);
                  }}
                >
                  <Typography noWrap={true}>{situation.title}</Typography>
                </Grid>
              );
            })}
          </Grid>
          <Button
            className={classes.addBtn}
            color="primary"
            variant="contained"
            onClick={onAddHandler}
          >
            Save
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme) => ({
  addBtn: {
    position: 'absolute',
    bottom: 50,
    right: 50,
  },
  test2: {
    height: 'fit-content',
  },
  accordionDetails: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%',
  },
  accordionHeader: {
    backgroundColor: '#424242',
  },
  situationsItem: {
    backgroundColor: '#616161',
    borderRadius: 16,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: 'fit-content',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    height: 'fit-content',
  },
  situationsText: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: '#424242',
    height: '48px',
    alignItems: 'center',
    paddingLeft: '16px',
    marginBottom: theme.spacing(1),
  },
});

export default compose(withStyles(styles))(SituationsModal);
