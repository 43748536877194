import * as THREE from 'three';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as colors from 'layout/colors';
import { useEffect, memo, useRef, useState, useMemo } from 'react';

import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import {
  between,
  convertMetric,
} from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import { WellboreSectionType } from 'features/wells/sections/wellboreSection.constants';
import WellboreTrajectoryContainer from 'app/components/WellboreTrajectory/WellboreTrajectoryContainer';
import WellboreContext3DContainer from 'app/components/WellboreContextualization/WellboreContext3DContainer';

const WellboreContextTrajectoryContainer = ({
  classes,
  hideLegends,
  disableOrbitControls,
  trajectory,
  wellboreId,
  fieldId,
  wellboreDetail,
  wellbore,
  wellboreSections,
  children = null,
  ...rest
}) => {
  const trajectoryRef = useRef([]);
  const nippleRef = useRef([]);
  const groundLayerRef = useRef([]);
  const depthMarkerRef = useRef([]);
  const [maxTVD, setMaxTVD] = useState(null);
  const [maxNE, setMaxNE] = useState(null);
  const [dhsvDepth, setDhsvDepth] = useState(null);
  const [maximumWorkingDepth, setMaximumWorkingDepth] = useState(null);
  const [waterDepth, setWaterDepth] = useState(null);
  const [materials, setMaterials] = useState(null);
  const [refFlag, setRefFlag] = useState(false);

  const depthsOfInterest = useMemo(
    () => [
      {
        depth: dhsvDepth?.value,
        name: 'DHSV',
        unit: dhsvDepth?.unit,
        material: materials?.dhsv,
        color: colors.dashboard.dhsv,
      },
      {
        depth: maximumWorkingDepth?.value,
        name: 'Max working depth',
        unit: maximumWorkingDepth?.unit,
        material: materials?.maximumWorkingDepth,
        color: colors.dashboard.maximumWorkingDepth,
      },
    ],
    [dhsvDepth, maximumWorkingDepth, materials],
  );

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    const metalAOMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_ambientOcclusion.jpg`,
    );
    const metalDispMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_height.png`,
    );
    const metalNormalMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_normal.jpg`,
    );
    const metalMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_basecolor.jpg`,
    );
    const metalRoughnessMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_roughness.jpg`,
    );
    const metalMetalnessMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_metallic.jpg`,
    );
    const soilAOMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/soil/damp_sand_ao_4k.jpg`,
    );
    const soilDispMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/soil/damp_sand_disp_4k.png`,
    );
    const soilNormalMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/soil/damp_sand_nor_gl_4k.jpg`,
    );
    const soilMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/soil/damp_sand_diff_4k.jpg`,
    );
    const soilRoughnessMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/soil/damp_sand_rough_4k.jpg`,
    );
    const waterAOMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/water/Water_002_OCC.jpg`,
    );
    const waterDispMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/water/Water_002_DISP.png`,
    );
    const waterNormalMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/water/Water_002_NORM.jpg`,
    );
    const waterColorMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/water/Water_002_COLOR.jpg`,
    );
    const waterRoughnessMap = loader.load(
      `${PUBLIC_ASSETS_URL}/textures/water/Water_002_ROUGH.jpg`,
    );
    setMaterials({
      water: new THREE.MeshStandardMaterial({
        color: '#0a5498',
        side: THREE.DoubleSide,
        map: waterColorMap,
        aoMap: waterAOMap,
        displacementMap: waterDispMap,
        normalMap: waterNormalMap,
        roughnessMap: waterRoughnessMap,
        alphaTest: 0.5,
        depthTest: true,
      }),
      soil: new THREE.MeshStandardMaterial({
        color: '#ab7954',
        side: THREE.DoubleSide,
        map: soilMap,
        aoMap: soilAOMap,
        displacementMap: soilDispMap,
        normalMap: soilNormalMap,
        roughnessMap: soilRoughnessMap,
        alphaTest: 0.1,
        depthTest: true,
      }),
      pipe: new THREE.MeshStandardMaterial({
        color: '#F5F5F5',
        side: THREE.DoubleSide,
        metalness: 1,
        roughness: 0.3,
        map: metalMap,
        aoMap: metalAOMap,
        displacementMap: metalDispMap,
        normalMap: metalNormalMap,
        roughnessMap: metalRoughnessMap,
        metalnessMap: metalMetalnessMap,
      }),
      nipple: new THREE.MeshStandardMaterial({
        color: '#8D918D',
        side: THREE.DoubleSide,
        metalness: 1,
        roughness: 0.3,
      }),
      maximumWorkingDepth: new THREE.MeshBasicMaterial({
        color: colors.dashboard.maximumWorkingDepth,
        side: THREE.DoubleSide,
      }),
      dhsv: new THREE.MeshBasicMaterial({
        color: colors.dashboard.dhsv,
        side: THREE.DoubleSide,
      }),
    });
  }, []);

  useEffect(() => {
    if (!wellbore.size || !wellboreDetail.size || !trajectory.size) return;
    setMaxTVD(
      convertMetric(
        wellbore.getIn(['tvd', 'roundedValue']),
        wellbore.getIn(['tvd', 'unit']),
      ),
    );
    setWaterDepth(
      convertMetric(
        wellbore.getIn(['waterDepth', 'roundedValue']),
        wellbore.getIn(['waterDepth', 'unit']),
      ),
    );
    setDhsvDepth(wellboreDetail.get('downHoleSafetyValveDepth'));
    setMaximumWorkingDepth(wellboreDetail.get('maximumWorkingDepth'));
    setMaxNE(
      convertMetric(
        trajectory.getIn(['maximumNorthEast', 'roundedValue']),
        trajectory.getIn(['maximumNorthEast', 'unit']),
      ),
    );
  }, [wellbore, wellboreDetail, trajectory]);

  useEffect(() => {
    if (!trajectory.size || !wellboreSections?.size || !maxTVD || !materials)
      return;
    const trajectoryPoints = trajectory.get('trajectoryPoints');
    if (trajectoryRef.current.length < trajectoryPoints.size - 1) {
      trajectoryPoints.forEach((previousPoint, index) => {
        const currentPoint = trajectoryPoints.get(index + 1);

        // Bottom reached
        if (!currentPoint) return false;

        const startVectorGround = new THREE.Vector3(
          convertMetric(
            previousPoint.getIn(['eastWest', 'value']),
            previousPoint.getIn(['eastWest', 'unit']),
          ),
          maxTVD -
            convertMetric(
              previousPoint.getIn(['verticalDepth', 'value']),
              previousPoint.getIn(['verticalDepth', 'unit']),
            ),
          convertMetric(
            previousPoint.getIn(['northSouth', 'value']),
            previousPoint.getIn(['northSouth', 'unit']),
          ),
        );
        const endVectorGround = new THREE.Vector3(
          convertMetric(
            currentPoint.getIn(['eastWest', 'value']),
            currentPoint.getIn(['eastWest', 'unit']),
          ),
          maxTVD -
            convertMetric(
              currentPoint.getIn(['verticalDepth', 'value']),
              currentPoint.getIn(['verticalDepth', 'unit']),
            ),
          convertMetric(
            currentPoint.getIn(['northSouth', 'value']),
            currentPoint.getIn(['northSouth', 'unit']),
          ),
        );

        if (
          waterDepth &&
          (waterDepth >=
            convertMetric(
              currentPoint.getIn(['verticalDepth', 'value']),
              currentPoint.getIn(['verticalDepth', 'unit']),
            ) ||
            between(
              waterDepth,
              convertMetric(
                previousPoint.getIn(['verticalDepth', 'value']),
                previousPoint.getIn(['verticalDepth', 'unit']),
              ),
              convertMetric(
                currentPoint.getIn(['verticalDepth', 'value']),
                currentPoint.getIn(['verticalDepth', 'unit']),
              ),
            ))
        ) {
          groundLayerRef.current.push({
            key: convertMetric(
              previousPoint.getIn(['measuredDepth', 'value']),
              previousPoint.getIn(['measuredDepth', 'unit']),
            ),
            value: {
              startVector: startVectorGround,
              endVector: endVectorGround,
              index,
              thetaStart: 0,
              radius: 60,
              name: `ground-${index}`,
              material: materials.water,
              measuredDepthStart: convertMetric(
                previousPoint.getIn(['measuredDepth', 'value']),
                previousPoint.getIn(['measuredDepth', 'unit']),
              ),
              measuredDepthEnd: convertMetric(
                currentPoint.getIn(['measuredDepth', 'value']),
                currentPoint.getIn(['measuredDepth', 'unit']),
              ),
            },
          });
        } else {
          groundLayerRef.current.push({
            key: convertMetric(
              previousPoint.getIn(['measuredDepth', 'value']),
              previousPoint.getIn(['measuredDepth', 'unit']),
            ),
            value: {
              startVector: startVectorGround,
              endVector: endVectorGround,
              index,
              thetaStart: 0,
              radius: 60,
              name: `ground-${index}`,
              material: materials.soil,
              measuredDepthStart: convertMetric(
                previousPoint.getIn(['measuredDepth', 'value']),
                previousPoint.getIn(['measuredDepth', 'unit']),
              ),
              measuredDepthEnd: convertMetric(
                currentPoint.getIn(['measuredDepth', 'value']),
                currentPoint.getIn(['measuredDepth', 'unit']),
              ),
            },
          });
        }

        wellboreSections.forEach((value) => {
          const startVector = new THREE.Vector3();
          const endVector = new THREE.Vector3();
          if (
            between(
              convertMetric(
                previousPoint.getIn(['measuredDepth', 'value']),
                previousPoint.getIn(['measuredDepth', 'unit']),
              ),
              convertMetric(
                value.getIn(['top', 'value']),
                value.getIn(['top', 'unit']),
              ),
              convertMetric(
                value.getIn(['bottom', 'value']),
                value.getIn(['bottom', 'unit']),
              ),
            )
          ) {
            startVector.set(
              convertMetric(
                previousPoint.getIn(['eastWest', 'value']),
                previousPoint.getIn(['eastWest', 'unit']),
              ),
              maxTVD -
                convertMetric(
                  previousPoint.getIn(['verticalDepth', 'value']),
                  previousPoint.getIn(['verticalDepth', 'unit']),
                ),
              convertMetric(
                previousPoint.getIn(['northSouth', 'value']),
                previousPoint.getIn(['northSouth', 'unit']),
              ),
            );

            endVector.set(
              convertMetric(
                currentPoint.getIn(['eastWest', 'value']),
                currentPoint.getIn(['eastWest', 'unit']),
              ),
              maxTVD -
                convertMetric(
                  currentPoint.getIn(['verticalDepth', 'value']),
                  currentPoint.getIn(['verticalDepth', 'unit']),
                ),
              convertMetric(
                currentPoint.getIn(['northSouth', 'value']),
                currentPoint.getIn(['northSouth', 'unit']),
              ),
            );
            if (value.get('type') !== WellboreSectionType.OPEN_HOLE) {
              trajectoryRef.current.push({
                key: convertMetric(
                  previousPoint.getIn(['measuredDepth', 'value']),
                  previousPoint.getIn(['measuredDepth', 'unit']),
                ),
                value: {
                  startVector,
                  endVector,
                  index,
                  thetaStart: 0,
                  radius:
                    convertMetric(
                      value.getIn(['innerDiameter', 'value']),
                      value.getIn(['innerDiameter', 'unit']),
                    ) * 100,
                  name: `trajectory-${index}`,
                  material: materials.pipe,
                  measuredDepthStart: convertMetric(
                    previousPoint.getIn(['measuredDepth', 'value']),
                    previousPoint.getIn(['measuredDepth', 'unit']),
                  ),
                  measuredDepthEnd: convertMetric(
                    currentPoint.getIn(['measuredDepth', 'value']),
                    currentPoint.getIn(['measuredDepth', 'unit']),
                  ),
                },
              });
            }

            const depthOfInterest = depthsOfInterest.find(
              ({ depth, unit }) =>
                depth &&
                convertMetric(depth, unit) >=
                  convertMetric(
                    previousPoint.getIn(['measuredDepth', 'value']),
                    previousPoint.getIn(['measuredDepth', 'unit']),
                  ) &&
                convertMetric(depth, unit) <=
                  convertMetric(
                    currentPoint.getIn(['measuredDepth', 'value']),
                    currentPoint.getIn(['measuredDepth', 'unit']),
                  ),
            );
            if (depthOfInterest) {
              depthMarkerRef.current.push({
                key: convertMetric(
                  previousPoint.getIn(['measuredDepth', 'value']),
                  previousPoint.getIn(['measuredDepth', 'unit']),
                ),
                value: {
                  startVector,
                  endVector,
                  index,
                  thetaStart: 0,
                  length: 10,
                  radius:
                    convertMetric(
                      value.getIn(['innerDiameter', 'value']),
                      value.getIn(['innerDiameter', 'unit']),
                    ) *
                      100 +
                    30,
                  name: `depthMarker-${index}`,
                  material: depthOfInterest.material,
                },
              });
            }
          }
          for (const nippleValue of value
            .get('wellboreSectionNipples')
            .values()) {
            if (
              nippleValue.get('isNipple') &&
              between(
                convertMetric(
                  nippleValue.getIn(['top', 'value']),
                  nippleValue.getIn(['top', 'unit']),
                ),
                convertMetric(
                  previousPoint.getIn(['measuredDepth', 'value']),
                  previousPoint.getIn(['measuredDepth', 'unit']),
                ),
                convertMetric(
                  currentPoint.getIn(['measuredDepth', 'value']),
                  currentPoint.getIn(['measuredDepth', 'unit']),
                ),
              )
            ) {
              startVector.set(
                convertMetric(
                  previousPoint.getIn(['eastWest', 'value']),
                  previousPoint.getIn(['eastWest', 'unit']),
                ),
                maxTVD -
                  convertMetric(
                    previousPoint.getIn(['verticalDepth', 'value']),
                    previousPoint.getIn(['verticalDepth', 'unit']),
                  ),
                convertMetric(
                  previousPoint.getIn(['northSouth', 'value']),
                  previousPoint.getIn(['northSouth', 'unit']),
                ),
              );

              endVector.set(
                convertMetric(
                  currentPoint.getIn(['eastWest', 'value']),
                  currentPoint.getIn(['eastWest', 'unit']),
                ),
                maxTVD -
                  convertMetric(
                    currentPoint.getIn(['verticalDepth', 'value']),
                    currentPoint.getIn(['verticalDepth', 'unit']),
                  ),
                convertMetric(
                  currentPoint.getIn(['northSouth', 'value']),
                  currentPoint.getIn(['northSouth', 'unit']),
                ),
              );
              const index = nippleRef.current.findIndex(
                (nip) =>
                  nip.key ===
                  convertMetric(
                    nippleValue.getIn(['top', 'value']),
                    nippleValue.getIn(['top', 'unit']),
                  ),
              );

              if (index === -1) {
                nippleRef.current.push({
                  key: convertMetric(
                    nippleValue.getIn(['top', 'value']),
                    nippleValue.getIn(['top', 'unit']),
                  ),
                  value: {
                    startVector,
                    endVector,
                    length: convertMetric(
                      nippleValue.get('length').roundedValue,
                      nippleValue.get('length').unit,
                    ),
                    radius:
                      convertMetric(
                        nippleValue.get('innerDiameter').roundedValue,
                        nippleValue.get('innerDiameter').unit,
                      ) * 100,
                    outerRadius:
                      convertMetric(
                        value.get('innerDiameter').roundedValue,
                        value.get('innerDiameter').unit,
                      ) * 100,
                    thetaStart: 0,
                    name: `nipple-${convertMetric(
                      nippleValue.getIn(['top', 'value']),
                      nippleValue.getIn(['top', 'unit']),
                    )}`,
                    material: materials.nipple,
                    spriteText: nippleValue.get('type'),
                    top: convertMetric(
                      nippleValue.getIn(['top', 'value']),
                      nippleValue.getIn(['top', 'unit']),
                    ),
                  },
                });
              }
            }
          }
        });
      });
      setRefFlag(true);
    }
  }, [
    trajectory,
    wellboreSections,
    maxTVD,
    waterDepth,
    materials,
    depthsOfInterest,
  ]);

  if (!trajectory.get('trajectoryPoints')?.size) return children;

  if (!wellbore?.size || !wellboreSections?.size || !wellboreDetail?.size) {
    return (
      <WellboreTrajectoryContainer fieldId={!fieldId ? wellbore.get('fieldId') : fieldId} wellboreId={wellboreId} />
    );
  }

  return (
    <>
      {refFlag && maxTVD && maxNE && (
        <WellboreContext3DContainer
          trajectoryRef={trajectoryRef}
          nippleRef={nippleRef}
          groundLayerRef={groundLayerRef}
          depthMarkerRef={depthMarkerRef}
          maxNE={maxNE}
          maxTVD={maxTVD}
          depthsOfInterest={depthsOfInterest}
          {...rest}
        />
      )}
    </>
  );
};

WellboreContextTrajectoryContainer.propTypes = {
  children: PropTypes.node,
  fieldId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wellboreId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default compose(memo)(WellboreContextTrajectoryContainer);
