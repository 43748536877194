import { List } from 'immutable';

export const ACTIONS = {
  REQUEST_PROJECT_MOBILISATION_TASKS: 'REQUEST_PROJECT_MOBILISATION_TASKS',
  RECEIVE_PROJECT_MOBILISATION_TASKS: 'RECEIVE_PROJECT_MOBILISATION_TASKS',
  RECEIVE_PROJECT_MOBILISATION_TOOLSTRINGS:
    'RECEIVE_PROJECT_MOBILISATION_TOOLSTRINGS',
  REQUEST_PROJECT_MOBILISATION_TOOLSTRING:
    'REQUEST_PROJECT_MOBILISATION_TOOLSTRING',
  RECEIVE_PROJECT_MOBILISATION_TOOLSTRING:
    'RECEIVE_PROJECT_MOBILISATION_TOOLSTRING',
  CREATE_STRING_TEST_REPORT: 'CREATE_STRING_TEST_REPORT',
  REQUEST_STRING_TEST_REPORTS_FOR_TASK: 'REQUEST_STRING_TEST_REPORTS_FOR_TASK',
  RECEIVE_STRING_TEST_REPORTS_FOR_TASK: 'RECEIVE_STRING_TEST_REPORTS_FOR_TASK',
  REQUEST_STRING_TEST_REPORT_EVENT: 'REQUEST_STRING_TEST_REPORT_EVENT',
  RECEIVE_STRING_TEST_REPORT_EVENT: 'RECEIVE_STRING_TEST_REPORT_EVENT',
  REQUEST_CREATE_SECONDARY_TEST_REPORT: 'REQUEST_CREATE_SECONDARY_TEST_REPORT',
  REQUEST_FINISH_STRING_TEST_REPORT: 'REQUEST_FINISH_STRING_TEST_REPORT',
};

export const MODAL = {
  STRING_TEST_REPORT_EVENT: 'STRING_TEST_REPORT_EVENT',
};

export const STRING_TEST_REPORT_EVENT_TYPE_ENUM = {
  PASS: 1,
  WARNING: 2,
  FAIL: 3,
};

export const STRING_TEST_REPORT_EVENT_TYPES_LIST = List([
  { id: '1', name: 'Pass' },
  { id: '2', name: 'Warning' },
  { id: '3', name: 'Fail' },
]);
