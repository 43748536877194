import { Field, ErrorMessage } from 'formik';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import TimeSpanFormik from 'app/components/form/formik/TimeSpanFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { TOOLS_TAB_FIELDS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { useAutoScroll } from 'features/projects/tasks/task/dataAcquisition/hooks/useScrollable';
import EditorFormik from 'app/components/form/formik/EditorFormik';

const ToolsTabContainer = ({ debrisTypes, fieldMarginClass, disabled }) => {
  const [maxHeight, vh] = useAutoScroll();
  return (
    <div
      style={{
        maxHeight: `${maxHeight * vh}px`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Grid container item spacing={2}>
        <Grid item xs>
          <Typography variant="h5">Log</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              fullWidth
              disabled={disabled}
              component={TimeSpanFormik}
              name={TOOLS_TAB_FIELDS.TIME_LOGGED}
              label="Time Logged"
              placeholder="00:00"
            />
            <ErrorMessage name={TOOLS_TAB_FIELDS.TIME_LOGGED}>
              {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
            </ErrorMessage>
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Distance Logged"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={TOOLS_TAB_FIELDS.DISTANCE_LOGGED}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Typography variant="h5">Tubing Cut</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Depth of Cut"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={TOOLS_TAB_FIELDS.DEPTH_OF_CUT}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              disabled={disabled}
              variant="outlined"
              component={EditorFormik}
              name={TOOLS_TAB_FIELDS.DESCRIPTION}
              label="Description"
              multiline
              rows={2}
              maxRows={2}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Typography variant="h5">Tractor</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Meters Tractored"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={TOOLS_TAB_FIELDS.METERS_TRACTORED}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              fullWidth
              disabled={disabled}
              component={TimeSpanFormik}
              name={TOOLS_TAB_FIELDS.TIME_TRACTORED}
              label="Time Tractored"
              placeholder="00:00"
            />
            <ErrorMessage name={TOOLS_TAB_FIELDS.TIME_TRACTORED}>
              {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
            </ErrorMessage>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Typography variant="h5">Jar</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              fullWidth
              disabled={disabled}
              component={TimeSpanFormik}
              name={TOOLS_TAB_FIELDS.JARING_TIME}
              label="Jaring Time"
              placeholder="00:00"
            />
            <ErrorMessage name={TOOLS_TAB_FIELDS.JARING_TIME}>
              {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
            </ErrorMessage>
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Jaring Strokes"
              disabled={disabled}
              component={TextFieldFormik}
              name={TOOLS_TAB_FIELDS.JARING_STROKES}
              placeholder="0"
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Typography variant="h5">Clean Out</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Debris Volume Collected"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={TOOLS_TAB_FIELDS.DEBRIS_VOLUME_COLLECTED}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="NOGO Size"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={TOOLS_TAB_FIELDS.NOGO_SIZE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Distance Milled"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={TOOLS_TAB_FIELDS.DISTANCE_MILLED}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              fullWidth
              disabled={disabled}
              component={TimeSpanFormik}
              name={TOOLS_TAB_FIELDS.TIME_MILLED}
              label="Time Milled"
              placeholder="00:00"
            />
            <ErrorMessage name={TOOLS_TAB_FIELDS.TIME_MILLED}>
              {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
            </ErrorMessage>
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Milling Speed"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              useRoundedValue={true}
              name={TOOLS_TAB_FIELDS.MILLING_SPEED}
              placeholder="0"
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Rotation Speed"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={TOOLS_TAB_FIELDS.ROTATION_SPEED}
              useRoundedValue={true}
              placeholder="0"
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <SelectTextFieldFormik
              label="Debris Type"
              margin="none"
              disabled={disabled}
              displayEmpty={false}
              className={fieldMarginClass}
              items={debrisTypes}
              name={TOOLS_TAB_FIELDS.DEBRIS_TYPE}
              getItemValue={(item) => item.get('id')}
              getItemName={(item) => item.get('name')}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default ToolsTabContainer;
