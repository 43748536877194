import React from 'react';
import * as THREE from 'three';
import DrawCylinder from 'app/components/WellboreContextualization/DrawCylinder';

const startVector = new THREE.Vector3();
const endVector = new THREE.Vector3();

const YAxis = ({ maxTVD }) => {
  startVector.set(0, 0, 0);
  endVector.set(0, maxTVD, 0);
  return (
    <DrawCylinder
      v1={startVector}
      v2={endVector}
      radius={3}
      isHalf={false}
      material={
        new THREE.MeshBasicMaterial({
          color: '#9E9E9E',
        })
      }
    />
  );
};

export default YAxis;
