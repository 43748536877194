import * as yup from 'yup';

import {
  ASSET_HISTORY_EVENT_TYPES_ITEM,
  ASSET_HISTORY_MAINTENANCE_TYPE,
  ASSET_HISTORY_TEST_TYPE,
  TEST_ASSET_RESULT,
} from 'features/equipment/equipment.constants';

import { validateTimeString } from 'utils/app.util';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';

const timeErrorMessage = 'Invalid time format, it should be in format HH:MM';
const FormFields = AssetHistoryForm;

const AssetHistoryYupValidator = yup.object().shape({
  [FormFields.TYPE_OF_EVENT]: yup
    .string()
    .required('Type of Event is required'),
  [FormFields.TYPE_OF_TEST]: yup.string().when(FormFields.TYPE_OF_EVENT, {
    is: '1',
    then: yup.string().required('Type of Test is required'),
    otherwise: yup.string().notRequired(),
  }),
  [FormFields.RESULT]: yup.string().when(FormFields.TYPE_OF_EVENT, {
    is: '1',
    then: yup.string().required('Result is required'),
    otherwise: yup.string().notRequired(),
  }),
  [FormFields.BOP_LEAK_TYPE]: yup
    .string()
    .when([FormFields.TYPE_OF_TEST, FormFields.RESULT], {
      is: (testType, result) =>
        testType === String(ASSET_HISTORY_TEST_TYPE.BOP_TEST) &&
        String(TEST_ASSET_RESULT.FAIL) === result,
      then: yup.string().required('Bop type of leak is required'),
      otherwise: yup.string().notRequired(),
    }),
  [FormFields.BOP_RAM]: yup
    .string()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.BOP_TEST) === typeOfTest,
      then: yup.string().required('Bop Ram set is required'),
      otherwise: yup.string().notRequired(),
    }),
  [FormFields.BOP_LEAK_TYPE_DESCRIPTION_VALIDATION]: yup
    .string()
    .when([FormFields.BOP_LEAK_TYPE, FormFields.RESULT], {
      is: (bopLeakType, result) =>
        bopLeakType === '6' && String(TEST_ASSET_RESULT.FAIL) === result,
      then: yup.string().required('Bop type of leak description is required'),
      otherwise: yup.string().notRequired(),
    }),
  [FormFields.TIME]: yup
    .string()
    .required('Required')
    .test('is-valid-time', timeErrorMessage, (value) =>
      validateTimeString(value),
    ),
  [FormFields.CABLE_TORSION_WRAP]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_TORSION_TEST) === typeOfTest,
      then: yup
        .number()
        .integer('Cable Torsion wrap must be an integer')
        .required('Cable Torsion wrap is required'),
      otherwise: yup.number().integer().notRequired(),
    }),
  [FormFields.CABLE_WRAP_TEST_STRAND_PASS_X]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_WRAP_TEST) === typeOfTest,
      then: yup
        .number()
        .integer('Cable Strand Pass X must be an integer')
        .required('Cable Strand Pass X is required'),
      otherwise: yup.number().integer().notRequired(),
    }),
  [FormFields.CABLE_WRAP_TEST_STRAND_OUT_Y]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_WRAP_TEST) === typeOfTest,
      then: yup
        .number()
        .integer('Cable Strand Out Y must be an integer')
        .required('Cable Strand Out Y is required'),
      otherwise: yup.number().integer().notRequired(),
    }),

  //Cable Torture Test
  [FormFields.CABLE_TORTURE_TEST_OUTER_ARMOUR_DUCTILITY_SCORE]: yup
    .number()
    .min(0, 'Ductility score must be at least 0')
    .max(10, 'Ductility score must not exceed 10')
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_TORTURE_TEST) === typeOfTest,
      then: yup
        .number()
        .min(0, 'Outer armour ductility score must be at least 0')
        .max(10, 'Outer armour ductility score must not exceed 10')
        .required('Outer armour ductility score is required'),
      otherwise: yup.number().min(0).max(10).notRequired(),
    }),
  [FormFields.CABLE_TORTURE_TEST_INNER_ARMOUR_DUCTILITY_SCORE]: yup
    .number()
    .min(0, 'Inner armour ductility score must be at least 0')
    .max(10, 'Inner armour ductility score must not exceed 10')
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_TORTURE_TEST) === typeOfTest,
      then: yup
        .number()
        .min(0, 'Inner armour ductility score must be at least 0')
        .max(10, 'Inner armour ductility score must not exceed 10')
        .required('Inner armour ductility score is required'),
      otherwise: yup.number().min(0).max(10).notRequired(),
    }),

  [FormFields.CABLE_CONTINUITY_TEST_RESISTANCE]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_CONTINUITY_TEST) === typeOfTest,
      then: yup
        .number()
        .min(0, 'Resistance must be a non-negative number')
        .required('Resistance is required'),
      otherwise: yup.number().notRequired(),
    }),
  [FormFields.CABLE_INSULATION_TEST_RESISTANCE]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_INSULATION_TEST) === typeOfTest,
      then: yup
        .number()
        .min(0, 'Resistance must be a non-negative number')
        .required('Resistance is required'),
      otherwise: yup.number().notRequired(),
    }),

  //SIL 2
  [FormFields.ASSET_SIL2_TEST_BOP]: yup
    .string()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.SIL2_TEST) === typeOfTest,
      then: yup.string().required('BOP is required'),
      otherwise: yup.string().notRequired(),
    }),
  [FormFields.ASSET_SIL2_TEST_BCS]: yup
    .string()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.SIL2_TEST) === typeOfTest,
      then: yup.string().required('BCS is required'),
      otherwise: yup.string().notRequired(),
    }),
  [FormFields.ASSET_SIL2_TEST_CABIN]: yup
    .string()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.SIL2_TEST) === typeOfTest,
      then: yup.string().required('Cabin is required'),
      otherwise: yup.string().notRequired(),
    }),

  // CABLE WRAP BRAIDED MONO TEST
  [FormFields.CABLE_WRAP_BRAIDED_MONO_TEST_INNER_STRAND_PASS_X]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_WRAP_BRAIDED_MONO_TEST) ===
          typeOfTest,
      then: yup
        .number()
        .min(0, 'Cable Inner Strand Pass X must be a non-negative number')
        .integer('Cable Inner Strand Pass X must be an integer')
        .required('Cable Inner Strand Pass X is required'),
      otherwise: yup.number().integer().notRequired(),
    }),
  [FormFields.CABLE_WRAP_BRAIDED_MONO_TEST_OUTER_STRAND_PASS_X]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_WRAP_BRAIDED_MONO_TEST) ===
          typeOfTest,
      then: yup
        .number()
        .min(0, 'Cable Outer Strand Pass X must be a non-negative number')
        .integer('Cable Outer Strand Pass X must be an integer')
        .required('Cable Outer Strand Pass X is required'),
      otherwise: yup.number().integer().notRequired(),
    }),
  [FormFields.CABLE_WRAP_BRAIDED_MONO_TEST_INNER_STRAND_OUT_OF_Y]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_WRAP_BRAIDED_MONO_TEST) ===
          typeOfTest,
      then: yup
        .number()
        .min(0, 'Cable Inner Strand Out Y must be a non-negative number')
        .integer('Cable Inner Strand Out Y must be an integer')
        .required('Cable Inner Strand Out Y is required'),
      otherwise: yup.number().integer().notRequired(),
    }),
  [FormFields.CABLE_WRAP_BRAIDED_MONO_TEST_OUTER_STRAND_OUT_OF_Y]: yup
    .number()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_WRAP_BRAIDED_MONO_TEST) ===
          typeOfTest,
      then: yup
        .number()
        .min(0, 'Cable Outer Strand Out Y must be a non-negative number')
        .integer('Cable Outer Strand Out Y must be an integer')
        .required('Cable Outer Strand Out Y is required'),
      otherwise: yup.number().integer().notRequired(),
    }),

  //CABLE BREAK FORCE
  [FormFields.CABLE_BREAK_TEST_FORCE]: yup
    .object()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST) &&
        String(ASSET_HISTORY_TEST_TYPE.CABLE_BREAK_TEST) === typeOfTest,
      then: yup
        .object()
        .test(
          'is-valid-value',
          'Force is required',
          (value) => value?.value > 0,
        ),
      otherwise: yup.object(),
    }),
  // CABLE CUT MAINTENANCE DISTANCE
  [FormFields.CABLE_CUT_MAINTENANCE_DISTANCE]: yup
    .object()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.MAINTENANCE) &&
        String(ASSET_HISTORY_MAINTENANCE_TYPE.CABLE_CUT) === typeOfTest,
      then: yup
        .object()
        .test(
          'is-valid-value',
          'Distance is required',
          (value) => value?.value > 0,
        ),
      otherwise: yup.object(),
    }),
  // CABLE SERVICE MAINTENANCE CABLE LENGTH
  [FormFields.CABLE_SERVICE_MAINTENANCE_CABLE_LENGTH]: yup
    .object()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.MAINTENANCE) &&
        String(ASSET_HISTORY_MAINTENANCE_TYPE.CABLE_SERVICE) === typeOfTest,
      then: yup
        .object()
        .test(
          'is-valid-value',
          'Cable Length is required',
          (value) => value?.value > 0,
        ),
      otherwise: yup.object(),
    }),
  // CABLE SERVICE MAINTENANCE MAX OD
  [FormFields.CABLE_SERVICE_MAINTENANCE_MAX_OD]: yup
    .object()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.MAINTENANCE) &&
        String(ASSET_HISTORY_MAINTENANCE_TYPE.CABLE_SERVICE) === typeOfTest,
      then: yup
        .object()
        .test(
          'is-valid-value',
          'Max. Outer Diameter is required',
          (value) => value?.value > 0,
        ),
      otherwise: yup.object(),
    }),
  // CABLE SPOOLING MAINTENANCE ITEM NUMBER
  [FormFields.CABLE_SPOOLING_DRUM_ITEM_NUMBER]: yup
    .string()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.MAINTENANCE) &&
        String(ASSET_HISTORY_MAINTENANCE_TYPE.CABLE_SPOOLING) === typeOfTest,
      then: yup.string().required('Item number is required'),
      otherwise: yup.string().notRequired(),
    }),
  // CABLE SPOOLING MAINTENANCE SERIAL NUMBER
  [FormFields.CABLE_SPOOLING_DRUM_SERIAL_NUMBER]: yup
    .string()
    .when([FormFields.TYPE_OF_EVENT, FormFields.TYPE_OF_TEST], {
      is: (typeOfEvent, typeOfTest) =>
        typeOfEvent === String(ASSET_HISTORY_EVENT_TYPES_ITEM.MAINTENANCE) &&
        String(ASSET_HISTORY_MAINTENANCE_TYPE.CABLE_SPOOLING) === typeOfTest,
      then: yup.string().required('Serial number is required'),
      otherwise: yup.string().notRequired(),
    }),
});

export default AssetHistoryYupValidator;
