import mappers from 'mappers';
import Agent from 'infrastructure/agent';
import { ToolType } from 'features/projects/tool/tool.constants';
import toolstringMapper from 'features/projects/tasks/task/toolstring/toolstring.mappers';

const getAllToolstringTemplates = () =>
  Agent.get('/template/toolstringsOptimized');

const getToolstringTemplateById = (templateId) =>
  Agent.get(`/template/toolstring/${templateId}`);

const getToolStringsForTask = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/toolstrings`);

const createToolString = (projectId, taskId, toolstring) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings`,
    toolstringMapper.Toolstring.to(toolstring),
  );

const getToolString = (projectId, taskId, toolStringId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}`,
  );

const updateToolString = (projectId, taskId, toolStringId, toolstring) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}`,
    toolstringMapper.Toolstring.to(toolstring),
  );

const toggleToolstringFavorite = (projectId, taskId, toolStringId) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/favorite`,
  );

const deleteToolString = (projectId, taskId, toolStringId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}`,
  );

const duplicateToolstring = (projectId, taskId, toolStringId) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/copy`,
  );

const getToolstringSections = (projectId, taskId, toolStringId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/sections`,
  );

const createToolstringItem = (projectId, taskId, toolStringId, tool) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/items`,
    toolstringMapper.ToolstringItem.toCreate(tool),
  );

const updateToolstringItem = (
  projectId,
  taskId,
  toolStringId,
  toolstringItem,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/${
      toolstringItem.type === ToolType.ASSEMBLY ? 'assembly' : 'tools'
    }/${toolstringItem.toolstringItemId}`,
    toolstringMapper.ToolstringItem.toUpdate(toolstringItem),
  );

const updatePlannedToolstringItem = (
  projectId,
  taskId,
  toolStringId,
  toolstringItem,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/${
      toolstringItem.type === ToolType.ASSEMBLY ? 'assembly' : 'plannedTools'
    }/${toolstringItem.toolstringItemId}`,
    toolstringMapper.ToolstringItem.toUpdate(toolstringItem),
  );

const updateToolstringAssemblyTool = (
  projectId,
  taskId,
  toolStringId,
  toolstringItem,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/assembly/${toolstringItem.toolAssemblyId}/tool/${toolstringItem.id}`,
    toolstringMapper.ToolAssemblyTool.toUpdate(toolstringItem),
  );

const updateToolstringNotPlannedAssemblyTool = (
  projectId,
  taskId,
  toolStringId,
  toolstringItem,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/notPlannedAssembly/${toolstringItem.toolAssemblyId}/tool/${toolstringItem.id}`,
    toolstringMapper.ToolAssemblyTool.toUpdate(toolstringItem),
  );

const deleteToolstringItem = (
  projectId,
  taskId,
  toolStringId,
  toolstringItemId,
) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/items/${toolstringItemId}`,
  );

const duplicateToolstringItem = (projectId, taskId, toolStringId, tool) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/items/copy`,
    toolstringMapper.ToolstringItem.toDuplicate(tool),
  );

const updateToolstringItemSequence = (
  projectId,
  taskId,
  toolStringId,
  toolstringItemId,
  indices,
) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/items/${toolstringItemId}/sequence`,
    mappers.Sequence.to(toolstringItemId, indices),
  );

const toggleToolstringItemBreakpoint = (
  projectId,
  taskId,
  toolStringId,
  toolstringItemId,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/items/${toolstringItemId}/breakpoint`,
  );

const updateToolstringAssemblyToolSequence = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  toolAssemblyToolId,
  sequences,
) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolstringId}/assembly/${toolstringItemId}/tool/${toolAssemblyToolId}/sequence`,
    mappers.Sequence.to(toolAssemblyToolId, sequences),
  );

const createToolstringAssemblyTool = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  tool,
) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolstringId}/assembly/${toolstringItemId}/tool`,
    toolstringMapper.ToolAssemblyTool.toCreate(tool),
  );

const deleteToolstringAssemblyTool = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  toolAssemblyToolId,
) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolstringId}/assembly/${toolstringItemId}/tool/${toolAssemblyToolId}`,
  );

const createToolstringFromTemplate = (
  projectId,
  taskId,
  toolstringTemplateId,
) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/template/${toolstringTemplateId}`,
  );

const getPlannedToolstring = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/toolstrings/planned`);

const createToolAttachment = (projectId, taskId, toolStringId, owner, tool) => {
  const ownerId =
    owner.get('type') === ToolType.TOOL
      ? owner.get('toolstringItemId')
      : owner.get('toolAssemblyToolId');

  return Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/tool/${ownerId}/attachment`,
    toolstringMapper.ToolstringAttachment.toCreate(owner, tool),
  );
};

const deleteToolstringAttachment = (
  projectId,
  taskId,
  toolStringId,
  ownerId,
  toolStringAttachmentId,
) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/tool/${ownerId}/attachment/${toolStringAttachmentId}`,
  );

const updateToolStringAttachmentSequence = (
  projectId,
  taskId,
  toolStringId,
  ownerId,
  toolStringAttachmentId,
  indices,
) => {
  return Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolStringId}/tool/${ownerId}/attachment/${toolStringAttachmentId}/sequence`,
    mappers.Sequence.to(toolStringAttachmentId, indices),
  );
};

const updateToolStringToolStatus = (
  projectId,
  taskId,
  toolstringId,
  toolstringItemId,
  status,
) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/toolstrings/${toolstringId}/tools/${toolstringItemId}/${status}`,
  );

const createToolstringTemplateAttachment = (toolStringId, owner, tool) => {
  const ownerId =
    owner.get('type') === ToolType.TOOL
      ? owner.get('toolstringItemId')
      : owner.get('toolAssemblyToolId');

  return Agent.post(
    `/template/toolstring/${toolStringId}/tool/${ownerId}/attachment`,
    toolstringMapper.ToolstringAttachment.toCreate(owner, tool),
  );
};

const deleteToolstringTemplateAttachment = (
  toolStringId,
  ownerId,
  toolStringAttachmentId,
) =>
  Agent.delete(
    `/template/toolstring/${toolStringId}/tool/${ownerId}/attachment/${toolStringAttachmentId}`,
  );

const updateToolStringTemplateAttachmentSequence = (
  toolStringId,
  ownerId,
  toolStringAttachmentId,
  indices,
) => {
  return Agent.post(
    `/template/toolstring/${toolStringId}/tool/${ownerId}/attachment/${toolStringAttachmentId}/sequence`,
    mappers.Sequence.to(toolStringAttachmentId, indices),
  );
};

const toolstringService = {
  getAllToolstringTemplates,
  getToolStringsForTask,
  createToolString,
  getToolString,
  updateToolString,
  toggleToolstringFavorite,
  deleteToolString,
  duplicateToolstring,
  getToolstringSections,
  createToolstringItem,
  updateToolstringItem,
  updatePlannedToolstringItem,
  deleteToolstringItem,
  duplicateToolstringItem,
  updateToolstringItemSequence,
  toggleToolstringItemBreakpoint,
  updateToolstringAssemblyToolSequence,
  createToolstringAssemblyTool,
  updateToolstringAssemblyTool,
  deleteToolstringAssemblyTool,
  createToolstringFromTemplate,
  getPlannedToolstring,
  updateToolstringNotPlannedAssemblyTool,
  createToolAttachment,
  deleteToolstringAttachment,
  updateToolStringAttachmentSequence,
  updateToolStringToolStatus,
  createToolstringTemplateAttachment,
  deleteToolstringTemplateAttachment,
  updateToolStringTemplateAttachmentSequence,
  getToolstringTemplateById,
};

export default toolstringService;
