import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import {
  getEventKeyByValue,
  TEST_RESULT_TO_TOOLTIP_TITLE,
  TOOL_ASSET_EVENTS_ENUM,
} from 'features/equipment/equipment.constants';

import GreenIcon from 'assets/Green.svg';
import { Format } from 'app/app.constants';
import ReadOnlyField from 'app/components/ReadOnlyField';
import EditorStateViewer from 'app/components/EditorStateViewer';
import { extractTimeIn24HourFormat, RichText } from 'utils/app.util';
import { testResultIcons } from 'features/equipment/assets/components/AssetHistoryTableRowDetails';
import TestDetailComponent from 'features/equipment/assets/components/drawer/drawer-events/TestDetailComponent';
import BopTestDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/BopTestDrawerComponent';
import Sil2TestDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/Sil2TestDrawerComponent';
import TaskDetailDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/TaskDetailDrawerComponent';
import MaintenanceDetailComponent from 'features/equipment/assets/components/drawer/drawer-events/MaintenanceDetailComponent';
import CableTorsionDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableTorsionDrawerComponent';
import CableWrapTestDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableWrapTestDrawerComponent';
import CableTortureTestDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableTotrtureTestDrawerComponent';
import CableContinuityTestDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableContinuityTestDrawerComponent';
import CableInsulationTestDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableInsulationTestDrawerComponent';
import CableWrapBraidedMonoTestDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableWrapBraidedMonoTestDrawerComponent';
import CableBreakTestDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableBreakTestDrawerComponent';
import CableCutMaintenanceDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableCutMaintenanceDrawerComponent';
import CableServiceMaintenanceDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableServiceMaintenanceDrawerComponent';
import CableSpoolingMaintenanceDrawerComponent from 'features/equipment/assets/components/drawer/drawer-events/CableSpoolingMaintenanceDrawerComponent';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const AssetHistoryDrawerContent = ({ classes, assetHistory }) => {
  const keyEvent = getEventKeyByValue(assetHistory.get('event'));
  let projectTitle = '-';
  const currentComment = assetHistory.getIn([keyEvent, 'comment']);
  const currentDescription = assetHistory.getIn([keyEvent, 'description']);
  const cableComments = assetHistory.getIn(
    [keyEvent, 'dataAcquisitionCableComments'],
    null,
  );

  if (assetHistory.get('facilityName') || assetHistory.get('wellboreName')) {
    projectTitle = `${assetHistory.get('facilityName')} - ${assetHistory.get(
      'wellboreName',
    )}`;
  }

  const handleResultIcon = (testResult) => {
    return testResultIcons[testResult] || GreenIcon;
  };

  const getTooltipTitle = (testResult) => {
    return TEST_RESULT_TO_TOOLTIP_TITLE[testResult] || '-';
  };

  const renderedComponent = (() => {
    switch (assetHistory.get('event')) {
      case TOOL_ASSET_EVENTS_ENUM.BOP_TEST:
        return <BopTestDrawerComponent assetHistory={assetHistory} />;
      case TOOL_ASSET_EVENTS_ENUM.CABLE_TORSION_TEST:
        return (
          <CableTorsionDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
            getTooltipTitle={getTooltipTitle}
            handleResultIcon={handleResultIcon}
            keyEvent={'cableTorsionTest'}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_WRAP_TEST:
        return (
          <CableWrapTestDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_TORTURE_TEST:
        return (
          <CableTortureTestDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_CONTINUITY_TEST:
        return (
          <CableContinuityTestDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_INSULATION_TEST:
        return (
          <CableInsulationTestDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.SIL2_TEST:
        return (
          <Sil2TestDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_WRAP_BRAIDED_MONO_TEST:
        return (
          <CableWrapBraidedMonoTestDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_BREAK_TEST:
        return (
          <CableBreakTestDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
            keyEvent={getEventKeyByValue(
              TOOL_ASSET_EVENTS_ENUM.CABLE_BREAK_TEST,
            )}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_CUT_MAINTENANCE:
        return (
          <CableCutMaintenanceDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
            keyEvent={getEventKeyByValue(
              TOOL_ASSET_EVENTS_ENUM.CABLE_CUT_MAINTENANCE,
            )}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_SERVICE_MAINTENANCE:
        return (
          <CableServiceMaintenanceDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
            keyEvent={getEventKeyByValue(
              TOOL_ASSET_EVENTS_ENUM.CABLE_SERVICE_MAINTENANCE,
            )}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_SPOOLING_MAINTENANCE:
        return (
          <CableSpoolingMaintenanceDrawerComponent
            assetHistory={assetHistory}
            classes={classes}
            keyEvent={getEventKeyByValue(
              TOOL_ASSET_EVENTS_ENUM.CABLE_SPOOLING_MAINTENANCE,
            )}
          />
        );
      default:
        return <></>;
    }
  })();

  const renderedAssetComponent = (() => {
    switch (assetHistory.get('event')) {
      case TOOL_ASSET_EVENTS_ENUM.MAINTENANCE:
        return (
          <MaintenanceDetailComponent
            assetHistory={assetHistory}
            classes={classes}
            projectTitle={projectTitle}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_CUT_MAINTENANCE:
        return (
          <MaintenanceDetailComponent
            assetHistory={assetHistory}
            classes={classes}
            projectTitle={projectTitle}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_SERVICE_MAINTENANCE:
        return (
          <MaintenanceDetailComponent
            assetHistory={assetHistory}
            classes={classes}
            projectTitle={projectTitle}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_SPOOLING_MAINTENANCE:
        return (
          <MaintenanceDetailComponent
            assetHistory={assetHistory}
            classes={classes}
            projectTitle={projectTitle}
          />
        );
      default:
        return (
          <TestDetailComponent
            assetHistory={assetHistory}
            classes={classes}
            keyEvent={keyEvent}
            projectTitle={projectTitle}
            getTooltipTitle={getTooltipTitle}
            handleResultIcon={handleResultIcon}
          />
        );
    }
  })();

  const renderedTaskComponent = (() => {
    return (
      <TaskDetailDrawerComponent
        assetHistory={assetHistory}
        classes={classes}
        keyEvent={keyEvent}
        projectTitle={projectTitle}
        getTooltipTitle={getTooltipTitle}
        handleResultIcon={handleResultIcon}
      />
    );
  })();

  return (
    <>
      <Grid container spacing={2} className={classes.bottomLabel}>
        {renderedTaskComponent}
      </Grid>
      <Grid container spacing={2}>
        {renderedAssetComponent}
      </Grid>
      <Grid container spacing={2}>
        {renderedComponent}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Date">
              {!assetHistory.get('startDate')
                ? '-'
                : moment(assetHistory.get('startDate')).format(Format.date)}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Time">
              {!assetHistory.get('startDate')
                ? '-'
                : extractTimeIn24HourFormat(assetHistory.get('startDate'))}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.paperMargin} spacing={2}>
        <Grid item xs={6}>
          <CustomReadOnlyField margin="none" label="Comment">
            {currentComment || currentDescription ? (
              <EditorStateViewer
                editorState={RichText(
                  assetHistory.get(keyEvent),
                  currentComment ? 'comment' : 'description',
                )}
              />
            ) : (
              '-'
            )}
          </CustomReadOnlyField>
        </Grid>
        <Grid item xs={6}>
          {cableComments && (
            <CustomReadOnlyField margin="none" label="Cable Comments">
              <EditorStateViewer
                editorState={RichText(
                  assetHistory.get(keyEvent),
                  'dataAcquisitionCableComments',
                )}
              />
            </CustomReadOnlyField>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  activityField: {
    paddingTop: `${theme.spacing(3)}px`,
  },
  subActivityBox: {
    marginTop: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    border: `2px solid ${theme.palette.table.row.hover}`,
  },
  subActivityField: {
    padding: `${theme.spacing(1)}px`,
  },
  disabledText: {
    color: '#777777',
  },
  marginLabel: {
    marginTop: '4vh',
  },

  bottomLabel: {
    marginBottom: '4vh',
  },
  iconStyle: {
    height: '2.2vh',
    display: 'inline-block',
    verticalAlign: 'middle',
    objectFit: 'contain',
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
});

export default withStyles(styles)(AssetHistoryDrawerContent);
