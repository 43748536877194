import 'layout/highcharts.styles.css';

import themes, { defaultThemeType } from 'layout/themes';

const theme = themes[defaultThemeType];

const highchartsTheme = {
  chart: {
    plotBorderColor: theme.palette.grey[700],
    backgroundColor: theme.altus.background.dashboard,
    style: {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
    },
  },
  noData: {
    style: {
      ...theme.typography.body2,
      color: theme.palette.text.primary,
    },
  },
  title: {
    style: {
      color: theme.palette.grey[700],
      textTransform: 'uppercase',
      fontSize: '20px',
    },
  },
  subtitle: {
    style: {
      color: theme.palette.grey[700],
      textTransform: 'uppercase',
    },
  },
  xAxis: {
    title: {
      style: {
        color: theme.palette.grey[100],
      },
    },
  },
  yAxis: {
    title: {
      style: {
        color: theme.palette.grey[100],
      },
    },
  },
  tooltip: {
    animation: false,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    style: {
      color: theme.palette.grey[100],
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: theme.palette.grey[100],
        style: {
          fontSize: '13px',
        },
      },
      marker: {
        lineColor: '#333',
      },
    },
    boxplot: {
      fillColor: '#505053',
    },
    candlestick: {
      lineColor: theme.palette.common.white,
    },
    errorbar: {
      color: theme.palette.common.white,
    },
  },
  legend: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    itemStyle: {
      color: theme.palette.grey[300],
    },
    itemHoverStyle: {
      color: theme.palette.common.white,
    },
    itemHiddenStyle: {
      color: theme.palette.grey[700],
    },
    title: {
      style: {
        color: '#C0C0C0',
      },
    },
  },
  credits: {
    enabled: false,
  },
  labels: {
    style: {
      color: theme.palette.grey[600],
    },
  },
  drilldown: {
    activeAxisLabelStyle: {
      color: theme.palette.grey[100],
    },
    activeDataLabelStyle: {
      color: theme.palette.grey[100],
    },
  },
  navigation: {
    buttonOptions: {
      symbolStroke: '#DDDDDD',
      theme: {
        fill: '#505053',
      },
    },
  },
  exporting: {
    enabled: false,
  },
  scrollbar: {
    barBackgroundColor: theme.palette.grey[600],
    barBorderColor: 'transparent',
    barBorderRadius: 1,
    buttonArrowColor: 'transparent',
    buttonBackgroundColor: 'transparent',
    buttonBorderWidth: 0,
    enabled: true,
    height: 10,
    trackBackgroundColor: theme.palette.grey[800],
    trackBorderColor: 'transparent',
    trackBorderWidth: 0,
  },
};

export default highchartsTheme;
