import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field } from 'redux-form/immutable';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Button, Dialog, DialogContent } from '@material-ui/core';

import { ModalHeader, ModalActions, LoadingButton } from 'altus-ui-components';

import {
  addNotification,
  NOTIFICATION_VARIANTS,
} from 'altus-redux-middlewares';

import { EMPTY_LIST } from 'app/app.constants';
import { required } from 'utils/validation.util';
import TextField from 'app/components/form/TextField';
import EditorReduxForm from 'app/components/form/EditorField';
import { getAvailableDepartmentsFromState } from 'app/app.selectors';
import { NPT_FORM } from 'features/projects/npt/npt.constants';
import DepartmentSelectField from 'app/components/DepartmentSelectField';
import DateTimePickerField from 'app/components/form/DateTimePickerField';
import { createProjectNonProductiveTime } from 'features/projects/npt/npt.actions';

const CreateNPTModal = ({
  valid,
  isOpen,
  project,
  submitting,
  departments,
  toggleModal,
  handleSubmit,
  dispatchOnLoad,
  dispatchOnSave,
}) => {
  const dispatch = useDispatch();
  const [availableDepartments, setAvailableDepartments] = useState(
    departments ?? EMPTY_LIST,
  );

  useEffect(() => {
    const ids = [];
    project.get('departments').forEach((dep) => {
      ids.push(dep.get('id'));
    });
    setAvailableDepartments(
      departments.filter((department) => ids.includes(department.get('id'))),
    );
  }, [project, departments]);

  const createNPT = useCallback(
    (values) => {
      if (values.get('startTime') >= values.get('endTime')) {
        dispatch(
          addNotification({
            message: 'End date must be after start date.',
            variant: NOTIFICATION_VARIANTS.ERROR,
          }),
        );
      } else {
        dispatchOnSave(project.get('id'), values).then(() => toggleModal());
      }
    },
    [project, dispatch, dispatchOnSave, toggleModal],
  );
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          maxHeight: '50%',
          height: '50%',
        },
      }}
      open={isOpen}
      onClose={toggleModal}
      TransitionProps={{
        onEnter: dispatchOnLoad,
      }}
    >
      <ModalHeader title="Add Non Productive Time" toggleModal={toggleModal} />
      <DialogContent>
        <form id={NPT_FORM.CREATE_ID} onSubmit={handleSubmit(createNPT)}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item container xs={6}>
                      <Field
                        label="Start time"
                        validate={[required]}
                        name={NPT_FORM.START}
                        component={DateTimePickerField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label="End time"
                        validate={[required]}
                        name={NPT_FORM.END}
                        component={DateTimePickerField}
                      />
                    </Grid>
                  </Grid>
                  <Field
                    maxRows={5}
                    label="Description"
                    xs={12}
                    validate={[required]}
                    component={EditorReduxForm}
                    name={NPT_FORM.DESCRIPTION}
                  />
                  <Field
                    component={TextField}
                    label="Case Number"
                    name={NPT_FORM.CASE_NUMBER}
                  />
                  <Field
                    format={null}
                    label="Departments"
                    departments={availableDepartments}
                    component={DepartmentSelectField}
                    name={NPT_FORM.DEPARTMENTS}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <ModalActions>
        <Button size="small" color="default" onClick={toggleModal}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          size="small"
          color="primary"
          variant="contained"
          loading={submitting}
          form={NPT_FORM.CREATE_ID}
          disabled={!valid || submitting}
          onClick={handleSubmit(createNPT)}
        >
          Create
        </LoadingButton>
      </ModalActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  departments: getAvailableDepartmentsFromState(state),
});
const mapDispatchToProps = {
  dispatchOnSave: createProjectNonProductiveTime,
};

export default compose(
  reduxForm({
    form: NPT_FORM.CREATE_ID,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(CreateNPTModal);
