import * as colors from '@material-ui/core/colors';

import themes, { defaultThemeType } from 'layout/themes';
import { formatValue } from 'utils/format.util';

const theme = themes[defaultThemeType];

const fontColor = theme.palette.common.white;
const backgroundColor = theme.altus.background.dashboard;
const defaultFontSize = theme.typography.subtitle1.fontSize;
const subFontSize = theme.typography.subtitle2.fontSize;
const capFontSize = theme.typography.caption.fontSize;

const dashboardHighchartsTheme = {
  boost: {
    enabled: true,
    seriesThreshold: 1,
  },
  chart: {
    animation: false,
    fontSize: defaultFontSize,
    style: {
      fontWeight: 'bold',
    },
  },
  legend: {
    itemStyle: {
      color: fontColor,
      fontSize: subFontSize,
    },
    itemHoverStyle: {
      color: colors.grey[600],
    },
    align: 'left',
    verticalAlign: 'top',
    borderRadius: 10,
    backgroundColor: colors.grey[800],
    layout: 'horizontal',
    borderWidth: 0,
    enabled: true,
    symbolHeight: 15,
    symbolWidth: 15,
    symbolRadius: 0,
    useHTML: true,
    labelFormatter: function () {
      const { name, unit } = this.options;
      return `<span>${name} ${unit ? `(${unit})` : ''}</span>`;
    },
    y: -10, // Negative y moves it up, positive y moves it down.
  },
  plotOptions: {
    line: {
      keys: ['x', 'depth', 'y'],
    },
    gauge: {
      keys: ['timestamp', 'depth', 'y'],
    },
  },
  title: {
    text: null,
  },
  tooltip: {
    animation: false,
    valueDecimals: 2,
    backgroundColor: colors.grey[800],
    borderColor: colors.grey[800],
    borderRadius: 20,
    borderWidth: 1,
    shared: true,
    split: false,
    style: {
      fontSize: defaultFontSize,
    },
    positioner: function () {
      return { x: 10, y: 50 };
    },
    headerFormat: `<span style="font-size:${defaultFontSize}"><b>{point.x:%A, %B %d, %H:%M:%S}</b></span><br>`,
    pointFormatter: function () {
      const { series, y: value } = this;
      const { name, color, options } = series;
      const { unit } = options;

      const formattedValue = unit
        ? formatValue(value, unit)
        : formatValue(value);

      const pointFormat = `<div style="display:inline;color:${color};font-size:${defaultFontSize}">
        ${name}: <b>${formattedValue}</b>
      </div>`;

      return `${pointFormat} <br />`;
    },
    useHTML: true,
  },
  subtitle: {
    text: null,
  },
  yAxis: {
    crosshair: true,
  },
  xAxis: {
    crosshair: true,
  },
  // NB! It is not an official Highcharts option, but added here for easy access.
  style: {
    fontColor,
    backgroundColor,
    defaultFontSize,
    subFontSize,
    capFontSize,
  },
};

export default dashboardHighchartsTheme;
