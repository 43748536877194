import { compose } from 'redux';
import PropTypes from 'prop-types';
import { memo, Fragment } from 'react';
import { Iterable, fromJS } from 'immutable';
import { Grid, Box } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import withStyles from '@material-ui/styles/withStyles';

import { invokeIfFunction } from 'utils/app.util';
import { EMPTY_LIST, UNITS } from 'app/app.constants';
import { Typography } from 'app/components/withNavigation';
import ToolDetails from 'features/projects/tool/components/ToolDetails';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';
import ToolstringPreviewEmpty from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewEmpty';
import ToolstringPreviewBreakpoint from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewBreakpoint';
import { useHighlightedToolstringItem } from 'features/projects/tasks/task/toolstring/components/ToolstringItemHighlightProvider';

const ToolstringPreviewSections = ({
  classes,
  hideToolDetails,
  onToggleBreakpoint,
  toolstringSections = EMPTY_LIST,
  showBreakPoint = true,
}) => {
  const { isItemHighlighted, setHighlightedItemId } =
    useHighlightedToolstringItem();
  if (!toolstringSections.size) {
    return <ToolstringPreviewEmpty />;
  }

  const toggleableBreakpoints = !!onToggleBreakpoint;

  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="column"
      classes={{ root: classes.root }}
    >
      {toolstringSections.valueSeq().map((toolstringSection, sectionIndex) => {
        const isLastSection = sectionIndex === toolstringSections.size - 1;
        const itemsInSection = toolstringSection.get('items');
        const lastSectionItem = itemsInSection.last();

        let flatItems = [];
        itemsInSection.forEach((item) => {
          const assemblyTools = item.get('toolAssemblyTools');
          if (assemblyTools && assemblyTools.size > 0) {
            assemblyTools.forEach((assemblyItem) => {
              flatItems.push(
                assemblyItem.set(
                  'parentToolstringItemId',
                  item.get('toolstringItemId'),
                ),
              );
            });
          } else {
            flatItems.push(item.set('isTopLevel', true));
          }
        });
        flatItems = fromJS(flatItems);

        const maxLength = flatItems
          .maxBy((item) => item.getIn(['length', 'roundedValue']))
          .getIn(['length', 'roundedValue']);

        let sumRatios = 0;
        const itemsWithInitialRatios = flatItems.map((item) => {
          const lengthValue = item.getIn(['length', 'roundedValue']);
          const ratio = (lengthValue / maxLength) * 100;
          sumRatios += ratio;
          return item.set('initialRatio', ratio);
        });

        const totalRatio = 100;
        const itemsWithRatioFlat = itemsWithInitialRatios.map((item) => {
          const initialRatio = item.get('initialRatio');
          const adjustedRatio = (initialRatio / sumRatios) * totalRatio;
          return item.set('ratio', adjustedRatio).delete('initialRatio');
        });

        const updatedItemsInSection = itemsInSection.map((topLevelItem) => {
          const topLevelItemId = topLevelItem.get('toolstringItemId');
          let updatedTopLevelItem = itemsWithRatioFlat.find(
            (flatItem) =>
              flatItem.get('toolstringItemId') === topLevelItemId &&
              flatItem.get('isTopLevel'),
          );
          updatedTopLevelItem = updatedTopLevelItem
            ? updatedTopLevelItem
            : topLevelItem;

          if (
            topLevelItem.get('toolAssemblyTools') &&
            topLevelItem.get('toolAssemblyTools').size > 0
          ) {
            const updatedAssemblyTools = topLevelItem
              .get('toolAssemblyTools')
              .map((assemblyItem) => {
                const assemblyItemId = assemblyItem.get('toolAssemblyToolId');
                const updatedAssemblyItem = itemsWithRatioFlat.find(
                  (flatItem) =>
                    flatItem.get('toolAssemblyToolId') === assemblyItemId,
                );
                return updatedAssemblyItem
                  ? updatedAssemblyItem
                      .delete('parentToolstringItemId')
                      .delete('isTopLevel')
                  : assemblyItem;
              });
            return updatedTopLevelItem.set(
              'toolAssemblyTools',
              updatedAssemblyTools,
            );
          } else {
            return updatedTopLevelItem;
          }
        });

        return (
          <Fragment key={sectionIndex}>
            <Grid
              item
              container
              wrap="nowrap"
              className={classes.sectionContainer}
            >
              <Grid
                xs
                item
                container
                wrap="nowrap"
                justifyContent="flex-end"
                alignItems="center"
                classes={{ root: classes.section }}
              >
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    classes={{ root: classes.sectionLengthText }}
                  >
                    {`${toolstringSection.get('length')?.toFixed(3)} ${
                      UNITS.METER
                    }`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3} container wrap="nowrap" direction="column">
                {updatedItemsInSection.map((toolstringItem, index) => {
                  const tooltip = <ToolDetails tool={toolstringItem.toJS()} />;
                  const toolstringItemId =
                    toolstringItem.get('toolstringItemId');

                  const isLastToolInSection =
                    index === updatedItemsInSection.size - 1;

                  /* Put the mouse events in the grid so that the hoverable area is larger */
                  if (
                    toolstringItem.get('toolAssemblyTools') &&
                    toolstringItem.get('toolAssemblyTools').size
                  ) {
                    const tools = toolstringItem.get('toolAssemblyTools');
                    return tools.map((tool, toolIndex) => (
                      <Fragment key={toolIndex}>
                        <Grid
                          item
                          style={{ height: `${tool.get('ratio')}%` }}
                          container
                          justifyContent="center"
                          alignItems="flex-end"
                          className={classes.image}
                          onMouseEnter={() =>
                            invokeIfFunction(
                              setHighlightedItemId,
                              toolstringItemId,
                            )
                          }
                          onMouseLeave={() =>
                            invokeIfFunction(setHighlightedItemId)
                          }
                        >
                          <ToolImageContainer
                            toolName={tool.get('name')}
                            toolImageUrl={tool.get('imageUrl')}
                            title={!hideToolDetails ? tooltip : undefined}
                            highlighted={isItemHighlighted(toolstringItemId)}
                            style={{ maxHeight: '100%' }}
                            assembly={true}
                          />
                        </Grid>
                      </Fragment>
                    ));
                  }
                  return (
                    <Fragment key={index}>
                      <Grid
                        item
                        style={{ height: `${toolstringItem.get('ratio')}%` }}
                        container
                        justifyContent="center"
                        alignItems="flex-end"
                        className={classes.image}
                        key={toolstringItem.get('toolstringItemId')}
                        onMouseEnter={() =>
                          invokeIfFunction(
                            setHighlightedItemId,
                            toolstringItemId,
                          )
                        }
                        onMouseLeave={() =>
                          invokeIfFunction(setHighlightedItemId)
                        }
                      >
                        <ToolImageContainer
                          onHoverScale
                          toolName={toolstringItem.get('name')}
                          toolImageUrl={toolstringItem.get('imageUrl')}
                          title={!hideToolDetails ? tooltip : undefined}
                          highlighted={isItemHighlighted(toolstringItemId)}
                          style={{
                            maxHeight: '100%',
                          }}
                        />
                      </Grid>
                      {showBreakPoint &&
                        toggleableBreakpoints &&
                        !isLastToolInSection && (
                          <ToolstringPreviewBreakpoint
                            toggleable
                            hasBreakpoint={false}
                            onToggleBreakpoint={() =>
                              onToggleBreakpoint(toolstringItem)
                            }
                          />
                        )}
                    </Fragment>
                  );
                })}
              </Grid>
              {<Grid xs item />}
            </Grid>
            {!isLastSection && (
              <Grid item container wrap="nowrap" justifyContent="center">
                {/* This is to align "on" breakpoints with the "off" ones. Margin left is classes.section.marginRight + classes.section.paddingRight */}
                <Grid item xs={3} component={Box} marginLeft={2}>
                  <ToolstringPreviewBreakpoint
                    hasBreakpoint
                    toggleable={toggleableBreakpoints}
                    onToggleBreakpoint={() =>
                      onToggleBreakpoint(lastSectionItem)
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        );
      })}
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  image: {
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
  },
  sectionContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  section: {
    borderRight: `1px solid ${amber['A700']}`,
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -theme.spacing(0.25),
      right: -theme.spacing(1),
      width: theme.spacing(2),
      height: theme.spacing(0.25),
      borderBottom: `1px solid ${amber['A700']}`,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -theme.spacing(0.25),
      right: -theme.spacing(1),
      width: theme.spacing(2),
      height: theme.spacing(0.25),
      borderBottom: `1px solid ${amber['A700']}`,
    },
  },
  sectionLengthText: {
    color: amber['A700'],
  },
});

ToolstringPreviewSections.propTypes = {
  hideToolDetails: PropTypes.bool,
  onToggleBreakpoint: PropTypes.func,
  toolstringSections: PropTypes.instanceOf(Iterable),
};

export default compose(memo, withStyles(styles))(ToolstringPreviewSections);
