import * as THREE from 'three';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Cylinder } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import React, { Suspense, useState, useEffect, useRef } from 'react';
import Environment from 'app/components/WellboreTrajectoryDetailed3DView/Environment';

const Tool3DObjectModalContent = ({
  tool3DObjectUrl,
  toolDiameter,
  toolLength,
}) => {
  const cameraPosition = toolDiameter > toolLength ? (10 * toolDiameter) : (5 * toolLength)
  return (
    <>
      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 70,
          zIndex: 100,
        }}
      >
        <img
          src={`${PUBLIC_ASSETS_URL}/images/loader/3DRotate.gif`}
          height="100"
          width="100"
          alt="3D Rotation"
        />
      </div>
      <Canvas shadows camera={{ position: [0, 0, cameraPosition], fov: 75 }}>
        <Suspense fallback={null}>
          <Environment />
          {tool3DObjectUrl ? (
            <ToolObject tool3DObjectUrl={tool3DObjectUrl} />
          ) : (
            <DefaultToolObject
              toolDiameter={toolDiameter}
              toolLength={toolLength}
            />
          )}
          <OrbitControls />
        </Suspense>
      </Canvas>
    </>
  );
};

const ToolObject = ({ tool3DObjectUrl }) => {
  const [objectLoaded, setObjectLoaded] = useState(null);
  const objectRef = useRef();

  useEffect(() => {
    let loader = new OBJLoader();
    if (tool3DObjectUrl) {
      loader.load(
        tool3DObjectUrl,
        (toolObj) => {
          setObjectLoaded(toolObj);
          toolObj.traverse((child) => {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial({
                color: '#63686c',
                roughness: 0,
                metalness: 1,
              });
            }
          });
          const boundingBox = new THREE.Box3().setFromObject(toolObj);

          // Compute the center of the bounding box
          const center = new THREE.Vector3();
          boundingBox.getCenter(center);

          // Translate all children (meshes) to re-center the group
          toolObj.children.forEach((child) => {
            child.position.sub(center);
          });
        },
        undefined,
        (error) => {
          console.error('An error occurred:', error);
        },
      );
    }

    return () => {
      loader = null;
      setObjectLoaded(null);
    };
  }, [tool3DObjectUrl]);

  return (
    objectLoaded && (
      <primitive ref={objectRef} object={objectLoaded} scale={0.005} />
    )
  );
};

const DefaultToolObject = ({ toolDiameter, toolLength }) => {
  return (
    <Cylinder
      name="BHA"
      rotation={[0, 0, Math.PI / 2]}
      args={[toolDiameter / 2, toolDiameter / 2, toolLength, 32, 1]}
      scale={10}
    >
      <meshStandardMaterial
        color={'#63686c'}
        roughness={0}
        metalness={1}
        envMapIntensity={3}
        side={THREE.DoubleSide}
      />
    </Cylinder>
  );
};

export default Tool3DObjectModalContent;
