import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Grid } from 'app/components/withTooltip';

import { Table, TableRow } from 'altus-ui-components';
import { isImmutable } from 'immutable';

import { toSurfaceEquipment } from 'utils/route.util';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import { getUnitsOfMeasurePreference } from 'features/equipment/equipment.selectors';
import { UNIT_OF_MEASURE } from 'features/equipment/equipment.constants';
import ToolImageTableCell from 'app/components/ToolImageTableCell';
import { ExifOrientation } from 'features/projects/tool/tool.constants';
import { compose } from 'redux';

const SurfaceEquipmentTable = ({ tools = [], classes }) => {
  const unitsOfMeasurePreference = useSelector(getUnitsOfMeasurePreference);
  const weightUnit =
    unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL ? 'lbs' : 'kg';
  const measureUnit =
    unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL ? 'in' : 'mm';

  const initialState = useMemo(
    () => ({
      pageSize: 10,
    }),
    [],
  );
  const getSubRows = (row) =>
    isImmutable(row) ? row.get('equipmentImage') : row.original?.equipmentImage;

  const getToolGroupSubRows = (row) =>
    isImmutable(row) ? row.get('equipmentGroup') : row.original?.equipmentGroup;

  const columns = useMemo(
    () => [
      {
        xs: 1,
        id: 'dataVerified',
        Header: '',
        Cell: ({ row }) => {
          const tool = row.original;
          if (!tool.get('dataVerified')) {
            return (
              <Grid container justifyContent="center" alignItems="center">
                <Tooltip title="Unverified tool">
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
              </Grid>
            );
          } else return null;
        },
      },
      {
        xs: 3,
        id: 'toolItemNo',
        Header: 'Item no.',
        accessor: (tool) => tool.get('m3ItemNumber'),
      },
      {
        xs: 3,
        id: 'toolImage',
        Header: 'Image',
        Cell: ({ row }) => {
          const { original: tool } = row;
          const image = getSubRows(tool);
          const isNull = Object.keys(image).length === 0;
          return (
            <ToolImageTableCell
              onHoverDarken
              title={isNull ? '' : image.get('name')}
              toolName={isNull ? '' : image.get('name')}
              toolImageUrl={isNull ? '' : image.get('imageUrl')}
              orientation={ExifOrientation.DEGREES_90_MIRRORED}
            />
          );
        },
      },
      {
        xs: 4,
        id: 'toolName',
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 4,
        id: 'surfaceEquipmentGroup',
        Header: 'Surface Eq. Group',
        Cell: ({ row }) => {
          const { original: tool } = row;
          const group = getToolGroupSubRows(tool);
          const isNull = Object.keys(group).length === 0;
          return isNull ? '' : group.get('name');
        },
      },
      {
        xs: 4,
        id: 'weight_kg',
        Header: (
          <QuantityTableHeaderTitleCell title="Weight" unit={weightUnit} />
        ),
        accessor: (tool) => tool.get('weight'),
      },
      {
        xs: 4,
        id: 'height_mm',
        Header: (
          <QuantityTableHeaderTitleCell title="Height" unit={measureUnit} />
        ),
        accessor: (tool) => tool.get('height'),
      },
      {
        xs: 4,
        id: 'length_mm',
        Header: (
          <QuantityTableHeaderTitleCell title="Length" unit={measureUnit} />
        ),
        accessor: (tool) => tool.get('length'),
      },
      {
        xs: 4,
        id: 'width_mm',
        Header: (
          <QuantityTableHeaderTitleCell title="Width" unit={measureUnit} />
        ),
        accessor: (tool) => tool.get('width'),
      },
    ],
    [classes.warningIcon, measureUnit, weightUnit],
  );

  const renderTableRowComponent = ({ item, ...rest }) => {
    return (
      <Link
        to={toSurfaceEquipment(item.get('equipmentId'))}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TableRow {...rest} />
      </Link>
    );
  };

  return (
    <Table
      stickyHeader
      usePagination
      columns={columns}
      items={tools}
      useGlobalFilter={true}
      initialState={initialState}
      TableRowComponent={renderTableRowComponent}
      noItemsMessage="No equipments..."
    />
  );
};

const styles = (theme) => ({
  warningIcon: {
    color: theme.palette.warning.main,
  },
});

export default compose(withStyles(styles))(SurfaceEquipmentTable);
