import {
  deleteWellboreSection,
  deleteWellboreSectionNipple,
  receiveWellboreSectionNipples,
  receiveWellboreSections,
} from 'features/wells/sections/wellboreSection.actions';

import { invokeIfFunction } from 'utils/app.util';
import { FormikFormStatus } from 'app/app.constants';
import projectWellboreSectionService from 'services/projectWellboreSection.service';
import { ACTIONS } from 'features/projects/wellbore/sections/projectWellboreSection.constants';
import { askProjectWellboreUpdateConfirmation } from 'features/projects/wellbore/projectWellbore.actions';

export const requestProjectWellboreSections = (projectId) => (dispatch) => {
  const payload = projectWellboreSectionService
    .getProjectWellboreSections(projectId)
    .then((wellboreSections) =>
      dispatch(receiveWellboreSections(wellboreSections)),
    );

  dispatch({
    payload,
    type: ACTIONS.REQUEST_PROJECT_WELLBORE_SECTIONS,
  });

  return payload;
};

export const requestCreateProjectWellboreSection =
  (projectId, wellboreSection, formik, callback) => (dispatch) => {
    const { setStatus, setSubmitting } = formik;

    const payload = () =>
      projectWellboreSectionService
        .createProjectWellboreSection(projectId, wellboreSection)
        .then(() => dispatch(requestProjectWellboreSections(projectId)))
        .then(() => {
          setSubmitting(false);
          setStatus(FormikFormStatus.SUCCESS);
          invokeIfFunction(callback);
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(FormikFormStatus.ERROR);
          throw error;
        });

    dispatch(
      askProjectWellboreUpdateConfirmation({
        onConfirm: () =>
          dispatch({
            payload,
            type: ACTIONS.REQUEST_CREATE_PROJECT_WELLBORE_SECTION,
            notification: {
              success: 'Section successfully created',
            },
          }),
        onCancel: () => setSubmitting(false),
      }),
    );

    return payload;
  };

export const requestUpdateProjectWellboreSection =
  (projectId, wellboreSectionId, wellboreSection) => (dispatch) => {
    const payload = () =>
      projectWellboreSectionService
        .updateProjectWellboreSection(
          projectId,
          wellboreSectionId,
          wellboreSection,
        )
        .then(() => dispatch(requestProjectWellboreSections(projectId)));

    dispatch(
      askProjectWellboreUpdateConfirmation({
        onConfirm: () =>
          dispatch({
            payload,
            type: ACTIONS.REQUEST_UPDATE_PROJECT_WELLBORE_SECTION,
            notification: {
              success: 'Section successfully updated',
            },
          }),
      }),
    );

    return payload;
  };

export const requestDeleteProjectWellboreSection =
  (projectId, wellboreSectionId) => (dispatch) => {
    const payload = () =>
      projectWellboreSectionService
        .deleteProjectWellboreSection(projectId, wellboreSectionId)
        .then(() => dispatch(deleteWellboreSection(wellboreSectionId)));

    dispatch(
      askProjectWellboreUpdateConfirmation({
        onConfirm: () =>
          dispatch({
            payload,
            type: ACTIONS.REQUEST_DELETE_PROJECT_WELLBORE_SECTION,
            notification: {
              success: 'Section successfully deleted',
            },
          }),
      }),
    );

    return payload;
  };

export const requestCreateProjectWellboreSectionNipple =
  (projectId, wellboreSectionId, wellboreSectionNipple, formik, callback) =>
  (dispatch) => {
    const { setStatus, setSubmitting } = formik;

    const payload = () =>
      projectWellboreSectionService
        .createProjectWellboreSectionNipple(
          projectId,
          wellboreSectionId,
          wellboreSectionNipple,
        )
        .then(({ wellboreSectionNipples }) =>
          dispatch(
            receiveWellboreSectionNipples(
              wellboreSectionId,
              wellboreSectionNipples,
            ),
          ),
        )
        .then(() => {
          setSubmitting(false);
          setStatus(FormikFormStatus.SUCCESS);
          invokeIfFunction(callback);
        })
        .catch((error) => {
          setSubmitting(false);
          setStatus(FormikFormStatus.ERROR);
          throw error;
        });

    dispatch(
      askProjectWellboreUpdateConfirmation({
        onConfirm: () =>
          dispatch({
            payload,
            type: ACTIONS.REQUEST_CREATE_PROJECT_WELLBORE_SECTION_NIPPLE,
            notification: {
              info: 'Saving...',
              success: 'Nipple section successfully created',
            },
          }),
        onCancel: () => setSubmitting(false),
      }),
    );

    return payload;
  };

export const requestUpdateProjectWellboreSectionNipple =
  (
    projectId,
    wellboreSectionId,
    wellboreSectionNippleId,
    wellboreSectionNipple,
  ) =>
  (dispatch) => {
    const payload = () =>
      projectWellboreSectionService
        .updateProjectWellboreSectionNipple(
          projectId,
          wellboreSectionId,
          wellboreSectionNippleId,
          wellboreSectionNipple,
        )
        .then(({ wellboreSectionNipples }) =>
          dispatch(
            receiveWellboreSectionNipples(
              wellboreSectionId,
              wellboreSectionNipples,
            ),
          ),
        );

    dispatch(
      askProjectWellboreUpdateConfirmation({
        onConfirm: () =>
          dispatch({
            payload,
            type: ACTIONS.REQUEST_UPDATE_PROJECT_WELLBORE_SECTION_NIPPLE,
            notification: {
              info: 'Saving...',
              success: 'Nipple section successfully updated',
            },
          }),
      }),
    );

    return payload;
  };

export const requestDeleteProjectWellboreSectionNipple =
  (projectId, wellboreSectionId, wellboreSectionNippleId) => (dispatch) => {
    const payload = () =>
      projectWellboreSectionService
        .deleteProjectWellboreSectionNipple(
          projectId,
          wellboreSectionId,
          wellboreSectionNippleId,
        )
        .then(() =>
          dispatch(
            deleteWellboreSectionNipple(
              wellboreSectionId,
              wellboreSectionNippleId,
            ),
          ),
        );

    dispatch(
      askProjectWellboreUpdateConfirmation({
        onConfirm: () =>
          dispatch({
            payload,
            type: ACTIONS.REQUEST_DELETE_PROJECT_WELLBORE_SECTION_NIPPLE,
            notification: {
              info: 'Deleting...',
              success: 'Nipple section successfully deleted',
            },
          }),
      }),
    );

    return payload;
  };

  export const syncProjectWellboreSectionWithLatest= (projectId) => (dispatch) => {
    const payload = () =>
        projectWellboreSectionService
        .syncProjectWellboreSectionsWithLatest(projectId)
        .then((sections) =>
          dispatch(receiveWellboreSections(sections)),
        );
  
    dispatch(
      askProjectWellboreUpdateConfirmation({
        onConfirm: () =>
          dispatch({
            payload,
            type: ACTIONS.SYNC_PROJECT_WELLBORE_SECTIONS_WITH_LATEST,
            notification: {
              info: 'Updating...',
              success: 'Project well sections successfully updated',
            },
          }),
      }),
    );
  
    return payload;
  }; 
