import { useCallback } from 'react';
import { Chip } from '@material-ui/core';

const YesNoSwitchFormik = ({ field, form, disabled, ...rest }) => {
  const { value, name } = field;
  const { setFieldValue } = form;

  const handleClick = useCallback(() => {
    setFieldValue(name, !value);
  }, [name, setFieldValue, value]);

  return (
    <Chip
      name={name}
      clickable={true}
      disabled={disabled}
      checked={!!value}
      onClick={handleClick}
      label={value ? 'Yes' : 'No'}
      color={value ? 'primary' : 'grey'}
      {...rest}
    />
  );
};

export default YesNoSwitchFormik;
