import _isNil from 'lodash/isNil';
import { Grid, Box, Tooltip, Link } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { EditorState, convertFromRaw } from 'draft-js';

import GreenIcon from 'assets/Green.svg';
import {
  TOOL_ASSET_EVENTS_ENUM,
  TEST_ASSET_RESULT,
  TEST_RESULT_TO_TOOLTIP_TITLE,
  ASSET_HISTORY_TEST_TYPES_DICT,
} from 'features/equipment/equipment.constants';
import OrangeIcon from 'assets/Orange.svg';
import RedIcon from 'assets/Red.svg';
import EditorStateViewer from 'app/components/EditorStateViewer';

export const testResultIcons = {
  [TEST_ASSET_RESULT.PASS]: GreenIcon,
  [TEST_ASSET_RESULT.FAIL]: RedIcon,
  [TEST_ASSET_RESULT.WARNING]: OrangeIcon,
};

const richText = (item, key) =>
  item?.get(key)
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(item?.get(key))))
    : EditorState.createEmpty();

const AssetHistoryTableRowDetails = ({ classes, item }) => {
  const bodyRenderHandler = (item) => {
    switch (item.get('event')) {
      case TOOL_ASSET_EVENTS_ENUM.TASK:
        return !item?.get('task') ? null : (
          <TaskBodyRow
            task={item?.get('task')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.RUN:
        return !item?.get('task') ? null : (
          <TaskBodyRow
            task={item?.get('task')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.TEST:
        return !item?.get('test') ? null : (
          <TestBodyRow
            test={item?.get('test')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.BOP_TEST:
        return !item?.get('bopTest') ? null : (
          <TestBodyRow
            test={item?.get('bopTest')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_TORSION_TEST:
        return !item?.get('cableTorsionTest') ? null : (
          <TestBodyRow
            test={item?.get('cableTorsionTest')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_CONTINUITY_TEST:
        return !item?.get('cableContinuityTest') ? null : (
          <TestBodyRow
            test={item?.get('cableContinuityTest')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_WRAP_TEST:
        return !item?.get('cableWrapTest') ? null : (
          <TestBodyRow
            test={item?.get('cableWrapTest')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_TORTURE_TEST:
        return !item?.get('cableTortureTest') ? null : (
          <TestBodyRow
            test={item?.get('cableTortureTest')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.CABLE_INSULATION_TEST:
        return !item?.get('cableInsulationTest') ? null : (
          <TestBodyRow
            test={item?.get('cableInsulationTest')}
            projectId={item?.get('projectId')}
          />
        );
      case TOOL_ASSET_EVENTS_ENUM.COMPLAINTS:
        return !item?.get('complaint') ? null : (
          <MaintenanceAndComplaintBody item={item?.get('complaint')} />
        );
      case TOOL_ASSET_EVENTS_ENUM.MAINTENANCE:
        return !item?.get('maintenance') ? null : (
          <MaintenanceAndComplaintBody item={item?.get('maintenance')} />
        );
      default:
        <></>;
    }
  };

  const headerRenderHandler = () => {
    switch (item.get('event')) {
      case TOOL_ASSET_EVENTS_ENUM.TASK:
        return <TaskHeaderRow task={item?.get('task')} />;
      case TOOL_ASSET_EVENTS_ENUM.RUN:
        return <TaskHeaderRow task={item?.get('task')} />;
      case TOOL_ASSET_EVENTS_ENUM.TEST:
        return <TestHeaderRow />;
      case TOOL_ASSET_EVENTS_ENUM.BOP_TEST:
        return <TestHeaderRow />;
      case TOOL_ASSET_EVENTS_ENUM.COMPLAINTS:
        return <MaintenanceAndComplaintHeader />;
      case TOOL_ASSET_EVENTS_ENUM.MAINTENANCE:
        return <MaintenanceAndComplaintHeader />;
      default:
        <></>;
    }
  };

  return (
    <Grid
      container
      paddingTop={3}
      component={Box}
      paddingBottom={3}
      alignItems="center"
      className={classes.root}
    >
      <Grid container className={classes.expansionPanelDetailsRow}>
        {headerRenderHandler()}
      </Grid>
      <Grid
        container
        alignItems="center"
        className={classes.expansionPanelDetailsSection}
      >
        {bodyRenderHandler(item)}
      </Grid>
    </Grid>
  );
};

const TestHeaderRow = () => {
  return (
    <>
      <Grid item xs={1}>
        Result
      </Grid>
      <Grid item xs={1}>
        Event Type
      </Grid>
      <Grid item xs={10}>
        Comment
      </Grid>
    </>
  );
};

const MaintenanceAndComplaintHeader = () => {
  return (
    <>
      <Grid item xs={11}>
        Comment
      </Grid>
    </>
  );
};

const MaintenanceAndComplaintBody = ({ item }) => {
  return (
    <>
      <Grid item xs={11}>
        {<EditorStateViewer editorState={richText(item, 'description')} />}
      </Grid>
    </>
  );
};

const TestBodyRow = ({ test }) => {
  const handleResultIcon = (testResult) => {
    return testResultIcons[testResult] || GreenIcon;
  };

  const getTooltipTitle = (testResult) => {
    return TEST_RESULT_TO_TOOLTIP_TITLE[testResult] || 'Unknown';
  };

  return (
    <>
      <Grid item xs={1}>
        <Tooltip title={getTooltipTitle(test.get('result'))}>
          <img src={handleResultIcon(test.get('result'))} alt="Status icon" />
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={1}
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        {ASSET_HISTORY_TEST_TYPES_DICT[test?.get('testType')]}
      </Grid>
      <Grid item xs={10}>
        {<EditorStateViewer editorState={richText(test, 'comment')} />}
      </Grid>
    </>
  );
};

const TaskHeaderRow = ({ task }) => {
  return (
    <>
      <Grid item xs={3}>
        Title
      </Grid>
      <Grid item xs={3}>
        Task Type
      </Grid>
      <Grid item xs={3}>
        Description
      </Grid>
      <Grid item xs={3}>
        Target depth ({task?.getIn(['targetDepth', 'unit'])})
      </Grid>
    </>
  );
};

const TaskBodyRow = ({ task, projectId }) => {
  const cableComments = task?.get('dataAcquisitionCableComments', null);

  return (
    <>
      <Grid item xs={3}>
        <Link
          target="_blank"
          href={`/projects/${projectId}/plan/${task?.get('taskId')}`}
        >
          {task?.get('title')}
        </Link>
      </Grid>
      <Grid item xs={3}>
        {task?.get('taskTypeName')}
      </Grid>
      <Grid item xs={3}>
        {<EditorStateViewer editorState={richText(task, 'description')} />}
      </Grid>
      <Grid item xs={3}>
        {task?.getIn(['targetDepth', 'roundedValue'])}
      </Grid>
      {!_isNil(cableComments) && (
        <Grid item xs={12}>
          <EditorStateViewer
            editorState={richText(task, 'dataAcquisitionCableComments')}
          />
        </Grid>
      )}
    </>
  );
};

const styles = (theme) => ({
  root: {
    backgroundColor: '#383838',
  },
  expansionPanelDetailsSection: {
    paddingLeft: theme.spacing(1.5),
  },
  expansionPanelDetailsRow: {
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
});

export default withStyles(styles)(AssetHistoryTableRowDetails);
