import * as colors from '@material-ui/core/colors';

export const white = 'rgb(255,255,255)';

export const graph = colors.grey[800];

const depthColor = colors.orange[600];

// TODO: Move to theme?
export const dashboard = {
  depth: depthColor,
  dhsv: colors.green[500],
  targetDepth: colors.yellow[600],
  maximumWorkingDepth: colors.red[500],
};
