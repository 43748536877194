import { createSelector } from 'utils/app.util';

export const getProjectMobilisationTasksFromState = createSelector(
  (state) => state.getIn(['entity', 'projectMobilisation', 'tasks']),
  (tasks) => tasks,
);

export const getStringTestReportsForTaskFromState = createSelector(
  (state) =>
    state.getIn(['entity', 'projectMobilisation', 'stringTestReportsForTask']),
  (reports) => reports,
);

export const getStringTestReportEventFromState = createSelector(
  (state) =>
    state.getIn(['entity', 'projectMobilisation', 'stringTestReportEvent']),
  (event) => event,
);
