import { compose } from 'redux';
import PropTypes from 'prop-types';
import { memo, Fragment } from 'react';
import { Iterable, fromJS } from 'immutable';
import { Grid, Typography } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import withStyles from '@material-ui/styles/withStyles';

import { EMPTY_LIST } from 'app/app.constants';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';
import ToolstringPreviewEmpty from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewEmpty';

const MobileToolstringPreview = ({
  classes,
  toolstringSections = EMPTY_LIST,
}) => {
  if (!toolstringSections.size) {
    return <ToolstringPreviewEmpty />;
  }

  return (
    <Grid
      item
      xs={11}
      container
      wrap="nowrap"
      direction="column"
      classes={{ root: classes.root }}
    >
      {toolstringSections.valueSeq().map((toolstringSection, sectionIndex) => {
        const itemsInSection = toolstringSection.get('items');

        let flatItems = [];
        itemsInSection.forEach((item) => {
          const assemblyTools = item.get('toolAssemblyTools');
          if (assemblyTools && assemblyTools.size > 0) {
            assemblyTools.forEach((assemblyItem) => {
              flatItems.push(
                assemblyItem.set(
                  'parentToolstringItemId',
                  item.get('toolstringItemId'),
                ),
              );
            });
          } else {
            flatItems.push(item.set('isTopLevel', true));
          }
        });
        flatItems = fromJS(flatItems);

        const maxLength = flatItems
          .maxBy((item) => item.getIn(['length', 'roundedValue']))
          .getIn(['length', 'roundedValue']);

        let sumRatios = 0;
        const itemsWithInitialRatios = flatItems.map((item) => {
          const lengthValue = item.getIn(['length', 'roundedValue']);
          const ratio = (lengthValue / maxLength) * 100;
          sumRatios += ratio;
          return item.set('initialRatio', ratio);
        });

        const totalRatio = 100;
        const itemsWithRatioFlat = itemsWithInitialRatios.map((item) => {
          const initialRatio = item.get('initialRatio');
          const adjustedRatio = (initialRatio / sumRatios) * totalRatio;
          return item.set('ratio', adjustedRatio).delete('initialRatio');
        });

        const updatedItemsInSection = itemsInSection.map((topLevelItem) => {
          const topLevelItemId = topLevelItem.get('toolstringItemId');
          let updatedTopLevelItem = itemsWithRatioFlat.find(
            (flatItem) =>
              flatItem.get('toolstringItemId') === topLevelItemId &&
              flatItem.get('isTopLevel'),
          );
          updatedTopLevelItem = updatedTopLevelItem
            ? updatedTopLevelItem
            : topLevelItem;

          if (
            topLevelItem.get('toolAssemblyTools') &&
            topLevelItem.get('toolAssemblyTools').size > 0
          ) {
            const updatedAssemblyTools = topLevelItem
              .get('toolAssemblyTools')
              .map((assemblyItem) => {
                const assemblyItemId = assemblyItem.get('toolAssemblyToolId');
                const updatedAssemblyItem = itemsWithRatioFlat.find(
                  (flatItem) =>
                    flatItem.get('toolAssemblyToolId') === assemblyItemId,
                );
                return updatedAssemblyItem
                  ? updatedAssemblyItem
                      .delete('parentToolstringItemId')
                      .delete('isTopLevel')
                  : assemblyItem;
              });
            return updatedTopLevelItem.set(
              'toolAssemblyTools',
              updatedAssemblyTools,
            );
          } else {
            return updatedTopLevelItem;
          }
        });

        return (
          <Fragment key={sectionIndex}>
            <Grid
              item
              container
              wrap="nowrap"
              className={classes.sectionContainer}
            >
              <Grid item xs={11} container wrap="nowrap" direction="column">
                {updatedItemsInSection.map((toolstringItem, index) => {
                  if (
                    toolstringItem.get('toolAssemblyTools') &&
                    toolstringItem.get('toolAssemblyTools').size
                  ) {
                    const tools = toolstringItem.get('toolAssemblyTools');
                    return tools.map((tool, toolIndex) => (
                      <Fragment key={toolIndex}>
                        <Grid
                          item
                          wrap="nowrap"
                          style={{ height: `${tool.get('ratio')}%` }}
                          container
                          justifyContent="center"
                          alignItems="center"
                          className={classes.image}
                        >
                          <Grid item xs={4} className={classes.toolImage}>
                            <ToolImageContainer
                              toolName={tool.get('name')}
                              toolImageUrl={tool.get('imageUrl')}
                              style={{ maxHeight: '100%' }}
                              assembly={true}
                            />
                          </Grid>
                          <Grid item xs={2} />
                          <Grid item xs={6}>
                            <Typography wrap variant="body2">
                              {' '}
                              {tool.get('name')}{' '}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Fragment>
                    ));
                  }
                  return (
                    <Fragment key={index}>
                      <Grid
                        item
                        wrap="nowrap"
                        style={{ height: `${toolstringItem.get('ratio')}%` }}
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.image}
                        key={toolstringItem.get('toolstringItemId')}
                      >
                        <Grid item xs={4} className={classes.toolImage}>
                          <ToolImageContainer
                            toolName={toolstringItem.get('name')}
                            toolImageUrl={toolstringItem.get('imageUrl')}
                            style={{
                              maxHeight: '100%',
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={6}>
                          <Typography wrap variant="body2">
                            {' '}
                            {toolstringItem.get('name')}{' '}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
              {<Grid xs item />}
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

const styles = () => ({
  root: {
    overflow: 'hidden',
  },
  image: {
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  sectionContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  sectionLengthText: {
    color: amber['A700'],
  },
  toolImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

MobileToolstringPreview.propTypes = {
  toolstringSections: PropTypes.instanceOf(Iterable),
};

export default compose(memo, withStyles(styles))(MobileToolstringPreview);
