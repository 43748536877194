import { useMemo } from 'react';
import PropTypes from 'prop-types';
import takeLast from 'lodash/last';
import Highcharts from 'highcharts';
import takeFirst from 'lodash/first';
import * as MuiColors from '@material-ui/core/colors';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useRef, useState } from 'react';

import { updateSeriesData } from 'utils/dashboard.util';
import winchHighchartsTheme from 'features/projects/dashboard/components/dashboards/winch/winch.highcharts.theme';

const { fontColor, defaultFontSize } = winchHighchartsTheme.style;

const DashboardGaugeChart = ({
  curve,
  startAngle = 0,
  registerDataPointsHandler,
}) => {
  const chartComponent = useRef(null);

  const onReceiveDataPoints = useCallback(
    (take) => (readings) =>
      updateSeriesData(chartComponent, readings, (reading, serie) => {
        const data = take(reading.data);

        if (!data) return;

        serie.setData(
          [data],
          false, // do not redraw, updateSeriesData will handle that
        );
      }),
    [],
  );

  const onReceiveInitialData = useMemo(
    () => onReceiveDataPoints(takeLast),
    [onReceiveDataPoints],
  );

  const onReceiveData = useMemo(
    () => onReceiveDataPoints(takeFirst),
    [onReceiveDataPoints],
  );

  const [chartOptions] = useState(
    Highcharts.merge(false, winchHighchartsTheme, {
      chart: {
        type: 'gauge',
        style: {
          fontWeight: 'bold',
        },
      },
      title: {
        text: null,
      },
      series: [
        {
          id: curve.get('id'),
          unit: curve.get('unit'),
          name: curve.get('name'),
          data: [[null, null, 0]], // [timestamp, depth, y]
        },
      ],
      subtitle: {
        text: null,
      },
      pane: {
        startAngle,
        background: {
          borderWidth: 1,
          outerRadius: '105%',
          backgroundColor: MuiColors.grey[900],
        },
      },
      plotOptions: {
        gauge: {
          dial: {
            borderColor: curve.get('color'),
            backgroundColor: curve.get('color'),
          },
        },
      },
      yAxis: {
        minorTickInterval: 'auto',
        minorTickWidth: 0,
        minorTickLength: 0,
        minorTickPosition: 'inside',
        minorTickColor: MuiColors.grey[700],
        lineWidth: 0,
        tickPixelInterval: 100,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 25,
        tickColor: MuiColors.grey[700],
        tickmarkPlacement: 'on',
        gridZIndex: 4,
        labels: {
          step: 2,
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
          formatter: function () {
            return this.value;
          },
          showFirstLabel: false,
        },
        softMax: curve.get('maxValue'),
        softMin: curve.get('minValue'),
        title: {
          align: 'middle',
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
          text: curve.get('caption'),
        },
      },
      tooltip: {
        enabled: false,
      },
    }),
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveInitialData, onReceiveData);
  }, [onReceiveData, onReceiveInitialData, registerDataPointsHandler]);

  return (
    <HighchartsReact
      allowChartUpdate
      ref={chartComponent}
      options={chartOptions}
      highcharts={Highcharts}
    />
  );
};

DashboardGaugeChart.propTypes = {
  startAngle: PropTypes.number,
  registerDataPointsHandler: PropTypes.func.isRequired,
};

export default DashboardGaugeChart;
