import moment from 'moment/moment';
import { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import toJSComponent from 'with-immutable-props-to-js';
import { reduxForm, Field } from 'redux-form/immutable';
import { Button, Grid, DialogActions } from '@material-ui/core';

import {
  addNotification,
  NOTIFICATION_VARIANTS,
} from 'altus-redux-middlewares';

import {
  updateProjectNonProductiveTime,
  onEditProjectNonProductiveTimeModalLoaded,
} from 'features/projects/npt/npt.actions';

import { toggleModal } from 'app/app.actions';
import { required } from 'utils/validation.util';
import TextField from 'app/components/form/TextField';
import LoadingButton from 'app/components/LoadingButton';
import { getFormValuesFromState } from 'app/app.selectors';
import EditorReduxForm from 'app/components/form/EditorField';
import ModalContainer from 'app/components/Modal/ModalContainer';
import { MODAL, NPT_FORM } from 'features/projects/npt/npt.constants';
import DepartmentSelectField from 'app/components/DepartmentSelectField';
import DateTimePickerField from 'app/components/form/DateTimePickerField';
import { getProjectNonProductiveTimeFromState } from 'features/projects/npt/npt.selectors';

const EditNPTModalContainer = ({
  npts,
  projectId,
  valid,
  children,
  submitting,
  formData,
  dispatchOnLoad,
  availableDepartments,
}) => {
  const dispatch = useDispatch();

  const handleSave = useCallback(
    (e) => {
      e.preventDefault();
      if (moment(formData.startTime) >= moment(formData.endTime)) {
        dispatch(
          addNotification({
            message: 'End date must be after start date.',
            variant: NOTIFICATION_VARIANTS.ERROR,
          }),
        );
      } else {
        dispatch(updateProjectNonProductiveTime(projectId));
        toggleModal();
      }
    },
    [formData, dispatch, projectId],
  );

  return (
    <ModalContainer
      items={npts}
      title="Edit Non Productive Time"
      onEnter={dispatchOnLoad}
      TriggerComponent={children}
      modalId={MODAL.EDIT_PROJECT_NPT}
      onNext={({ goNext }) => goNext()}
      onPrevious={({ goPrevious }) => goPrevious()}
      Actions={({ toggleModal }) => (
        <DialogActions>
          <Button size="small" onClick={toggleModal}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            color="primary"
            disabled={!valid}
            variant="contained"
            onClick={handleSave}
            submitting={submitting}
            form={NPT_FORM.EDIT_ID}
          >
            Save and close
          </LoadingButton>
        </DialogActions>
      )}
    >
      <form id={NPT_FORM.EDIT_ID} onSubmit={handleSave}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item container xs={6}>
                    <Field
                      label="Start time"
                      validate={[required]}
                      name={NPT_FORM.START}
                      component={DateTimePickerField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label="End time"
                      validate={[required]}
                      name={NPT_FORM.END}
                      component={DateTimePickerField}
                    />
                  </Grid>
                </Grid>
                <Field
                  maxRows={5}
                  label="Description"
                  xs={12}
                  validate={[required]}
                  component={EditorReduxForm}
                  name={NPT_FORM.DESCRIPTION}
                />
                <Field
                  component={TextField}
                  label="Case Number"
                  name={NPT_FORM.CASE_NUMBER}
                />
                <Field
                  format={null}
                  label="Departments"
                  departments={availableDepartments}
                  component={DepartmentSelectField}
                  name={NPT_FORM.DEPARTMENTS}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  npts: getProjectNonProductiveTimeFromState(state),
  formData: getFormValuesFromState(state, NPT_FORM.EDIT_ID),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchOnLoad: (npt) =>
    dispatch(onEditProjectNonProductiveTimeModalLoaded(npt)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  toJSComponent(
    reduxForm({
      form: NPT_FORM.EDIT_ID,
    })(EditNPTModalContainer),
  ),
);
