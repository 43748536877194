import * as THREE from 'three';
import { Cylinder } from '@react-three/drei';
import React, { useRef, useEffect, useState } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { convertMetric } from 'app/components/WellboreTrajectoryDetailed3DView/Utils';

const BHA = ({ BHA_LENGTH, maxOD, toolsByToolstringToolId }) => {
  const tools = useRef([]);
  const [_obj, setObj] = useState(null);

  useEffect(() => {
    tools.current = [];
    let posX = 0;
    const loader = new OBJLoader();
    Object.values(toolsByToolstringToolId).forEach((tool, index) => {
      if (
        tool.sequence === index + 1 &&
        convertMetric(
          tool.outerDiameter.roundedValue,
          tool.outerDiameter.unit,
        ) <= maxOD.value
      ) {
        posX -= convertMetric(tool.length.roundedValue, tool.length.unit);
        if (tool.tool3DObjectUrl) {
          loader.load(
            tool.tool3DObjectUrl,
            (toolObj) => {
              setObj(toolObj);
              toolObj.traverse((child) => {
                if (child.isMesh) {
                  child.material = new THREE.MeshStandardMaterial({
                    color: '#63686c',
                    roughness: 0,
                    metalness: 1,
                  });
                }
              });
              const boundingBox = new THREE.Box3().setFromObject(toolObj);

              // Compute the center of the bounding box
              const center = new THREE.Vector3();
              boundingBox.getCenter(center);

              // Translate all children (meshes) to re-center the group
              toolObj.children.forEach((child) => {
                child.position.sub(center);
              });
              tools.current.push(
                <>
                  <primitive
                    object={toolObj}
                    key={`${index}-${tool.sequence}`}
                    name="BHA"
                    scale={[0.001, 0.001, 0.001]}
                  />
                </>,
              );
            },
            undefined,
            (error) => {
              console.error('An error occurred:', error);
            },
          );
        } else {
          tools.current.push(
            <>
              <Cylinder
                key={`${index}-${tool.sequence}`}
                name="BHA"
                position={[
                  posX +
                    convertMetric(tool.length.roundedValue, tool.length.unit) /
                      2,
                  0,
                  0,
                ]}
                rotation={[-Math.PI / 2, 0, Math.PI / 2]}
                args={[
                  convertMetric(
                    tool.outerDiameter.roundedValue,
                    tool.outerDiameter.unit,
                  ) / 2,
                  convertMetric(
                    tool.outerDiameter.roundedValue,
                    tool.outerDiameter.unit,
                  ) / 2,
                  convertMetric(tool.length.roundedValue, tool.length.unit),
                  32,
                  1,
                ]}
              >
                <meshStandardMaterial
                  color={'#63686c'}
                  roughness={0}
                  metalness={1}
                  envMapIntensity={3}
                  side={THREE.DoubleSide}
                />
              </Cylinder>
              <Cylinder
                key={`${index}-${tool.sequence}-separator`}
                name={`separator-${tool.sequence}`}
                position={[
                  posX +
                    convertMetric(tool.length.roundedValue, tool.length.unit),
                  0,
                  0,
                ]}
                rotation={[-Math.PI / 2, 0, Math.PI / 2]}
                args={[
                  convertMetric(
                    tool.outerDiameter.roundedValue,
                    tool.outerDiameter.unit,
                  ) /
                    2 +
                    0.005,
                  convertMetric(
                    tool.outerDiameter.roundedValue,
                    tool.outerDiameter.unit,
                  ) /
                    2 +
                    0.005,
                  0.005,
                  32,
                  1,
                ]}
              >
                <meshStandardMaterial
                  color={'black'}
                  roughness={1}
                  metalness={0}
                  side={THREE.DoubleSide}
                />
              </Cylinder>
            </>,
          );
        }
      }
    });
  }, [
    toolsByToolstringToolId,
    BHA_LENGTH.roundedValue,
    BHA_LENGTH.unit,
    maxOD.value,
  ]);

  return (
    <>
      {tools && tools.current && tools.current.length ? (
        <group>{tools.current}</group>
      ) : null}
    </>
  );
};

export default BHA;
