import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogContent,
} from '@material-ui/core';

import { EMPTY_MAP } from 'app/app.constants';

import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import AddSurfaceEquipmentInPlanningModalTable from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddSurfaceEquipmentInPlanningModalTable';

const AddSurfaceEquipmentInPlanningModal = ({
  isOpen,
  classes,
  toggleModal,
  onRemoveSurface,
  tools = EMPTY_MAP,
  onSaveSurfaceEquipment,
  selectedSurfaceEquipment,
  setSelectedSurfaceEquipment,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          maxHeight: '80%',
          height: '80%',
        },
      }}
      open={isOpen}
      onClose={toggleModal}
    >
      <ApplicationHeaderBase>
        <Grid container alignItems="center">
          <Grid xs item container direction="column">
            <Typography item component={Typography} variant="h6">
              Add surface equipment
            </Typography>
          </Grid>
          <Grid item container xs justifyContent="flex-end">
            <Button color="secondary" variant="standard" onClick={toggleModal}>
              Close
            </Button>
          </Grid>
        </Grid>
      </ApplicationHeaderBase>
      <DialogContent>
        <AddSurfaceEquipmentInPlanningModalTable
          tools={tools}
          classes={classes}
          onRemoveSurface={onRemoveSurface}
          onSaveSurfaceEquipment={onSaveSurfaceEquipment}
          selectedSurfaceEquipment={selectedSurfaceEquipment}
          setSelectedSurfaceEquipment={setSelectedSurfaceEquipment}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddSurfaceEquipmentInPlanningModal;
