import moment from 'moment';
import { Map, OrderedMap, List } from 'immutable';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import toolMapper from 'features/projects/tool/tool.mappers';
import { ToolType } from 'features/projects/tool/tool.constants';
import { ThirdPartyLabel } from 'features/projects/tool/tool.constants';

const initialToolstring = () =>
  Map({
    toolsByToolstringToolId: EMPTY_MAP,
  });

export const toolstringItemFromDto = ({
  individualSerialNumber,
  toolAssemblyTools = [],
  attachedTools = [],
  maximumPullForce,
  swl,
  toolstringItemId,
  ...rest
}) =>
  Map({
    serialNo: individualSerialNumber,
    maxPullForce: maximumPullForce,
    safeWorkingLoad: swl,
    toolstringItemId,
    ...rest,
    toolAssemblyTools: List(toolAssemblyTools).map((toolAssemblyTool) =>
      toolAssemblyToolFromDto({ ...toolAssemblyTool, toolstringItemId }),
    ),
    attachedTools: List(attachedTools).map((a) =>
      toolstringItemFromDto(a)
        .set('accumulatedLength', undefined)
        .set('attachmentOwnerId', toolstringItemId),
    ),
  });

export const toolstringSectionsFromDto = (sections) =>
  List(
    sections.map(({ length, items }) =>
      Map({
        length,
        items: List(items.map((item) => item.toolstringItemId)),
      }),
    ),
  );

export const toolAssemblyToolFromDto = ({
  toolAssemblyToolId,
  maximumPullForce,
  swl,
  referenceNumber,
  individualSerialNumber,
  attachedTools = [],
  ...toolAssemblyTool
}) =>
  Map({
    id: toolAssemblyToolId,
    type: ToolType.ASSEMBLY_TOOL,
    safeWorkingLoad: swl,
    maxPullForce: maximumPullForce,
    toolAssemblyToolId,
    serialNo: individualSerialNumber,
    attachedTools: List(attachedTools).map((a) =>
      toolstringItemFromDto(a)
        .set('accumulatedLength', undefined)
        .set('attachmentOwnerId', toolAssemblyToolId),
    ),
    ...toolAssemblyTool,
  });

export const toolstringItemToCreateDto = (toolItem) => ({
  itemId: toolItem.get('id'),
  type: toolItem.get('type'),
});

export const toolstringItemDuplicateToCreateDto = (toolItem) => {
  const type = toolItem.get('type');
  return {
    type: type,
    itemId:
      type === ToolType.ASSEMBLY_TOOL
        ? toolItem.get('id')
        : toolItem.get('toolstringItemId'),
  };
};

export const toolstringItemToUpdateDto = (toolstringItem) => {
  if (toolstringItem.type === ToolType.ASSEMBLY) {
    return {
      name: toolstringItem.name,
    };
  } else {
    return toolToUpdateDto(toolstringItem);
  }
};

export const toolstringAttachmentToCreateDto = (owner, tool) => ({
  owner:
    owner.get('type') === ToolType.ASSEMBLY_TOOL
      ? ToolType.ASSEMBLY
      : owner.get('type'),
  externalToolId:
    tool.get('type') === ToolType.TOOL ? tool.get('id') : undefined,
  externalToolAssemblyId:
    tool.get('type') === ToolType.ASSEMBLY ? tool.get('id') : undefined,
});

export const toolAssemblyToolToUpdateDto = (toolstringItem) => {
  return toolToUpdateDto(toolstringItem);
};

const toolToUpdateDto = (toolstringItem) => ({
  name: toolstringItem.name,
  individualSerialNumber: toolstringItem.serialNo,
  topToolConnectorId: toolstringItem.topToolConnectorId,
  bottomToolConnectorId: toolstringItem.bottomToolConnectorId,
});

export const toolAssemblyToolToCreateDto = (tool) => ({
  toolId: tool.get('id'),
});

export const toolstringFromDto = ({
  cable,
  cableId,
  created,
  modified,
  toolStringId,
  toolStringItems = [],
  ...toolstring
}) => {
  const items = toolStringItems
    ? List(toolStringItems).map(toolstringItemFromDto)
    : EMPTY_LIST;

  return Map({
    id: toolStringId,
    cableId,
    cableType: cable?.type,
    cable: cable ? toolMapper.Cable.from(cable) : undefined,
    created: created ? moment(created) : undefined,
    modified: modified ? moment(modified) : undefined,
    toolsByToolstringToolId: OrderedMap(
      items.map((toolstringItem) => [
        toolstringItem.get('toolstringItemId').toString(),
        toolstringItem,
      ]),
    ),
    ...toolstring,
  });
};

export const toolstringToDto = ({ name, comment, cableId, observations }) => ({
  name,
  comment,
  cableId,
  observations,
});

const toolStringToolDataVerifiedThirdParty = (toolsList) => {
  return toolsList.map((tool) => {
    if (tool.get('m3ItemNumber') === ThirdPartyLabel) {
      return tool.set('dataVerified', new Date());
    }
    return tool;
  });
};

const toolstringMappers = {
  Toolstring: {
    to: toolstringToDto,
    from: toolstringFromDto,
    initial: initialToolstring,
  },
  ToolstringItem: {
    from: toolstringItemFromDto,
    toCreate: toolstringItemToCreateDto,
    toUpdate: toolstringItemToUpdateDto,
    toDuplicate: toolstringItemDuplicateToCreateDto,
  },
  ToolAssemblyTool: {
    from: toolAssemblyToolFromDto,
    toUpdate: toolAssemblyToolToUpdateDto,
    toCreate: toolAssemblyToolToCreateDto,
  },
  ToolstringSections: {
    from: toolstringSectionsFromDto,
  },
  ToolstringTool: {
    thirdPartyDataVerification: toolStringToolDataVerifiedThirdParty,
  },
  ToolstringAttachment: {
    toCreate: toolstringAttachmentToCreateDto,
  },
};

export default toolstringMappers;
