import _isNil from 'lodash/isNil';

import {
  SimulationDirection,
  SimulationResultSeries,
} from 'features/projects/tasks/task/simulation/simulation.constants';
import { getQuantityUnitFromItems } from 'utils/app.util';

export const getSimulationChartSeries = (
  simulationResults,
  operationLoadPercent,
  direction,
) =>
  [
    {
      index: 0,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: SimulationResultSeries.SURFACE_WEIGHT_RIH,
      visible: true,
      color: '#81D4FA',
      name: 'Surface Weight RIH',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'surfaceTension']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'surfaceTension', 'value']))
        .toArray(),
    },
    {
      index: 1,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.SURFACE_WEIGHT_POOH,
      visible: true,
      color: '#03A9F4',
      name: 'Surface Weight POOH',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'surfaceTension']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'surfaceTension', 'value']))
        .toArray(),
    },
    {
      index: 2,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      visible: true,
      color: '#FF41FF',
      name: operationLoadPercent
        ? `SW at Safe Operating Load (${operationLoadPercent}%)`
        : 'SW at Safe Operating Load',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'workingLimit']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'workingLimit', 'value']))
        .toArray(),
    },
    {
      index: 3,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: SimulationResultSeries.COMPRESSION_LIMIT,
      visible: false,
      color: '#FFFF00',
      name: 'Compression Limit',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'compressionLimit']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'compressionLimit', 'value']))
        .toArray(),
    },
    {
      index: 4,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.WEAK_POINT_SAFETY_FACTOR,
      visible: false,
      color: '#CE93D8',
      name: 'Weak Point with SF',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'weakPointLowSF']),
        ),
      },
      dashStyle: 'dot',
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'weakPointLowSF', 'value']))
        .toArray(),
    },
    {
      index: 5,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.WEAK_POINT,
      visible: false,
      color: '#CE93D8',
      name: 'Weak Point',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'weakPointLow']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'weakPointLow', 'value']))
        .toArray(),
    },
    {
      index: 7,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: SimulationResultSeries.MAX_SET_DOWN,
      visible: true,
      color: '#C5CAE9',
      name: 'Max. Set Down force',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'maxSetDown']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'maxSetDown', 'value']))
        .toArray(),
    },
    {
      index: 8,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      visible: true,
      color: '#8C9EFF',
      name: 'Max. Pick-up Force POOH',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'maxPickupWeight']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'maxPickupWeight', 'value']))
        .toArray(),
    },
    {
      index: 9,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: SimulationResultSeries.MIN_TRACTOR_PULL,
      visible: true,
      color: '#4CAF50',
      name: 'Minimum Tractor Pull',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'tractorForce']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'tractorForce', 'value']))
        .toArray(),
    },
    {
      index: 10,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
      visible: true,
      color: '#FF8A80',
      name: 'Cable Head Tension RIH',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'cableHeadTension']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'cableHeadTension', 'value']))
        .toArray(),
    },
    {
      index: 11,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.CABLE_HEAD_TENSION_POOH,
      visible: true,
      color: '#F76464',
      name: 'Cable Head Tension POOH',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'cableHeadTension']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'cableHeadTension', 'value']))
        .toArray(),
    },
    {
      index: 13,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.SW_JAR_ACTIVATION,
      visible: false,
      color: '#4CAF50',
      name: 'SW at Jar Activation',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'surfaceWeightJarFiringLow']),
        ),
      },
      data: simulationResults
        .map((x) =>
          x.getIn(['pullOutOfHole', 'surfaceWeightJarFiringLow', 'value']),
        )
        .toArray(),
    },
    {
      index: 15,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.CHT_JAR_ACTIVATION,
      visible: false,
      color: '#4CAF50',
      name: 'CHT at Jar Activation',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'cableHeadTensionJarFiringLow']),
        ),
      },
      data: simulationResults
        .map((x) =>
          x.getIn(['pullOutOfHole', 'cableHeadTensionJarFiringLow', 'value']),
        )
        .toArray(),
    },
    {
      index: 17,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: SimulationResultSeries.STRETCH_RIH,
      visible: true,
      color: '#FAD000',
      name: 'Stretch RIH',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'stretch']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'stretch', 'value']))
        .toArray(),
    },
    {
      index: 18,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: SimulationResultSeries.STRETCH_POOH,
      visible: true,
      color: '#FA9A00',
      name: 'Stretch POOH',
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'stretch']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'stretch', 'value']))
        .toArray(),
    },
  ].filter((series) => _isNil(direction) || series.direction === direction);

export const getSimulationCompressionDepthSeries = (
  id,
  direction,
  simulationResults,
  key,
) => {
  const compressionDepth = getSimulationCompressionDepth(simulationResults);
  const dir =
    direction === SimulationDirection.PULL_OUT_OF_HOLE
      ? 'pullOutOfHole'
      : 'runInHole';

  if (_isNil(compressionDepth?.[dir]) || !compressionDepth[dir].hasValue) {
    return null;
  }

  return {
    zIndex: 2,
    direction,
    id,
    visible: true,
    color: 'gray',
    name: 'Compression Depth',
    showInLegend: false,
    custom: {
      tooltip: false,
      unit: getQuantityUnitFromItems(simulationResults, (result) =>
        result.getIn([dir, key]),
      ),
    },
    marker: {
      symbol: 'triangle',
      enabled: false,
    },
    data: simulationResults
      .toArray()
      .filter((x) => x[0] >= compressionDepth[dir].value)
      .map((x, i) => ({
        x: x[0],
        y: x[1].getIn([dir, key, 'value']),
        marker: { enabled: i === 0, fillColor: 'red' },
      })),
  };
};

export const getSimulationCompressionDepth = (simulationResults) => {
  const runInHole = simulationResults
    ?.find((result) => result.get('isCompressionDepthRunInHole'))
    ?.get('measuredDepth');

  const pullOutOfHole = simulationResults
    ?.find((result) => result.get('isCompressionDepthPullOutOfHole'))
    ?.get('measuredDepth');

  if (_isNil(runInHole) && _isNil(pullOutOfHole)) {
    return undefined;
  }

  return { runInHole, pullOutOfHole };
};

// Helper to toggle show/hide compression series based on related series
const toggleCompressionEvents = {
  hide: (series) => {
    const compressionSeries = series?.target?.chart?.series?.find(
      (s) =>
        s.options.id ===
        series.target.options.id + SimulationResultSeries.COMPRESSION_DEPTH,
    );
    if (!_isNil(compressionSeries)) {
      compressionSeries.hide();
    }
  },
  show: (series) => {
    const compressionSeries = series?.target?.chart?.series?.find(
      (s) =>
        s.options.id ===
        series.target.options.id + SimulationResultSeries.COMPRESSION_DEPTH,
    );
    if (!_isNil(compressionSeries)) {
      compressionSeries.show();
    }
  },
};
