import { Map, List, fromJS } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import { EQUIPMENT_ACTIONS } from './equipment.constants';
import toolMapper, {
  surfaceEquipmentItemDto,
} from 'features/projects/tool/tool.mappers';
import {
  EQUIPMENT_DETAILS_ACTIONS,
  SURFACE_EQUIPMENT_DETAILS_ACTIONS,
} from 'features/equipment/details/equipmentDetails.constants';
import mappers from '../../mappers';
import toolstringMappers from 'features/projects/tasks/task/toolstring/toolstring.mappers';

const initialState = Map({
  tool: EMPTY_MAP,
  tools: EMPTY_MAP,
  cable: EMPTY_MAP,
  cables: EMPTY_MAP,
  toolGroups: EMPTY_MAP,
  createdTool: EMPTY_MAP,
  bhaTemplates: EMPTY_MAP,
  assetHistory: EMPTY_MAP,
  toolSuppliers: EMPTY_MAP,
  toolAssemblies: EMPTY_MAP,
  toolConnectors: EMPTY_MAP,
  surfaceEquipment: EMPTY_MAP,
  surfaceEquipments: EMPTY_MAP,
  surfaceEquipmentCategories: EMPTY_MAP,
  bhaTemplateToEdit: EMPTY_MAP,
  bhaTemplateSectionsToEdit: EMPTY_MAP,
  assets: EMPTY_MAP,
  cableTestDetails: EMPTY_MAP,
});

const equipmentReducer = (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case EQUIPMENT_ACTIONS.TOOL_GROUPS_LOADED: {
      if (action.error) return state;

      return state.setIn(
        ['toolGroups'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case EQUIPMENT_ACTIONS.TOOLS_LOADED: {
      if (action.error) return state;

      return state.setIn(['tools'], toolMapper.ToolSearch.from(action.payload));
    }

    case EQUIPMENT_ACTIONS.TOOL_ASSEMBLIES_LOADED: {
      if (action.error) return state;

      return state.setIn(
        ['toolAssemblies'],
        toolMapper.ToolAssemblies.from(action.payload),
      );
    }

    case EQUIPMENT_ACTIONS.SUPPLIERS_LOADED: {
      if (action.error) return state;

      return state.setIn(
        ['toolSuppliers'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case EQUIPMENT_ACTIONS.CABLES_LOADED: {
      if (action.error) return state;

      return state.setIn(
        ['cables'],
        Map(
          action.payload.map((cable) => [
            cable.cableId.toString(),
            toolMapper.Cable.from(cable),
          ]),
        ),
      );
    }

    case EQUIPMENT_ACTIONS.CABLES_LOADED_BY_PROJECTID: {
      if (action.error) return state;
      return state.setIn(
        ['cables'],
        Map(
          action.payload.map((cable) => [
            cable.cableId.toString(),
            toolMapper.Cable.from(cable),
          ]),
        ),
      );
    }

    case EQUIPMENT_ACTIONS.BHA_TEMPLATES_LOADED: {
      if (action.error) return state;

      return state.setIn(
        ['bhaTemplates'],
        Map(
          action.payload.map((template) => [
            template.toolStringId.toString(),
            toolMapper.bhaTemplate.from(template),
          ]),
        ),
      );
    }

    case EQUIPMENT_ACTIONS.PAGE_UNLOADED: {
      if (action.error) return state;

      return state.setIn(['tools'], EMPTY_MAP);
    }

    case EQUIPMENT_DETAILS_ACTIONS.PAGE_LOADED: {
      if (action.error) return state;

      if (action.payload) {
        return state.setIn(['tool'], toolMapper.Tool.from(action.payload));
      } else return state;
    }

    case EQUIPMENT_DETAILS_ACTIONS.UNLOAD_TOOL: {
      if (action.error) return state;

      return state.setIn(['tool'], EMPTY_MAP);
    }

    case EQUIPMENT_ACTIONS.TOOL_CONNECTORS_LOADED: {
      if (action.error) return state;

      return state.setIn(
        ['toolConnectors'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED: {
      if (action.error) return state;
      return state.setIn(
        ['surfaceEquipments'],
        List(action.payload).map(surfaceEquipmentItemDto),
      );
    }

    case EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_BY_CATEGORIES: {
      if (action.error) return state;
      return state.setIn(
        ['surfaceEquipmentCategories'],
        List(action.payload).map(surfaceEquipmentItemDto),
      );
    }

    case EQUIPMENT_ACTIONS.RECEIVE_CABLE_TEST_DETAILS: {
      if (action.error) return state;

      return state.setIn(
        ['cableTestDetails'],
        toolMapper.Cable.from(action.payload),
      );
    }

    case SURFACE_EQUIPMENT_DETAILS_ACTIONS.PAGE_LOADED: {
      if (action.error) return state;

      if (action.payload) {
        return state.setIn(
          ['surfaceEquipment'],
          toolMapper.SurfaceEquipment.from(action.payload),
        );
      } else return state;
    }

    case EQUIPMENT_ACTIONS.RECEIVE_SURFACE_EQUIPMENT_GROUPS: {
      if (action.error) return state;
      return state.setIn(
        ['surfaceEquipmentGroups'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case EQUIPMENT_ACTIONS.LOAD_ASSET_HISTORY: {
      if (action.error) return state;
      return state.setIn(['assetHistory'], fromJS(action.payload));
    }

    case EQUIPMENT_ACTIONS.CREATE_ASSET_HISTORY: {
      if (action.error) return state;
      return state.setIn(['assetHistory'], fromJS(action.payload));
    }

    case EQUIPMENT_ACTIONS.LOAD_CABLE: {
      if (action.error) return state;
      return state.setIn(['cable'], fromJS(action.payload));
    }

    case EQUIPMENT_DETAILS_ACTIONS.UNLOAD_CABLE: {
      return state.setIn(['cable'], EMPTY_MAP);
    }

    case EQUIPMENT_ACTIONS.RECEIVE_BHA_TEMPLATE_FOR_EDITING: {
      if (action.error) return state;

      const toolstringTest = toolstringMappers.Toolstring.from(action.payload);
      // clean mapping of toolMapper.bhaTemplate.from(action.payload) throws errors (only) on assemblies thus toJS/fromJS
      return state.setIn(['bhaTemplateToEdit'], toolstringTest);
    }

    case EQUIPMENT_ACTIONS.RECEIVE_BHA_TEMPLATE_SECTIONS_FOR_EDITING: {
      if (action.error) return state;

      return state.setIn(['bhaTemplateSectionsToEdit'], fromJS(action.payload));
    }

    case EQUIPMENT_ACTIONS.UNLOAD_BHA_TEMPLATE_FOR_EDITING: {
      if (action.error) return state;

      return state.setIn(['bhaTemplateToEdit'], EMPTY_MAP);
    }

    case EQUIPMENT_ACTIONS.RECEIVE_EQUIPMENT_ASSETS: {
      if (action.error) return state;

      return state.set('assets', mappers.equipmentAsset.from(action.payload));
    }

    default:
      return state;
  }
};

export default equipmentReducer;
