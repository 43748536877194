import { useMemo } from 'react';
import { fromJS } from 'immutable';
import { useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Close';
import { Grid, IconButton } from '@material-ui/core';
import DuplicateIcon from '@material-ui/icons/FileCopy';

import { Table } from 'altus-ui-components';

import { cableTypeToString } from 'features/projects/tool/tool.mappers';

const defaultInitialState = {
  pageSize: 50,
};

const AddedCablesTable = ({
  classes,
  onRemoveCable,
  selectedCables,
  onDuplicateItem,
  execute = false,
  setSelectedCables,
  initialState = defaultInitialState,
  ...rest
}) => {
  const removeCable = useCallback(
    (externalGuid) => {
      const index = selectedCables.indexOf(
        selectedCables.find((cable) => cable.externalGuid === externalGuid),
      );
      const newNumbers = [
        ...selectedCables.slice(0, index),
        ...selectedCables.slice(index + 1),
      ];
      setSelectedCables(newNumbers);
    },
    [selectedCables, setSelectedCables],
  );

  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Item no.',
        accessor: (cable) =>
          cable.get('partNo')
            ? cable.get('partNo')
            : cable.get('manufacturerPartNo'),
      },
      {
        xs: 2,
        Header: 'Cable',
        accessor: (cable) => cable.get('name'),
      },
      {
        xs: 2,
        Header: 'Type',
        accessor: (cable) => cableTypeToString(cable.get('type')),
      },
      {
        xs: 2,
        Header: 'Nominal Dia.',
        accessor: (cable) =>
          `${cable?.getIn(
            ['nominalDiameter', 'roundedValue'],
            null,
          )} ${cable?.getIn(['nominalDiameter', 'unit'])}`,
      },
      {
        xs: 2,
        Header: 'Weight in Air',
        accessor: (cable) => {
          const weightKey = cable.get('weight') ? 'weight' : 'weightInAir';
          const roundedValue = cable?.getIn([weightKey, 'roundedValue'], null);
          const unit = cable?.getIn([weightKey, 'unit']);
          return `${roundedValue} ${unit}`;
        },
      },
      {
        xs: 2,
        Header: 'Stretch',
        accessor: (cable) => {
          const weightKey = cable.get('stretchCoefficient') ? 'stretchCoefficient' : 'stretch';
          const roundedValue = cable?.getIn([weightKey, 'roundedValue'], null);
          const unit = cable?.getIn([weightKey, 'unit']);
          return `${roundedValue} ${unit}`;
        },
      },
      {
        xs: 2,
        Header: 'Breaking Strength',
        accessor: (cable) =>
          `${cable?.getIn(['strength', 'roundedValue'], null)} ${cable?.getIn([
            'strength',
            'unit',
          ])}`,
      },
      {
        xs: 2,
        id: 'action',
        Cell: ({ row }) => {
          const cable = row.original;

          if (execute === true) return null;
          return (
            <Grid>
              <IconButton
                title="Duplicate"
                onClick={() => onDuplicateItem(cable.toJS())}
              >
                <DuplicateIcon />
              </IconButton>
              <IconButton
                title="Delete"
                onClick={() => {
                  onRemoveCable(cable);
                  removeCable(cable.get('externalGuid'));
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          );
        },
      },
    ],
    [removeCable, onDuplicateItem, onRemoveCable, execute],
  );

  return (
    <Table
      stickyHeader
      usePagination
      items={fromJS(selectedCables)}
      columns={columns}
      initialState={initialState}
      useGlobalFilter={false}
      {...rest}
    />
  );
};

export default AddedCablesTable;
