import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useMemo, memo } from 'react';
import { Collection, fromJS, isImmutable } from 'immutable';
import { Grid, IconButton, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { EMPTY_MAP, UNITS } from 'app/app.constants';
import { formatFraction } from 'utils/format.util';
import TableCellDense from 'app/components/Table/TableCellDense';
import TableHeaderTitleCell from 'app/components/TableHeaderTitleCell';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import { useEffect } from 'react';
import { receiveToolstringTemplateByID } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { useState } from 'react';

const getSubRows = (row) =>
  isImmutable(row)
    ? row.get('toolAssemblyTools')
    : row.original?.toolAssemblyTools;

const ToolstringTemplateToolsTable = ({ classes, templateId = 0 }) => {
  const [template, setTemplate] = useState(EMPTY_MAP);

  useEffect(() => {
    if (templateId) {
      receiveToolstringTemplateByID(templateId).payload.then(
        (toolstringTemplate) => {
          console.log(toolstringTemplate);
          setTemplate(fromJS(toolstringTemplate));
        },
      );
    }
  }, [templateId]);

  const columns = useMemo(
    () => [
      {
        xs: 1,
        id: 'expander',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 6,
        id: 'name',
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 1,
        id: 'length',
        accessor: (tool) => tool.getIn(['length', 'value']),
        Header: () => <TableHeaderTitleCell title="Length" subTitle="(m)" />,
        Cell: ({ value }) => value?.toFixed(2),
      },
      {
        xs: 1,
        id: 'weight',
        accessor: (tool) => tool.getIn(['weight', 'value']),
        Header: () => <TableHeaderTitleCell title="Weight" subTitle="(lbs)" />,
        Cell: ({ value }) => value?.toFixed(1),
      },
      {
        xs: 2,
        id: 'outerDiameter',
        accessor: (tool) => tool.getIn(['outerDiameter', 'value']),
        Header: () => (
          <TableHeaderTitleCell title="Outer Dia." subTitle="(in)" />
        ),
        Cell: ({ value }) => formatFraction(value, '"'),
      },
      {
        xs: 1,
        id: 'yieldValue',
        accessor: (tool) => tool.getIn(['yieldValue', 'value']),
        Header: () => <TableHeaderTitleCell title="Yield" subTitle="(klbs)" />,
        Cell: ({ value }) => (value ? value?.toFixed(0) : null),
      },
      {
        xs: 2,
        id: 'topC',
        Header: 'Top C.',
        accessor: (tool) => tool.get('topConnectorName'),
      },
      {
        xs: 2,
        id: 'bottomC',
        Header: 'Bot. C',
        accessor: (tool) => tool.get('bottomConnectorName'),
      },
    ],
    [],
  );

  return (
    <Grid
      xs
      item
      container
      wrap="nowrap"
      direction="column"
      className={classes.scrollable}
    >
      <Grid
        container
        direction="row"
        spacing={4}
        className={classes.titleContainer}
      >
        <Grid
          item
          xs={2}
          direction="column"
          container
          className={classes.titleBox}
        >
          <Typography variant="body2">
            <b>Total Length</b> {'(m)'}
          </Typography>
          <Typography variant="body2">
            {template.getIn(['totalLength', 'value'])?.toFixed(1)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          direction="column"
          container
          className={classes.titleBox}
        >
          <Typography variant="body2">
            <b>Total Weight</b> {'(lbs)'}
          </Typography>
          <Typography variant="body2">
            {template.getIn(['totalWeight', 'value'])?.toFixed(1)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          direction="column"
          container
          className={classes.titleBox}
        >
          <Typography variant="body2">
            <b>Max Outer Diameter</b> {'(in)'}
          </Typography>
          <Typography variant="body2">
            {formatFraction(
              template.getIn(['maxOD', 'value'])?.toFixed(1),
              '"',
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          direction="column"
          container
          className={classes.titleBox}
        >
          <Typography variant="body2">
            <b>Force</b> {'(lbs)'}
          </Typography>
          <Typography variant="body2">
            {template.getIn(['totalForce', 'value'])
              ? `${template.getIn(['totalForce', 'value'])?.toFixed(1)} ${
                  UNITS.POUND_WEIGHT
                }`
              : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Table
          useExpanded
          disableSortBy
          columns={columns}
          useGlobalFilter={false}
          items={template.size ? template.get('toolStringItems') : EMPTY_MAP}
          getSubRows={getSubRows}
          noItemsMessage="This BHA got no tools"
          TableCellComponent={TableCellDense}
          TableHeaderCellComponent={TableHeaderCellDense}
        />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  scrollable: {
    overflowY: 'auto',
  },
  fontSizeTitle: {
    fontSize: '1rem',
  },
  titleContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    justifyContent: 'flex-start',
  },
  titleBox: {
    alignItems: 'center',
  },
});

ToolstringTemplateToolsTable.propTypes = {
  toolstringItems: PropTypes.instanceOf(Collection),
};

export default compose(memo, withStyles(styles))(ToolstringTemplateToolsTable);
