import isNil from 'lodash/isNil';
import { createRef, PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, LinearProgress } from '@material-ui/core';

import RunNumber from 'features/projects/components/RunNumber';
import { scrollSmoothlyIntoViewIfNeeded } from 'utils/app.util';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import { Button as NavigationButton } from 'app/components/withNavigation';

class TaskSelectItem extends PureComponent {
  constructor(props) {
    super(props);

    this.item = createRef();
    this.scrollSmoothlyIntoViewIfNeeded =
      this.scrollSmoothlyIntoViewIfNeeded.bind(this);
  }

  scrollSmoothlyIntoViewIfNeeded() {
    const { isSelected } = this.props;

    if (isSelected) {
      scrollSmoothlyIntoViewIfNeeded(this.item.current);
    }
  }

  componentDidUpdate(prevProps) {
    const { isSelected: wasSelected } = prevProps;

    if (!wasSelected) {
      this.scrollSmoothlyIntoViewIfNeeded();
    }
  }

  componentDidMount() {
    this.scrollSmoothlyIntoViewIfNeeded();
  }

  render() {
    const { to, task, classes, progress, isSelected } = this.props;

    return (
      <Grid item xs={12} key={task.get('id')} ref={this.item}>
        <NavigationButton
          to={to}
          fullWidth
          color="primary"
          title={task.get('description')}
          variant={isSelected ? 'contained' : 'outlined'}
          classes={{
            root: classes.taskButton,
          }}
        >
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item className={classes.buttonIcon}>
              <WorkItemStatus status={task.get('status')} />
            </Grid>
            <Grid item>
              <RunNumber size={24}>{task.get('runNumber')}</RunNumber>
            </Grid>
            <Grid item xs>
              <Typography
                align="left"
                variant="body2"
                color="textPrimary"
                className={classes.title}
              >
                {task.get('title')}
              </Typography>
            </Grid>
          </Grid>
        </NavigationButton>
        {!isNil(progress) && (
          <LinearProgress
            key="progress"
            value={progress}
            variant="determinate"
          />
        )}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  taskButton: {
    borderColor: theme.palette.divider,
    borderRadius: 0,
    textTransform: 'none',
    marginTop: 2,
    padding: theme.spacing(1.4),
    border: '1px solid transparent',
  },
  buttonIcon: {
    display: 'flex',
    width: theme.spacing(4),
  },
  title: {
    wordBreak: 'word',
  },
});

export default withStyles(styles)(TaskSelectItem);
