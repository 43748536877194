import { compose } from 'redux';
import { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useSelector, connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import {
  onLoadWithoutToolstrings as onLoad,
  onUnload,
} from 'features/equipment/equipment.actions';
import CablesTable from 'features/equipment/cables/components/CablesTable';
import { getCablesFromState } from 'features/equipment/equipment.selectors';
import { EQIPMENT_CATEGORY_IDS } from 'features/equipment/equipment.constants';

const CablesContainer = ({ classes, dispatchOnLoad, dispatchOnUnload }) => {
  useEffect(() => {
    dispatchOnLoad(EQIPMENT_CATEGORY_IDS.THIRD_PARTY_TOOLS);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload]);

  const cables = useSelector(getCablesFromState);

  return (
    <Grid container>
      <Grid item xs={12} container component={Box} direction="column">
        <Grid item container className={classes.tableContainer}>
          <CablesTable cables={cables} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  filterContainer: {
    height: 'fit-content',
    width: '20%',
  },
  tableContainer: {
    flex: 1,
  },
});

export default compose(
  connect(() => ({}), {
    dispatchOnLoad: onLoad,
    dispatchOnUnload: onUnload,
  }),
  withStyles(styles),
)(CablesContainer);
