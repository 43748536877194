import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { Box, Chip, Grid } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  formatDate,
  formatDuration,
  formatQuantity,
  formatDurationDiff,
} from 'utils/format.util';

import Counter from 'app/components/Counter';
import ReadOnlyField from 'app/components/ReadOnlyField';
import toolstringService from 'services/toolstring.service';
import { TaskType } from 'features/projects/tasks/tasks.constants';
import { ProjectPermission, WORK_ITEM_STATUS } from 'app/app.constants';
import TaskDetails from 'features/projects/tasks/components/TaskDetails';
import { getTaskActivities } from 'features/projects/activities/activities.actions';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import { createTaskActivitiesSelector } from 'features/projects/activities/activities.selectors';
import {
  getToolstring,
  receiveTaskToolstring,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';
import TaskActivitiesTableSimple from 'features/projects/tasks/task/activities/TaskActivitiesTableSimple';
import TaskTableRowDetailsToolstring from 'features/projects/tasks/components/TaskTableRowDetailsToolstring';
import { EditorState, convertFromRaw } from 'draft-js';
import EditorStateViewer from 'app/components/EditorStateViewer';
import { checkIfNotASubActivity } from 'features/projects/tasks/task/activities/activityUtils';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const TaskTableRowDetails = ({
  task,
  classes,
  copyTask,
  projectId,
  deleteTask,
  isExpanded,
}) => {
  const [toolstringId, setToolstringId] = useState();

  const dispatch = useDispatch();

  const taskId = task.get('id');
  const type = task.get('type');
  const status = task.get('status');
  const endTime = task.get('endTime');
  const comments = task.get('comments')
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(task.get('comments'))),
      )
    : EditorState.createEmpty();
  const description = task.get('description')
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(task.get('description'))),
      )
    : EditorState.createEmpty();
  const objective = task.get('objective')
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(task.get('objective'))),
      )
    : EditorState.createEmpty();
  const startTime = task.get('startTime');
  const departments = task.get('departments');
  const durationPlanned = task.get('durationPlanned');

  const getPlannedToolstring = useCallback(
    () =>
      toolstringService
        .getPlannedToolstring(projectId, taskId)
        .then((toolstring) => {
          setToolstringId(toolstring.toolStringId);
          dispatch(getToolstring(projectId, taskId, toolstring.toolStringId));
          dispatch(receiveTaskToolstring(projectId, taskId, toolstring));
        }),
    [dispatch, projectId, taskId],
  );

  useEffect(() => {
    if (isExpanded) {
      getPlannedToolstring(projectId, taskId);
      dispatch(getTaskActivities(projectId, taskId));
    }
  }, [taskId, dispatch, projectId, isExpanded, getPlannedToolstring]);

  const activitiesSelector = useMemo(
    () => createTaskActivitiesSelector(taskId),
    [taskId],
  );

  const taskActivities = useSelector(activitiesSelector);

  const filteredTaskActivities = useMemo(() => {
    if (taskActivities) {
      return taskActivities
        .filter((x) => checkIfNotASubActivity(x))
        .sortBy((activity) => activity.get('sequence'))
        .map((activity, index) => activity.set('sequence', index + 1));
    }
    return taskActivities;
  }, [taskActivities]);

  const { hasPermission: hasReadActivityCommentsPermission } =
    useProjectPermissions(ProjectPermission.READ_ACTIVITY_COMMENTS);

  return (
    <Grid
      container
      paddingTop={3}
      component={Box}
      paddingBottom={3}
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Grid container item xs={10} spacing={2}>
        <TaskDetails
          task={task}
          taskId={taskId}
          copyTask={copyTask}
          projectId={projectId}
          deleteTask={deleteTask}
          displayNavigationBtn
          ReadOnlyFieldProps={{
            variant: 'body2',
          }}
        />
        <Grid item xs={3}>
          <CustomReadOnlyField
            margin="none"
            label="Title"
            value={task.get('title')}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomReadOnlyField
            margin="none"
            label="Type"
            value={task.get('taskTypeName')}
          />
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <ReadOnlyField margin="none" label="Description">
              <Grid container className={classes.expansionPanelDetailsSection}>
                <EditorStateViewer editorState={description} />
              </Grid>
            </ReadOnlyField>
          </Grid>
          <Grid container item xs={6}>
            <ReadOnlyField margin="none" label="Objective">
              <Grid container className={classes.expansionPanelDetailsSection}>
                <EditorStateViewer editorState={objective} />
              </Grid>
            </ReadOnlyField>
          </Grid>
        </Grid>
        {[
          [
            'TD - Target Depth',
            type === TaskType.SUB_SURFACE &&
              formatQuantity(task.get('targetDepth')),
          ],
          [
            'Estimated to',
            durationPlanned
              ? formatDuration(moment.duration(durationPlanned))
              : 'N/A',
          ],
          [
            'Actual Runtime',
            <Counter
              endTime={endTime}
              startTime={startTime}
              calculateDurationFromStartAndEndtime
              isTimerActive={status === WORK_ITEM_STATUS.STARTED}
              renderValue={({ elapsedTime }) => formatDuration(elapsedTime)}
            />,
          ],
          [
            'Difference',
            <Counter
              endTime={endTime}
              startTime={startTime}
              calculateDurationFromStartAndEndtime
              isTimerActive={status === WORK_ITEM_STATUS.STARTED}
              renderValue={({ elapsedTime }) =>
                formatDurationDiff(
                  moment.duration(durationPlanned),
                  elapsedTime,
                )
              }
            />,
          ],
        ].map(
          ([label, value]) =>
            value && (
              <Grid item xs={3} key={label}>
                <CustomReadOnlyField
                  margin="none"
                  label={label}
                  value={value}
                />
              </Grid>
            ),
        )}
        <Grid container item spacing={2}>
          {[
            ['Started', startTime],
            ['Ended', endTime],
          ].map(([label, value]) => (
            <Grid item xs={3} key={label}>
              <CustomReadOnlyField
                margin="none"
                label={label}
                value={value ? formatDate(value) : 'N/A'}
              />
            </Grid>
          ))}
        </Grid>
        {!!departments.size && (
          <Grid item xs>
            <ReadOnlyField margin="none" label="Departments">
              <Grid item xs={12} container spacing={1}>
                {departments.map((department) => (
                  <Grid item key={department.get('id')}>
                    <Chip label={department.get('name')} />
                  </Grid>
                ))}
              </Grid>
            </ReadOnlyField>
          </Grid>
        )}
        {comments && (
          <Grid container item>
            <ReadOnlyField margin="none" label="Comments">
              <Grid container className={classes.expansionPanelDetailsSection}>
                <EditorStateViewer editorState={comments} />
              </Grid>
            </ReadOnlyField>
          </Grid>
        )}
        {task.get('type') === TaskType.SUB_SURFACE && (
          <Grid item xs={12}>
            <TaskTableRowDetailsToolstring
              taskId={taskId}
              projectId={projectId}
              toolstringId={toolstringId}
            />
          </Grid>
        )}
        <Grid container item>
          <TaskActivitiesTableSimple
            useGlobalFilter={false}
            activities={filteredTaskActivities}
            displayComments={hasReadActivityCommentsPermission}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    background: theme.palette.table.row.main,
  },
  expansionPanelDetailsSection: {
    position: 'relative',
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
});

export default withStyles(styles)(TaskTableRowDetails);
