import moment from 'moment';
import isNaN from 'lodash/isNaN';

import { Format } from 'app/app.constants';

export const formatDate = (date, format = Format.time) => {
  if (!moment.isMoment(date)) return null;

  return date.format(format);
};

export const formatDuration = (duration, format = Format.duration_display) =>
  moment.isDuration(duration) ? duration.format(format, { trim: false }) : '';

export const formatDurationDiff = (durationA, durationB) =>
  [durationA, durationB].every((duration) => moment.isDuration(duration))
    ? formatDuration(
        moment.duration(durationB.clone().subtract(durationA).abs()),
      )
    : '';

export const formatStartToEndTime = (startTime, endTime) =>
  !startTime && !endTime
    ? 'Not started yet'
    : `${formatDate(startTime)}${endTime ? ` - ${formatDate(endTime)}` : ''}`;

const formatUnit = (unit) => {
  switch (unit) {
    case '':
    case '"':
      return unit;
    default:
      return ` ${unit}`;
  }
};

export const formatFractionString = (value, unit, numerator, denominator) => {
  const remainder = Math.floor(numerator % denominator);
  const wholeNumber = Math.floor((numerator - remainder) / denominator);
  numerator = remainder;
  return `${wholeNumber}-${remainder}/${denominator}${formatUnit(
    unit,
  )} (${formatValue(value, unit)})`;
};

export const formatValue = (value, unit = '', accuracy = 0.001) => {
  const float = parseFloat(value, 10);
  if (isNaN(float)) {
    return '';
  }

  const multiplier = 1 / accuracy;
  const roundedValue =
    Math.round(float * multiplier + Number.EPSILON) / multiplier;
  return `${roundedValue}${formatUnit(unit)}`;
};

export const formatEstimatedDate = (estimatedStart) => {
  const [year, month, day] = estimatedStart;

  if (year && !month && !day) {
    return `${year}`;
  } else if (year && month && !day) {
    return `${year}-${moment(month, 'MM').format('MM')}`;
  } else if (year && month && day) {
    return moment({ year, month: month - 1, day }).format(Format.date);
  } else {
    return <i>N/A</i>;
  }
};

export const formatFraction = (value, unit = '', accuracy = 0.001) => {
  const originalValue = value;
  const sign = Math.sign(value);
  value = Math.abs(value);

  // Accuracy is the maximum relative error; convert to absolute maxError
  const maxError = sign === 0 ? accuracy : value * accuracy;

  const n = Math.floor(value);
  value -= n;

  if (value < maxError) {
    return formatFractionString(originalValue, unit, sign * n, 1);
  }
  if (1 - maxError < value) {
    return formatFractionString(originalValue, unit, sign * (n + 1), 1);
  }

  // The lower fraction is 0/1
  let lower_n = 0;
  let lower_d = 1;
  // The upper fraction is 1/1
  let upper_n = 1;
  let upper_d = 1;

  while (true) {
    // The middle fraction is (lower_n + upper_n) / (lower_d + upper_d)
    const middle_n = lower_n + upper_n;
    const middle_d = lower_d + upper_d;

    if (middle_d * (value + maxError) < middle_n) {
      // real + error < middle : middle is our new upper
      upper_n = middle_n;
      upper_d = middle_d;
    } else if (middle_n < (value - maxError) * middle_d) {
      // middle < real - error : middle is our new lower
      lower_n = middle_n;
      lower_d = middle_d;
    } else {
      // Middle is our best fraction
      return formatFractionString(
        originalValue,
        unit,
        (n * middle_d + middle_n) * sign,
        middle_d,
      );
    }
  }
};

export const formatQuantity = (quantity, emptyValue = 'N/A') => {
  if (!quantity) return null;

  const { hasValue, roundedValue, unit } = quantity;

  if (!hasValue) return emptyValue;

  return `${roundedValue} ${unit}`;
};
