import { useCallback } from 'react';
import { Field, Formik } from 'formik';
import Edit from '@material-ui/icons/Edit';
import { Grid, IconButton, Typography } from '@material-ui/core';

import { formatDate } from 'utils/format.util';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import DateTimePickerFormik from 'app/components/form/formik/DateTimePickerFormik';
import EditorFormik from 'app/components/form/formik/EditorFormik';

const FormFields = {
  START_TIME: 'startTime',
  DESCRIPTION: 'description',
};

const ActivityChildComponent = ({
  child,
  classes,
  onClick,
  updateActivityTimes,
  onChildDescriptionChanged,
  isLastSubActivity = false,
}) => {
  const initialFormValues = {
    [FormFields.START_TIME]: formatDate(child.startTime),
    [FormFields.DESCRIPTION]: child.description ? child.description : '',
  };

  const onSubmit = useCallback(
    (values, formik) => {
      if (values.description !== child.description) {
        onChildDescriptionChanged(values.description, child);
      }
      if (formatDate(child.startTime) !== values.startTime) {
        child.startTime = values.startTime;
        child.endTime = values.endTime;
        updateActivityTimes(child.projectId, child.taskId, false, child);
        formik.resetForm();
      }
    },
    [child, onChildDescriptionChanged, updateActivityTimes],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={onSubmit}
    >
      <AutoSaveFormik timeout={1000}>
        <Grid item container xs={12} alignItems="center" direction="row" style={{ marginBottom: isLastSubActivity ? '3rem' : '0rem' }}>
          <Grid item xs={1} />
          <Grid item xs={1}>
            <WorkItemStatus
              status={child.status}
              isWaiting={child.isWait}
              isPointInTime={child.isPointInTime}
            />
          </Grid>
          <Grid item container xs={9} direction="row" alignItems="center">
            <Grid item container xs={2} alignItems="center">
              <Typography noWrap variant="body2">
                {child.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Field name={FormFields.START_TIME}>
                {({ form, ...formik }) => (
                  <DateTimePickerFormik
                    disableFuture={true}
                    form={form}
                    {...formik}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={7} className={classes.childDescription}>
              <Field name={FormFields.DESCRIPTION}>
                {({ form, ...formik }) => (
                  <EditorFormik
                    outlinedTextField
                    form={form}
                    {...formik}
                    label="Description"
                    fullWidth
                    toolbar={{
                      options: ['inline', 'list'],
                    }}
                    toolbarOnFocus
                    minHeight={7}
                    maxHeight={7}
                    labelMarginLeft={8}
                    xs={12}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid item xs={1} container direction="row" justifyContent="flex-end">
            <IconButton
              title="Edit"
              onClick={onClick}
              classes={{
                root: classes.iconButtonRoot,
              }}
            >
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
      </AutoSaveFormik>
    </Formik>
  );
};

export default ActivityChildComponent;
