import * as yup from 'yup';
import { convertToRaw } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog, Button, DialogContent } from '@material-ui/core';

import { ModalHeader, ModalActions, LoadingButton } from 'altus-ui-components';

import {
  STRING_TEST_REPORT_EVENT_TYPE_ENUM,
  STRING_TEST_REPORT_EVENT_TYPES_LIST,
} from './projectMobilisation.constants';

import { EMPTY_STRING } from 'app/app.constants';
import { useViewMode } from 'app/hooks/useViewMode';
import { formatDateTimeFromSeconds, validateTimeString } from 'utils/app.util';
import { getCurrentUserFromState } from 'app/app.selectors';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { Avatar as TooltipAvatar } from 'app/components/withTooltip';
import TimeSpanFormik from 'app/components/form/formik/TimeSpanFormik';
import DatePickerFormik from 'app/components/form/formik/DatePickerFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import {
  createStringTestReport,
  requestFinishStringTestReport,
} from 'features/projects/mobilisation/projectMobilisation.actions';

const useStyles = makeStyles((theme) =>
  createStyles({
    triggerIconRoot: {
      height: 45,
      width: 45,
      marginRight: 10,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7,
      },
    },
    gridBackground: {
      background: theme.altus.components.ApplicationSidebar.background,
    },
    gridBackgroundMobile: {
      background: theme.altus.components.ApplicationSidebar.background,
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
  }),
);

const FORM_FIELDS = {
  EVENT_TYPE: 'eventTypeEnum',
  COMMENT: 'comment',
  DATE: 'date',
  TIME: 'time',
};

const timeErrorMessage = 'Invalid time format, it should be in format HH:MM';
const stringTestValidationSchema = yup.object().shape({
  [FORM_FIELDS.TIME]: yup
    .string()
    .required('Required')
    .test('is-valid-time', timeErrorMessage, (value) =>
      validateTimeString(value),
    ),
  [FORM_FIELDS.DATE]: yup.string().required('Required'),
});

const StringTestEventModalContainer = ({
  toggleModal,
  isOpen,
  stringTestReportEventTypes = STRING_TEST_REPORT_EVENT_TYPES_LIST,
  taskId,
  projectId,
  selectedToolstringId,
  secondaryStringTestReports = false,
  selectedStringTestReport,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useViewMode() === 'mobile';

  const user = useSelector(getCurrentUserFromState);

  const getId = (item) => item.id;
  const getName = (item) => item.name;

  const initialFormValues = {
    [FORM_FIELDS.COMMENT]: EMPTY_STRING,
    [FORM_FIELDS.EVENT_TYPE]: STRING_TEST_REPORT_EVENT_TYPE_ENUM.PASS,
    [FORM_FIELDS.DATE]: null,
    [FORM_FIELDS.TIME]: null,
  };

  const convertEditorContentToJson = (editorContent) => {
    return editorContent
      ? JSON.stringify(convertToRaw(editorContent.getCurrentContent()))
      : null;
  };

  const handleSubmit = (values) => {
    const comment = convertEditorContentToJson(values?.comment);
    const eventTypeEnum = parseInt(values.eventTypeEnum);
    const timeCreated = formatDateTimeFromSeconds(values.date, values.time);
    const userId = user.get('id');
    const toolstringId = selectedToolstringId;

    const stringTestReport = {
      comment,
      eventTypeEnum,
      timeCreated,
      userId,
      toolstringId,
    };

    if (secondaryStringTestReports) {
      dispatch(
        requestFinishStringTestReport(
          projectId,
          taskId,
          selectedStringTestReport.get('stringTestReportId'),
          stringTestReport,
        ),
      );
    } else {
      dispatch(createStringTestReport(projectId, taskId, stringTestReport));
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={false}
      open={isOpen}
      maxWidth="sm"
      onClose={toggleModal}
    >
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={stringTestValidationSchema}
      >
        {(formik) => {
          const { isValid, isSubmitting } = formik;
          return (
            <Form id="EVENT_MODAL">
              <ModalHeader title="New Event" toggleModal={toggleModal} />
              <DialogContent>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <SelectTextFieldFormik
                          getItemName={getName}
                          placeholder="Select"
                          label="Event"
                          getItemValue={getId}
                          items={stringTestReportEventTypes}
                          name={FORM_FIELDS.EVENT_TYPE}
                          displayEmpty={false}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field name={FORM_FIELDS.DATE}>
                            {({ form, ...formik }) => (
                              <DatePickerFormik
                                style={{ paddingTop: '3px' }}
                                autoOk
                                label="Date"
                                placeholder="yyyy/mm/dd"
                                form={form}
                                {...formik}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            label="Time"
                            name={FORM_FIELDS.TIME}
                            component={TimeSpanFormik}
                            placeholder="hh:mm"
                          />
                          <ErrorMessage name={FORM_FIELDS.TIME}>
                            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Field
                          label="Comment"
                          name={FORM_FIELDS.COMMENT}
                          component={EditorFormik}
                          xs={12}
                        />
                      </Grid>
                      <br></br>
                      <Grid
                        container
                        alignItems="center"
                        style={{ height: '100%', marginBottom: '20px' }}
                      >
                        <TooltipAvatar
                          title={user.get('name')}
                          src={user.get('avatar')}
                          classes={{
                            root: classes.triggerIconRoot,
                          }}
                        >
                          {!user.get('avatar') && user.get('initials')}
                        </TooltipAvatar>
                        {user.get('name')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <Grid
                className={
                  isMobile
                    ? classes.gridBackgroundMobile
                    : classes.gridBackground
                }
              >
                <ModalActions className={classes.gridBackground}>
                  <Button
                    size={isMobile ? 'large' : 'small'}
                    color="default"
                    onClick={toggleModal}
                  >
                    CANCEL
                  </Button>
                  <LoadingButton
                    type="submit"
                    size={isMobile ? 'large' : 'small'}
                    color="primary"
                    variant="contained"
                    loading={isSubmitting}
                    form={'EVENT_MODAL'}
                    disabled={!isValid || isSubmitting}
                  >
                    SAVE
                  </LoadingButton>
                </ModalActions>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default StringTestEventModalContainer;
