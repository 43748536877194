import { MOBILE_TASK_VIEWS } from 'features/projects/tasks/tasks.constants';
import { useState } from 'react';
import { AppBar, Box, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { Tabs, Tab } from 'altus-ui-components';
import MobileExecutionActivities from 'features/mobile/projects/execute/taskActivities/MobileExecutionActivities';
import MobileExecutionBHATabContainer from '../taskToolstring/MobileExecutionBHATabContainer';

const MobileExecutionDetails = ({
  classes,
  defaultView = MOBILE_TASK_VIEWS.ACTIVITIES,
  task,
  projectId,
}) => {
  const [selectedView, setSelectedView] = useState(defaultView);
  const toggleViewChange = (_event, newValue) => {
    setSelectedView(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedView}
          variant="fullWidth"
          disableUnderline={false}
          onChange={toggleViewChange}
        >
          <Tab
            label="ACTIVITIES"
            selected={selectedView === MOBILE_TASK_VIEWS.ACTIVITIES}
            style={{
              color:
                selectedView === MOBILE_TASK_VIEWS.ACTIVITIES
                  ? '#90CAF9'
                  : '#ffffff',
            }}
          />
          <Tab
            label="BHA"
            selected={selectedView === MOBILE_TASK_VIEWS.BHA}
            style={{
              color:
                selectedView === MOBILE_TASK_VIEWS.BHA ? '#90CAF9' : '#ffffff',
            }}
          />
        </Tabs>
      </AppBar>
      <TabPanel
        value={selectedView}
        index={MOBILE_TASK_VIEWS.ACTIVITIES}
        className={classes.tabPanel}
      >
        <MobileExecutionActivities
          task={task}
          projectId={projectId}
          taskId={task.get('taskId')}
        />
      </TabPanel>
      <TabPanel
        value={selectedView}
        index={MOBILE_TASK_VIEWS.BHA}
        className={classes.tabPanel}
      >
        <MobileExecutionBHATabContainer
          task={task}
          projectId={projectId}
          taskId={task.get('taskId')}
        />
      </TabPanel>
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabPanel: {
    padding: theme.spacing(0),
  },
});

export default withStyles(styles)(MobileExecutionDetails);
