import Agent from 'infrastructure/agent';

const searchM3Items = (search) => Agent.post('/m3/search', search);

const findM3ItemsBySerialNumber = (search) => Agent.post('/m3/search', search);

const uploadSurfaceEquipmentImage = (file, meta = {}) =>
  Agent.file.post('/equipment/image/upload', file, meta);

const deleteSurfaceEquipmentImage = (imageId) =>
  Agent.delete(`/equipment/image/${imageId}`);

const getAllSurfaceEquipmentGroups = () => Agent.get('/equipment/groups');

const createSurfaceEquipment = (tool) => Agent.post('/equipment/create', tool);

const getAllSurfaceEquipment = () => Agent.get('/equipment');

const searchSurfaceEquipmentByGroup = (groupId) =>
  Agent.get(`/equipment/getequipment/${groupId}`);

const getSurfaceEquipmentById = (equipmentId) =>
  Agent.get(`/equipment/${equipmentId}`);

const getAssetHistoryByExternalToolId = (
  equipmentId,
  equipmentType,
  serialNumber,
) =>
  Agent.get(
    `/assethistory/${equipmentId}/items/${serialNumber}?equipmentType=${equipmentType}`,
  );

const createTestAssetHistory = (
  asset,
  equipmentId,
  equipmentType,
  serialNumber,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/tests?equipmentType=${equipmentType}`,
    asset,
  );

const createBopTestAssetHistory = (
  asset,
  equipmentId,
  _,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/boptests/${taskId}`,
    asset,
  );

const createStringTestAssetHistory = (asset) => {
  const { taskId, toolstringId } = asset;
  return Agent.post(
    `/assethistory/items/${taskId}/${toolstringId}/stringtests`,
    asset,
  );
};

const createcableTorsionTestAssetHistory = (
  asset,
  equipmentId,
  _,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableTorsionTest/${taskId}`,
    asset,
  );

const createcableWrapTestAssetHistory = (
  asset,
  equipmentId,
  _,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableWrapTest/${taskId}`,
    asset,
  );

const createcableTortureTestAssetHistory = (
  asset,
  equipmentId,
  _,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableTortureTest/${taskId}`,
    asset,
  );

const createcableContinuityTestAssetHistory = (
  asset,
  equipmentId,
  _,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableContinuityTest/${taskId}`,
    asset,
  );

const createcableInsulationTestAssetHistory = (
  asset,
  equipmentId,
  _,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableInsulationTest/${taskId}`,
    asset,
  );

const createSil2TestAssetHistory = (asset, ___, _, __, taskId) =>
  Agent.post(`/assethistory/items/sil2Test/${taskId}`, asset);

const validateToolStringSerialNumbers = (toolstringId) => {
  return Agent.post(`/assethistory/${toolstringId}/validate-serial-number`);
};

const validateSurfaceEquipmentSerialNumbers = (taskId) => {
  return Agent.post(
    `/assethistory/${taskId}/validate-surface-equipment-serial-number`,
  );
};

const createMaintenanceAssetHistory = (
  asset,
  equipmentId,
  equipmentType,
  serialNumber,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/maintenances?equipmentType=${equipmentType}`,
    asset,
  );

const getEquipmentAssets = (equipmentId, equipmentType) => {
  return Agent.get(
    `/assethistory/${equipmentId}/items?equipmentType=${equipmentType}`,
  );
};

const getStringVerificationsForToolstring = (taskId, toolstringId) => {
  return Agent.get(`/assethistory/items/${taskId}/${toolstringId}/stringtests`);
};

const getEquipmentByCategories = (taskId) =>
  Agent.get(`/assethistory/${taskId}/equipment/categories`);

const createCableWrapBraidedMonoTestAssetHistory = (
  asset,
  equipmentId,
  _,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableWrapBraidedMonoTest/${taskId}`,
    asset,
  );

const createCableBreakTestAssetHistory = (
  asset,
  equipmentId,
  _,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableBreakTest/${taskId}`,
    asset,
  );

const createCableCutMaintenanceAssetHistory = (
  asset,
  equipmentId,
  equipmentType,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableCutMaintenances/${taskId}?equipmentType=${equipmentType}`,
    asset,
  );

const createCableServiceMaintenanceAssetHistory = (
  asset,
  equipmentId,
  equipmentType,
  serialNumber,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableServiceMaintenances/${taskId}?equipmentType=${equipmentType}`,
    asset,
  );

const createCableSpoolingMaintenanceAssetHistory = (
  asset,
  equipmentId,
  equipmentType,
  serialNumber,
  taskId,
  drumItemNumber,
  drumSerialNumber,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/${serialNumber}/cableSpoolingMaintenances/${taskId}?equipmentType=${equipmentType}&drumItemNumber=${drumItemNumber}&drumSerialNumber=${drumSerialNumber}`,
    asset,
  );

const getCableTestDetails = (cableId, serialNumber) =>
  Agent.get(`/assethistory/${cableId}/cable/${serialNumber}/details`);

const equipmentService = {
  getAllSurfaceEquipment,
  uploadSurfaceEquipmentImage,
  deleteSurfaceEquipmentImage,
  createSurfaceEquipment,
  getAllSurfaceEquipmentGroups,
  searchM3Items,
  findM3ItemsBySerialNumber,
  getAssetHistoryByExternalToolId,
  getSurfaceEquipmentById,
  searchSurfaceEquipmentByGroup,
  createTestAssetHistory,
  createMaintenanceAssetHistory,
  getEquipmentAssets,
  createBopTestAssetHistory,
  createStringTestAssetHistory,
  validateToolStringSerialNumbers,
  createcableTorsionTestAssetHistory,
  createcableWrapTestAssetHistory,
  createcableTortureTestAssetHistory,
  createcableContinuityTestAssetHistory,
  createcableInsulationTestAssetHistory,
  createSil2TestAssetHistory,
  validateSurfaceEquipmentSerialNumbers,
  getStringVerificationsForToolstring,
  getEquipmentByCategories,
  createCableWrapBraidedMonoTestAssetHistory,
  createCableBreakTestAssetHistory,
  createCableCutMaintenanceAssetHistory,
  getCableTestDetails,
  createCableServiceMaintenanceAssetHistory,
  createCableSpoolingMaintenanceAssetHistory,
};

export default equipmentService;
