import { useState, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { Grid, Divider } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import ProjectStatusHeader from 'features/mobile/projects/project/ProjectStatusHeader';
import { useSelector, connect } from 'react-redux';
import {
  getCurrentProjectWellboreFromState,
  getCurrentProjectWellboreTrajectoryFromState,
} from 'features/projects/wellbore/projectWellbore.selectors';
import {
  getCurrentClientOrganizationIdFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';
import { PROJECT_WELLBORE_ACTIONS } from 'features/projects/wellbore/projectWellbore.constants';
import { ACTIONS } from 'features/projects/wellbore/sections/projectWellboreSection.constants';
import {
  projectWellboreOnLoad,
  syncProjectWellboreTrajectoryWithLatest,
} from 'features/projects/wellbore/projectWellbore.actions';
import { getProjectStatusLite } from 'features/projects/activities/activities.actions';
import { requestProjectWellboreSections } from 'features/projects/wellbore/sections/projectWellboreSection.actions';
import { EMPTY_MAP } from 'app/app.constants';
import {
  createProjectWellboreSectionsSelector,
  getCurrentProject,
} from 'features/projects/projects.selectors';
import ProjectWellboreTrajectory3DView from 'features/projects/wellbore/survey/ProjectWellboreTrajectory3DView';
import useLatestProjectWellboreDetail from 'features/projects/hooks/useLatestProjectWellboreDetails';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
import { BasePage } from 'altus-ui-components';
import { searchOnlineOperations } from 'features/operations/operations.actions';
import { initialFilters } from 'features/operations/components/OperationFilter';
import { getOperationsFromState } from 'features/operations/operations.selectors';
import ProjectStatusOperationValues from 'features/mobile/projects/project/ProjectStatusOperationValues';
import ProjectStatusDurationValues from 'features/mobile/projects/project/ProjectStatusDurationValues';
import { onLoad } from 'features/projects/details/details.actions';

const ProjectStatusContainer = ({
  classes,
  projectId,
  dispatchOnLoad,
  dataState,
  wellbore = EMPTY_MAP,
  trajectory = EMPTY_MAP,
  operations,
  dispatchGetProjectStatus,
  dispatchRequestProjectWellboreSections,
  dispatchSearchOperations,
  dispatchOnProjectLoad,
}) => {
  const [operation, setOperation] = useState();
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  useEffect(() => {
    const updatedFilters = {
      ...initialFilters,
      organizationId: currentClientOrganizationId,
    };

    dispatchSearchOperations(updatedFilters);
  }, [currentClientOrganizationId, dispatchSearchOperations]);

  useEffect(() => {
    if (operations.size > 0) {
      const projectOp = operations.toJS()[projectId];
      setOperation(projectOp);
    }
  }, [operations, projectId]);

  useEffect(() => {
    dispatchRequestProjectWellboreSections(projectId);
  }, [projectId, dispatchRequestProjectWellboreSections]);

  useEffect(() => {
    dispatchOnProjectLoad(projectId);
  }, [projectId, dispatchOnProjectLoad]);

  const [wellboreId, setWellboreId] = useState();
  useEffect(() => {
    dispatchOnLoad(projectId);
  }, [projectId, dispatchOnLoad]);

  useEffect(() => {
    // Fetch status again if trajectory changes, in case we need to ask/stop asking for confirmation before updating again.
    dispatchGetProjectStatus(projectId);
  }, [projectId, trajectory, dispatchGetProjectStatus]);

  const projectStatus = useSelector(getExecuteStatusFromState);

  const [latestWellboreDetail = EMPTY_MAP] =
    useLatestProjectWellboreDetail(projectId);

  useEffect(() => {
    if (latestWellboreDetail.get('wellboreId'))
      setWellboreId(latestWellboreDetail.get('wellboreId'));
  }, [latestWellboreDetail]);

  const projectWellboreSectionsSelector = useMemo(
    () => createProjectWellboreSectionsSelector(projectId),
    [projectId],
  );

  const wellboreSections = useSelector(projectWellboreSectionsSelector);

  return (
    <BasePage
      dataState={dataState}
      LoadingOverlayProps={{
        timeout: 0,
      }}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid item container>
        <ProjectStatusHeader projectId={projectId} />
      </Grid>
      <Divider className={classes.divider} />
      {operation && (
        <Grid item container>
          <ProjectStatusOperationValues operation={operation} />
        </Grid>
      )}
      <Grid xs={12} item container>
        {wellboreId && (
          <>
            <ProjectWellboreTrajectory3DView
              trajectory={trajectory}
              wellboreDetail={latestWellboreDetail}
              wellboreId={wellboreId}
              wellbore={wellbore}
              wellboreSections={wellboreSections}
            />
          </>
        )}
      </Grid>
      <ProjectStatusDurationValues projectStatus={projectStatus} />
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'column',
    padding: theme.spacing(0),
    backgroundColor: '#232323',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
});

export default compose(
  connect(
    (state) => ({
      project: getCurrentProject(state),
      wellbore: getCurrentProjectWellboreFromState(state),
      trajectory: getCurrentProjectWellboreTrajectoryFromState(state),
      operations: getOperationsFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        PROJECT_WELLBORE_ACTIONS.PROJECT_WELLBORE_ON_LOAD,
        ACTIONS.REQUEST_PROJECT_WELLBORE_SECTIONS,
      ),
    }),
    {
      dispatchOnLoad: projectWellboreOnLoad,
      dispatchGetProjectStatus: getProjectStatusLite,
      dispatchSyncProjectWellboreTrajectoryWithLatest:
        syncProjectWellboreTrajectoryWithLatest,
      dispatchRequestProjectWellboreSections: requestProjectWellboreSections,
      dispatchSearchOperations: searchOnlineOperations,
      dispatchOnProjectLoad: onLoad,
    },
  ),
  withStyles(styles),
)(ProjectStatusContainer);
