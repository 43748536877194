import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Dialog, DialogContent } from '@material-ui/core';

import { LoadingOverlay } from 'altus-ui-components';

import BhaTemplatesCreator from 'features/equipment/bhaTemplates/addBhaTemplates/BhaTemplatesCreator';
import AddBhaTemplatesModalHeader from 'features/equipment/bhaTemplates/addBhaTemplates/AddBhaTemplatesModalHeader';
import AddBhaTemplatesModalFooter from 'features/equipment/bhaTemplates/addBhaTemplates/AddBhaTemplatesModalFooter';

const AddBhaTemplatesModal = ({
  open,
  classes,
  toggleModal,
  bhaTemplateInfo,
  onSaveBhaTemplate,
  toggleAddToolAssemblyToBhaModal,
  onSortToolstringItem,
  onDeleteToolstringItem,
  onDuplicateItem,
  selectedTools,
  setBhaTemplateInfo,
  dataState,
}) => {
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={toggleModal}
        classes={{ paperScrollPaper: classes.root }}
      >
        <Grid className={classes.header}>
          <AddBhaTemplatesModalHeader
            closeModal={toggleModal}
            title={'Add BHA template'}
          />
        </Grid>
        <DialogContent className={classes.content}>
          <BhaTemplatesCreator
            toggleAddToolAssemblyToBhaModal={toggleAddToolAssemblyToBhaModal}
            onSortToolstringItem={onSortToolstringItem}
            onDeleteToolstringItem={onDeleteToolstringItem}
            onDuplicateItem={onDuplicateItem}
            selectedTools={selectedTools}
            setBhaTemplateInfo={setBhaTemplateInfo}
          />
        </DialogContent>
        <Grid className={classes.footer}>
          <AddBhaTemplatesModalFooter
            bhaTemplateInfo={bhaTemplateInfo}
            selectedTools={selectedTools}
            onSaveBhaTemplate={onSaveBhaTemplate}
          />
        </Grid>
        <LoadingOverlay dataState={dataState} />
      </Dialog>
    </>
  );
};

const styles = (theme) => ({
  root: {
    // height: 'fit-content',
  },
  content: {
    overflow: 'scroll-y',
  },
  header: {
    padding: theme.spacing(3),
    backgroundColor: '#212121',
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    backgroundColor: '#212121',
  },
  filters: {
    paddingBottom: theme.spacing(2),
  },
  table: {
    paddingBottom: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(AddBhaTemplatesModal);
