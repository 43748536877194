import React, { useRef } from 'react';
import * as THREE from 'three';
import { CSG } from 'app/components/WellboreTrajectoryDetailed3DView/CSGMesh.js';

const Nipple = ({ innerRadius, outerRadius, position, length, id }) => {
  const cylinderRef = useRef();

  const arcShape = new THREE.Shape().absarc(
    0,
    0,
    outerRadius,
    0,
    Math.PI,
    true,
  );

  const innerShape = new THREE.Shape();
  innerShape.absarc(0, 0, -innerRadius, 0, Math.PI);

  const outerGeometry = new THREE.ExtrudeBufferGeometry(arcShape, {
    steps: 1,
    depth: length,
    bevelEnabled: false,
  });

  const innerGeometry = new THREE.ExtrudeBufferGeometry(innerShape, {
    steps: 1,
    depth: length,
    bevelEnabled: false,
  });

  const bspA = CSG.fromGeometry(outerGeometry, 0);
  const bspB = CSG.fromGeometry(innerGeometry, 1);

  let bspResult = bspA.subtract(bspB);
  bspResult = CSG.toGeometry(bspResult);

  return (
    <mesh
      ref={cylinderRef}
      name={`Nipple-${id}`}
      position={position}
      rotation={[-Math.PI / 2, Math.PI / 2, -Math.PI]}
      geometry={bspResult}
    >
      <meshStandardMaterial
        color="#919598"
        roughness={0.2}
        metalness={1}
        envMapIntensity={2}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default Nipple;
