import { compose } from 'redux';
import { memo, useCallback } from 'react';
import { Grid, Button, Box } from '@material-ui/core';

const ToolAssembliesModalFooter = ({
  edit = false,
  selectedTools,
  toolAssemblyInfo,
  onSaveToolAssembly,
}) => {
  const checkSaveDisabled = useCallback(() => {
    if (edit) return false;
    if (
      toolAssemblyInfo !== null &&
      toolAssemblyInfo.name !== '' &&
      selectedTools.length > 0
    ) {
      return false;
    }
    return true;
  }, [selectedTools, toolAssemblyInfo, edit]);

  return (
    <Grid
      item
      container
      justifyContent="spaceBetween"
      alignItems="center"
      component={Box}
    >
      <Grid
        item
        container
        justifyContent="spaceBetween"
        xs={6}
        alignItems="center"
      ></Grid>
      <Grid item container justifyContent="flex-end" xs={6}>
        <Button
          color="primary"
          variant="contained"
          disabled={checkSaveDisabled()}
          onClick={onSaveToolAssembly}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default compose(memo)(ToolAssembliesModalFooter);
