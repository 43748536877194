import moment from 'moment';
import { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { EditorState, convertFromRaw } from 'draft-js';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { EMPTY_MAP } from 'app/app.constants';
import EditorStateViewer from 'app/components/EditorStateViewer';
import ProjectUser from 'features/projects/components/ProjectUser';
import { requestStringTestReportEvent } from 'features/projects/mobilisation/projectMobilisation.actions';
import { getStringTestReportEventFromState } from 'features/projects/mobilisation/projectMobilisation.selectors';
import { STRING_TEST_REPORT_EVENT_TYPE_ENUM } from 'features/projects/mobilisation/projectMobilisation.constants';

const getStatusString = (eventType) => {
  switch (eventType) {
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.PASS:
      return 'Pass';
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.WARNING:
      return 'Warning';
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.FAIL:
      return 'Fail';
    default:
      return 'Invalid event type';
  }
};

const formatDateString = (dateString) => {
  return moment.utc(dateString).format('YYYY/M/D HH:mm');
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.table.row.main,
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1.25),
      padding: theme.spacing(2.25),
    },
  }),
);

const StringTestEventContainer = ({
  projectId,
  taskId,
  stringTestReportEventId = null,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const event = useSelector(getStringTestReportEventFromState);

  const comments = event.get('comment')
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(event.get('comment'))),
      )
    : EditorState.createEmpty();

  useEffect(() => {
    if (stringTestReportEventId) {
      dispatch(
        requestStringTestReportEvent(
          projectId,
          taskId,
          stringTestReportEventId,
        ),
      );
    }
  }, [stringTestReportEventId, dispatch, projectId, taskId]);

  if (event === EMPTY_MAP) return null;
  return (
    <Grid item xs={12} container className={classes.root}>
      <Grid item xs={8} container spacing={4} alignItems="center">
        <Grid item>
          <Typography>
            Result: {getStatusString(event.get('eventType'))}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Date: {formatDateString(event.get('timeCreated'))}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <EditorStateViewer editorState={comments} />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
        <Grid item>
          <ProjectUser user={event.get('user')} organization={false} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StringTestEventContainer;
