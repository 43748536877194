import { compose } from 'redux';
import { Grid, Box } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';

import {
  getSimulationFromState,
  createSimulationWellboreSectionsSelector,
} from 'features/projects/tasks/task/simulation/simulation.selectors';

import {
  updateWellboreSectionForSimulation,
  requestWellboreSectionsForSimulation,
} from 'features/projects/tasks/task/simulation/simulation.actions';

import NavigationLink from 'app/components/NavigationLink';
import { toProjectWellboreSections } from 'utils/route.util';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import SimulationWellboreSectionsTable from './SimulationWellboreSectionsTable';
import { ACTIONS as WELL_ACTIONS } from 'features/wells/sections/wellboreSection.constants';
import useDefaultProjectWellboreSection from 'features/projects/hooks/useDefaultProjectWellboreSection';

const TaskSimulationWellboreSectionsContainer = ({
  taskId,
  projectId,
  simulationId,
  dispatchRequestWellboreSectionsForSimulation,
  dispatchUpdateRequestWellboreSectionForSimulation,
}) => {
  useEffect(() => {
    dispatchRequestWellboreSectionsForSimulation(
      projectId,
      taskId,
      simulationId,
    );
  }, [
    projectId,
    taskId,
    simulationId,
    dispatchRequestWellboreSectionsForSimulation,
  ]);

  const updateSimulationWellboreSection = useCallback(
    (wellboreSection) =>
      dispatchUpdateRequestWellboreSectionForSimulation(
        projectId,
        taskId,
        simulationId,
        wellboreSection.wellboreSectionId,
        wellboreSection,
      ),
    [
      taskId,
      projectId,
      simulationId,
      dispatchUpdateRequestWellboreSectionForSimulation,
    ],
  );

  const simulationWellboreSectionSelector = useMemo(
    () => createSimulationWellboreSectionsSelector(simulationId),
    [simulationId],
  );

  const wellboreSections = useSelector(simulationWellboreSectionSelector);

  const simulation = useSelector((state) =>
    getSimulationFromState(state, simulationId),
  );

  const defaultWellboreSection = useDefaultProjectWellboreSection(projectId);

  return (
    <>
      <SimulationWellboreSectionsTable
        simulation={simulation}
        wellboreSections={wellboreSections}
        defaultWellboreSection={defaultWellboreSection}
        updateWellboreSection={updateSimulationWellboreSection}
      />
      <Grid container justifyContent="flex-end" component={Box} paddingTop={2}>
        <NavigationLink
          target="_blank"
          variant="subtitle1"
          to={toProjectWellboreSections(projectId)}
        >
          Well Sections
        </NavigationLink>
      </Grid>
    </>
  );
};

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        WELL_ACTIONS.REQUEST_WELLBORE_SECTIONS,
      ),
    }),
    {
      dispatchRequestWellboreSectionsForSimulation:
        requestWellboreSectionsForSimulation,
      dispatchUpdateRequestWellboreSectionForSimulation:
        updateWellboreSectionForSimulation,
    },
  ),
)(TaskSimulationWellboreSectionsContainer);
