import _isNil from 'lodash/isNil';
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { Collection } from 'immutable';
import * as colors from '@material-ui/core/colors';
import HighchartsReact from 'highcharts-react-official';

import { formatValue } from 'utils/format.util';
import { getQuantityUnitFromItems } from 'utils/app.util';
import {
  getSimulationChartSeries,
  getSimulationCompressionDepthSeries,
} from 'features/projects/tasks/task/simulation/simulation.utils';
import {
  SimulationCharts,
  SimulationResultSeries,
} from 'features/projects/tasks/task/simulation/simulation.constants';

const SimulationResultsChart = ({
  tab,
  simulationResults,
  operationLoadPercent,
}) => {
  const { label, activeSeries } = tab;
  const chartRef = useRef(null);
  const tabKey = useRef(null);
  const [chartOptions, setChartOptions] = useState(() => {
    const depthUnit = getQuantityUnitFromItems(simulationResults, (result) =>
      result.get('measuredDepth'),
    );
    const xLabel = (tab) => {
      const path = SimulationCharts.find((c) => c.key === tab.key)?.unit;
      if (!_isNil(path)) {
        const u = getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(path),
        );
        return `${label} (${u})`;
      }
      return label;
    };
    return {
      series: getSimulationChartSeries(
        simulationResults,
        operationLoadPercent,
      ).filter((series) => activeSeries?.includes(series.id)),
      chart: {
        inverted: true,
        backgroundColor: colors.grey[800],
      },
      yAxis: [
        {
          allowDecimals: false,
          gridLineDashStyle: 'ShortDash',
          gridLineColor: colors.grey[700],
          title: {
            text: xLabel(tab),
          },
          plotLines: [
            {
              value: 0,
              width: 2,
              color: colors.grey[300],
            },
          ],
        },
      ],
      xAxis: {
        min: 0,
        gridLineWidth: 1,
        allowDecimals: false,
        gridLineDashStyle: 'ShortDash',
        gridLineColor: colors.grey[700],
        max: simulationResults.keySeq().last(),
        title: {
          text: `Tool Depth (${depthUnit})`,
        },
      },
      legend: {
        shadow: false,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        backgroundColor: 'transparent',
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        formatter() {
          let format = `<b>Depth: ${formatValue(this.x, depthUnit)}</b>`;

          this.points.forEach((point) => {
            const { series, y } = point;
            const {
              color,
              name,
              options: {
                custom: { unit, tooltip = true },
              },
            } = series;
            if (tooltip) {
              const formattedValue = formatValue(y, unit);
              format += `<br /><span style="color: ${color}">${name}: ${formattedValue}</span>`;
            }
          });

          return format;
        },
        positioner: function (w, h, point) {
          this.chart.pointer.chartPosition = null;
          return this.getPosition(w, h, point);
        },
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      title: {
        text: null,
      },
    };
  });

  useEffect(() => {
    let series = getSimulationChartSeries(
      simulationResults,
      operationLoadPercent,
    )
      .filter((series) => activeSeries?.includes(series.id))
      .map((series) =>
        tab.key === tabKey.current
          ? { id: series.id, data: series.data } // Only update data when tab is unchanged
          : series,
      ); // Set series to initial configuration on a new tab

    // Add compression depth if it is defined on the tab
    if (!_isNil(tab.compressionDepth)) {
      tab.compressionDepth.forEach((compression) => {
        const compressionSeries = getSimulationCompressionDepthSeries(
          compression.series + SimulationResultSeries.COMPRESSION_DEPTH,
          compression.direction,
          simulationResults,
          compression.value,
        );
        if (!_isNil(compressionSeries)) {
          series = [...series, compressionSeries];
        }
      });
    }

    setChartOptions({ series });
    tabKey.current = tab.key;
  }, [
    tab.key,
    tab.compressionDepth,
    activeSeries,
    operationLoadPercent,
    simulationResults,
  ]);

  return (
    <HighchartsReact
      options={chartOptions}
      highcharts={Highcharts}
      ref={chartRef}
    />
  );
};

SimulationResultsChart.propTypes = {
  simulationResults: PropTypes.instanceOf(Collection).isRequired,
  tab: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    label: PropTypes.string,
    activeSeries: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(SimulationResultSeries)),
    ),
  }).isRequired,
};

export default SimulationResultsChart;
