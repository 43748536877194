import { useMemo } from 'react';
import { compose } from 'redux';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, CircularProgress } from '@material-ui/core';

import { Table } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';

const CablePickerModalTable = ({
  loading,
  onSelectCable,
  cables = EMPTY_LIST,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 3,
        id: 'cableName',
        Header: 'Cable',
        accessor: (cable) => cable.get('name'),
      },
      {
        xs: 1,
        id: 'cableType',
        Header: 'Type',
        accessor: (cable) => cable.get('typeName'),
      },
      {
        xs: true,
        id: 'nominalDiameter',
        Header: 'Nominal Dia.',
        sortType: 'basic',
        accessor: (cable) =>
          `${cable?.getIn(
            ['nominalDiameter', 'roundedValue'],
            null,
          )} ${cable?.getIn(['nominalDiameter', 'unit'])}`,
      },
      {
        xs: true,
        id: 'weightInAir',
        Header: 'Weight in Air',
        sortType: 'basic',
        accessor: (cable) =>
          `${cable?.getIn(
            ['weightInAir', 'roundedValue'],
            null,
          )} ${cable?.getIn(['weightInAir', 'unit'])}`,
      },
      {
        xs: true,
        id: 'stretch',
        Header: 'Stretch',
        sortType: 'basic',
        accessor: (cable) => {
          const weightKey = cable.get('stretch')
            ? 'stretch'
            : 'stretchCoefficient';
          const roundedValue = cable?.getIn([weightKey, 'roundedValue'], null);
          const unit = cable?.getIn([weightKey, 'unit']);
          return `${roundedValue} ${unit}`;
        },
      },
      {
        xs: true,
        id: 'strength',
        Header: 'Breaking Strength',
        sortType: 'basic',
        accessor: (cable) =>
          `${cable?.getIn(['strength', 'roundedValue'], null)} ${cable?.getIn([
            'strength',
            'unit',
          ])}`,
      },
    ],
    [],
  );

  if (loading) {
    return (
      <Grid item container xs alignItems="center" justifyContent="center">
        <Grid item component={CircularProgress} color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid item container direction="column">
      <Grid xs item container wrap="nowrap" direction="column">
        <Grid item>
          <Table
            stickyHeader
            items={cables}
            columns={columns}
            noItemsMessage="No cables found. Try changing the filters"
            onRowClick={onSelectCable}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  action: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

CablePickerModalTable.propTypes = {
  cables: PropTypes.instanceOf(List),
};

export default compose(withStyles(styles))(CablePickerModalTable);
