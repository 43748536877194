import React from 'react';

import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';

const Sil2FormComponent = ({
  sil2TestBopItems,
  sil2TestBcsItems,
  sil2TestCabinItems,
}) => {
  const getId = (item) => item.get('externalId');
  const getName = (item) => item.get('name');
  
  return (
    <>
      <SelectTextFieldFormik
        getItemName={getName}
        label="BCS"
        getItemValue={getId}
        items={sil2TestBcsItems}
        name={AssetHistoryForm.ASSET_SIL2_TEST_BCS}
        displayEmpty={false}
        placeholder="Select"
      />
      <SelectTextFieldFormik
        getItemName={getName}
        label="BOP"
        getItemValue={getId}
        items={sil2TestBopItems}
        name={AssetHistoryForm.ASSET_SIL2_TEST_BOP}
        displayEmpty={false}
        placeholder="Select"
      />
      <SelectTextFieldFormik
        getItemName={getName}
        label="Cabin"
        getItemValue={getId}
        items={sil2TestCabinItems}
        name={AssetHistoryForm.ASSET_SIL2_TEST_CABIN}
        displayEmpty={false}
        placeholder="Select"
      />
    </>
  );
};

export default Sil2FormComponent;
