import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { ACTIONS } from 'features/projects/mobilisation/projectMobilisation.constants';

export const requestProjectMobilisationTasks = (projectId) => ({
  projectId,
  type: ACTIONS.REQUEST_PROJECT_MOBILISATION_TASKS,
});

export const receiveProjectMobilisationTasks = (projectMobilisationTasks) => ({
  payload: projectMobilisationTasks,
  type: ACTIONS.RECEIVE_PROJECT_MOBILISATION_TASKS,
});

export const receiveProjectMobilisationToolstrings = (
  projectMobilisationToolstrings,
) => ({
  payload: projectMobilisationToolstrings,
  type: ACTIONS.RECEIVE_PROJECT_MOBILISATION_TOOLSTRINGS,
});

export const requestProjectMobilisationToolstringForTask = (
  projectId,
  taskId,
) => ({
  projectId,
  taskId,
  type: ACTIONS.REQUEST_PROJECT_MOBILISATION_TOOLSTRING,
});

export const receiveProjectMobilisationToolstringForTask = (toolstring) => ({
  payload: toolstring,
  type: ACTIONS.RECEIVE_PROJECT_MOBILISATION_TOOLSTRING,
});

export const createStringTestReport = (
  projectId,
  taskId,
  stringTestReport,
) => ({
  projectId,
  taskId,
  stringTestReport,
  type: ACTIONS.CREATE_STRING_TEST_REPORT,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: 'String test report created',
  },
});

export const requestStringReportsForTask = (projectId, taskId) => ({
  projectId,
  taskId,
  type: ACTIONS.REQUEST_STRING_TEST_REPORTS_FOR_TASK,
});

export const receiveStringTestReportsForTask = (stringTestReports) => ({
  payload: stringTestReports,
  type: ACTIONS.RECEIVE_STRING_TEST_REPORTS_FOR_TASK,
});

export const requestStringTestReportEvent = (
  projectId,
  taskId,
  stringTestReportEventId,
) => ({
  projectId,
  taskId,
  stringTestReportEventId,
  type: ACTIONS.REQUEST_STRING_TEST_REPORT_EVENT,
});

export const receiveStringTestReportEvent = (stringTestReportEvent) => ({
  payload: stringTestReportEvent,
  type: ACTIONS.RECEIVE_STRING_TEST_REPORT_EVENT,
});

export const requestCreateSecondaryStringTestReport = (
  projectId,
  taskId,
  toolstringId,
) => ({
  projectId,
  taskId,
  toolstringId,
  type: ACTIONS.REQUEST_CREATE_SECONDARY_TEST_REPORT,
});

export const requestFinishStringTestReport = (
  projectId,
  taskId,
  stringTestReportId,
  stringTestReport,
) => ({
  projectId,
  taskId,
  stringTestReportId,
  stringTestReport,
  type: ACTIONS.REQUEST_FINISH_STRING_TEST_REPORT,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: 'String test report created',
  },
});
