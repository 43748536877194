import { compose } from 'redux';
import { useState } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import ExportPdfIcon from '@material-ui/icons/PictureAsPdf';

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { ModalActions } from 'altus-ui-components';

const TaskToolstringExportModal = ({ open, onClose, classes, onExport }) => {
  const [collapseAssemblies, setCollapseAssemblies] = useState(false);
  const toggleCollapseAssemblies = () => {
    setCollapseAssemblies(!collapseAssemblies);
  };
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Grid className={classes.header}>
        <Grid container item xs alignItems="center">
          <Typography variant="h6">Export Toolstring</Typography>
        </Grid>
      </Grid>
      <DialogContent className={classes.content}>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title}>
              Toolstring Details
            </Typography>
            <Grid item container>
              <ListItem dense button onClick={() => toggleCollapseAssemblies()}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    disableRipple
                    checked={collapseAssemblies}
                  />
                </ListItemIcon>
                <ListItemText primary={'Collapse Assemblies'} />
              </ListItem>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <ModalActions classes={{ dialogActionsRoot: classes.actions }}>
        <Grid container item xs justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button onClick={onClose}>Close</Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                onExport({
                  collapseAssemblies,
                })
              }
            >
              Export
              <Box component={ExportPdfIcon} marginLeft={0.5} />
            </Button>
          </Grid>
        </Grid>
      </ModalActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  content: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  header: {
    padding: theme.spacing(3),
  },
  actions: {
    paddingTop: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
});

export default compose(withStyles(styles))(TaskToolstringExportModal);
