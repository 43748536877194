import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { onLoadSurfaceEquipment } from 'features/equipment/equipment.actions';
import { getSurfaceEquipmentFromState } from 'features/equipment/equipment.selectors';
import AddSurfaceEquipmentInPlanningModal from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddSurfaceEquipmentInPlanningModal';

const AddSurfaceEquipmentInPlanningModalContainer = ({
  tools,
  isOpen,
  classes,
  dataState,
  toggleModal,
  dispatchOnLoad,
  onRemoveSurface,
  onSaveSurfaceEquipment,
  selectedSurfaceEquipment,
  setSelectedSurfaceEquipment,
}) => {
  useEffect(() => {
    dispatchOnLoad();
  }, [dispatchOnLoad]);

  return (
    <AddSurfaceEquipmentInPlanningModal
      tools={tools}
      isOpen={isOpen}
      classes={classes}
      dataState={dataState}
      toggleModal={toggleModal}
      onRemoveSurface={onRemoveSurface}
      onSaveSurfaceEquipment={onSaveSurfaceEquipment}
      selectedSurfaceEquipment={selectedSurfaceEquipment}
      setSelectedSurfaceEquipment={setSelectedSurfaceEquipment}
    />
  );
};

export default compose(
  connect(
    (state) => ({
      tools: getSurfaceEquipmentFromState(state),
    }),
    {
      dispatchOnLoad: onLoadSurfaceEquipment,
    },
  ),
)(AddSurfaceEquipmentInPlanningModalContainer);
