import moment from 'moment';
import uniq from 'lodash/uniq';
import isNil from 'lodash/isNil';
import Highcharts from 'highcharts';
import * as MuiColors from '@material-ui/core/colors';

import { Format, UNITS as Units } from 'app/app.constants';
import { formatDate, formatValue } from 'utils/format.util';
import dashboardHighchartsTheme from 'features/projects/dashboard/components/dashboards/winch/dashboard.highcharts.theme';

const { fontColor, defaultFontSize } = dashboardHighchartsTheme.style;

export const firstColumnWidth = 35;
export const secondColumnWidth = 55;
export const secondColumnLeft = 45;

export const getDefaultYAxisOptions = (curve) => ({
  id: curve.get('id').toString(),
  opposite: true,
  margin: 0,
  title: {
    text: curve.get('caption'),
    style: {
      color: curve.get('color'),
      fontSize: defaultFontSize,
    },
    y: 40,
  },
  labels: {
    align: 'center',
    style: {
      color: curve.get('color'),
      fontSize: defaultFontSize,
    },
    y: -10,
  },
  resize: {
    enabled: true,
  },
  lineWidth: 1,
  lineColor: curve.get('color'),
  gridLineColor: MuiColors.grey[800],
  min: curve.get('minValue'),
  max: curve.get('maxValue'),
  startOnTick: true,
  endOnTick: true,
  autoRotationLimit: 0,
  rotation: 0,
  maxPadding: 0,
  minPadding: 0,
  offset: 0,
  tickPositioner: function () {
    var minValues = [this.options.min, this.min, this.dataMin];
    var maxValues = [this.options.max, this.max, this.dataMax];

    if (minValues.every(isNil)) {
      return [];
    }

    if (maxValues.every(isNil)) {
      return [];
    }

    var min = minValues.find((value) => !isNil(value));
    var max = maxValues.find((value) => !isNil(value));

    return [Math.floor(min), Math.ceil(max)];
  },
});

const loggingDashboardHighchartsTheme = {
  chart: {
    events: {
      load: function () {
        // Add up/down buttons
        const chart = this;
        const renderer = chart.renderer;

        renderer
          .button('▲', 0, 0, function () {
            const xAxis = chart.xAxis[0];

            const currentMin = xAxis.min;
            const currentMax = xAxis.max;
            const delta = (currentMax - currentMin) / 2;

            xAxis.setExtremes(currentMin - delta, currentMax - delta);
          })
          .attr({
            zIndex: 3,
          })
          .align({
            align: 'left',
            verticalAlign: 'bottom',
            x: 50,
            y: -115,
          })
          .add();

        renderer
          .button('▼', 0, 0, function () {
            const xAxis = chart.xAxis[0];

            const currentMin = xAxis.min;
            const currentMax = xAxis.max;
            const delta = (currentMax - currentMin) / 2;

            xAxis.setExtremes(currentMin + delta, currentMax + delta);
          })
          .attr({
            zIndex: 3,
          })
          .align({
            align: 'left',
            verticalAlign: 'bottom',
            x: 77,
            y: -115,
          })
          .add();
      },
    },
    alignTicks: true,
    inverted: true,
    zoomType: 'x',
    resetZoomButton: {
      position: {
        align: 'left',
        verticalAlign: 'bottom',
        x: 70,
        y: -100,
      },
    },
  },
  plotOptions: {
    line: {
      keys: ['timestamp', 'x', 'y'],
    },
    series: {
      animation: false,
      requireSorting: false,
      marker: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  },
  rangeSelector: {
    enabled: true,
    allButtonsEnabled: true,
  },
  tooltip: {
    shared: true,
    outside: true,
    crosshairs: true,
    positioner: function () {
      return { x: 10, y: this.chart.plotTop };
    },
    formatter: function () {
      const depthUnitFromSeries = this.points[0].series.userOptions.depthUnit;
      const depthUnit =
        Units[
          Object.keys(Units).find((key) => Units[key] === depthUnitFromSeries)
        ];
      const value = formatValue(this.x, depthUnit);

      let format = `<span style="color:${fontColor}"><b>Depth: ${value}</b></span>`;

      const uniqueTimestamps = uniq(
        this.points.map((point) => point.point.timestamp),
      );

      const allPointsHaveSameTimestamp = uniqueTimestamps.length === 1;

      if (allPointsHaveSameTimestamp) {
        const commonTimestamp = uniqueTimestamps[0];

        const formattedTimestamp = formatDate(
          moment(commonTimestamp),
          Format.time_long,
        );

        format += `<br /><span style="color:${fontColor}"><b>Time: ${formattedTimestamp}</b></span>`;
      }

      this.points.forEach((point) => {
        const { series, y: value } = point;
        const { color, options } = series;
        const { unit, caption } = options;

        const formattedValue = unit
          ? formatValue(value, unit)
          : formatValue(value);

        format += `<br /><span style="color:${color}">${caption}: ${formattedValue}</span>`;
      });

      return format;
    },
  },
};

export default Highcharts.merge(
  false,
  dashboardHighchartsTheme,
  loggingDashboardHighchartsTheme,
);
