import throttle from 'lodash/throttle';
import { HubConnectionState } from '@microsoft/signalr';
import { useCallback, useMemo, useContext } from 'react';

import { LoadingDataState, ReceivedDataState } from 'altus-datastate';

import config from 'infrastructure/config';
import useStream from 'features/projects/dashboard/hooks/useStream';
import useHubConnection from 'features/projects/dashboard/hooks/useHubConnection';
import useProjectDashboard from 'features/projects/dashboard/hooks/useProjectDashboard';
import { ProjectDashboardContext } from 'features/projects/dashboard/ProjectDashboardContainer';

const projectDashboardHubUrl = `${config.dashboardHubsBaseUrl}/project`;

const ProjectDashboardHub = {
  SetExtremes: 'SetExtremes',
  GetInitialReadings: 'GetInitialReadings',
  StartStreamingReadings: 'StartStreamingReadings',
  ReceiveInitialReadings: 'ReceiveInitialReadings',
};

const useProjectDashboardHubConnection = (projectId, projectDashboardId) => {
  const setDataState = useContext(ProjectDashboardContext);

  const { curves, projectDashboard } = useProjectDashboard(
    projectId,
    projectDashboardId,
  );

  const [connection, connectionState] = useHubConnection(
    projectDashboardHubUrl,
    setDataState,
  );

  const createStream = useCallback(
    (connection) =>
      connection
        .invoke(
          ProjectDashboardHub.GetInitialReadings,
          projectId,
          projectDashboardId,
        )
        .then(() =>
          connection.stream(
            ProjectDashboardHub.StartStreamingReadings,
            projectId,
            projectDashboardId,
          ),
        )
        .finally(() => setDataState(ReceivedDataState)),
    [projectId, setDataState, projectDashboardId],
  );

  const stream = useStream(connection, connectionState, createStream);

  const registerDataPointsHandler = useCallback(
    (onReceiveInitialDataPoints, onReceiveDataPoint) => {
      if (onReceiveInitialDataPoints) {
        connection.on(
          ProjectDashboardHub.ReceiveInitialReadings,
          onReceiveInitialDataPoints,
        );
      }

      if (!stream) return;

      if (onReceiveDataPoint) {
        stream.subscribe({
          next: onReceiveDataPoint,
          error: (error) => {
            setDataState(ReceivedDataState);
            console.error(error);
          },
        });
      }
    },
    [stream, connection, setDataState],
  );

  const setExtremes = useCallback(
    (request) => {
      if (connection.state !== HubConnectionState.Connected) {
        return Promise.reject();
      }

      setDataState(LoadingDataState);

      return connection
        .invoke(
          ProjectDashboardHub.SetExtremes,
          projectId,
          projectDashboardId,
          request,
        )
        .finally(() => setDataState(ReceivedDataState));
    },
    [projectId, connection, projectDashboardId, setDataState],
  );

  const setExtremesThrottled = useMemo(
    () => throttle(setExtremes, 500),
    [setExtremes],
  );

  return {
    curves,
    connection,
    setDataState,
    projectDashboard,
    registerDataPointsHandler,
    setExtremes: setExtremesThrottled,
  };
};

export default useProjectDashboardHubConnection;
