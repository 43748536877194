import takeLast from 'lodash/last';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState, useMemo } from 'react';

import { EMPTY_QUANTITY } from 'app/app.constants';
import ThreeJsManagerProvider from 'app/threeJs/ThreeJsManagerProvider';
import { getProjectWellbore } from 'features/projects/wellbore/projectWellbore.actions';
import { getTargetDepthFromState } from 'features/projects/activities/activities.selectors';
import ProjectWellGraphTrajectoryContainer from 'app/components/WellGraphAndGuages/ProjectWellGraphTrajectoryContainer';
import {
  createGetToolstringSelector,
  getToolstringsByTaskFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';
import { requestProjectWellboreSections } from 'features/projects/wellbore/sections/projectWellboreSection.actions';
import { getToolstringsForTask } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { createProjectWellboreSectionsSelector } from 'features/projects/projects.selectors';
import { getToolstring } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { getAllTasksFromState } from 'features/projects/tasks/tasks.selectors';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { getAllTasks } from 'features/projects/tasks/tasks.actions';
import useProject from 'features/projects/hooks/useProject';

const WellGraphAndGuagesDataContainer = ({
  curve,
  projectId,
  registerDataPointsHandler,
}) => {
  const dispatch = useDispatch();
  const [currentDepth, setCurrentDepth] = useState(EMPTY_QUANTITY);
  const [toolsByToolstringToolId, setToolsByToolstringToolId] = useState(null);
  const [BHA_LENGTH, setBHALength] = useState(EMPTY_QUANTITY);
  const [toolStringId, setToolStringId] = useState(null);
  const [maxOD, setMaxOD] = useState(EMPTY_QUANTITY);
  const [taskId, setTaskId] = useState(null);
  const [unplannedToolString, setUnPlannedToolString] = useState(false);

  useEffect(() => {
    dispatch(getProjectWellbore(projectId));
    dispatch(getAllTasks(projectId));
  }, [dispatch, projectId]);

  const project = useProject(projectId);
  const tasks = useSelector(getAllTasksFromState);

  useEffect(() => {
    dispatch(requestProjectWellboreSections(projectId));
  }, [projectId, dispatch]);

  const projectWellboreSectionsSelector = useMemo(
    () => createProjectWellboreSectionsSelector(projectId),
    [projectId],
  );

  const wellboreSections = useSelector(projectWellboreSectionsSelector);

  useEffect(() => {
    if (
      project &&
      project.toJS() &&
      project.toJS().taskServices &&
      [...tasks?.entries()].length > 0
    ) {
      tasks.forEach((task) =>
        project.toJS().taskServices.forEach((taskService) => {
          if (task.get('customTitle') === taskService.name) {
            dispatch(getToolstringsForTask(projectId, task.get('taskId')));
          }
        }),
      );
    }
  }, [project, tasks, projectId, dispatch]);

  const toolstrings = useSelector(getToolstringsByTaskFromState);

  useEffect(() => {
    toolstrings.forEach((tool) => {
      const toolObj = tool.toJS();
      const id = Object.values(toolObj)?.find(
        (toolstring) => toolstring?.isPlanned === true,
      );
      if (id) {
        setToolStringId(id?.id);
        setTaskId(id.taskId);
        setUnPlannedToolString(false);
      }
    });
  }, [toolstrings]);

  useEffect(() => {
    if (toolStringId) dispatch(getToolstring(projectId, taskId, toolStringId));
    else {
      setUnPlannedToolString(true);
    }
  }, [projectId, taskId, toolStringId, dispatch]);

  const toolstring = useSelector(createGetToolstringSelector(toolStringId));

  useEffect(() => {
    const ts = toolstring.toJS();
    if (ts.maxOD && ts.totalLength) {
      setToolsByToolstringToolId(ts.toolsByToolstringToolId);
      setBHALength(ts.totalLength);
      setMaxOD(ts.maxOD);
    }
  }, [toolstring]);

  const targetDepth = useSelector(getTargetDepthFromState);

  const onReceiveInitialData = useCallback(
    (readings) => {
      if (!curve) return;

      const reading = readings.find(
        (reading) => reading.name === curve.get('name'),
      );

      var latestDepthData = takeLast(reading.data);

      if (!latestDepthData) return;

      const [_timestamp, _depth, value] = latestDepthData;

      setCurrentDepth({
        hasValue: true,
        unit: curve.get('unit'),
        value: value,
      });
    },
    [curve, setCurrentDepth],
  );

  const onReceiveData = useCallback(
    (readings) => {
      if (!curve) return;

      const reading = readings.find(
        (reading) => reading.name === curve.get('name'),
      );

      if (!reading) return;
      if (!reading.data[0]) return;

      const [_timestamp, _depth, value] = reading.data[0];

      setCurrentDepth({
        hasValue: true,
        unit: curve.get('unit'),
        value: value,
      });
    },
    [curve],
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveInitialData, onReceiveData);
  }, [onReceiveData, onReceiveInitialData, registerDataPointsHandler]);

  if (wellboreSections && BHA_LENGTH && maxOD && toolsByToolstringToolId)
    return (
      <ThreeJsManagerProvider>
        <ProjectWellGraphTrajectoryContainer
          projectId={projectId}
          targetDepth={targetDepth}
          currentDepth={currentDepth}
          wellboreSections={wellboreSections}
          BHA_LENGTH={BHA_LENGTH}
          maxOD={maxOD}
          toolsByToolstringToolId={toolsByToolstringToolId}
        >
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2">
              Well Trajectory Not Available
            </Typography>
          </Grid>
        </ProjectWellGraphTrajectoryContainer>
      </ThreeJsManagerProvider>
    );

  if (unplannedToolString) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="body2">
            Please mark a BHA as planned from Simulations
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      xs
      item
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  );
};

WellGraphAndGuagesDataContainer.propTypes = {
  curveId: PropTypes.number,
  registerDataPointsHandler: PropTypes.func.isRequired,
};

export default WellGraphAndGuagesDataContainer;
