import { put, call, takeEvery } from 'redux-saga/effects';

import { toggleModal } from 'altus-modal';
import { callAsync } from 'app/sagas/helperSagas';

import {
  ACTIONS,
  MODAL,
} from 'features/projects/mobilisation/projectMobilisation.constants';
import projectMobilisationService from 'features/projects/mobilisation/projectMobilisation.service';
import {
  receiveProjectMobilisationTasks,
  receiveStringTestReportEvent,
  receiveStringTestReportsForTask,
} from 'features/projects/mobilisation/projectMobilisation.actions';

export function* requestMobilisationTasks(action) {
  const { projectId } = action;

  const projectMobilisationTasks = yield call(
    projectMobilisationService.getProjectMobilisationTasks,
    projectId,
  );

  yield call(
    projectMobilisationService.getProjectMobilisationToolstrings,
    projectId,
  );

  yield put(receiveProjectMobilisationTasks(projectMobilisationTasks));
}

export function* createStringTestReport(action) {
  const { projectId, taskId, stringTestReport } = action;

  yield call(
    projectMobilisationService.createStringTestReport,
    projectId,
    taskId,
    stringTestReport,
  );

  const stringTestReports = yield call(
    projectMobilisationService.getStringTestReportsForTask,
    projectId,
    taskId,
  );

  yield put(receiveStringTestReportsForTask(stringTestReports));

  yield put(toggleModal({ modalId: MODAL.STRING_TEST_REPORT_EVENT }));
}

export function* requestStringTestReportsForTask(action) {
  const { projectId, taskId } = action;

  const stringTestReports = yield call(
    projectMobilisationService.getStringTestReportsForTask,
    projectId,
    taskId,
  );

  yield put(receiveStringTestReportsForTask(stringTestReports));
}

export function* requestStringTestReportEventById(action) {
  const { projectId, taskId, stringTestReportEventId } = action;

  const event = yield call(
    projectMobilisationService.getStringTestReportEventById,
    projectId,
    taskId,
    stringTestReportEventId,
  );

  yield put(receiveStringTestReportEvent(event));
}

export function* createSecondaryStringTestReport(action) {
  const { projectId, taskId, toolstringId } = action;

  yield call(
    projectMobilisationService.createSecondaryStringTestReport,
    projectId,
    taskId,
    toolstringId,
  );

  const stringTestReports = yield call(
    projectMobilisationService.getStringTestReportsForTask,
    projectId,
    taskId,
  );

  yield put(receiveStringTestReportsForTask(stringTestReports));
}

export function* finishStringTestReport(action) {
  const { projectId, taskId, stringTestReportId, stringTestReport } = action;

  yield call(
    projectMobilisationService.finishStringTestReport,
    projectId,
    taskId,
    stringTestReportId,
    stringTestReport,
  );

  const stringTestReports = yield call(
    projectMobilisationService.getStringTestReportsForTask,
    projectId,
    taskId,
  );

  yield put(receiveStringTestReportsForTask(stringTestReports));

  yield put(toggleModal({ modalId: MODAL.STRING_TEST_REPORT_EVENT }));
}

export default function* root() {
  yield takeEvery(
    ACTIONS.REQUEST_PROJECT_MOBILISATION_TASKS,
    callAsync,
    requestMobilisationTasks,
  );

  yield takeEvery(
    ACTIONS.CREATE_STRING_TEST_REPORT,
    callAsync,
    createStringTestReport,
  );

  yield takeEvery(
    ACTIONS.REQUEST_STRING_TEST_REPORTS_FOR_TASK,
    callAsync,
    requestStringTestReportsForTask,
  );

  yield takeEvery(
    ACTIONS.REQUEST_STRING_TEST_REPORT_EVENT,
    callAsync,
    requestStringTestReportEventById,
  );

  yield takeEvery(
    ACTIONS.REQUEST_CREATE_SECONDARY_TEST_REPORT,
    callAsync,
    createSecondaryStringTestReport,
  );

  yield takeEvery(
    ACTIONS.REQUEST_FINISH_STRING_TEST_REPORT,
    callAsync,
    finishStringTestReport,
  );
}
