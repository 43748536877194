import { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportTableRow from 'features/projects/report/ReportTableRow';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import DurationComparison from 'features/projects/report/components/DurationComparison';

const ReportTable = ({
  tasks,
  classes,
  diffDuration,
  spentDuration,
  formatDuration,
  estimatedDuration,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 1,
        id: 'placeholder',
        Cell: ({ row }) => {
          const task = row.original;
          return (
            <Grid item xs={1}>
              <WorkItemStatus status={task.get('status')} />
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Task',
        accessor: (task) => task.get('title'),
      },
      {
        xs: 2,
        Header: 'Time planned',
        Cell: ({ row }) => {
          const task = row.original;
          const estDuration = estimatedDuration(task);
          return (
            <Grid item xs={2}>
              <Typography variant="body2">
                {estDuration ? formatDuration(estDuration) : null}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Time spent',
        Cell: ({ row }) => {
          const task = row.original;
          const spentTime = spentDuration(task);
          return (
            <Grid item xs={2}>
              <Typography variant="body2">
                {spentTime ? formatDuration(spentTime) : null}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Difference',
        Cell: ({ row }) => {
          const task = row.original;
          return (
            <Grid item xs={2}>
              <Typography variant="body2" className={classes.timeDifference}>
                {diffDuration(task) ? formatDuration(diffDuration(task)) : ''}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 3,
        id: 'comparison',
        Cell: ({ row }) => {
          const task = row.original;
          return (
            <Grid item xs={3}>
              <DurationComparison
                diffDuration={diffDuration(task)}
                durations={[
                  [
                    estimatedDuration(task),
                    'Time estimated',
                    classes.progressBarDisabled,
                  ],
                  [spentDuration(task), 'Time spent', classes.progressBarLess],
                  [
                    diffDuration(task),
                    'Difference',
                    classes.progressBarMore,
                    'flex-end',
                  ],
                ]}
              />
            </Grid>
          );
        },
      },
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
    ],
    [
      classes.progressBarDisabled,
      classes.progressBarLess,
      classes.progressBarMore,
      classes.timeDifference,
      diffDuration,
      estimatedDuration,
      formatDuration,
      spentDuration,
    ],
  );

  return (
    <Table
      items={tasks}
      columns={columns}
      useGlobalFilter={false}
      TableRowComponent={ReportTableRow}
      noItemsMessage="There are no tasks on this project."
    />
  );
};

const styles = (theme) => ({
  progressBarLess: {
    backgroundColor: theme.altus.status.report,
  },
  progressBarDisabled: {
    backgroundColor: theme.palette.grey[700],
  },
  progressBarMore: {
    backgroundColor: theme.palette.error.main,
  },
  timeDifference: {
    color: theme.palette.error.main,
  },
});

export default withStyles(styles)(ReportTable);
