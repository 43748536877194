import { List } from 'immutable';
import { useState, useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import mappers from 'mappers';
import { EMPTY_LIST } from 'app/app.constants';
import operationService from 'services/operation.service';
import OperationFilter from 'features/operations/components/OperationFilter';

const OperationFilterContainer = ({ organizationId, ...rest }) => {
  const [fields, setFields] = useState(EMPTY_LIST);
  const [facilities, setFacilities] = useState(EMPTY_LIST);

  const getOperationsFilter = useCallback(() => {
    const filter = {
      organizationId,
    };

    return operationService.getOperationsFilter(filter).then((filters) => {
      const { facilities, fields } = filters;

      setFields(List(fields.map(mappers.Field.from)));
      setFacilities(List(facilities.map(mappers.Facility.from)));
    });
  }, [organizationId]);

  useRequest(getOperationsFilter);

  return <OperationFilter fields={fields} facilities={facilities} {...rest} />;
};

export default OperationFilterContainer;
