import moment from 'moment';

import { Format } from 'app/app.constants';
import { EDIT_ACTIVITY_FORM as FORM_FIELDS } from 'features/projects/activities/activities.constants';

export const validateEditActivityForm = (values) => {
  const errors = {};

  const endTimeValue = values.get(FORM_FIELDS.END_TIME);
  const startTimeValue = values.get(FORM_FIELDS.START_TIME);
  const previousActivityStartTimeValue = values.get(FORM_FIELDS.PREVIOUS_ACTIVITY_START_TIME);

  const startTime = startTimeValue
    ? moment(startTimeValue, Format.datetime_local)
    : null;

  const endTime = endTimeValue
    ? moment(endTimeValue, Format.datetime_local)
    : endTimeValue;

  const previousActivityStartTime = previousActivityStartTimeValue
      ? moment(previousActivityStartTimeValue, Format.datetime_local)
      : previousActivityStartTimeValue;

  if (startTime?.isAfter(moment())) {
    errors[FORM_FIELDS.START_TIME] = 'Invalid start time';
  }

  if (endTime?.isAfter(moment())) {
    errors[FORM_FIELDS.END_TIME] = 'Invalid end time';
  }

  if (startTime && endTime && previousActivityStartTime) {
    if (startTime.isAfter(endTime)) {
      errors[FORM_FIELDS.START_TIME] = 'Start time must be before start time of following activity';
    }
    if (startTime.isBefore(previousActivityStartTime)) {
      errors[FORM_FIELDS.START_TIME] = 'Start time must be after start time of previous activity';
    }
  }

  return errors;
};
