import { compose } from 'redux';
import { memo, Fragment } from 'react';
import { Grid, Box } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import withStyles from '@material-ui/styles/withStyles';

import { UNITS } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';
import { Typography } from 'app/components/withNavigation';
import ToolDetails from 'features/projects/tool/components/ToolDetails';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';
import ToolstringPreviewEmpty from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewEmpty';
import ToolstringPreviewBreakpoint from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewBreakpoint';
import { useHighlightedToolstringItem } from 'features/projects/tasks/task/toolstring/components/ToolstringItemHighlightProvider';

const ToolAssemblyCreatorToolstringPreviewSection = ({
  classes,
  hideToolDetails,
  onToggleBreakpoint,
  toolstringSections = [],
}) => {
  const { setHighlightedItemId } = useHighlightedToolstringItem();

  if (toolstringSections.length === 0) {
    return <ToolstringPreviewEmpty />;
  }

  const toggleableBreakpoints = !!onToggleBreakpoint;

  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="column"
      classes={{ root: classes.root }}
    >
      {toolstringSections.map((toolstringSection, sectionIndex) => {
        const isLastSection = sectionIndex === toolstringSections.size - 1;
        const itemsInSection = toolstringSection.items;
        const lastSectionItem = itemsInSection[itemsInSection.length - 1];
        let toolstringLength = 0;
        itemsInSection.map((tool) => {
          return (toolstringLength += tool.length.value
            ? tool.length.value
            : tool.length / 1000);
        });
        // return null;
        return (
          <Fragment key={sectionIndex}>
            <Grid
              item
              container
              wrap="nowrap"
              className={classes.sectionContainer}
            >
              <Grid
                xs
                item
                container
                wrap="nowrap"
                justifyContent="flex-end"
                alignItems="center"
                classes={{ root: classes.section }}
              >
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    classes={{ root: classes.sectionLengthText }}
                  >
                    {`${toolstringLength.toFixed(3)} ${UNITS.METER}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3} container wrap="nowrap" direction="column">
                {itemsInSection.map((toolstringItem, index) => {
                  const tooltip = <ToolDetails tool={toolstringItem} />;
                  const toolstringItemId = toolstringItem.toolstringItemId;

                  const isLastToolInSection = index === itemsInSection.size - 1;
                  /* Put the mouse events in the grid so that the hoverable area is larger */
                  if (
                    toolstringItem.toolAssemblyTools &&
                    toolstringItem.toolAssemblyTools.length > 0
                  ) {
                    const tools = toolstringItem.toolAssemblyTools;
                    return tools.map((tool, toolIndex) => (
                      <Fragment key={toolIndex}>
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="flex-end"
                          className={classes.image}
                          onMouseEnter={() =>
                            invokeIfFunction(
                              setHighlightedItemId,
                              toolstringItemId,
                            )
                          }
                          onMouseLeave={() =>
                            invokeIfFunction(setHighlightedItemId)
                          }
                        >
                          <ToolImageContainer
                            onHoverScale
                            toolName={tool.name}
                            toolImageUrl={tool.imageUrl}
                            title={!hideToolDetails ? tooltip : undefined}
                            style={{ maxHeight: '100%' }}
                            assembly={true}
                          />
                        </Grid>
                      </Fragment>
                    ));
                  }
                  return (
                    <Fragment key={index}>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="flex-end"
                        className={classes.image}
                        key={toolstringItem.toolstringItemId}
                        onMouseEnter={() =>
                          invokeIfFunction(
                            setHighlightedItemId,
                            toolstringItemId,
                          )
                        }
                        onMouseLeave={() =>
                          invokeIfFunction(setHighlightedItemId)
                        }
                      >
                        <ToolImageContainer
                          onHoverScale
                          toolName={toolstringItem.name}
                          toolImageUrl={
                            toolstringItem.imageUrl !== undefined
                              ? toolstringItem.imageUrl
                              : toolstringItem.externalToolImage?.blobStoreUri
                          }
                          title={!hideToolDetails ? tooltip : undefined}
                          style={{
                            maxHeight: '100%',
                          }}
                        />
                      </Grid>
                      {toggleableBreakpoints && !isLastToolInSection && (
                        <ToolstringPreviewBreakpoint
                          toggleable
                          hasBreakpoint={false}
                          onToggleBreakpoint={() =>
                            onToggleBreakpoint(toolstringItem)
                          }
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
              {/** Placeholder to align the BHA */}
              <Grid xs item />
            </Grid>
            {!isLastSection && (
              <Grid item container wrap="nowrap" justifyContent="center">
                {/* This is to align "on" breakpoints with the "off" ones. Margin left is classes.section.marginRight + classes.section.paddingRight */}
                <Grid item xs={3} component={Box} marginLeft={2}>
                  <ToolstringPreviewBreakpoint
                    hasBreakpoint
                    toggleable={toggleableBreakpoints}
                    onToggleBreakpoint={() =>
                      onToggleBreakpoint(lastSectionItem)
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        );
      })}
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  image: {
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
  },
  sectionContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  section: {
    borderRight: `1px solid ${amber['A700']}`,
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -theme.spacing(0.25),
      right: -theme.spacing(1),
      width: theme.spacing(2),
      height: theme.spacing(0.25),
      borderBottom: `1px solid ${amber['A700']}`,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -theme.spacing(0.25),
      right: -theme.spacing(1),
      width: theme.spacing(2),
      height: theme.spacing(0.25),
      borderBottom: `1px solid ${amber['A700']}`,
    },
  },
  sectionLengthText: {
    color: amber['A700'],
  },
});

export default compose(
  memo,
  withStyles(styles),
)(ToolAssemblyCreatorToolstringPreviewSection);
