import { call, put } from 'redux-saga/effects';
import activitiesService from '../../../../services/activities.service';
import { WORK_ITEM_STATUS } from '../../../../app/app.constants';
import {
  ACTIVITIES_ACTIONS,
  COMPLETE_TASK_MODAL_ID,
  COMPLETE_TASK_MODE,
} from '../activities.constants';
import { replace } from 'connected-react-router/immutable';
import { toExecutionSection } from '../../../../utils/route.util';
import { getAllTask, toggleModal } from './common.sagas';

function* completeTaskDefault(projectId, values) {
  const payload = yield call(
    activitiesService.completeTask,
    projectId,
    values,
    values.objectivesMet ? WORK_ITEM_STATUS.COMPLETED : WORK_ITEM_STATUS.FAILED,
  );
  yield put({
    type: ACTIVITIES_ACTIONS.COMPLETE_TASK_DEFAULT,
    payload,
  });
  return payload;
}

function* completeTaskRepeat(projectId, values) {
  const payload = yield call(
    activitiesService.completeAndRepeatTask,
    projectId,
    values,
    WORK_ITEM_STATUS.FAILED,
  );
  yield call(getAllTask, projectId);

  yield put({
    type: ACTIVITIES_ACTIONS.COMPLETE_AND_REPEAT_TASK,
    payload,
  });
  return payload;
}

function* completeTaskWithContingency(
  projectId,
  values,
  continueWithContingencyTaskId,
) {
  const payload = yield call(
    activitiesService.completeTask,
    projectId,
    values,
    values.objectivesMet ? WORK_ITEM_STATUS.COMPLETED : WORK_ITEM_STATUS.FAILED,
    continueWithContingencyTaskId,
  );
  yield put({
    type: ACTIVITIES_ACTIONS.COMPLETE_TASK_WITH_CONTINGENCY,
    payload,
  });
  return payload;
}

function* completeTaskAndEndOperation(projectId, values) {
  const payload = yield call(
    activitiesService.completeTaskAndEndOperation,
    projectId,
    values,
    values.objectivesMet ? WORK_ITEM_STATUS.COMPLETED : WORK_ITEM_STATUS.FAILED,
  );
  yield call(getAllTask, projectId);
  yield put({
    type: ACTIVITIES_ACTIONS.COMPLETE_TASK_AND_END_OPERATION,
    payload,
  });
  return payload;
}

function* completeTaskPostOp(payload, projectId, connection) {
  const { currentTask, previousTask } = payload;
  if (previousTask && currentTask) {
    const { taskId } = currentTask;
    if (taskId) {
      yield put(replace(toExecutionSection(projectId, taskId, 'activities')));
      yield call(
        activitiesService.sendProjectChangedNotification,
        connection,
        projectId,
        taskId,
      );
      yield call(toggleModal, { modalId: COMPLETE_TASK_MODAL_ID });
    }
  }
  yield put({
    type: ACTIVITIES_ACTIONS.COMPLETE_TASK,
    payload,
  });
}

export function* completeTask(action) {
  const {
    projectId,
    completeTaskMode,
    continueWithContingencyTaskId,
    values,
    connection,
  } = action;

  let payload;
  switch (completeTaskMode) {
    case COMPLETE_TASK_MODE.REPEAT:
      payload = yield call(completeTaskRepeat, projectId, values);
      yield call(completeTaskPostOp, payload, projectId, connection);
      break;
    case COMPLETE_TASK_MODE.END_OPERATION:
      payload = yield call(completeTaskAndEndOperation, projectId, values);
      yield call(completeTaskPostOp, payload, projectId, connection);
      break;
    case COMPLETE_TASK_MODE.CONTINUE_WITH_CONTINGENCY:
      payload = yield call(
        completeTaskWithContingency,
        projectId,
        values,
        continueWithContingencyTaskId,
      );
      yield call(completeTaskPostOp, payload, projectId, connection);
      break;
    default:
      payload = yield call(completeTaskDefault, projectId, values);
      yield call(completeTaskPostOp, payload, projectId, connection);
      break;
  }

  return payload;
}
