import { Field } from 'redux-form/immutable';
import { Switch, FormControlLabel, Grid } from '@material-ui/core';

import { EDIT_PROJECT_FORM } from 'features/projects/details/details.constants';
import ProjectNumberField from 'features/projects/details/components/ProjectNumberField';

const ProjectNumber = ({ hasPermission, onChange, disabled }) => (
  <Grid item container>
    <Grid item xs={3}>
      <Field
        updateProject={onChange}
        hasPermission={hasPermission}
        component={ProjectNumberField}
        disabled={disabled}
        name={EDIT_PROJECT_FORM.DISPLAY_REFERENCE_NUMBER}
      />
    </Grid>
    <FormControlLabel
      label="M3"
      labelPlacement="end"      
      control={
        <Field
          format={null}
          onChange={onChange}          
          name={EDIT_PROJECT_FORM.DISPLAY_REFERENCE_NUMBER}
          component={({ input }) => (
            <Switch
              color="primary"
              checked={!!input.value}
              onChange={input.onChange}
              disabled={disabled}
            />
          )}
        />
      }
    />
  </Grid>
);

export default ProjectNumber;
