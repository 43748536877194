import { compose } from 'redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Field, change } from 'redux-form/immutable';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Paper, InputAdornment } from '@material-ui/core';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import { isEqual } from 'lodash';

import { TASK_FORMS, TaskType } from 'features/projects/tasks/tasks.constants';

import TextField from 'app/components/form/TextField';
import DepartmentSelectField from 'app/components/DepartmentSelectField';
import TaskDetails from 'features/projects/tasks/components/TaskDetails';
import TaskTypeSelect from 'features/projects/tasks/components/TaskTypeSelect';
import QuantityTextFieldReduxForm from 'app/components/form/redux-form/QuantityTextFieldReduxForm';
import TaskMultipleContingenciesSelect from 'features/projects/tasks/task/details/components/TaskMultipleContingenciesSelect';
import useTaskTypes from 'features/projects/tasks/hooks/useTaskTypes';
import RiskSectionsTable from 'features/projects/components/RiskSectionsTable';

import { Field as FieldFormik, Formik } from 'formik';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { getRichEditorText } from 'utils/app.util';
import './styles.css';
import { useHandleToolbarHeight } from 'features/projects/tasks/hooks/useHandleToolbarHeight';

const { EDIT_TASK } = TASK_FORMS;

const EditTaskForm = ({
  task,
  tasks,
  classes,
  copyTask,
  disabled,
  projectId,
  updateTask,
  deleteTask,
  departments,
  taskRiskboreSections,
  createTaskRiskSection,
  updateTaskRiskSection,
  deleteTaskRiskSection,
  defaultTaskRiskSection,
  currentTaskId,
}) => {
  const dispatch = useDispatch();
  const type = task.get('type');
  const taskId = task.get('taskId');
  const currentId = currentTaskId ?? taskId;

  const [rawObjectiveJson, setRawObjectiveJson] = useState();
  const [rawDescriptionJson, setRawDescriptionJson] = useState();
  const [rawCommentsJson, setRawCommentsJson] = useState();
  const [changeFlag, setChangeFlag] = useState(false);
  const [objectiveEditorState, setObjectiveEditorState] = useState(
    EditorState.createEmpty(),
  );
  const [descriptionEditorState, setDescriptionEditorState] = useState(
    EditorState.createEmpty(),
  );
  const [commentsEditorState, setCommentsEditorState] = useState(
    EditorState.createEmpty(),
  );

  const updateContent = useCallback(
    (content, setContent, setEditorState, setContentState) => {
      if (task.get(content) && !setContent) {
        const rawContentState = JSON.parse(task.get(content));
        const newEditorState = EditorState.createWithContent(
          convertFromRaw(rawContentState),
        );
        setEditorState(newEditorState);
        setContentState(task.get(content));
      }
    },
    [task],
  );

  const updateValues = (values, key, rawJson, setRawJson, changeValue) => {
    const rawValue = convertToRaw(values[key].getCurrentContent());
    const JSONValue = JSON.stringify(rawValue);
    const oldTextValue = getRichEditorText(task.get(key));
    const newTextValue = getRichEditorText(JSONValue);

    if (
      !isEqual(JSONValue, task.get(key)) &&
      !isEqual(oldTextValue, newTextValue) &&
      newTextValue &&
      taskId === Number(currentId)
    ) {
      setRawJson(JSONValue);
      changeValue(JSONValue);
      setChangeFlag(!changeFlag);
    }
  };

  useEffect(() => {
    if (rawDescriptionJson || rawObjectiveJson || rawCommentsJson)
      updateTask({
        objective: rawObjectiveJson,
        description: rawDescriptionJson,
        comments: rawCommentsJson,
      });
    // eslint-disable-next-line
  }, [changeFlag]);

  useEffect(() => {
    updateContent(
      'objective',
      rawObjectiveJson,
      setObjectiveEditorState,
      setRawObjectiveJson,
    );
  }, [task, rawObjectiveJson, updateContent]);

  useEffect(() => {
    updateContent(
      'description',
      rawDescriptionJson,
      setDescriptionEditorState,
      setRawDescriptionJson,
    );
  }, [task, rawDescriptionJson, updateContent]);

  useEffect(() => {
    updateContent(
      'comments',
      rawCommentsJson,
      setCommentsEditorState,
      setRawCommentsJson,
    );
  }, [task, rawCommentsJson, updateContent]);

  const changeObjective = (newValue) => {
    dispatch(change(EDIT_TASK.TASK_OBJECTIVE, 'objective', newValue));
  };

  const changeDescription = (newValue) => {
    dispatch(change(EDIT_TASK.TASK_DESCRIPTION, 'description', newValue));
  };

  const changeComments = (newValue) => {
    dispatch(change(EDIT_TASK.TASK_COMMENTS, 'comments', newValue));
  };

  const initialFormValues = {
    [EDIT_TASK.TASK_OBJECTIVE]: objectiveEditorState,
    [EDIT_TASK.TASK_DESCRIPTION]: descriptionEditorState,
    [EDIT_TASK.TASK_COMMENTS]: commentsEditorState,
  };

  const getRawJson = (key, values) => {
    if (values) {
      const rawValue = convertToRaw(values[key].getCurrentContent());
      const JSONValue = JSON.stringify(rawValue);
      const oldTextValue = getRichEditorText(task.get(key));
      const newTextValue = getRichEditorText(JSONValue);

      if (
        !isEqual(oldTextValue, newTextValue) &&
        newTextValue &&
        taskId === Number(currentId)
      ) {
        return JSONValue;
      }
    }

    return task.get(key);
  };

  const onSubmitObjective = (values) => {
    updateValues(
      values,
      'objective',
      rawObjectiveJson,
      setRawObjectiveJson,
      changeObjective,
    );
  };
  const onSubmitDescription = (values) => {
    updateValues(
      values,
      'description',
      rawDescriptionJson,
      setRawDescriptionJson,
      changeDescription,
    );
  };
  const onSubmitComments = (values) => {
    updateValues(
      values,
      'comments',
      rawCommentsJson,
      setRawCommentsJson,
      changeComments,
    );
  };

  const callUpdateTask = () => {
    const objectiveValue = getRawJson('objective');
    const descriptionValue = getRawJson('description');
    const commentsValue = getRawJson('comments');
    updateTask({
      objective: objectiveValue,
      description: descriptionValue,
      comments: commentsValue,
    });
  };

  const toolbarHeight = useHandleToolbarHeight();

  const [taskTypes, taskTypesDataState] = useTaskTypes();

  return (
    <Paper square className={classes.paper}>
      <Grid container spacing={2}>
        <TaskDetails
          task={task}
          displayProject
          copyTask={copyTask}
          projectId={projectId}
          deleteTask={deleteTask}
          taskId={taskId}
          displayNavigationBtn={false}
        />
        <Grid container item xs={12} spacing={2} justifyContent="space-between">
          <Grid item xs={6} spacing={2}>
            <Field
              label="Title"
              disabled={disabled}
              onChange={callUpdateTask}
              component={TextField}
              name={EDIT_TASK.TASK_CUSTOM_TITLE}
            />
          </Grid>
          <Grid item xs={6} spacing={2}>
            <TaskTypeSelect
              onChange={callUpdateTask}
              taskTypes={taskTypes}
              name={EDIT_TASK.TASK_TYPE}
              disabled={disabled || taskTypesDataState.isLoading()}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} justifyContent="space-between">
          <Grid item xs={6} spacing={2}>
            <Field
              task={task}
              tasks={tasks}
              disabled={disabled}
              label="Contingencies"
              onChange={callUpdateTask}
              name={EDIT_TASK.TASK_CONTINGENCIES}
              component={TaskMultipleContingenciesSelect}
            />
          </Grid>
          <Grid item xs={6} spacing={2}>
            {type === TaskType.SUB_SURFACE && (
              <Grid container item spacing={12}>
                <Grid container item xs={12}>
                  <Field
                    format={null}
                    disabled={disabled}
                    onChange={callUpdateTask}
                    label="TD - Target Depth"
                    type="number"
                    component={QuantityTextFieldReduxForm}
                    name={EDIT_TASK.TASK_TARGET_DEPTH}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2} justifyContent="space-between">
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            onSubmit={onSubmitDescription}
          >
            <AutoSaveFormik>
              <FieldFormik name={EDIT_TASK.TASK_DESCRIPTION}>
                {({ form, ...formik }) => (
                  <EditorFormik
                    form={form}
                    {...formik}
                    toolbar={{
                      options: ['inline', 'list'],
                    }}
                    toolbarOnFocus
                    toolbarHeight={toolbarHeight}
                    label="Description"
                    minHeight={7}
                    maxHeight={7}
                  />
                )}
              </FieldFormik>
            </AutoSaveFormik>
          </Formik>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            onSubmit={onSubmitObjective}
          >
            <AutoSaveFormik>
              <FieldFormik name={EDIT_TASK.TASK_OBJECTIVE}>
                {({ form, ...formik }) => (
                  <EditorFormik
                    form={form}
                    {...formik}
                    toolbar={{
                      options: ['inline', 'list'],
                    }}
                    toolbarOnFocus
                    toolbarHeight={toolbarHeight}
                    label="Objective"
                    minHeight={7}
                    maxHeight={7}
                  />
                )}
              </FieldFormik>
            </AutoSaveFormik>
          </Formik>
        </Grid>

        <Grid container item xs={6} justifyContent="space-between">
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Field
                type="number"
                disabled={true}
                component={TextField}
                label={'Estimated Runtime'}
                name={EDIT_TASK.TASK_PLANNED_DURATION.HOURS}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Hours</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                label=" "
                type="number"
                disabled={true}
                component={TextField}
                name={EDIT_TASK.TASK_PLANNED_DURATION.MINUTES}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Min.</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <RiskSectionsTable
            createRiskSection={createTaskRiskSection}
            updateRiskSection={updateTaskRiskSection}
            deleteRiskSection={deleteTaskRiskSection}
            defaultRiskSection={defaultTaskRiskSection}
            riskSections={taskRiskboreSections}
          />
        </Grid>
        <Grid container item xs={12} spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <Field
              format={null}
              label="Departments"
              disabled={disabled}
              onChange={callUpdateTask}
              departments={departments}
              component={DepartmentSelectField}
              name={EDIT_TASK.TASK_DEPARTMENTS}
            />
          </Grid>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            onSubmit={onSubmitComments}
          >
            <AutoSaveFormik>
              <FieldFormik name={EDIT_TASK.TASK_COMMENTS}>
                {({ form, ...formik }) => (
                  <EditorFormik
                    form={form}
                    {...formik}
                    toolbar={{
                      options: ['inline', 'list'],
                    }}
                    toolbarOnFocus
                    toolbarHeight={toolbarHeight}
                    label="Comments"
                    minHeight={7}
                    maxHeight={7}
                    disabled={disabled}
                  />
                )}
              </FieldFormik>
            </AutoSaveFormik>
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = (theme) => ({
  content: {
    height: 'auto',
  },
  progressBarLess: {
    backgroundColor: theme.altus.status.report,
  },
  progressBarDisabled: {
    backgroundColor: theme.palette.grey[700],
  },
  progressBarMore: {
    backgroundColor: theme.palette.error.main,
  },
  paper: {
    padding: theme.spacing(2.5),
    background: theme.altus.background.panel,
  },
});

export default compose(withStyles(styles))(EditTaskForm);
