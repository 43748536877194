import moment from 'moment';
import { initialize } from 'redux-form/immutable';
import { replace } from 'connected-react-router/immutable';

import {
  ACTIVITIES_ACTIONS as ACTIONS,
  ACTIVITIES_ACTIONS,
  COMPLETE_TASK_FORM,
  COMPLETE_TASK_MODAL_ID,
  CREATE_ACTIVITY_FORM,
  CREATE_ACTIVITY_MODAL_ID,
  CREATE_TIMER_ENTRY_MODAL_ID,
  CREATE_TIMER_FORM,
  EDIT_ACTIVITY_FORM,
  EDIT_ACTIVITY_MODAL_ID,
  EDIT_TIMER_ENTRY_MODAL_ID,
  EDIT_TIMER_FORM,
  END_OPERATION_FORM,
  END_OPERATION_MODAL_ID,
} from 'features/projects/activities/activities.constants';

import { Format, NOTIFICATION_VARIANTS } from 'app/app.constants';

import { toggleModal } from 'app/app.actions';
import taskService from 'services/task.service';
import { toExecutionSection } from 'utils/route.util';
import projectsService from 'services/project.service';
import activitiesService from 'services/activities.service';
import { formatDate, formatDuration } from 'utils/format.util';
import { getFormValuesFromState } from 'app/app.selectors';
import { durationFromStartAndEndTime } from 'utils/app.util';
import activitiesMappers from 'features/projects/activities/activities.mappers';
import { useViewMode } from 'app/hooks/useViewMode';

export const onLoad = (projectId) => (dispatch) => {
  dispatch({
    type: ACTIVITIES_ACTIONS.ACTIVITIES_INITIALIZED,
    payload: () =>
      Promise.all([
        dispatch(getAllTasks(projectId)),
        dispatch(getProjectTimers(projectId)),
        dispatch(getProjectStatusLite(projectId)),
      ]),
  });
};

export const getAllActivityTypes = () => (dispatch) => {
  const payload = activitiesService.getAllActivityTypes();

  dispatch({
    type: ACTIVITIES_ACTIONS.GET_ALL_ACTIVITY_TYPES,
    payload,
  });

  return payload;
};

export const getAllTasks = (projectId) => (dispatch) => {
  const payload = taskService.getAllTasks(projectId);

  dispatch({
    payload,
    type: ACTIVITIES_ACTIONS.GET_ALL_TASKS,
  });

  return payload;
};

export const getAllTasksWithProjects = (projectId) => (dispatch) => {
  const payload = taskService.getAllTasksWithProjects(projectId);

  dispatch({
    payload,
    type: ACTIVITIES_ACTIONS.GET_ALL_TASKS,
  });

  return payload;
};

export const redirectToTask = (projectId, taskId) => (dispatch) => {
  dispatch(replace(toExecutionSection(projectId, taskId, 'activities')));
};

export const onUnload = () => (dispatch) => {
  dispatch({ type: ACTIVITIES_ACTIONS.ACTIVITIES_UNINITIALIZED });
};

export const activityListOnLoad = (projectId, taskId) => (dispatch) => {
  const payload = dispatch(getTaskActivities(projectId, taskId));

  dispatch({
    taskId,
    type: ACTIVITIES_ACTIONS.ACTIVITY_LIST_LOADED,
    payload,
  });

  return payload;
};

export const activityListOnUnload = () => (dispatch) => {
  dispatch({
    type: ACTIVITIES_ACTIONS.ACTIVITY_LIST_UNLOADED,
  });
};

export const selectActivity = (index) => (dispatch) =>
  dispatch(
    toggleModal({ modalId: EDIT_ACTIVITY_MODAL_ID, activeIndex: index }),
  );

export const updateActivity =
  (projectId, taskId, activity, connection) => (dispatch) => {
    dispatch({
      type: ACTIVITIES_ACTIONS.INITIATE_UPDATE_ACTIVITY,
      projectId,
      taskId,
      activity,
      connection,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'The activity was successfully updated',
      },
    });
  };

export const getActivityPdf = (projectId, taskId, activityId) => (dispatch) => {
  dispatch({
    type: ACTIVITIES_ACTIONS.DOWNLOAD_ACTIVITY_PDF,
    projectId,
    taskId,
    activityId,
  });
};

export const updateActivityTimes =
  (projectId, taskId, autosave, values, formikPrimitives, connection) =>
  (dispatch) => {
    dispatch({
      type: ACTIVITIES_ACTIONS.INITIATE_UPDATE_ACTIVITY_TIMES,
      projectId,
      taskId,
      autosave,
      values,
      formikPrimitives,
      connection,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'The activity was successfully updated',
        [NOTIFICATION_VARIANTS.ERROR]: 'Failed to update activity times',
      },
    });
  };

export const updateActivityFormOnSubmitWithNotification =
  (connection) =>
  (projectId, taskId, autosave, reset, callback) =>
  (dispatch) => {
    dispatch({
      type: ACTIVITIES_ACTIONS.INITIATE_UPDATE_ACTIVITY_FORM_WITH_NOTIFICATION,
      projectId,
      taskId,
      autosave,
      callback,
      connection,
      reset,
    });
  };

export const updateActivityFormOnSubmitWithoutNotification =
  (projectId, taskId, autosave, callback) => (dispatch) => {
    dispatch({
      type: ACTIVITIES_ACTIONS.INITIATE_UPDATE_ACTIVITY_FORM_WITHOUT_NOTIFICATION,
      projectId,
      taskId,
      autosave,
      callback,
    });
  };

const confirmDeleteActivityWithBase =
  (actionType) =>
  (projectId, taskId, activityId, index, connection) =>
  (dispatch) => {
    dispatch({
      type: actionType,
      projectId,
      taskId,
      activityId,
      index,
      connection,
    });
  };
const confirmDeleteActivityWithNotification = confirmDeleteActivityWithBase(
  ACTIVITIES_ACTIONS.INITIATE_CONFIRM_DELETE_ACTIVITY_WITH_NOTIFICATION,
);
const confirmDeleteActivityWithoutNotification = confirmDeleteActivityWithBase(
  ACTIVITIES_ACTIONS.INITIATE_CONFIRM_DELETE_ACTIVITY_WITHOUT_NOTIFICATION,
);

const deleteActivityBase =
  (confirmDeleteActionFn) =>
  (projectId, taskId, activityId, index, connection) =>
  (dispatch) =>
    dispatch({
      type: ACTIVITIES_ACTIONS.PROJECT_ACTIVITIES_DELETE_ACTIVITY,
      confirmationDialog: {
        description: 'Are you sure you want to delete this activity?',
        title: 'Delete activity',
      },
      payload: () =>
        dispatch(
          confirmDeleteActionFn(
            projectId,
            taskId,
            activityId,
            index,
            connection,
          ),
        ),
    });

export const deleteActivityWithNotification = deleteActivityBase(
  confirmDeleteActivityWithNotification,
);
export const deleteActivityNoNotification = deleteActivityBase(
  confirmDeleteActivityWithoutNotification,
);

export const sortActivitiesBase =
  (actionType) => (projectId, taskId, indices, connection) => (dispatch) => {
    const { oldIndex, newIndex } = indices;
    dispatch({
      type: actionType,
      projectId,
      taskId,
      oldIndex,
      newIndex,
      connection,
    });
  };

// This function will emit msg to SignalR hub
export const sortActivitiesWithNotification = sortActivitiesBase(
  ACTIVITIES_ACTIONS.INITIATE_SORT_ACTIVITIES,
);
// Default version, no message to SignalR hub
export const sortActivities = sortActivitiesBase(
  ACTIVITIES_ACTIONS.INITIATE_SORT_ACTIVITIES_NO_NOTIFICATION,
);

// Connection being SignalR connection
export const handleEstablishingConnectionError = () => (dispatch) => {
  dispatch({
    type: ACTIVITIES_ACTIONS.ESTABLISHING_REAL_TIME_CONNECTION_FAILED,
  });
};

export const onEnterEditActivityModal = (activity) => (dispatch) => {
  const startTime = activity.startTime;
  const endTime = activity.endTime;
  const duration =
    startTime &&
    endTime &&
    formatDuration(
      durationFromStartAndEndTime(startTime, endTime),
      Format.duration_short,
    );

  dispatch(
    initialize(EDIT_ACTIVITY_FORM.ID, {
      ...activity,
      [EDIT_ACTIVITY_FORM.START_TIME]: formatDate(
        startTime,
        Format.datetime_local,
      ),
      [EDIT_ACTIVITY_FORM.END_TIME]: formatDate(endTime, Format.datetime_local),
      [EDIT_ACTIVITY_FORM.EDITED_DURATION]: duration ? duration : null,
    }),
  );
};

export const toggleCreateActivityModal = (mode, sequence) => (dispatch) => {
  dispatch(
    toggleModal({
      modalId: CREATE_ACTIVITY_MODAL_ID,
      payload: () =>
        dispatch(
          initialize(CREATE_ACTIVITY_FORM.ID, {
            [CREATE_ACTIVITY_FORM.MODE]: mode,
            // create new activities after the current, if any
            [CREATE_ACTIVITY_FORM.SEQUENCE]: sequence + 1,
          }),
        ),
    }),
  );
};

export const createActivityBase =
  (actionType) => (taskId, connection) => (dispatch) => (values) => {
    dispatch({
      type: actionType,
      taskId,
      connection,
      values,
    });
  };

export const duplicateActivityBase =
  (actionType) => (taskId, connection, values) => (dispatch) => {
    dispatch({
      type: actionType,
      taskId,
      connection,
      values,
    });
  };

export const createActivity = createActivityBase(
  ACTIONS.INITIATE_CREATE_ACTIVITY_WITHOUT_NOTIFICATION,
);
export const createActivityWithNotification = createActivityBase(
  ACTIONS.INITIATE_CREATE_ACTIVITY_WITH_NOTIFICATION,
);
export const duplicateActivityWithNotification = duplicateActivityBase(
  ACTIONS.PROJECT_ACTIVITIES_DUPLICATE_ACTIVITY,
);

export const startOperation = (allTaskActivities) => {
  return {
    type: ACTIVITIES_ACTIONS.START_OPERATION,
    payload: allTaskActivities,
  };
};

export const startOperationInitiate = (projectId, taskId) => (dispatch) => {
  dispatch({
    taskId,
    projectId,
    type: ACTIVITIES_ACTIONS.START_OPERATION_INITIATE,
  });
};

export const completeActivity =
  (projectId, taskId, activityId, connection) => (dispatch) => {
    dispatch({
      type: ACTIVITIES_ACTIONS.INITIATE_COMPLETE_ACTIVITY,
      projectId,
      taskId,
      activityId,
      connection,
    });
  };

/// Handling same functionality as completeTask but by using redux-saga
/// This is the new way of handling this functionality
/// For now only default case (completeTaskDefault) is implemented.
export const completeTask =
  (
    projectId,
    completeTaskMode,
    continueWithContingencyTaskId,
    values,
    connection,
  ) =>
  (dispatch) => {
    dispatch({
      type: ACTIVITIES_ACTIONS.INITATE_COMPLETE_TASK,
      projectId,
      completeTaskMode,
      continueWithContingencyTaskId,
      values,
      connection,
    });
  };

export const setProjectStatus = (status) => (dispatch) => {
  dispatch(setProjectStatusAsPlainAction(status));
  return status;
};

export const setProjectStatusAsPlainAction = (status) => {
  return {
    type: ACTIVITIES_ACTIONS.GET_PROJECT_STATUS,
    payload: status,
  };
};

export const getProjectStatusLite = (projectId) => (dispatch) => {
  const payload = projectsService.getProjectStatusLite(projectId);
  const viewMode = useViewMode();
  if (viewMode === 'mobile')
    dispatch({
      type: ACTIVITIES_ACTIONS.GET_PROJECT_STATUS_MOBILE,
      payload,
    });
  else
    dispatch({
      type: ACTIVITIES_ACTIONS.GET_PROJECT_STATUS,
      payload,
    });

  return payload;
};

export const getProjectStatus = (projectId) => (dispatch) => {
  const payload = projectsService.getProjectStatus(projectId);

  dispatch({
    type: ACTIVITIES_ACTIONS.GET_PROJECT_STATUS,
    payload,
  });

  return payload;
};

export const refreshProjectStatus = (projectId, taskId) => (dispatch) => {
  projectsService.getProjectStatusLite(projectId).then((status) => {
    return dispatch(
      getTaskActivities(projectId, taskId, { silent: true }),
    ).then(() => dispatch(setProjectStatus(status)));
  });

  dispatch({
    type: ACTIVITIES_ACTIONS.REFRESH_PROJECT_STATUS,
  });
};

export const toggleCompleteTaskModal = (objectivesMet, task) => (dispatch) => {
  dispatch(
    toggleModal({
      modalId: COMPLETE_TASK_MODAL_ID,
      payload: () =>
        dispatch(
          initialize(COMPLETE_TASK_FORM.ID, {
            [COMPLETE_TASK_FORM.OBJECTIVES_MET]: objectivesMet,
            [COMPLETE_TASK_FORM.TASK_ID]: task.id,
            [COMPLETE_TASK_FORM.COMMENTS]: task.comments,
          }),
        ),
    }),
  );
};

export const toggleEndOperationModal = (isProjectSuccessful) => (dispatch) => {
  dispatch(
    toggleModal({
      modalId: END_OPERATION_MODAL_ID,
      payload: () =>
        dispatch(
          initialize(END_OPERATION_FORM.ID, {
            [END_OPERATION_FORM.IS_SUCCESSFUL]: isProjectSuccessful,
          }),
        ),
    }),
  );
};

export const getAllWaitingActivityTypes = () => (dispatch) => {
  const payload = activitiesService.getAllWaitingActivityTypes();

  dispatch({
    type: ACTIVITIES_ACTIONS.GET_WAITING_ACTIVITY_TYPES,
    payload,
  });

  return payload;
};

export const getAllPointInTimeActivityTypes = () => (dispatch) => {
  const payload = activitiesService.getAllPointInTimeActivityTypes();

  dispatch({
    type: ACTIVITIES_ACTIONS.GET_POINT_IN_TIME_ACTIVITY_TYPES,
    payload,
  });

  return payload;
};

export const createNewPointInTimeActivity =
  (projectId, taskId, activityId, activityTypeId, connection) => (dispatch) => {
    dispatch({
      projectId,
      taskId,
      activityId,
      activityTypeId,
      connection,
      type: ACTIVITIES_ACTIONS.INITIATE_CREATE_NEW_POINT_IN_TIME_ACTIVITY,
    });
  };

export const pauseActivity =
  (projectId, taskId, activityId, activityTypeId, connection) => (dispatch) => {
    dispatch({
      type: ACTIONS.INITIATE_PAUSE_ACTIVITY,
      projectId,
      taskId,
      activityId,
      activityTypeId,
      connection,
    });
  };

export const completeActivityAbortTask =
  (projectId, taskId, activityId, connection) => (dispatch) => {
    const payload = activitiesService
      .completeActivityAbortTask(projectId, taskId, activityId)
      .then((status) => dispatch(setProjectStatus(status)))
      .then((status) =>
        activitiesService
          .sendProjectChangedNotification(connection, projectId, taskId)
          .then(() => status),
      );

    dispatch({
      taskId,
      payload,
      projectId,
      type: ACTIVITIES_ACTIONS.COMPLETE_ACTIVITY_ABORT_TASK,
    });

    return payload;
  };

export const endOperation = (projectId) => (dispatch, getState) => {
  const values = getFormValuesFromState(
    getState(),
    END_OPERATION_FORM.ID,
  ).toJS();

  dispatch({
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Project successfully completed',
    },
    confirmationDialog: {
      description:
        'Are you sure you want to complete this project? This will change the project status to Report and cannot be undone',
      title: 'Are you sure?',
      confirmButtonText: 'Yes, complete project',
      onCancel: toggleModal({
        modalId: END_OPERATION_MODAL_ID,
      }),
    },
    type: ACTIVITIES_ACTIONS.END_OPERATION,
    payload: () => activitiesService.endOperation(projectId, values),
  });
};

export const goBack =
  (projectId, previousCurrentTaskId, connection) => (dispatch) => {
    dispatch({
      projectId,
      connection,
      previousCurrentTaskId,
      type: ACTIVITIES_ACTIONS.CONFIRM_ACTIVITY_GO_BACK,
    });
  };

export const onEnterEditTimerModal = (timer) => (dispatch) => {
  const startTime = timer.startTime;
  const endTime = timer.endTime;
  const duration =
    startTime &&
    formatDuration(
      durationFromStartAndEndTime(startTime, endTime),
      Format.duration_short,
    );

  dispatch(
    initialize(EDIT_TIMER_FORM.ID, {
      ...timer,
      [EDIT_TIMER_FORM.START_TIME]: formatDate(
        startTime,
        Format.datetime_local,
      ),
      [EDIT_TIMER_FORM.END_TIME]: formatDate(endTime, Format.datetime_local),
      [EDIT_TIMER_FORM.EDITED_DURATION]: duration ? duration : null,
    }),
  );
};

export const onEnterCreateTimerModal = (typeId) => (dispatch) => {
  const now = moment();

  dispatch(
    initialize(CREATE_TIMER_FORM.ID, {
      [CREATE_TIMER_FORM.TIMER_TYPE]: typeId,
      [CREATE_TIMER_FORM.START_TIME]: formatDate(now, Format.datetime_local),
      [CREATE_TIMER_FORM.END_TIME]: formatDate(now, Format.datetime_local),
      [CREATE_TIMER_FORM.DURATION]: formatDuration(moment.duration()),
    }),
  );
};

export const editTimer = (projectId, timer) => (dispatch) => {
  const timerJS = timer.toJS();
  const payload = activitiesService
    .updateProjectTimer(projectId, timerJS)
    .then(() => dispatch(getProjectTimers(projectId)))
    .then((response) =>
      dispatch(
        toggleModal({ modalId: EDIT_TIMER_ENTRY_MODAL_ID, payload: response }),
      ),
    );

  dispatch({
    id: timerJS.id,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The timer was successfully updated',
    },
    type: ACTIVITIES_ACTIONS.EDIT_TIMER,
    payload,
  });

  return payload;
};

export const createTimer = (projectId, timer) => (dispatch) => {
  const timerJS = timer.toJS();
  const payload = activitiesService
    .createProjectTimer(projectId, timerJS)
    .then(() => dispatch(getProjectTimers(projectId)))
    .then((response) =>
      dispatch(
        toggleModal({
          modalId: CREATE_TIMER_ENTRY_MODAL_ID,
          payload: response,
        }),
      ),
    );

  dispatch({
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'A new entry was successfully created',
    },
    type: ACTIVITIES_ACTIONS.CREATE_TIMER,
    payload,
  });
  return payload;
};

export const getProjectActivities = (projectId) => (dispatch) => {
  const payload = activitiesService.allActivitiesForProject(projectId);

  dispatch({
    type: ACTIVITIES_ACTIONS.GET_PROJECT_ACTIVITIES,
    payload,
  });

  return payload;
};

export const getAllProjectActivities = (projectId) => (dispatch) => {
  const payload = activitiesService.allActivitiesForProject(projectId);

  dispatch({
    type: ACTIVITIES_ACTIONS.GET_ALL_PROJECT_ACTIVITIES,
    payload,
  });

  return payload;
};

export const getTaskActivities = (projectId, taskId) => (dispatch) => {
  const payload = activitiesService.allActivitiesForTask(projectId, taskId);

  dispatch({
    taskId,
    payload,
    type: ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
  });

  return payload;
};

/**
 * Proper name should be getTaskActivities but that is already used in previous method
 * @param taskId
 * @param payload
 * @return {{payload, type: string, taskId}}
 */
export const getTaskActivitiesDispatchOnly = (taskId, payload) => {
  return {
    taskId,
    payload,
    type: ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
  };
};

export const getProjectTimers = (projectId) => (dispatch) => {
  const payload = activitiesService.getAllProjectTimers(projectId);

  dispatch({
    type: ACTIVITIES_ACTIONS.GET_PROJECT_TIMERS,
    payload,
  });

  return payload;
};

export const updateActivitySetOrRetrieveItems =
  (projectId, taskId, updateData) => (dispatch) => {
    const mappedData = updateData.map((data) =>
      activitiesMappers.UpdateActivitySetOrRetrieveItems.to(data),
    );

    const payload = activitiesService
      .updateActivitySetOrRetrieveItems(projectId, taskId, mappedData)
      .then(() => dispatch(getTaskActivities(projectId, taskId)));

    dispatch({
      type: ACTIVITIES_ACTIONS.UPDATE_ACTIVITY_SET_OR_RETRIEVE_ITEMS,
      payload,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Updating...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'The activity was successfully updated',
        [NOTIFICATION_VARIANTS.ERROR]: 'Failed to update activity',
      },
    });

    return payload;
  };

export const deleteActivitySetOrRetrieveItems =
  (projectId, taskId, activityId) => (dispatch) => {
    const payload = activitiesService.deleteActivitySetOrRetrieveItems(
      projectId,
      taskId,
      activityId,
    );

    dispatch({
      type: ACTIVITIES_ACTIONS.DELETE_ACTIVITY_SET_OR_RETRIEVE_ITEMS,
      payload,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Updating...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'The activity was successfully updated',
        [NOTIFICATION_VARIANTS.ERROR]: 'Failed to update activity',
      },
    });

    return payload;
  };
