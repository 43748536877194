import classNames from 'classnames';
import withStyles from '@material-ui/styles/withStyles';
import { Fab, Slide, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { SLIDE_DIRECTIONS } from 'app/app.constants';

const styles = () => ({
  relative: {
    position: 'relative',
    outline: 'none',
  },
  previousNext: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  next: {
    right: -70,
  },
  previous: {
    left: -70,
  },
  index: {
    color: 'white',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  buttonLabel: {
    position: 'absolute',
    bottom: -25,
  },
});

const resolveSlideDirection = (direction, open, fullScreen) => {
  if (fullScreen) return 'up';

  switch (direction) {
    case SLIDE_DIRECTIONS.NEXT:
      return open ? 'left' : 'right';
    case SLIDE_DIRECTIONS.PREVIOUS:
      return open ? 'right' : 'left';

    default:
      return 'left';
  }
};

const Transition = ({
  item,
  index,
  onNext,
  modalId,
  classes,
  noOfItems,
  nextLabel,
  direction,
  fullScreen,
  onPrevious,
  activeIndex,
  NextBtnProps,
  previousLabel,
  dispatchOnNext,
  PreviousBtnProps,
  dispatchOnPrevious,
  onRightLeftKeyDown,
  nextPreviousDirection,
  ...rest
}) => {
  const Slider = (
    <Slide
      {...rest}
      direction={
        direction ||
        resolveSlideDirection(nextPreviousDirection, rest.in, fullScreen)
      }
    />
  );

  if (!fullScreen) {
    const displayNextBtn = activeIndex >= 0 && activeIndex < noOfItems - 1;
    const displayPrevBtn = activeIndex > 0;

    const focusOnNext =
      !displayPrevBtn ||
      !nextPreviousDirection ||
      nextPreviousDirection === SLIDE_DIRECTIONS.NEXT;
    const focusOnPrev =
      !displayNextBtn || nextPreviousDirection === SLIDE_DIRECTIONS.PREVIOUS;

    return (
      <div className={classes.relative}>
        {displayPrevBtn && (
          <Fab
            color="primary"
            title={previousLabel}
            autoFocus={focusOnPrev}
            onClick={() =>
              dispatchOnPrevious({ onPrevious, modalId, item, index })
            }
            onKeyDown={onRightLeftKeyDown({ item, index })}
            className={classNames(classes.previous, classes.previousNext)}
            {...PreviousBtnProps}
          >
            <ChevronLeftIcon />
            <span className={classes.buttonLabel}>Previous</span>
          </Fab>
        )}
        {displayNextBtn && (
          <Fab
            color="primary"
            title={nextLabel}
            autoFocus={focusOnNext}
            onClick={() => dispatchOnNext({ onNext, modalId, item, index })}
            onKeyDown={onRightLeftKeyDown({ item, index })}
            className={classNames(classes.next, classes.previousNext)}
            {...NextBtnProps}
          >
            <ChevronRightIcon />
            <span className={classes.buttonLabel}>Next</span>
          </Fab>
        )}
        {activeIndex >= 0 && noOfItems > 1 && (
          <Typography color="inherit" variant="h5" className={classes.index}>
            {`${activeIndex + 1} / ${noOfItems}`}
          </Typography>
        )}

        {Slider}
      </div>
    );
  }

  return Slider;
};

export default withStyles(styles)(Transition);
