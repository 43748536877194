import WellboreTrajectoryDetailed3DView from 'app/components/WellboreTrajectoryDetailed3DView/WellboreTrajectoryDetailed3DView';
import useLatestProjectWellboreDetail from 'features/projects/hooks/useLatestProjectWellboreDetails';
import WellboreTrajectoryLegends from 'app/components/WellboreTrajectory/WellboreTrajectoryLegends';
import NavigationHelpTooltip from 'app/components/WellboreContextualization/NavigationHelpTooltip';
import useProjectWellboreTrajectory from 'features/wells/hooks/useProjectWellboreTrajectory';
import { getTargetDepthFromState } from 'features/projects/activities/activities.selectors';
import { getProjectWellbore } from 'features/projects/wellbore/projectWellbore.actions';
import Environment from 'app/components/WellboreTrajectoryDetailed3DView/Environment';
import { convertMetric } from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import React, {
  useCallback,
  useEffect,
  useState,
  Suspense,
  useMemo,
} from 'react';
import Loader from 'app/components/WellboreTrajectoryDetailed3DView/Loader';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  EMPTY_MAP,
  EMPTY_QUANTITY,
  PUBLIC_ASSETS_URL,
} from 'app/app.constants';
import { Grid, CircularProgress, Box } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import * as colors from 'layout/colors';
import takeLast from 'lodash/last';
import * as THREE from 'three';

const WellboreTrajectoryDetailed3DContainer = ({
  classes,
  curve,
  projectId,
  BHA_LENGTH,
  hideHelp = false,
  wellboreSections,
  registerDataPointsHandler,
  children = null,
  materialMap,
  maxOD,
  toolsByToolstringToolId,
}) => {
  const [_domElement, setDomElement] = useState();
  const [help, setHelp] = useState();
  const rootRef = useCallback((node) => {
    if (node) {
      setDomElement(node);
    }
  }, []);
  const [currentDepth, setCurrentDepth] = useState(EMPTY_QUANTITY);

  /** Mocking the current depth simulation for gradual change
   * Keep these lines commented while pushing to dev
   */
  // const [currentDepth, setCurrentDepth] = useState({
  //   unit: 'm',
  //   value: 1210,
  //   hasValue: true,
  // });

  // useEffect(() => {
  //   if (currentDepth && targetDepth && currentDepth.value <= targetDepth.value) {
  //     setTimeout(() => setCurrentDepth({ ...currentDepth, value: currentDepth.value + 0.3 }), 1000)
  //   }
  // }, [currentDepth])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectWellbore(projectId));
  }, [dispatch, projectId]);

  const targetDepth = useSelector(getTargetDepthFromState);

  const onReceiveInitialData = useCallback(
    (readings) => {
      if (!curve) return;

      const reading = readings.find(
        (reading) => reading.name === curve.get('name'),
      );

      var latestDepthData = takeLast(reading.data);

      if (!latestDepthData) return;

      const [_timestamp, _depth, value] = latestDepthData;

      setCurrentDepth({
        hasValue: true,
        unit: curve.get('unit'),
        value: value,
      });
    },
    [curve, setCurrentDepth],
  );

  const onReceiveData = useCallback(
    (readings) => {
      if (!curve) return;

      const reading = readings.find(
        (reading) => reading.name === curve.get('name'),
      );

      if (!reading) return;
      if (!reading.data[0]) return;

      const [_timestamp, _depth, value] = reading.data[0];

      setCurrentDepth({
        hasValue: true,
        unit: curve.get('unit'),
        value: value,
      });
    },
    [curve],
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveInitialData, onReceiveData);
  }, [onReceiveData, onReceiveInitialData, registerDataPointsHandler]);

  const [latestWellboreDetails = EMPTY_MAP, wellDetailsDataState] =
    useLatestProjectWellboreDetail(projectId);

  const [trajectory = EMPTY_MAP, trajectoryDataState] =
    useProjectWellboreTrajectory(projectId);

  const dataState = getSummarizedDataStateFromState([
    wellDetailsDataState,
    trajectoryDataState,
  ]);

  const legends = useMemo(
    () => [
      {
        depth: currentDepth?.value,
        name: 'Current depth',
        unit: currentDepth?.unit,
        color: colors.dashboard.depth,
      },
    ],
    [currentDepth],
  );

  useEffect(() => {
    prepareHelpData();
  }, []);

  const prepareHelpData = () => {
    setHelp({
      title: 'HOW TO INTERACT WITH 3D BHA?',
      data: [
        {
          navType: '1- Dragging around the BHA:',
          content: 'Use simple dragging to go up and down on the BHA',
          src: `${PUBLIC_ASSETS_URL}/images/navigation/navigate.png`,
        },
        {
          navType: '2- Zoom in and out:',
          content: (
            <ul>
              <li>
                Touchpad: Use two fingers and move them closer to zoom in
                further to zoom out
              </li>
              <li>
                Mouse: Scroll forward to zoom in and backward to zoom out.
              </li>
            </ul>
          ),
          src: `${PUBLIC_ASSETS_URL}/images/navigation/zoomIn.png`,
        },
      ],
    });
  };

  if (dataState.isLoading()) {
    return (
      <Grid
        xs
        item
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (!trajectory.size) return children;

  return (
    <>
      <Box
        ref={rootRef}
        className={classes.root}
        onClick={(event) => {
          // Do not propagate click events that happen on the scene (like when clicking to orbit around)
          event.stopPropagation();
        }}
      >
        {!hideHelp && (
          <Box className={classes.help}>
            {help && <NavigationHelpTooltip data={help} />}
          </Box>
        )}
        <Box className={classes.legend}>
          <WellboreTrajectoryLegends legends={legends} />
        </Box>
      </Box>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <Canvas
          perspective
          camera={{
            near: 0.1,
            far: convertMetric(BHA_LENGTH.value, BHA_LENGTH.unit) * 3,
            position: [
              0,
              0,
              convertMetric(BHA_LENGTH.value, BHA_LENGTH.unit) / 1.5,
            ],
          }}
        >
          <Suspense fallback={<Loader />}>
            <ambientLight args={[0x000000, 1]} />
            <Environment />
            <OrbitControls
              enablePan={true}
              enableZoom={true}
              enableRotate={false}
              screenSpacePanning={true}
              maxDistance={convertMetric(BHA_LENGTH.value, BHA_LENGTH.unit)}
              minZoom={convertMetric(BHA_LENGTH.value, BHA_LENGTH.unit)}
              mouseButtons={{ LEFT: THREE.MOUSE.PAN }}
              enableDamping={true}
              keyPanSpeed={0.01}
              dampingFactor={1.0}
            />
            <WellboreTrajectoryDetailed3DView
              trajectory={trajectory}
              wellboreDetail={latestWellboreDetails}
              targetDepth={targetDepth}
              BHA_LENGTH={BHA_LENGTH}
              cDepth={currentDepth}
              wellboreSections={wellboreSections}
              materialMap={materialMap}
              maxOD={maxOD}
              toolsByToolstringToolId={toolsByToolstringToolId}
            />
          </Suspense>
        </Canvas>
      </div>
    </>
  );
};

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
  },
  legend: {
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  help: {
    position: 'absolute',
    top: theme.spacing(5),
    left: theme.spacing(1),
    zIndex: 1,
  },
});

export default withStyles(styles)(WellboreTrajectoryDetailed3DContainer);
