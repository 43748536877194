import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import { RiskMatrix, EMPTY_LIST } from 'app/app.constants';

const RiskMatrixComponent = ({ classes, allRisks = EMPTY_LIST }) => {
  const getBackgroundColor = (value) => {
    switch (value) {
      case 'Red':
        return '#F44336';
      case 'Yellow':
        return '#F4FF81';
      case 'Green':
        return '#4CAF50';
      default:
        return '';
    }
  };

  const getIntersectionNumber = (rowIndex, cellIndex) => {
    const consequenceValue = rowIndex + 1;
    const probabilityValue = 6 - cellIndex;

    let count = 0;
    for (let risk of allRisks) {
      if (
        risk.get('consequence') === consequenceValue &&
        risk.get('probability') === probabilityValue
      ) {
        count++;
      }
    }
    return count || null;
  };

  const ReversedRiskMatrix = [...RiskMatrix];
  ReversedRiskMatrix[0] = ['', ...ReversedRiskMatrix[0].slice(1).reverse()];
  for (let i = 1; i < ReversedRiskMatrix.length; i++) {
    ReversedRiskMatrix[i] = [
      ReversedRiskMatrix[i][0],
      ...ReversedRiskMatrix[i].slice(1).reverse(),
    ];
  }

  return (
    <Table className={classes.tableSpacing}>
      <TableHead>
        <TableRow className={classes.headerRowSpacing}>
          <TableCell
            colSpan={2}
            className={`${classes.borderNone}`}
          ></TableCell>
          <TableCell
            colSpan={ReversedRiskMatrix[0].length - 1}
            className={`${classes.centeredContent} ${classes.roundedCell} ${classes.columnWidth}`}
            style={{ border: 'none', backgroundColor: '#212121' }}
          >
            <Typography variant="h6">Probability</Typography>
          </TableCell>
        </TableRow>
        <TableRow className={classes.headerRowSpacing}>
          {ReversedRiskMatrix[0].map((header, index) => {
            if (header === 'N/A') {
              return <TableCell key={index} style={{ display: 'none' }} />;
            }
            const [symbol, name] = header.split('-');
            const cellClasses = [classes.roundedCell];
            cellClasses.push(
              classes.myToolbar,
              classes.centeredContent,
              classes.borderNone,
              classes.columnWidth,
            );

            if (index === 0) {
              return (
                <TableCell
                  key={index}
                  className={`${classes.borderNone} ${classes.columnWidth}`}
                  colSpan={2}
                ></TableCell>
              );
            }
            return (
              <TableCell key={index} className={cellClasses.join(' ')}>
                <Typography variant="h7">
                  {symbol}
                  <br />
                  {name}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {ReversedRiskMatrix.slice(1).map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {rowIndex === 0 && (
              <TableCell
                key={'consequence_matrix'}
                className={`${classes.consequence} ${classes.borderNone} ${classes.centeredContent} ${classes.roundedCell}`}
                style={{ border: 'none', backgroundColor: '#212121' }}
                rowSpan={ReversedRiskMatrix[0].length}
                colSpan={1}
              >
                <Typography variant="h6">Consequence</Typography>
              </TableCell>
            )}
            {row.map((cell, cellIndex) => {
              const cellClasses = [classes.roundedCell];
              const [symbol, name] = cell.split('-');

              if (cell === 'N/A')
                return (
                  <TableCell
                    key={cellIndex}
                    className={`${classes.columnWidth}`}
                  />
                );

              if (cellIndex === 0) {
                return (
                  <TableCell
                    key={cellIndex}
                    className={`${classes.borderNone} ${classes.centeredContent} ${classes.roundedCell} ${classes.myToolbar} ${classes.columnWidth}`}
                    style={{
                      backgroundColor: getBackgroundColor(cell),
                    }}
                  >
                    <Typography variant="h7">
                      {symbol}
                      <br />
                      {name}
                    </Typography>
                  </TableCell>
                );
              } else cellClasses.push(classes.coloredCell);

              const number = getIntersectionNumber(rowIndex, cellIndex);
              return (
                <TableCell
                  key={cellIndex}
                  className={`${classes.borderNone} ${classes.roundedCell}`}
                  style={{
                    backgroundColor: getBackgroundColor(cell),
                    textAlign: 'center',
                    verticalAlign: 'middle',
                  }}
                >
                  <div
                    style={{ display: 'inline-block', lineHeight: 'normal' }}
                  >
                    <Avatar
                      className={classes.circle}
                      style={{ opacity: number ? 1 : 0 }}
                    >
                      {number || 0}
                    </Avatar>
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

// previously defined as
// const styles = (theme) => ({..})
// Removed argument theme due to lint warning
const styles = () => ({
  myToolbar: {
    borderRadius: '4px',
    backgroundColor: '#303030',
    border: 'none',
  },
  tableSpacing: {
    borderSpacing: '6px',
    borderCollapse: 'separate',
    margin: '0 auto',
  },
  columnWidth: {
    width: '100vw',
  },
  circle: {
    backgroundColor: 'black',
    color: 'white',
    width: '5vw',
    height: '5vw',
    maxWidth: '60px',
    maxHeight: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.9rem',
  },
  roundedCell: {
    borderRadius: '4px',
    border: 'none',
  },
  coloredCell: (props) => ({
    backgroundColor: props.color,
  }),
  centeredContent: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  consequence: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '40px',
  },
  borderNone: {
    border: 'none',
  },
  headerRowSpacing: {
    borderSpacing: '0px !important',
  },
});

export default compose(withStyles(styles))(RiskMatrixComponent);
